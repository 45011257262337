import React, { useState, useEffect } from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col,Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import Api from "../../../Api";

function UniversityName() {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const universityTypes = ["Private","Public", "Government"];
  const grades = ["A", "A+", "A++", "B", "B+", "B++", "C", "D"];

  const [countryList, setCountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setStateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setDistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  const [cityValue, setCityValue] = useState();
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    getCountry();
  }, []);

  const handleCreateFormSubmit = async (data) => {
    const userDetails = {
      UniversityName: getValues().UniversityName,
      UniversityType: getValues().UniversityType,
      Grade: getValues().Grade,
      Country: getValues().Country,
      State: getValues().State,
      District: getValues().District,
      City: getValues().City,
      Address: getValues().Address,
      Pincode: getValues().Pincode,
      PhoneNumber: getValues().PhoneNumber,
      Email: getValues().Email,
      WebsiteLink: getValues().WebsiteLink,
    };

    await Api.post(`fields/createUniversityList`, userDetails)
      .then((resp) => {
        console.log("resp.data", resp.data);
      })
      .catch((error) => console.log(error));
  };

  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setCountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setStateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setDistrictList(res.data.data);
    });
  };

  const getCity = (districtId) => {
    setDistrictValue(districtId);
    Api.get(`city/cityById/${districtId}`).then((res) => {
      setCityList(res.data.data);
    });
  };

  const [universityType, setUniversityType] = useState(null);
  const [grade, setGrade] = useState(null);

  return (
    <div>
      <Container className="content-style">
        <Row style={{ marginLeft: "5%" }}>
          <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
            <div className="pages-title mt-3">Create University:</div>
            <Row
              className="mt-4"
              style={{ justifyContent: "flex-start", marginLeft: "5%" }}
            >
              {/* University Name */}
              <Col sm={12} md={4}>
                <div>
                  <label className="create-title">University Name</label>
                  <input
                    {...register("UniversityName", { required: true })}
                    className="Create-input"
                  />
                  {errors.UniversityName && (
                    <p className="error-text-color">
                      University Name is required
                    </p>
                  )}
                </div>
              </Col>

              {/* University Type */}
              <Col sm={12} md={4}>
                <div>
                  <label className="create-title">University Type</label>
                  <Dropdown
                    value={universityType}
                    options={universityTypes}
                    placeholder="Select Type"
                    onChange={(e) => {
                      setUniversityType(e.value); // Update local state
                      setValue("UniversityType", e.value); // Update react-hook-form value
                    }}
                    className="Create-input"
                  />
                  {errors.UniversityType && (
                    <p className="error-text-color">
                      University Type is required
                    </p>
                  )}
                </div>
              </Col>

              {/* Country */}
              <Col sm={12} md={4}>
                <label className="mb-1 font-sign-log">Country</label>
                <Dropdown
                  filter
                  className="Create-input"
                  value={countryValue}
                  options={countryList}
                  {...register("Country", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  onChange={(e) => getState(e.value)}
                />
                {errors.Country && (
                  <p className="error-text-color">Country is required</p>
                )}
              </Col>

              {/* State */}
              <Col sm={12} md={4}>
                <label className="mb-1 font-sign-log">State</label>
                <Dropdown
                  filter
                  className="Create-input"
                  value={stateValue}
                  options={stateList}
                  {...register("State", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  onChange={(e) => getDistrict(e.value)}
                />
                {errors.State && (
                  <p className="error-text-color">State is required</p>
                )}
              </Col>

              {/* District */}
              <Col sm={12} md={4}>
                <label className="mb-1 font-sign-log">District</label>
                <Dropdown
                  filter
                  className="Create-input"
                  value={districtValue}
                  options={districtList}
                  {...register("District", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  onChange={(e) => getCity(e.value)}
                />
                {errors.District && (
                  <p className="error-text-color">District is required</p>
                )}
              </Col>

              {/* City */}
              <Col sm={12} md={4}>
                <label className="mb-1 font-sign-log">City</label>
                <Dropdown
                  filter
                  className="Create-input"
                  value={cityValue}
                  options={cityList}
                  {...register("City", { required: true })}
                  optionLabel={"cityName"}
                  optionValue={"_id"}
                  onChange={(e) => setCityValue(e.value)}
                />
                {errors.City && (
                  <p className="error-text-color">City is required</p>
                )}
              </Col>

              {/* Address */}
              <Col sm={12} md={4}>
                <label className="mb-1 font-sign-log">Address</label>
                <input
                  type="text"
                 className="Create-input"
                  {...register("Address", { required: true })}
                />
                {errors.Address?.type === "required" && (
                  <p className="error-text-color">Address is required</p>
                )}
              </Col>

              {/* Pincode */}
              <Col sm={12} md={4}>
                <label className="mb-1 font-sign-log">Pincode</label>
                <input
                  type="number"
                  className="Create-input"
                  {...register("Pincode", {
                    required: true,
                    minLength: 6,
                    maxLength: 6,
                  })}
                />
                {errors.Pincode?.type === "required" && (
                  <p className="error-text-color">Pincode is required</p>
                )}
                {errors.Pincode?.type === "minLength" && (
                  <p className="error-text-color">6 digit only</p>
                )}
                {errors.Pincode?.type === "maxLength" && (
                  <p className="error-text-color">6 digit only</p>
                )}
              </Col>

              {/* Phone Number */}
              <Col sm={12} md={4}>
                <div>
                  <label className="create-title">Phone Number</label>
                  <input
                    type="tel"
                    {...register("PhoneNumber", {
                      required: true,
                      pattern: /^\d{10}$/,
                    })}
                    className="Create-input"
                  />
                  {errors.PhoneNumber && (
                    <p className="error-text-color">
                      Valid Phone Number is required
                    </p>
                  )}
                </div>
              </Col>

              {/* Email */}
              <Col sm={12} md={4}>
                <div>
                  <label className="create-title">Email</label>
                  <input
                    type="email"
                    {...register("Email", { required: true })}
                    className="Create-input"
                  />
                  {errors.Email && (
                    <p className="error-text-color">Valid Email is required</p>
                  )}
                </div>
              </Col>

              {/* Website Link */}
              <Col sm={12} md={4}>
                <div>
                  <label className="create-title">Website Link</label>
                  <input
                    type="url"
                    {...register("WebsiteLink", { required: true })}
                    className="Create-input"
                  />
                  {errors.WebsiteLink && (
                    <p className="error-text-color">Valid URL is required</p>
                  )}
                </div>
              </Col>

              {/* Grade */}
              <Col sm={12} md={4}>
                <div>
                  <label className="create-title">Grade</label>
                  <Dropdown
                    value={grade}
                    options={grades}
                    placeholder="Select Grade"
                    onChange={(e) => {
                      setGrade(e.value);
                      setValue("Grade", e.value);
                    }}
                    className="Create-input"
                  />
                  {errors.Grade && (
                    <p className="error-text-color">Grade is required</p>
                  )}
                </div>
              </Col>
            </Row>
            <div className="submitbuttons p-2">
              <button
                className="button1 m-2 p-2"
                type="submit"
              >
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
        </Row>
      </Container>
    </div>
  );
}

export default UniversityName;

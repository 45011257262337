import React from "react";
import AdminDashboard from "../Pages/Admin/AdminDashboard/index";
import EmployerApprovalList from "../Pages/Admin/Dashboard/EmployerList";
import SchoolApprovalList from "../Pages/Admin/Dashboard/SchoolList";
import Blogslist from "../Pages/Admin/DashboardTablelist/Bloglist";
import Advertisementlist from "../Pages/Admin/DashboardTablelist/Advertismentlist";
import Jobpostlist from "../Pages/Admin/DashboardTablelist/Jobpostlist";
import Intershiprequest from "../Pages/Admin/DashboardTablelist/Intershiprequest";
import AboutUs from "../Pages/Admin/CreateFields/CreateAboutUs";
import Companylist from "../Pages/Admin/DashboardTablelist/Companylist";
import CompanyListForm from "../Pages/Admin/AdminCompanyList/CompanyListForm";
import CreateAboutUs from "../Pages/Admin/CreateFields/CreateAboutUs";
import FreelancingCarousel from "../Pages/Admin/Dashboard/AdminSidebar/FreelancingCarousel/FreelancingCarousel";
import FreelancingCarouselAdmin from "../Pages/Admin/Dashboard/AdminSidebar/FreelancingCarouselAdmin/FreelancingCarouselAdmin";
//exam
import ExamPatternList from "../Pages/Exam/Exampattern/ExamPattern";
import PatternCreate from "../Pages/Exam/Exampattern/PatternCreate";
import QuestionBank from "../Pages/Exam/QuestionBank/QuestionBank";
import QuestionCreate from "../Pages/Exam/QuestionBank/QuestionCreate";
import QuestionBankWeek from "../Pages/Exam/QuestionBank/QuestionBankWeek";
import QuestionBankQuestion from "../Pages/Exam/QuestionBank/QuestionBankQuestion";
import CourseList from "../Pages/Exam/Course/Course";
import CourseCreate from "../Pages/Exam/Course/CourseCreate";
import TechnologyList from "../Pages/Exam/Technology/Technology";
import TechnologyCreate from "../Pages/Exam/Technology/TechnologyCreate";
import Score from "../Pages/Admin/AdminDashboard/Score";
import ProfessionaList from "../Pages/Admin/Dashboard/ProfessionalList/ProfessionaList";
import ProfessionalDetails from "../Pages/Admin/Dashboard/ProfessionalList/ProfessionalDetails";
import CollegeList from "../Pages/Admin/Dashboard/CollegeList/CollegeList";
import CollegeDetails from "../Pages/Admin/Dashboard/CollegeList/CollegeDetails";
import StudentList from "../Pages/Admin/Dashboard/StudentList/StudentList";
import StudentDetails from "../Pages/Admin/Dashboard/StudentList/StudentDetails";
import InstituteList from "../Pages/Admin/Dashboard/TrainingInstitutionList/InstituteList";
import InstituteDetails from "../Pages/Admin/Dashboard/TrainingInstitutionList/InstituteDetails";
import List from "../Pages/Admin/HomeStudentList/List";
import Create from "../Pages/Admin/HomeStudentList/Create";
import CarouselList from "../Pages/Admin/CarouselList/List";
import CarouselCreate from "../Pages/Admin/CarouselList/Create";
import UniversityName from "../Pages/Admin/Fields/UniversityName";
import CollegeName from "../Pages/Admin/Fields/CollegeName";
import CompanyLogo from "../Pages/Admin/Dashboard/CompanyLogo/CompanyLogo";
import CompanyDetails from "../Pages/Admin/Dashboard/CompanyLogo/CompanyDetails";
import CompanyApprovedList from "../Pages/Admin/Dashboard/CompanyLogo/CompanyApprovedList";
import TrainerList from "../Pages/Exam/Trainer/Trainer";
import TrainerCreate from "../Pages/Exam/Trainer/TrainerCreate";
import BatchList from "../Pages/Exam/Batch/BatchList";
import BatchCreate from "../Pages/Exam/Batch/BatchCreate";
import LanguageAdded from "../Pages/Admin/Fields/LanguageAdded";
import LocationAdded from "../Pages/Admin/Fields/LocationAdded";
import QualificationAdded from "../Pages/Admin/Fields/QualificationAdded";
import OurEmployess from "../Pages/Admin/Resource/OurEmployees/OurEmployeesTable";
import ContactorStaffing from "../Pages/Admin/Resource/ContactorStaffing/ContactorStaffing";
import VendorList from "../Pages/Admin/Dashboard/VendorList/VendorList";
import TrainersList from "../Pages/Admin/Dashboard/TrainersList/TrainersList";
import OurEmployees from "../Pages/Admin/Resource/OurEmployees/OurEmployess";
import ContactorStaffingTable from "../Pages/Admin/Resource/ContactorStaffing/ContactorStaffingTable";
import Student from "../Pages/Admin/Packages/Student";
import Company from "../Pages/Admin/Packages/Company";
import Vendor from "../Pages/Admin/Packages/Vendor";
import Professional from "../Pages/Admin/Packages/Professional";
import District from "../Pages/Admin/Fields/District";
// import careerblitzFreelancing from "../Pages/Admin/careerblitz/careerblitzFreelancing/careerblitzFreelancing";
import JobPost from "../Pages/Admin/Feril/JobPost/JobPost";
import ProjectPost from "../Pages/Admin/Feril/ProjectJob/ProjectPost";
import FerilFreelancinglist from "../Pages/Admin/Feril/FerilFreelancing/FerilFreelancinglist";
import JobPostList from "../Pages/Admin/Feril/JobPost/JobPostList";
import ProjectPostList from "../Pages/Admin/Feril/ProjectJob/ProjectPostList";
import Request from "../Pages/Admin/Feril/ContactorStaff/Request";
import Post from "../Pages/Admin/Feril/ContactorStaff/Post";
// import RequestTable from "../Pages/Admin/careerblitz/ContactorStaff/RequestTable";
// import PostTable from "../Pages/Admin/careerblitz/ContactorStaff/PostTable";
import Area from "../Pages/Admin/Fields/Area";
import RequestTable from "../Pages/Admin/Feril/ContactorStaff/RequestTable";
import PostTable from "../Pages/Admin/Feril/ContactorStaff/PostTable";
// import ActiveClients from "../Pages/Admin/Resource/ContactorStaffing/ActiveClients/ActiveClients";
// import InactiveClients from "../Pages/Admin/Resource/ContactorStaffing/InactiveClients/InactiveClients";
// import ActiveEmployee from "../Pages/Admin/Resource/OurEmployees/ActiveEmployee/ActiveEmployee";
// import InActiveEmployee from "../Pages/Admin/Resource/OurEmployees/InActiveEmployee/InActiveEmployee";
import StudentBlog from "../Pages/Admin/Blogs/StudentsBlog";
import StudentBlogDetails from "../Pages/Admin/Blogs/StudentBlogDetails";
import EmployerDetails from "../Pages/Admin/Dashboard/EmployerList/EmployerListDetails";
import ApprovedBlog from "../Pages/Admin/Blogs/ApprovedBlogList";

import { Navigate } from "react-router-dom";
import CategoryAdded from "../Pages/Admin/Fields/CategoryAdded";
import Curriculam from "../Pages/Exam/Curriculam/Curriculam";
import CreateCurriculam from "../Pages/Exam/Curriculam/CreateCurriculam";
import CreateSchool from "../Pages/Admin/Fields/CreateSchool";
import AddSector from "../Pages/Admin/CourseFields/AddSector";
import AddDomain from "../Pages/Admin/CourseFields/AddDomain";
import AddDomainForm from "../Pages/Admin/CourseFields/AddDomainForm";
import AddCategory from "../Pages/Admin/CourseFields/AddCategory";
import AddSubCategory from "../Pages/Admin/CourseFields/AddSubCategory";
import AddCategoryTable from "../Pages/Admin/CourseFields/AddCategoryTable";
import ManageSectors from "../Pages/Admin/CourseFields/AddSector";
import AddSectorForm from "../Pages/Admin/CourseFields/AddSectorForm";
import AddNewSubCategory from "../Pages/Admin/CourseFields/AddNewSubCategory";
import AppliedStudents from "../Pages/Exam/AplliedStudents";
import AppliedStudentsTable from "../Pages/Exam/AppliedStudentsTable";
import UsersTab from "../Pages/Admin/Dashboard/AdminSidebar/Users/UsersTab";
import AdvertaismentUpload from "../Pages/Admin/Dashboard/AdminSidebar/Advertaisment/Advertaisment";
import ManageAds from "../Pages/Admin/Dashboard/AdminSidebar/Advertaisment/Advertaisment";
import AdvertaismentEdit from "../Pages/Admin/Dashboard/AdminSidebar/Advertaisment/AdvertaismentEdit";
import ManageCourses from "../Pages/Admin/Dashboard/AdminSidebar/Manage Courses/ManageCourses";
import ManageBatches from "../Pages/Admin/Dashboard/AdminSidebar/Manage Courses/BatchMange";
import ViewAppliedStudents from "../Pages/Admin/Dashboard/AdminSidebar/Manage Courses/ViewAppliedStudents";
import ExamsTabs from "../Pages/Admin/Dashboard/AdminSidebar/ExamsTabs/ExamsTabs";
import ResourceTabs from "../Pages/Admin/Dashboard/AdminSidebar/ResourceTabs/ResourceTabs";
import CareerBlitzTabs from "../Pages/Admin/Dashboard/AdminSidebar/CareerBlitzTabs/CareerBlitzTabs";
import DisplayEdit from "../Pages/Admin/Dashboard/AdminSidebar/Display/DisplayEdit";
import DisplayAds from "../Pages/Admin/Dashboard/AdminSidebar/Display/Display";
import BlogForm from "../Pages/Admin/AdminBlog/AdminBlogform";
import ManageBlogs from "../Pages/Admin/AdminBlog/AdminBlogManage";


const role = localStorage.getItem("Role");

const AdminRoutes = [
  {
    path: "/admin",
    name: "AdminDashboard",
    element: role === "admin" ? <AdminDashboard /> : <Navigate to="/login" />,
  },

  {
    path: "/admin/managesector",
    name: "Sector",
    element: role === "admin" ? <ManageSectors /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/addsector",
    name: "Sector",
    element: role === "admin" ? <AddSectorForm /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/createblog",
    name: "Sector",
    element: role === "admin" ? <BlogForm /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/manageblog",
    name: "Sector",
    element: role === "admin" ? <ManageBlogs /> : <Navigate to="/login" />,
    
  },
  {
    path: "/admin/editblog/:id",
    name: "Sector",
    element: role === "admin" ? <BlogForm /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/manageads",
    name: "advertaisment",
    element: role === "admin" ? <ManageAds /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/adedit/:adId",
    name: "advertaisment",
    element: role === "admin" ? <AdvertaismentEdit /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/adcreate",
    name: "advertaisment",
    element: role === "admin" ? <AdvertaismentEdit /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/displayads",
    name: "display",
    element: role === "admin" ? <DisplayAds /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/displaycardedit/:adId",
    name: "display",
    element: role === "admin" ? <DisplayEdit /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/displaycardcreate",
    name: "display",
    element: role === "admin" ? <DisplayEdit /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/editsector/:sectorId",
    name: "Sector",
    element: role === "admin" ? <AddSectorForm /> : <Navigate to="/login" />,
  },

  {
    path: "/admin/domain",
    name: "Sector",
    element: role === "admin" ? <AddDomain /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/adddomainform",
    name: "Sector",
    element: role === "admin" ? <AddDomainForm /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/editdomain/:domainId",
    name: "Sector",
    element: role === "admin" ? <AddDomainForm /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/category",
    name: "Sector",
    element: role === "admin" ? <AddCategory /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/subcategory",
    name: "Sector",
    element: role === "admin" ? <AddSubCategory /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/categoryTable",
    name: "Sector",
    element: role === "admin" ? <AddCategoryTable /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/addSubCategory",
    name: "Sector",
    element:
      role === "admin" ? <AddNewSubCategory /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/editSubCategory/:id",
    name: "Sector",
    element:
      role === "admin" ? <AddNewSubCategory /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/category",
    name: "Sector",
    element: role === "admin" ? <AddCategory /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/subcategory",
    name: "Sector",
    element: role === "admin" ? <AddSubCategory /> : <Navigate to="/login" />,
  },

  {
    path: "Companylist",
    name: "Companylist",
    element: role === "admin" ? <Companylist /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/Companylist/Companyform/:id",
    name: "Companyform",
    element: role === "admin" ? <CompanyListForm /> : <Navigate to="/login" />,
  },

  {
    path: "/admin/Companylist/approved/",
    name: "Companyform",
    element: role === "admin" ? <ApprovedBlog /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/studentblog/approvedblog",
    name: "ApprovedBlog",
    element: role === "admin" ? <ApprovedBlog /> : <Navigate to="/login" />,
  },
  // {
  //   path: "/admin/Companylist/Companyform",
  //   name: "Companyform",
  //   element: <CompanyListForm />,
  // },
  {
    path: "Blogs/Blogform",
    name: "Blog",
    element: role === "admin" ? <ApprovedBlog /> : <Navigate to="/login" />,
  },
  {
    path: "StudentList",
    name: "StudentApprovalList",
    // element: <StudentApprovalList />,
    element: role === "admin" ? <StudentList /> : <Navigate to="/login" />,
  },
  {
    path: "StudentList/details/:id",
    name: "StudentApprovalList",
    // element: <StudentApprovalList />,
    element: role === "admin" ? <StudentDetails /> : <Navigate to="/login" />,
  },
  {
    path: "student/studentblog/:id",
    name: "StudentBlogDetails",
    // element: <StudentApprovalList />,
    element:
      role === "admin" ? <StudentBlogDetails /> : <Navigate to="/login" />,
  },

  {
    path: "ProfessionalList",
    name: "ProfessionalApprovalList",
    // element: <ProfessionalApprovalList />,
    element: role === "admin" ? <ProfessionaList /> : <Navigate to="/login" />,
  },
  {
    path: "ProfessionalList/details/:id",
    name: "ProfessionalListDetails",
    element:
      role === "admin" ? <ProfessionalDetails /> : <Navigate to="/login" />,
  },
  {
    path: "EmployerList",
    name: "EmployerApprovalList",
    element:
      role === "admin" ? <EmployerApprovalList /> : <Navigate to="/login" />,
  },
  {
    path: "EmployerList/details/:id",
    name: "EmployerListDetails",
    element: role === "admin" ? <EmployerDetails /> : <Navigate to="/login" />,
  },
  {
    path: "SchoolList",
    name: "SchoolApprovalList",
    element:
      role === "admin" ? <SchoolApprovalList /> : <Navigate to="/login" />,
    // element: <InstituteList />,
  },
  {
    path: "CollegeList",
    name: "CollegeApprovalList",
    // element: <CollegeApprovalList />,
    element: role === "admin" ? <CollegeList /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/companylogo",
    name: "companylogo",
    element: role === "admin" ? <CompanyLogo /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/StudentList",
    name: "users",
    element: role === "admin" ? <UsersTab /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/exams",
    name: "exams",
    element: role === "admin" ? <ExamsTabs /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/resources",
    name: "exams",
    element: role === "admin" ? <ResourceTabs /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/careerblitz",
    name: "careerblitz",
    element: role === "admin" ? <CareerBlitzTabs /> : <Navigate to="/login" />,
  },
  {
    path: "Companylist/details/:id",
    name: "CompanyListDetails",
    element: role === "admin" ? <CompanyDetails /> : <Navigate to="/login" />,
  },
  {
    path: "CollegeList/details/:id",
    name: "CollegeDetails",
    element: role === "admin" ? <CollegeDetails /> : <Navigate to="/login" />,
  },
  {
    path: "TrainingInstitutionList",
    name: "TrainingInstitutionList",
    // element: role === "admin" ? : <Navigate to="/login"/>  <TrainingInstitutionList />,
    element: role === "admin" ? <InstituteList /> : <Navigate to="/login" />,
  },
  {
    path: "TrainingInstitutionList/details/:id",
    name: "TrainingInstitutionListDetails",
    element: role === "admin" ? <InstituteDetails /> : <Navigate to="/login" />,
  },
  {
    path: "Blogs",
    name: "Blogs",
    element: role === "admin" ? <Blogslist /> : <Navigate to="/login" />,
  },
  {
    path: "Advertisementlist",
    name: "Advertisementlist",
    element:
      role === "admin" ? <Advertisementlist /> : <Navigate to="/login" />,
  },
  {
    path: "Jobpostlist",
    name: "Jobpostlist",
    element: role === "admin" ? <Jobpostlist /> : <Navigate to="/login" />,
  },

  // exam

  {
    path: "exampattern",
    name: "exampattern",
    element: role === "admin" ? <ExamPatternList /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/managecourses",
    name: "managecourses",
    element: role === "admin" ? <ManageCourses /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/batchmanagement/:id",
    name: "batchmanagement",
    element: role === "admin" ? <ManageBatches /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/viewappliedstudents",
    name: "viewaplliedstudents",
    element: role === "admin" ? <ViewAppliedStudents /> : <Navigate to="/login" />,
  },
  {
    path: "exampattern/PatternCreate",
    name: "PatternCreate",
    element: role === "admin" ? <PatternCreate /> : <Navigate to="/login" />,
  },
  {
    path: "Question",
    name: "QuestionBank",
    element: role === "admin" ? <QuestionBank /> : <Navigate to="/login" />,
  },
  {
    path: "Question/qcreate",
    name: "QuestionBank",
    element: role === "admin" ? <QuestionCreate /> : <Navigate to="/login" />,
  },
  {
    path: "Question/week/qcreate",
    name: "QuestionBank",
    element: role === "admin" ? <QuestionCreate /> : <Navigate to="/login" />,
  },
  {
    path: "Question/week/questionBank/qcreate",
    name: "QuestionBank",
    element: role === "admin" ? <QuestionCreate /> : <Navigate to="/login" />,
  },
  {
    path: "Question/week",
    name: "QuestionBankWeek",
    element: role === "admin" ? <QuestionBankWeek /> : <Navigate to="/login" />,
  },
  {
    path: "Question/week/questionBank",
    name: "QuestionBankQuestion",
    element:
      role === "admin" ? <QuestionBankQuestion /> : <Navigate to="/login" />,
  },
  {
    path: "Technology",
    name: "Technology",
    element: role === "admin" ? <TechnologyList /> : <Navigate to="/login" />,
  },
  {
    path: "Technology/create",
    name: "Technologycreate",
    element: role === "admin" ? <TechnologyCreate /> : <Navigate to="/login" />,
  },
  {
    path: "examcourse",
    name: "examcourse",
    element: role === "admin" ? <CourseList /> : <Navigate to="/login" />,
  },
  {
    path: "examcourse/create",
    name: "Coursecreate",
    element: role === "admin" ? <CourseCreate /> : <Navigate to="/login" />,
  },
  {
    path: "Trainer",
    name: "Trainer",
    element: role === "admin" ? <TrainerList /> : <Navigate to="/login" />,
  },
  {
    path: "Trainer/create",
    name: "Trainercreate",
    element: role === "admin" ? <TrainerCreate /> : <Navigate to="/login" />,
  },
  {
    path: "Batch",
    name: "Batch",
    element: role === "admin" ? <BatchList /> : <Navigate to="/login" />,
  },
  {
    path: "aplliedcourses",
    name: "aplliedcourses",
    element: role === "admin" ? <AppliedStudents /> : <Navigate to="/login" />,
  },
  {
    path: "admin/aplliedstudentstable/:id",
    name: "aplliedstudentstable",
    element: role === "admin" ? <AppliedStudentsTable /> : <Navigate to="/login" />,
  },
  {
    path: "Batch/create",
    name: "Batchcreate",
    element: role === "admin" ? <BatchCreate /> : <Navigate to="/login" />,
  },
  {
    path: "Aboutus",
    name: "aboutus",
    element: role === "admin" ? <AboutUs /> : <Navigate to="/login" />,
  },
  {
    name: "CreateAboutUs",
    name: "CreateAboutUs",
    element: role === "admin" ? <CreateAboutUs /> : <Navigate to="/login" />,
  },
  {
    path: "intershiprequest",
    name: "intershiprequest",
    element: role === "admin" ? <Intershiprequest /> : <Navigate to="/login" />,
  },
  {
    path: "FreelancingCarousel",
    name: "FreelancingCarousel",
    element:
      role === "admin" ? <FreelancingCarousel /> : <Navigate to="/login" />,
  },
  {
    path: "FreelancingCarouselAdmin",
    name: "FreelancingCarouselAdmin",
    element:
      role === "admin" ? (
        <FreelancingCarouselAdmin />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/admin/score",
    name: "adminscore",
    element: role === "admin" ? <Score /> : <Navigate to="/login" />,
  },
  {
    path: "homecarousellist",
    name: "HomecarouselList",
    element: role === "admin" ? <CarouselList /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/homecarouseltcreate",
    name: "HomecarouselCreate",
    element: role === "admin" ? <CarouselCreate /> : <Navigate to="/login" />,
  },
  {
    path: "homestudentlist",
    name: "HomeStudentList",
    element: role === "admin" ? <List /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/homestudentcreate",
    name: "HomeStudentCreate",
    element: role === "admin" ? <Create /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/universityname",
    name: "universityname",
    element: role === "admin" ? <UniversityName /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/collegename",
    name: "collegename",
    element: role === "admin" ? <CollegeName /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/languageadded",
    name: "languageadded",
    element: role === "admin" ? <LanguageAdded /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/createSchoolList",
    name: "createSchoolList",
    element: role === "admin" ? <CreateSchool /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/locationadded",
    name: "locationadded",
    element: role === "admin" ? <LocationAdded /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/categoryadded",
    name: "categoryadded",
    element: role === "admin" ? <CategoryAdded /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/qualificationadded",
    name: "qualificationadded",
    element:
      role === "admin" ? <QualificationAdded /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/companylogo/approvedlist",
    name: "approvedlist",
    element:
      role === "admin" ? <CompanyApprovedList /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/Resource/OurEmployess",
    name: "OurEmployess",
    element: role === "admin" ? <OurEmployess /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/Resource/ContactorStaffing/List",
    name: "ContactorStaffing",
    element:
      role === "admin" ? <ContactorStaffing /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/Users/Vendor",
    name: "VendorList",
    element: role === "admin" ? <VendorList /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/Users/Trainers",
    name: "TrainersList",
    element: role === "admin" ? <TrainersList /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/Resource/OurEmployess/List",
    name: "OurEmployees",
    element: role === "admin" ? <OurEmployees /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/Resource/ContactorStaffing",
    name: "ContactorStaffingTable",
    element:
      role === "admin" ? <ContactorStaffingTable /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/student",
    name: "student",
    element: role === "admin" ? <Student /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/company",
    name: "company",
    element: role === "admin" ? <Company /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/vendor",
    name: "vendor",
    element: role === "admin" ? <Vendor /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/professional",
    name: "professional",
    element: role === "admin" ? <Professional /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/fields/district",
    name: "professional",
    element: role === "admin" ? <District /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/careerblitz/Freelancing",
    name: "Freelancing",
    element:
      role === "admin" ? <FerilFreelancinglist /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/careerblitz/ContactorStaffing/Request",
    name: "Request",
    element: role === "admin" ? <Request /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/careerblitz/ContactorStaffingRequestTable",
    name: "RequestTable",
    element: role === "admin" ? <RequestTable /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/careerblitz/ContactorStaffing/Post",
    name: "Post",
    element: role === "admin" ? <Post /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/careerblitz/ContactorStaffing/PostTable",
    name: "PostTable",
    element: role === "admin" ? <PostTable /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/careerblitz/Jobpost",
    name: "JobPost",
    element: role === "admin" ? <JobPost /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/careerblitz/ProjectPost",
    name: "ProjectPost",
    element: role === "admin" ? <ProjectPost /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/careerblitz/careerblitzFreelancinglist",
    name: "careerblitzFreelancinglist",
    element:
      role === "admin" ? (
        <careerblitzFreelancinglist />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/admin/users/careerblitz/JobPost/List",
    name: "JobPostList",
    element: role === "admin" ? <JobPostList /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/careerblitz/ProjectPost/List",
    name: "ProjectPostList",
    element: role === "admin" ? <ProjectPostList /> : <Navigate to="/login" />,
  },
  {
    path: "/admin/users/fields/area",
    name: "area",
    element: role === "admin" ? <Area /> : <Navigate to="/login" />,
  },
  // {
  //   path: "admin/Resource/ContactorStaffing/ActiveClients",
  //   name: "ActiveClients",
  //   element: <ActiveClients />,
  // },
  // {
  //   path: "admin/Resource/ContactorStaffing/InActiveClients",
  //   name: "InactiveClients",
  //   element: <InactiveClients />,
  // },
  // {
  //   path: "admin/Resource/OurEmployee/ActiveEmployee",
  //   name: "ActiveEmployee",
  //   element: <ActiveEmployee />,
  // },
  // {
  //   path: "admin/Resource/OurEmployee/InActiveEmployee",
  //   name: "InActiveEmployee",
  //   element: <InActiveEmployee />,
  // },
  {
    path: "studentsBlog",
    name: "StudentBlog",
    element: role === "admin" ? <StudentBlog /> : <Navigate to="/login" />,
  },

  {
    path: "curriculamcourse",
    name: "curriculam",
    element: role === "admin" ? <Curriculam /> : <Navigate to="/login" />,
  },

  {
    path: "curriculamcourse/curriculamcreate",
    name: "addUser",
    element: role === "admin" ? <CreateCurriculam /> : <Navigate to="/login" />,
  },
];
export default AdminRoutes;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Checkbox, Select } from "antd";
import { Dropdown } from "primereact/dropdown";
import Api from "../../../Api.js";
import "../../../Components/Css/Students/Student.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { toast, ToastContainer } from "react-toastify";
import "aos/dist/aos.css";
import AOS from "aos";
import { Option } from "antd/lib/mentions/index.js";

function AreaInterest() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: "ease-in-out", // Animation easing
    });
  }, []);

  const [domainList, setdomainList] = useState([]);
  const [domainValue, setDomainValue] = useState();
  const [subdomainList, setsubdomainList] = useState([]);
  const [subdomainValue, setSubDomainValue] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [categoryValue, setCategoryValue] = useState();
  const [fieldList, setfieldList] = useState([]);
  const [fieldValue, setfieldValue] = useState();

  // console.log('domainValue', domainValue)
  const submit = () => toast("successfully added");

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({});

  useEffect(() => {
    getDomain();
  }, []);

  const getDomain = async () => {
    await Api.get("coursesector/getsector").then((res) => {
      setdomainList(res.data);
    });
  };

  const getSubDomain = (domainId) => {
    setDomainValue(domainId);
    Api.get(`/coursedomain/getdomain/${domainId}`).then((res) => {
      setsubdomainList(res.data);
    });
  };

  const getSubCategory = (categoryId) => {
    setCategoryValue(categoryId);
    Api.get(`/coursecategory/getcategory/${categoryId}`).then((res) => {
      console.log("res", res);

      setCategoryList(res.data);
    });
  };

  const userId = localStorage.getItem("id");

  const handleFormSubmit = async () => {
    const Details = {
      domainName: getValues().domainValue,
      subdomainName: getValues().subdomainValue,
      // fieldName: getValues().fieldValue,
      userId: userId,
    };
    alert("your Datas stored");
    try {
      if (rowdy) {
        await Api.put(`studentdata/updateAreaofInterest/${userId}`, Details);
        toast("Successfully updated!");
      } else {
        await Api.post(`studentdata/createareaofinterest`, Details);
        toast("Successfully added!");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("An error occurred while saving data.");
    }
  };
  const [rowdy, setRowdy] = useState();

  useEffect(() => {
    const getAreaofInterest = async () => {
      try {
        const response = await Api.get(
          `studentdata/getOneAreaofInterest/${userId}`
        );
        console.log("AreaOfInterest", response.data.data);
        setRowdy(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching get Area of interest:", error);
      }
    };
    getAreaofInterest();
  }, []);

  useEffect(() => {
    if (rowdy?.domainName && domainList.length > 0) {
      const filteredDomain = domainList.find(
        (item) => item.domainName === rowdy.domainName
      );
      if (filteredDomain?._id) {
        setValue("domainValue", filteredDomain._id);
        setDomainValue(filteredDomain._id);
        getSubDomain(filteredDomain._id);
        getSubCategory(filteredDomain._id);
      }
    }
  }, [rowdy, domainList]);

  useEffect(() => {
    if (rowdy?.subdomainName && subdomainList.length > 0) {
      const filteredSubDomain = subdomainList.find(
        (subdomain) => subdomain.subdomainName === rowdy.subdomainName
      );
      if (filteredSubDomain?._id) {
        setValue("subdomainValue", filteredSubDomain._id);
        setSubDomainValue(filteredSubDomain._id);
      }
    }
  }, [rowdy, subdomainList]);

  return (
    <div className="area-of-intrest-container">
      <div className="col-12 col-sm-10 col-md-8">
        <div className="pages-title mb-3">Area Of Interest</div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="p-3">
            <Row className="mb-3">
              <Col
                sm={24}
                md={12}
                className="Stud-aresint-col p-2"
                data-aos="fade-right"
              >
                <label>Sector</label>
                <br />

                <Dropdown
                  className="Stud-aresint-input"
                  options={domainList}
                  value={domainValue}
                  {...register("domainValue", { required: true })}
                  optionLabel="sectorName"
                  optionValue="_id"
                  onChange={(e) => {
                    setValue("domainValue", e.value);
                    getSubDomain(e.value);
                  }}
                  placeholder="Select a Domain"
                />

                <br />
                {errors.domainValue && (
                  <span className="Stud-qual-error">Domain is required</span>
                )}
              </Col>

              <Col
                sm={24}
                md={12}
                className="Stud-aresint-col  p-2"
                data-aos="fade-left"
              >
                <label>Domain</label>
                <br />
                <Dropdown
                  className="Stud-aresint-input"
                  value={subdomainValue}
                  options={subdomainList}
                  {...register("subdomainValue", { required: true })}
                  optionLabel="domainName"
                  optionValue="_id"
                  onChange={(e) => {
                    setValue("subdomainValue", e.value); // Update react-hook-form state
                    setSubDomainValue(e.value); // Update local state
                    getSubCategory(e.value); // Fetch related data for subcategory
                  }}
                  placeholder="Select a Domain"
                />
                <br />
                {errors.subdomainValue && (
                  <span className="Stud-qual-error">Domain is required</span>
                )}
              </Col>
              <Col
                sm={24}
                md={12}
                className="Stud-aresint-col  p-2"
                data-aos="fade-left"
              >
                <label>SubDomain</label>
                <br />
                <Dropdown
                  className="Stud-aresint-input "
                  value={categoryValue}
                  options={categoryList}
                  {...register("categoryValue", { required: true })}
                  optionLabel={"categoryName"}
                  optionValue={"_id"}
                  onChange={(e) => setCategoryValue(e.value)}
                  placeholder="Select a Sub-Domain"
                />
                <br />
                {errors.categoryValue && (
                  <span className="Stud-qual-error">
                    Sub-Domain is required
                  </span>
                )}
              </Col>
            </Row>
            {fieldList.length >= 1 ? (
              <Row>
                <label>Fields </label>
                <br />
                {fieldList.map((data) => {
                  return (
                    <div key={data.id} className="field-checkbox ps-4">
                      <Checkbox
                        inputId={data.id}
                        name="fieldList"
                        optionValue={"_id"}
                        onChange={(e) => setfieldValue(e.value)}
                        {...register("fieldValue", { required: true })}
                      />{" "}
                      <label htmlFor={data.id}>{data.fieldName}</label>
                    </div>
                  );
                })}
              </Row>
            ) : (
              <></>
            )}

            <br />
            {errors.fieldValue && (
              <span className="Stud-qual-error">Field is required</span>
            )}
            <Row className="submitbuttons p-2">
              <button
                className="button1 m-2 p-2"
                type="submit"
                onClick={submit}
                data-aos="fade-right"
              >
                Submit
              </button>
              <button
                className="button2 m-2 p-2"
                type="reset"
                data-aos="fade-left"
              >
                Reset
              </button>
              <ToastContainer />
            </Row>
          </div>
        </form>
      </div>
    </div>
  );
}
export default AreaInterest;

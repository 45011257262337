import React, { useEffect, useState } from "react";
import { Card, Row, Col, Avatar, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../Api";
import HeaderNavbar from "../../Components/HeaderNavbar";
import Footer from "../../Components/Footer";

const BatchesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [batches, setBatches] = useState([]);
  const [timers, setTimers] = useState({});
  const studentId = localStorage.getItem("userId");

  const courseId = location.state?.id;

  useEffect(() => {
    const fetchBatches = async () => {
      if (!courseId) {
        console.error("No courseId provided.");
        return;
      }

      try {
        console.log("Fetching batches for course ID:", courseId);
        const response = await Api.get(`/course/getallbatches/${courseId}`);
        console.log("Batches response:", response.data);

        if (response.data && Array.isArray(response.data)) {
          setBatches(response.data);
        } else {
          console.error("Invalid batches data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching batches data:", error);
      }
    };

    if (courseId) {
      fetchBatches();
    }
  }, [courseId]);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedTimers = {};

      batches.forEach((batch) => {
        const now = new Date();
        const startDate = new Date(batch.start_date);

        if (startDate > now) {
          const diff = startDate - now;
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((diff / (1000 * 60)) % 60);
          const seconds = Math.floor((diff / 1000) % 60);

          updatedTimers[
            batch.batch_id
          ] = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
          updatedTimers[batch.batch_id] = "Batch Started!";
        }
      });

      setTimers(updatedTimers);
    }, 1000);

    return () => clearInterval(interval);
  }, [batches]);

  const handleNavigateToNextBatch = (batchId, courseId) => {
 
    navigate(`/student/learning/courselandingpage`, {
      state: { batchId, courseId },
    });
  };

  if (!batches.length) {
    return <div>Loading batches...</div>;
  }

  return (
    <>
      <HeaderNavbar />

      <div className="course-cards-container">
        <Row gutter={[24, 24]} className="course-cards-row">
          {batches.map((batch) => (
            <Col
              style={{ padding: "20px" }}
              key={batch.batch_id}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <Card
                style={{
                  height: "fit-content",
                  padding: "25px",
                  cursor: "pointer",
                }}
                hoverable
                className="course-card"
                cover={
                  <img
                    alt={batch.course_name.title}
                    src={batch.course_name.image}
                    className="course-card-image"
                  />
                }
                onClick={() =>
                  handleNavigateToNextBatch(batch.batch_id, courseId)
                }
              >
                <Card.Meta
                  title={batch.batch_name}
                  description={
                    <>
                      <p>
                        Course: <strong>{batch.course_name.title}</strong>
                      </p>
                      <p>Schedule: {batch.batch_schedule.join(", ")}</p>
                      <p>
                        Enrollments: {batch.current_enrollments}/
                        {batch.max_enrollments}
                      </p>
                      <p>
                        Duration:{" "}
                        {new Date(batch.start_date).toLocaleDateString()} to{" "}
                        {new Date(batch.end_date).toLocaleDateString()}
                      </p>
                      <p>
                        <strong>Booking Last Date:</strong>{" "}
                        {new Date(batch.booking_last_date).toLocaleDateString()}
                      </p>
                      <p>
                        <strong>Starts on:</strong>{" "}
                        {timers[batch.batch_id] || "Calculating..."}
                      </p>
                      <div className="author-info">
                        <Avatar
                          src={batch.trainer.profilePhoto}
                          size="small"
                          className="course-card-profileimg"
                        />
                        <span>By {batch.trainer.firstName}</span>
                      </div>
                      <Button type="primary" className="learn-more-btn">
                        Learn More
                      </Button>
                    </>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default BatchesPage;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BiRupee, BiTimeFive } from "react-icons/bi";
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { TfiBag } from "react-icons/tfi";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../../../Api";
import "../../../../Components/Css/Students/Student.scss";

function JobInnerPage() {
  const db = useLocation();
  const [createForm, setcreateForm] = useState();
  const navigate = useNavigate();
  const jobPost = db.state;
  const UserId = localStorage.getItem("userId");
  const name = localStorage.getItem("name");


  console.log('db', db)

  const id = jobPost._id;
  const userEmail = localStorage.getItem("email");
  const jobTitle = jobPost.jobTitle;
  const carrerlevel = jobPost.carrerlevel;
  const companyWebsite = jobPost.companyWebsite;
  const email = jobPost.email;
  const experience = jobPost.experience;
  const jobId = jobPost.jobId;
  const noticePeriod = jobPost.noticePeriod;
  const locations = jobPost.locations;
  const jobDescription = jobPost.jobDescription;
  const qualification = jobPost.qualification;
  const role = jobPost.role;
  const salaryRange = jobPost.salaryRange;
  const skill = jobPost.skill;
  const technology = jobPost.skill;

  useEffect(() => {
    getCreateForm();
  }, []);

  const getCreateForm = async () => {
    await Api.get(`createForm/getForm/${id}`).then((res) => {
      setcreateForm([res?.data?.data]);
    });
  };

  const onSubmit = (e) => {
    const details = {
      role: name,
      UserID: UserId,
      userEmail: userEmail,
      jobTitle: jobTitle,
      carrerlevel: carrerlevel,
      companyWebsite: companyWebsite,
      email: email,
      experience: experience,
      jobId: jobId,
      noticePeriod: noticePeriod,
      locations: locations,
      jobDescription: jobDescription,
      qualification: qualification,
      role: role,
      salaryRange: salaryRange,
      skill: skill,
      technology: technology,
    };
    Api.post("professional/createJobs", details).then((res) => {
      console.log("res", res);
    });
  };

  return (
    //     <Container>
    //       <div>
    //       <div
    //   className="ms-4 mx-auto" // Centering on larger screens
    //   style={{
    //     background: "#f5f5f5", // Light ash background
    //     borderRadius: "10px",
    //     padding: "20px",
    //     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for a modern look
    //     maxWidth: "600px", // Restricting width on larger screens
    //     width: "100%", // Ensuring responsiveness
    //     marginTop: "30px", // Adding margin-top for spacing
    //   }}
    // >
    //   <Row className="p-3">
    //     <Col>
    //       <h3 className="mt-3" style={{ color: "#003f5c" }}>
    //         {jobTitle}
    //       </h3>
    //       <div className="ms-4 p-2">
    //         <h6 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //           <AiOutlineUnorderedList style={{ color: "#28a745" }} />
    //           {technology}
    //         </h6>
    //         <h6 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //           <AiOutlineUnorderedList style={{ color: "#17a2b8" }} />
    //           {experience}
    //         </h6>
    //         <h6 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //           <AiOutlineUnorderedList style={{ color: "#ffc107" }} />
    //           {jobDescription}
    //         </h6>
    //         <h6 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //           <AiOutlineUnorderedList style={{ color: "#007bff" }} />
    //           {jobDescription}
    //         </h6>
    //         <h6 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //           <AiOutlineUnorderedList style={{ color: "#e52727" }} />
    //           {noticePeriod}
    //         </h6>
    //         <h6 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //           <AiOutlineUnorderedList style={{ color: "#28a745" }} />
    //           {jobPost.jobRole}
    //         </h6>
    //         <h6 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //           <AiOutlineUnorderedList style={{ color: "#ff6f61" }} />
    //           {jobPost.jobMode}
    //         </h6>
    //         <h6 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //           <AiOutlineUnorderedList style={{ color: "#ff8c00" }} />
    //           {jobPost.qualification}
    //         </h6>
    //       </div>
    //     </Col>
    //   </Row>
    //   <br />
    //   {/* Buttons */}
    //   <div className="d-flex justify-content-start mt-3 mb-2" style={{ gap: "10px" }}>
    //     <Button
    //       style={{
    //         backgroundColor: "#6c757d",
    //         borderColor: "#6c757d",
    //         padding: "10px 20px",
    //         borderRadius: "8px",
    //         fontSize: "1rem",
    //       }}
    //       onClick={() => navigate("/professional/jobs")}
    //     >
    //       Back
    //     </Button>
    //     <Button
    //       style={{
    //         backgroundColor: "#007bff",
    //         borderColor: "#007bff",
    //         padding: "10px 20px",
    //         borderRadius: "8px",
    //         fontSize: "1rem",
    //       }}
    //       onClick={() => onSubmit()}
    //     >
    //       Apply
    //     </Button>
    //   </div>
    // </div>

    //       </div>
    //     </Container>
    <Container style={{width:'90%'}}>
      <div>
        <h4 className="mt-3" style={{ textAlign: "center" }}>Job Details</h4>
      </div>
      <div className="seminarpage mt-3 ms-4">
        <h3 className="studseminar">{jobTitle}</h3>
        {/* <h5 style={{ marginLeft: "2%" }}>{technology}</h5> */}
        <div className="ms-4">
        <div className="studseminardetail">
            <h6> Technology : </h6>
            <p className="studsemimode"> {technology}</p>
          </div>
          <div className="studseminardetail">
            <h6> Experience : </h6>
            <p className="studsemimode"> {experience}</p>
          </div>
          <div className="studseminardetail">
            <h6> Description : </h6>
            <p className="studsemimode"> {jobDescription}</p>
          </div>
          <div className="studseminardetail">
            <h6> Notice Period : </h6>
            <p className="studsemimode"> {noticePeriod}</p>
          </div>
          <div className="studseminardetail">
            <h6> Role : </h6>
            <p className="studsemimode"> {jobPost.jobRole}</p>
          </div>
          <div className="studseminardetail">
            <h6> Job mode : </h6>
            <p className="studsemimode"> {jobPost.jobMode}</p>
          </div>
          <div className="studseminardetail">
            <h6> Qualification : </h6>
            <p className="studsemimode"> {jobPost.qualification}</p>
          </div>
          {/* <div className="studseminardetail">
            <h6> Job mode : </h6>
            <p className="studsemimode"> {jobPost.jobMode}</p>
          </div> */}
        </div>
      </div>

    </Container>
  );
}

export default JobInnerPage;

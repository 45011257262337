import React, { useState, useEffect } from "react";
import { Row, Col, Card, Divider, message } from "antd";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Api from "../../Api";
import "../../Pages/Student/Learning/Learning.scss";

import _ from "lodash";
import { CalendarOutlined } from "@ant-design/icons";
import "aos/dist/aos.css";
// import img from "../../../Images/card1.jpg";
import img from "../../Images/card1.jpg";
import AOS from "aos";
import { FaCalendarDay } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
import { IoBookOutline } from "react-icons/io5";
import { GiTechnoHeart } from "react-icons/gi";
import { Tab, Tabs } from "react-bootstrap";
import CourseCurriculum from "../../Pages/Student/Learning/Course Landing content/Curriculam";
import InstructorDetails from "../../Pages/Student/Learning/Course Landing content/InstructorDetails";
import WhatYoullLearn from "../../Pages/Student/Learning/Course Landing content/WhatYoullLearn";
// import InstructorDetails from "./Course Landing content/InstructorDetails";
// import CourseCurriculum from "./Course Landing content/Curriculam";
// import WhatYoullLearn from "./Course Landing content/WhatYoullLearn";

const CourseLandingPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const courseDetail = location?.state;
  console.log("courseDetail", courseDetail);
  const [activeTab, setActiveTab] = useState("overview");

  let parsedTechnologies = [];
  try {
    const technologiesString = courseDetail.technologies?.[0];
    if (Array.isArray(technologiesString)) {
      parsedTechnologies = technologiesString;
    } else if (typeof technologiesString === "string") {
      parsedTechnologies = technologiesString.startsWith("[")
        ? JSON.parse(technologiesString)
        : [technologiesString];
    }
  } catch (error) {
    console.error("Error parsing technologies:", error);
  }
  const formattedTechnologies = parsedTechnologies.join(", ");

  const parsedClassTimes = Array.isArray(courseDetail.classTime)
    ? courseDetail.classTime.map((time) => time.trim()) // Trim each time in the array
    : (courseDetail.classTime || "") // Handle the case when it's a string
        .replace(/[\[\]"]/g, "") // Remove brackets and quotes
        .split(",") // Split by commas
        .map((time) => time.trim()); // Trim each time

  // const parsedClassDays = Array.isArray(courseDetail.classDays)
  //   ? JSON.parse(courseDetail.classDays[0])
  //   : [];

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const studentId = localStorage.getItem("userId");

  return (
    <div className="filterpage_skillset">
      <div className="col-12">
        <img src={img} alt="img" style={{ height: "60vh", width: "100vw" }} />
      </div>
      <div className="row  course-details">
        <div className="col-12 col-sm-9 col-md-5 col-lg-4 col-xl-4 col-xxl-4 apply-card">
          {/* <img
            className="img-course"
            src={`${process.env.REACT_APP_DEV_BASE_URL}${courseDetail.image}`}
            alt={courseDetail.title}
          /> */}
          <img
            className="img-course"
            // src={`http://localhost:4000/api/public/${courseDetail.image}`}
            src="http://localhost:4000/api/public/TrainingCourseImages/image-1723445306142.jpg"
            alt={courseDetail.title}
          />

          <div className="course-details">
            <h1 className="course-title-card">{courseDetail.title}</h1>

            <p className="course-date">
              <CalendarOutlined className="date-icon" /> <b>Start Date:</b>{" "}
              {courseDetail.startDate.slice(0, 10)}
            </p>
            <p className="course-date">
              <CalendarOutlined className="date-icon" /> <b>End Date:</b>{" "}
              {courseDetail.endDate.slice(0, 10)}
            </p>

            <p className="course-tech">
              <b>Technologies:</b> {formattedTechnologies}
            </p>
            <p className="course-instructor">
              <b>Created By:</b> <span>{courseDetail.instructor}</span>
            </p>

            {/* <div className="class-days">
              <h4 className="section-title">
                <CalendarOutlined /> <span>Class Days</span>
              </h4>
              <ul className="days-list">
                {parsedClassDays.map((day, index) => (
                  <li key={index} className="day-item">
                    - {day}
                  </li>
                ))}
              </ul>
            </div> */}

            <div className="class-times">
              <h4 className="section-title">
                <CalendarOutlined /> <span>Class Times</span>
              </h4>
              <ul className="times-list">
                {parsedClassTimes.map((time, index) => (
                  <li key={index} className="time-item">
                    {time}
                  </li>
                ))}
              </ul>
            </div>

            {/* <div className="apply-button-wrapper">
              <button
                className="landing-apply-btn"
                // onClick={handleApply}
                // disabled={loading || hasApplied}
              >
                {loading
                  ? "Applying..."
                  : hasApplied
                  ? "Already Applied"
                  : "Apply"} 
              </button>
            </div> */}
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-7 col-xl-7 col-xxl-7 course-detail-container">
          <div className="tab-titles">
            <div
              className={`tab-title ${
                activeTab === "overview" ? "active" : ""
              }`}
              onClick={() => handleTabClick("overview")}
            >
              Overview
            </div>
            <div
              className={`tab-title ${
                activeTab === "curriculum" ? "active" : ""
              }`}
              onClick={() => handleTabClick("curriculum")}
            >
              Curriculum
            </div>
            <div
              className={`tab-title ${
                activeTab === "instructor" ? "active" : ""
              }`}
              onClick={() => handleTabClick("instructor")}
            >
              Instructor
            </div>
            {/* <div 
          className={`tab-title ${activeTab === 'reviews' ? 'active' : ''}`} 
          onClick={() => handleTabClick('reviews')}
        >
          Reviews
        </div> */}
            <div
              className={`tab-title ${
                activeTab === "what-you-learn" ? "active" : ""
              }`}
              onClick={() => handleTabClick("what-you-learn")}
            >
              What You Learn
            </div>
          </div>
          <div className="tab-contents">
            {activeTab === "overview" && <div> Overview Tab</div>}
            {activeTab === "curriculum" && <div>{<CourseCurriculum />}</div>}
            {activeTab === "instructor" && <div>{<InstructorDetails />}</div>}
            {activeTab === "reviews" && <div>Content for Reviews Tab</div>}
            {activeTab === "what-you-learn" && <div>{<WhatYoullLearn />}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLandingPage;

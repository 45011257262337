import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Spin, Avatar, Rate, Button, Comment, List } from "antd";
import { CiBookmark } from "react-icons/ci";
import { message } from "antd";
import Api from "../../Api";
import Footer from "../../Components/Footer";
import HeaderNavbar from "../../Components/HeaderNavbar";

const { Meta } = Card;

const BlogDetail = () => {
  const { id } = useParams(); 
  console.log(id)
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setLoading(true);
        const response = await Api.get(`/adminblog/getblog/${id}`); 
        if (response.data) {
          setBlog(response.data);
        } else {
          message.error("Blog not found");
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
        message.error("Error fetching blog details");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) {
    return <Spin size="large" tip="Loading..." />;
  }

  if (!blog) {
    return <p>No blog found with this ID.</p>;
  }

  return (
    <>
    <HeaderNavbar/>
    <div className="container">
      {/* <Card
        hoverable
        cover={<img alt={blog.title} src={blog.blogImage} className="blog-detail-image" />}
      >
        <Meta
          title={blog.title}
          description={
            <>
              <p>
                <strong>Author: </strong> {blog.author}
              </p>
              <p>
                <strong>Description: </strong> {blog.description}
              </p>
              <p>
                <strong>Status: </strong> {blog.status}
              </p>
              <Rate disabled defaultValue={blog.rating || 0} />
              <p>Views: {blog.views}</p>
            </>
          }
        />
      </Card> */}

      <div className="blog-content">
  {blog.content.map((section, index) => (
    <div
      key={index}
      className={`blog-section row ${
        index % 2 === 0 ? "flex-row" : "flex-row-reverse"
      } align-items-center mb-4`}
    >
      {section.images && (
        <div className="col-md-4 text-center">
          {section.images.map((image, idx) => (
            <img
              key={idx}
              src={image}
              alt={`Content Image ${idx}`}
              className="img-fluid rounded blog-content-image"
            />
          ))}
        </div>
      )}
      <div className="col-md-8 p-3">
        <h3>{section.heading}</h3>
        <h4>{section.subheading}</h4>
        <p style={{fontFamily:"poppins",textAlign:"justify"}}>{section.text}</p>
      </div>
    </div>
  ))}
</div>
    </div>
    <Footer/>
    </>
  );
};

export default BlogDetail;

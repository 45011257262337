import React, { useState, useEffect } from "react";
import Api from "../../Api";
import { useForm } from "react-hook-form";
import { Row, Card, Col, Button } from "react-bootstrap";
import "../Vendor/Index.css";
import { Dropdown } from "primereact/dropdown";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import '../../Components/Css/Professional/professionalstyle.scss'

function TabsVendor() {
  const [countryList, setcountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();

  // const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  const [cityvalue, setCityValue] = useState();

  const Cityselect = [
    { label: "Theni", value: "Theni" },
    { label: "Chennai", value: "Chennai" },
    { label: "Tiruvannamalai", value: "Tiruvannamalai" },
  ];

  const city = [
    { label: "Cumbum", value: "Cumbum" },
    { label: "Theni", value: "Theni" },
    { label: "Bodi", value: "Bodi" },
  ];

  useEffect(() => {
    getCountry();
  }, []);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    console.log("stateList", country_id);
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
    });
  };
  const [cityList, setCityList] = useState([]);
  const getCity = (districtId) => {
    setDistrictValue(districtId);
    Api.get(`city/cityById/${districtId}`).then((res) => {
      console.log(res.data, "ghjfhjgf");
      setCityList(res.data.data);
    });
  };
  const [companyNamess, setCompanyName] = useState();
  const [emailId, setEmailId] = useState();
  const [addresss, setAddress] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [vendorDetails, setVendorDetails] = useState([]);
  const [country, setCountry] = useState(null);
  console.log("country@@", country);
  const id = localStorage.getItem("userId");
  useEffect(() => {
    getValue();
  }, []);
  const getValue = () => {
    Api.get(`/vendor/vendor_get/${id}`).then((res) => {
      setVendorDetails(res.data.data);
      setCompanyName(res.data.data.companyName);
      setPhoneNumber(res.data.data.phone);
      setEmailId(res.data.data.email);
      setAddress(res.data.data.address);
      setCountry(res.data.data.country);
      setStateValue(res.data.data.state);
      setDistrictValue(res.data.data.district);
      setCityValue(res.data.data.city);
      console.log("res.data", res.data.data);
    });
  };
  console.log("vendorDetails", vendorDetails);
  console.log("companyNamess", companyNamess);

  const handleFormSubmit = async () => {
    console.log("getValues()", getValues());
    const Details = {
      Name: getValues().Name,
      email: getValues().email,
      phone: getValues().phone,
      address: getValues().address,
      businessName: getValues().businessName,
      country: getValues().country,
      state: getValues().state,
      district: getValues().district,
      city: getValues().city,
    };
    const userId = localStorage.getItem("userId");
    console.log("Detailsss", Details);
    await Api.put(`/vendor/vendorput/${userId}`, Details).then((resp) => {
      alert("your Semester datas stored");
      console.log(resp.data.data, "respppppp");
    });
  };

 
  return (
    <div style={{ width: '100%' }}>
      <div>
        <div style={{ paddingLeft: '10px' }}>
          <h4 className="pages-title mt-3 mb-5">Vendor Details</h4>
  
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Row className="gx-3 gy-3">
              {/* Name */}
              <Col lg={4} md={6} xs={12} className="px-4 py-2">
                <label className="input-title">Name:</label>
                <input
                className="Professional__UpdateProfile__Input"
                // className="inputcolumn"
                  type="text"
                  name="name"
                  {...register('Name')}
                  value={companyNamess}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Name"
                />
                {errors.Name && <p className="text-danger">Name is required</p>}
              </Col>
  
              {/* Email */}
              <Col lg={4} md={6} xs={12} className="px-4 py-2">
                <label className="input-title">Email:</label>
                <input
                className="Professional__UpdateProfile__Input"
                // className="inputcolumn"
                  type="text"
                  name="email"
                  {...register('email')}
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                  placeholder="Email"
                />
                {errors.email && <p className="text-danger">Email is required</p>}
              </Col>
  
              {/* Country */}
              <Col lg={4} md={6} xs={12} className="px-4 py-2">
              {/* <Col lg={4} md={6} xs={12} className="px-4"> */}
                <label className="input-title">Country:</label>
                <Select
                className="Professional__UpdateProfile__Input"
                // className="inputcolumn"
                  value={country}
                  onChange={(e) => {
                    setCountryValue(e);
                    getState(e);
                  }}
                  placeholder="Select a Country"
                  style={{ border: 'none' }}
                >
                  {countryList?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
                {errors.country && <p className="text-danger">Country is required</p>}
              </Col>
  
              {/* State */}
              <Col lg={4} md={6} xs={12} className="px-4 py-2">
              {/* <Col lg={4} md={6} xs={12} className="px-4"> */}
                <label className="input-title">State:</label>
                <Select
                className="input-field"
                // className="inputcolumn"
                  value={stateValue}
                  onChange={(value) => getDistrict(value)}
                  placeholder="Select a State"
                  style={{ border: 'none' }}
                >
                  {stateList?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
                {errors.state && <p className="text-danger">State is required</p>}
              </Col>
  
              {/* District */}
              <Col lg={4} md={6} xs={12} className="px-4 py-2">
                <label className="input-title">District:</label>
                <Select
                className="input-field"
                // className="inputcolumn"
                  value={districtValue}
                  onChange={(value) => getCity(value)}
                  placeholder="Select a District"
                  style={{ border: 'none' }}
                >
                  {districtList?.map((option) => (
                    <Option key={option._id} value={option.name}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
                {errors.district && <p className="text-danger">District is required</p>}
              </Col>
  
              {/* City */}
              <Col lg={4} md={6} xs={12} className="px-4 py-2">
                <label className="input-title">City:</label>
                <Select
                className="input-field"
                // className="inputcolumn"
                  value={cityvalue}
                  onChange={(value) => setCityValue(value)}
                  placeholder="Select a City"
                  style={{ border: 'none' }}
                >
                  {cityList?.map((option) => (
                    <Option key={option.id} value={option.name}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
                {errors.city && <p className="text-danger">City is required</p>}
              </Col>
  
              {/* Mobile Number */}
              <Col lg={4} md={6} xs={12} className="px-4 py-2">
                <label className="input-title">Mobile Number:</label>
                <input
                className="Professional__UpdateProfile__Input"
                // className="inputcolumn"
                  type="text"
                  name="phone"
                  {...register('phone')}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Mobile Number"
                />
                {errors.phone && <p className="text-danger">Phone is required</p>}
              </Col>
  
              {/* Address */}
              <Col lg={4} md={6} xs={12} className="px-4 py-2">
                <label className="input-title">Address:</label>
                <input
                className="Professional__UpdateProfile__Input"
                // className="inputcolumn"
                  type="text"
                  name="address"
                  {...register('address')}
                  value={addresss}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Address"
                />
                {errors.address && <p className="text-danger">Address is required</p>}
              </Col>
  
              {/* Business Name */}
              <Col lg={4} md={6} xs={12} className="px-4 py-2">
                <label className="input-title">Business Name:</label>
                <input
                className="Professional__UpdateProfile__Input"
                // className="inputcolumn"
                  type="text"
                  name="businessName"
                  {...register('businessName')}
                  placeholder="Business Name"
                />
                {errors.businessName && <p className="text-danger">Business Name is required</p>}
              </Col>
            </Row>
  
            <div className="upgrade_column mt-4">
              <Button className="button1" type="submit">
                Upgrade
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  
}

export default TabsVendor;

import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";
import { FaCircle } from "react-icons/fa";
import "../../../Components/seminar/viewSeminar.scss";

export default function Seminar() {
  const [createForm, setcreateForm] = useState([]);

  useEffect(() => {
    getCreateForm();
  }, []);

  const getCreateForm = async () => {
    await Api.get("seminar/getSeminar").then((res) => {
      setcreateForm(res.data.data);
    });
  };

  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("viewsinnerpage", { state: data });
  };

  return (
    <div className="seminar-container p-4">
      <div className="pages-title mb-4 text-center">Seminars</div>
      <Row className="g-4">
        {createForm.map((data, i) => (
          <Col xs={12} sm={12} md={6} lg={4} key={i} className='p-2'>
            <Card className="seminar-card shadow-lg">
              <Card.Body>
                <Badge bg="success" className="seminar-badge">
                  {data.SeminarMode}
                </Badge>
                <Card.Text className="seminar-join">Join our Upcoming <br /> Seminar </Card.Text>
                <Card.Title className="seminar-card-title mb-2">
                  {data.seminarTitle}
                </Card.Title>
                <Card.Text className="seminardaytime">
                  <div className="seminar-card-date">
                    {new Date(data.fromdate).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" })}
                    </div>
                  <div className="seminar-card-daytime">
                    {new Date(data.fromdate).toLocaleDateString("en-US", { weekday: "short" })}
                    <FaCircle style={{color:'white',fontSize:'10px', paddingLeft:'5px'}}/>
                    <span style={{ paddingLeft: '5px' }}>
                      {new Date(`1970-01-01T${data.time}`).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "2-digit",
                      })}
                    </span>
                  </div>
                </Card.Text>
                <Card.Text className="seminar-card-technology">
                  <strong>Technology: </strong>
                  {data.technology}
                </Card.Text>
                <Card.Text className="seminar-card-details">
                  <strong>Access: </strong>
                  {data.audience}
                  <br />
                  <strong>Contact : </strong>
                  <br />
                  <p style={{color:'#e74c3c'}}>{data.contactPerson} <span> | </span> {data.contactNumber}</p>
                </Card.Text>
                <Card.Text className="seminar-card-fees">
                  <strong>Fees : </strong>{data.fees}
                </Card.Text>
                <Card.Text className="seminar-card-link">
                  <a href={data.registrationLink} target="_blank" rel="noopener noreferrer" >
                    Click Here to register
                  </a>
                </Card.Text>
                  <div style={{display:"flex",justifyContent:"center"}}>
                <Button
                variant="secondary"
                size="small"
                className="seminar-button"
                  onClick={() => onSubmit(data)}
                >
                  View More
                </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

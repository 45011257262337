import React, { useState, useEffect } from "react";
import Api from "../../Api.js";

const BatchByCourse = () => {
  const staticCourseId = "67598b474f1d3d5711b67b98";
  const [course, setCourse] = useState(null);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const courseResponse = await Api.get(
          `/courses/getcourseById/${staticCourseId}`
        );
        setCourse(courseResponse.data);
      } catch (error) {
        console.error("Error fetching course:", error);
      }
    };
    fetchCourse();
  }, []);

  return (
    <div className="container my-5">
      {course ? (
        <>
          <h1 className="text-center mb-4">{course.title}</h1>
          <p>{course.description}</p>
          <h3>Applied Students</h3>
          <table className="table table-bordered mt-3">
            <thead>
              <tr>
                <th>Student ID</th>
                <th>Status</th>
                <th>Applied Date</th>
              </tr>
            </thead>
            <tbody>
              {course.appliedStudents && course.appliedStudents.length > 0 ? (
                course.appliedStudents.map((application, index) => (
                  <tr key={index}>
                    <td>
                      {application.studentId ? application.studentId : "No ID"}
                    </td>
                    <td>{application.status}</td>
                    <td>
                      {new Date(application.appliedDate).toLocaleDateString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No students applied yet.</td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      ) : (
        <p>Loading course details...</p>
      )}
    </div>
  );
};

export default BatchByCourse;

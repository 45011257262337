// import React, { useState } from "react";
// import Layout from "../Components/Layout";
// import { useLocation } from "react-router-dom";
// import "./PricingTagLandingPage.css";
// import Api from "../Api";

// export const PricingTagLandingPage = () => {
//   const location = useLocation();
//   const { price } = location.state || {};

//   const [formData, setFormData] = useState({
//     firstName: "",
//     secondName: "",
//     phoneNumber: "",
//     email: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const [amount, setAmount] = useState(1);
//   const [merchantUserId, setMerchantUserId] = useState("MUID123");

//   const handlePayment = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await Api.post(`pay`, {
//         amount,
//         merchantUserId,
//       });

//       const { url, method } = response.data;

//       window.location.href = url;
//     } catch (error) {
//       console.error(
//         "Error initiating payment:",
//         error.response ? error.response.data : error.message
//       );
//     }
//   };

//   // const handlePayment = async (e) => {
//   //   e.preventDefault();
//   //   try {
//   //     const response = await Api.post("/pay", {
//   //       amount: 10,
//   //       mobileNumber: formData.phoneNumber,
//   //       // merchantTransactionId: "TRANSACTION_ID_12345",
//   //       // merchantUserId: "USER_ID_456",
//   //       // merchantId: "CAREERBLITZONLINE",
//   //       // redirectUrl: "http://localhost:3000/payment-success",
//   //       // callbackUrl: "https://careerblitz.in/api/payment/callback",
//   //     });

//   //     if (response.data.success && response.data.code === "PAYMENT_INITIATED") {
//   //       // Extract the redirect URL from the response data
//   //       const paymentUrl =
//   //         response.data.data.instrumentResponse.redirectInfo.url;
//   //       // Redirect the user to the payment page
//   //       window.location.href = paymentUrl;
//   //     } else {
//   //       console.error("Payment initiation failed", response.data.message);
//   //       alert("Failed to initiate payment. Please try again.");
//   //     }
//   //   } catch (error) {
//   //     console.error("Payment initiation failed", error);
//   //     alert("An error occurred. Please try again later.");
//   //   }
//   // };

//   return (
//     <Layout>
//       <div className="paying-container" style={{ marginTop: "50px" }}>
//         <div className="paying-form-section">
//           <h2 className="paying-form-heading">Complete Your Payment</h2>
//           <form className="paying-form" onSubmit={handlePayment}>
//             <div className="paying-form-group">
//               <input
//                 type="text"
//                 name="firstName"
//                 placeholder="First Name"
//                 value={formData.firstName}
//                 onChange={handleChange}
//                 required
//               />
//               <input
//                 type="text"
//                 name="secondName"
//                 placeholder="Second Name"
//                 value={formData.secondName}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="paying-form-group">
//               <input
//                 type="text"
//                 name="phoneNumber"
//                 placeholder="Phone Number"
//                 value={formData.phoneNumber}
//                 onChange={handleChange}
//                 required
//               />
//               <input
//                 type="email"
//                 name="email"
//                 placeholder="Email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="paying-form-group">
//               <input type="text" name="amount" value={`Rs ${price}`} readOnly />
//             </div>
//             <button type="submit" className="pay-button">
//               Pay Now
//             </button>
//           </form>
//         </div>

//         <div className="paying-image-section">
//           <img
//             src="https://img.freepik.com/free-vector/vector-illustration-retro-style-hand-giving-money-other-hand_1284-42589.jpg?t=st=1727028562~exp=1727032162~hmac=ee70644737d12d15aaf646e6cba48e36c92838da9bcc942a5baeaa8f81414b48&w=740"
//             alt="Payment illustration"
//             className="payment-image"
//           />
//         </div>
//       </div>
//     </Layout>
//   );
// };
// PaymentComponent.js
import React, { useState } from 'react';
import axios from 'axios';
import Api from "../Api";
const PricingTagLandingPage = () => {
  const [amount, setAmount] = useState(100); // Amount in Paise
  const [merchantUserId, setMerchantUserId] = useState('MUID123'); // Unique user ID

  const handlePayment = async () => {
    try {
      const response = await Api.post(`pay`, {
        amount,
        merchantUserId
      });

      const { url, method } = response.data;

      // Redirect to the payment URL
      window.location.href = url; // Redirects in the same tab
    } catch (error) {
      console.error('Error initiating payment:', error.response ? error.response.data : error.message);
    }
  };


  
  return (
    <div>
      <h2>Payment Page</h2>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter Amount (in Paise)"
      />
      <button onClick={handlePayment}>Pay Now</button>
    </div>
  );
};

export default PricingTagLandingPage;

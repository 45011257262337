import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaUpload } from "react-icons/fa";
import "./PersonalInfo.scss";
import Api from "../../../Api";
import { notification } from "antd";

const PersonalInfo = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [key, setKey] = useState("personal");
  const [userData, setUserData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [maritalList, setMaritalList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(
    userData?.profilePhoto);
  const [areaValue, setAreaValue] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const userId = localStorage.getItem("userId");

  const handleImageChange = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
  };

  useEffect(() => {
    getDetails();
    getCountry();
    getGender();
    getMarital();
  }, []);

  useEffect(() => {
    if (userData) {
      setCountryValue(userData?.locationDetails?.country || "");
      setStateValue(userData?.locationDetails?.state || "");
      setDistrictValue(userData?.locationDetails?.district || "");
      setCityValue(userData?.locationDetails?.city || "");
      setAreaValue(userData?.locationDetails?.alert || "");
    }
  }, [userData]);

  const getMarital = async () => {
    const res = await Api.get("marital/getMarital");
    setMaritalList(res.data.data);
  };

  const getGender = async () => {
    const res = await Api.get("gender/getGenderlist");
    setGenderList(res.data.data);
  };

  const getCountry = async () => {
    try {
      const res = await Api.get("country/getallcountry");
      setCountryList(res.data.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const getState = (country_id) => {
    Api.get(`state/stateById/${country_id}`)
      .then((res) => setStateList(res.data.data))
      .catch((error) => console.error("Error fetching states:", error));
  };

  const getDistrict = (state_id) => {
    Api.get(`district/districtById/${state_id}`).then((res) =>
      setDistrictList(res.data.data)
    );
  };

  const getCity = (districtId) => {
    Api.get(`city/cityById/${districtId}`).then((res) =>
      setCityList(res.data.data)
    );
  };
  useEffect(() => {
    getCountry();

    if (userData?.locationDetails?.country) {
      const selectedCountry = userData.locationDetails.country;
      setCountryValue(selectedCountry);
      getState(selectedCountry);
    }
  }, [userData]);

  useEffect(() => {
    if (countryValue) {
      getState(countryValue);
    }
  }, [countryValue]);

  useEffect(() => {
    if (stateValue) {
      getDistrict(stateValue);
    }
  }, [stateValue]);

  useEffect(() => {
    if (districtValue) {
      getCity(districtValue);
    }
  }, [districtValue]);

  useEffect(() => {
    if (userData?.locationDetails?.state) {
      setStateValue(userData.locationDetails.state);
    }
    if (userData?.locationDetails?.district) {
      setDistrictValue(userData.locationDetails.district);
    }
    if (userData?.locationDetails?.city) {
      setCityValue(userData.locationDetails.city);
    }
  }, [userData]);

  const getDetails = async () => {
    Api.get(`signup/student_getone/${userId}`)
      .then((res) => {
        console.log("API Response:", res.data);

        setUserData(res?.data?.data);
        setProfileImage(res?.data?.data?.profileImage || null);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  };

  const handleProfilePhotoUpload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "darshan");

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
        {
          method: "POST",
          body: data,
        }
      );

      const cloudinaryData = await response.json();
      if (cloudinaryData.secure_url) {
        setProfilePhoto(cloudinaryData.secure_url);
        return cloudinaryData.secure_url;
      } else {
        throw new Error("Cloudinary upload failed");
      }
    } catch (error) {
      console.error("Error uploading profile photo:", error);
      notification.error({
        message: "Error",
        description: "Failed to upload profile photo.",
      });
      return null;
    }
  };
  useEffect(() => {
    if (userData.profileImage) {
      setProfilePhoto(userData.profileImage);
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const profilePhotoUrl = profilePhoto.startsWith(
        "https://via.placeholder.com"
      )
        ? null
        : profilePhoto;

      const updatedUserData = {
        ...userData,
        profileImage: profilePhotoUrl || userData?.profileImage,
        locationDetails: {
          ...userData.locationDetails,
          country: countryValue,
          state: stateValue,
          district: districtValue,
          city: cityValue,
        },
      };

      const response = await Api.put(
        `signup/update/${userId}`,
        updatedUserData
      );

      if (response.status === 200) {
        console.log("Profile updated successfully:", response.data);
        notification.success({
          message: "Success",
          description: "Profile updated successfully!",
        });
      } else {
        throw new Error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      notification.error({
        message: "Error",
        description: "An unexpected error occurred while updating the profile.",
      });
    }
  };

  return (
    <div className="form-center">
      <div>
        <Row className="mb-4">
          <Col xs={12} className="text-center">
            <div
              className="position-relative"
              style={{
                display: "inline-block",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "8px",
                  overflow: "hidden",
                  border: "4px solid #fff",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                }}
              >
                <img
                  src={
                    profilePhoto ||
                    userData.profileImage ||
                    "https://via.placeholder.com/120"
                  }
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onError={(e) =>
                    (e.target.src = "https://via.placeholder.com/120")
                  }
                />
                <input
                  type="file"
                  accept="image/*"
                  id="profileUpload"
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const uploadedPhoto = await handleProfilePhotoUpload(
                        file
                      );
                      if (uploadedPhoto) {
                        setProfilePhoto(uploadedPhoto);
                      }
                    }
                  }}
                />
                <label
                  htmlFor="profileUpload"
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                    background: "#fff",
                    borderRadius: "50%",
                    padding: "6px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                  }}
                >
                  <FaUpload size={16} color="#6c757d" />
                </label>
              </div>
            </div>

            <h5 style={{ fontWeight: "600", color: "#333" }}>
              {userData.firstName} {userData.lastName}
            </h5>
          </Col>
        </Row>

        <Row className="mb-4">
          <h5 className="label-for-student">Personal Information</h5>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">First Name</Form.Label>
                <Form.Control
                  placeholder="Enter your first name"
                  className="student-personal-info"
                  type="text"
                  value={userData.firstName}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      firstName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Phone Number</Form.Label>
                <Form.Control
                  placeholder="Enter your phone number"
                  className="student-personal-info"
                  type="text"
                  value={userData.phone}
                  onChange={(e) =>
                    setUserData({ ...userData, phone: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
          </Col>

          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  className="student-personal-info"
                  value={userData.lastName}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      lastName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Date of Birth</Form.Label>
                <Form.Control
                  placeholder="Enter your date of birth"
                  className="student-personal-info"
                  type="date"
                  value={userData.dob}
                  onChange={(e) =>
                    setUserData({ ...userData, dob: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
          </Col>

          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Gender</Form.Label>
                <Form.Select
                  className="student-personal-info"
                  style={{ height: "50px" }}
                  value={userData.gender ? userData.gender.toLowerCase() : ""}
                  onChange={(e) =>
                    setUserData({ ...userData, gender: e.target.value })
                  }
                >
                  <option value="">Select a Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="transgender">Transgender</option>
                  <option value="other">Other</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>

          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Email Id</Form.Label>
                <Form.Control
                  placeholder="Enter your email"
                  className="student-personal-info"
                  type="email"
                  value={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className="mb-2">
          <h5 className="label-for-student">Address Information</h5>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Country</Form.Label>
                <Form.Select
                  id="countrySelect"
                  className="student-personal-info"
                  style={{ height: "50px" }}
                  value={countryValue}
                  onChange={(e) => {
                    const selectedCountry = e.target.value;
                    setCountryValue(selectedCountry);
                    getState(selectedCountry);
                  }}
                >
                  <option value="">Select Country</option>
                  {countryList.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>

          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">State</Form.Label>
                <Form.Select
                  id="stateSelect"
                  className="student-personal-info"
                  style={{ height: "50px" }}
                  value={stateValue}
                  onChange={(e) => {
                    const selectedState = e.target.value;
                    setStateValue(selectedState);
                    getDistrict(selectedState);
                  }}
                >
                  <option value="">Select State</option>
                  {stateList.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">District</Form.Label>
                <Form.Select
                  id="districtSelect"
                  className="student-personal-info"
                  style={{ height: "50px" }}
                  value={districtValue}
                  onChange={(e) => {
                    const selectedDistrict = e.target.value;
                    setDistrictValue(selectedDistrict);
                    getCity(selectedDistrict);
                  }}
                >
                  <option value="">Select District</option>
                  {districtList.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>

          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">City</Form.Label>
                <Form.Select
                  id="citySelect"
                  className="student-personal-info"
                  style={{ height: "50px" }}
                  value={cityValue}
                  onChange={(e) => setCityValue(e.target.value)}
                >
                  <option value="">Select City</option>
                  {cityList.map((city) => (
                    <option key={city._id} value={city._id}>
                      {city.cityName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Area</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your area"
                  className="student-personal-info"
                  value={userData.locationDetails?.area || ""}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      locationDetails: {
                        ...userData.locationDetails,
                        area: e.target.value,
                      },
                    })
                  }
                />
              </Form.Group>
            </Form>
          </Col>

          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Pin Code</Form.Label>
                <Form.Control
                  placeholder="Enter your pin code"
                  className="student-personal-info"
                  type="text"
                  value={userData?.locationDetails?.pincode || ""}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      locationDetails: {
                        ...userData.locationDetails,
                        pincode: e.target.value,
                      },
                    });
                  }}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col xs={12}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  type="text"
                  value={userData?.locationDetails?.address || ""}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      locationDetails: {
                        ...userData.locationDetails,
                        address: e.target.value,
                      },
                    });
                  }}
                  placeholder="Enter your full address"
                  className="student-personal-info"
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="text-center">
            <Button variant="success" onClick={handleSubmit}>
              Save Changes
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PersonalInfo;

import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { DatePicker } from "antd";
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ManOutlined,
} from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./StudentSignupForm.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Api from "../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StudentSignupForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [value, setValue] = useState(false);
  const [registrantType, setRegistrantType] = useState("myself");
  const navigate = useNavigate();
  const [resRes, setResRes] = useState(null);


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (data) => {
    const userDetails = {
      role: "student",
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.mail,
      gender: data.gender,
      phone: data.phone,
      educationLevel: data.educationLevel,
      dob: data.dob,
      password: data.password,
      registrantType: data.registrantType,
      fatherormotherName:
        registrantType === "parent" ? data.fatherormotherName : undefined,
      guardianName:
        registrantType === "guardian" ? data.guardianName : undefined,
    };
    console.log("Submit", userDetails);
    console.log('Submitdata', data)

    try {
      const res = await Api.post(`signup/create`, userDetails);
      setResRes({
        status: res.data?.status,
        message: res.data?.message,
      });
      localStorage.setItem("userId", res.data.data._id);
      toast.success(res.data?.message);
      setTimeout(() => {
        navigate("/login");
      }, 10000);
    } catch (err) {
      setResRes({
        status: err?.response.data?.status,
        message: err?.response.data?.message,
      });
      toast.error(err?.response.data?.message);
    }
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleRegistrantChange = (event) => {
    setRegistrantType(event.target.value);
  };

  return (
    <div className="signup-form-container">
      <h2 className="form-heading" style={{ color: "blue" }}>
        Student Signup
      </h2>

      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Form.Group className="registrant-type-group">
          <Form.Label className="form-label">Registering by:</Form.Label>
          <Form.Control
            name="registrantType"
            {...register("registrantType")}
            as="select"
            value={registrantType}
            onChange={handleRegistrantChange}
            className="registrant-select"
          >
            <option value="myself">Myself</option>
            <option value="parent">Parent</option>
            <option value="guardian">Guardian</option>
          </Form.Control>
        </Form.Group>

        <p>
          Already Have an Account try <span>Login</span>
        </p>
        {registrantType === "parent" && (
          <Row>
            <Col md={6}>
              <Form.Group className="custom-form-group">
                <Form.Label className="form-label">
                  Father's/Mother's Name
                </Form.Label>
                <Form.Control
                  name="fatherormotherName"
                  {...register("fatherormotherName")}
                  className="custom-input"
                  placeholder="Enter Father's Name"
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="custom-form-group">
                <Form.Label className="form-label">Phone Number</Form.Label>

                <Form.Control
                  {...register("phone", {
                    minLength: 10,
                    maxLength: 10,
                    required: true,
                  })}
                  className="custom-input"
                  placeholder="Enter Phone Number"
                  type="text"
                />

                {errors.phone?.type === "required" && (
                  <p className="error-text-color">Phone Number is required</p>
                )}
                {errors.phone?.type === "minLength" && (
                  <p className="error-text-color">
                    minimum 10 number is required
                  </p>
                )}
                {errors.phone?.type === "maxLength" && (
                  <p className="error-text-color">
                    maximum 10 number is required
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
        )}
        {registrantType === "guardian" && (
          <Row>
            <Col md={6}>
              <Form.Group className="custom-form-group">
                <Form.Label className="form-label">Guardian's Name</Form.Label>

                <Form.Control
                  name="guardianName"
                  {...register("guardianName")}
                  className="custom-input"
                  placeholder="Enter Guardian's Name"
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="custom-form-group">
                <Form.Label className="form-label">Phone Number</Form.Label>

                <Form.Control
                  {...register("phone", {
                    minLength: 10,
                    maxLength: 10,
                    required: true,
                  })}
                  className="custom-input"
                  placeholder="Enter Phone Number"
                  type="text"
                />

                {errors.phone?.type === "required" && (
                  <p className="error-text-color">Phone Number is required</p>
                )}
                {errors.phone?.type === "minLength" && (
                  <p className="error-text-color">
                    minimum 10 number is required
                  </p>
                )}
                {errors.phone?.type === "maxLength" && (
                  <p className="error-text-color">
                    maximum 10 number is required
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">First Name</Form.Label>

              <Form.Control
                {...register("firstName", {
                  required: true,
                  pattern: /^[A-Za-z]+$/i,
                })}
                placeholder="Enter First Name"
                className="custom-input"
                type="text"
              />

              {errors.firstName?.type === "required" && (
                <p className="error-text-color">First Name is required</p>
              )}
              {errors?.firstName?.type === "pattern" && (
                <p className="error-text-color">characters only</p>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Last Name</Form.Label>

              <Form.Control
                {...register("lastName", {
                  required: true,
                  pattern: /^[A-Za-z]+$/i,
                })}
                className="custom-input"
                placeholder="Enter Last Name"
                type="text"
              />

              {errors.lastName?.type === "required" && (
                <p className="error-text-color">Last Name is required</p>
              )}
              {errors?.lastName?.type === "pattern" && (
                <p className="error-text-color">characters only</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Education Level</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  id="school"
                  label="School"
                  value="school"
                  defaultChecked // Automatically selects "School"
                  {...register("educationLevel", { required: true })}
                />
                <Form.Check
                  type="radio"
                  id="college"
                  label="College"
                  value="college"
                  {...register("educationLevel", { required: true })}
                />
              </div>
              {errors.educationLevel && (
                <p className="error-text-color">Education Level is required</p>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Email ID</Form.Label>

              <Form.Control
                {...register("mail", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
                className="custom-input"
                placeholder="Enter Email"
                type="email"
              />

              {errors.mail && (
                <p className="error-text-color">Email is required</p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Gender</Form.Label>

              <Form.Control
                {...register("gender", { required: true })}
                className="custom-input"
                as="select"
              >
                <option className="option">Male</option>
                <option className="option">Female</option>
                <option className="option">Other</option>
              </Form.Control>

              {errors.gender && (
                <span className="error-text">Gender is required</span>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Phone Number</Form.Label>

              <Form.Control
                {...register("phone", {
                  minLength: 10,
                  maxLength: 10,
                  required: true,
                })}
                className="custom-input"
                placeholder="Enter Phone Number"
                type="text"
              />

              {errors.phone?.type === "required" && (
                <p className="error-text-color">Phone Number is required</p>
              )}
              {errors.phone?.type === "minLength" && (
                <p className="error-text-color">
                  minimum 10 number is required
                </p>
              )}
              {errors.phone?.type === "maxLength" && (
                <p className="error-text-color">
                  maximum 10 number is required
                </p>
              )}
            </Form.Group>
          </Col>
          {/* <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Date of Birth</Form.Label>
              <div className="input-icon-container">
                <DatePicker
                  format="DD-MM-YYYY"
                  {...register("dob", { required: true })}
                  onChange={(date, dateString) => setValue("dob", dateString)}
                  className="custom-input date"
                />
              </div>
              {errors.dob && (
                <p className="error-text-color">Date of Birth is required</p>
              )}
            </Form.Group>
          </Col> */}
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Password</Form.Label>

              <Form.Control
                {...register("password", { required: true, minLength: 6 })}
                className="custom-input"
                placeholder="Enter Password"
                type={passwordVisible ? "text" : "password"}
              />
              <div
                className="eye-icon-container"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </div>
              {errors.password?.type === "required" && (
                <p className="error-text-color">Password is required</p>
              )}
              {errors.password?.type === "minLength" && (
                <p className="error-text-color">
                  Password must be at least 6 characters
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Button className="next-btn" type="submit">
          Submit
        </Button>
        <br />
        {resRes && (
          <div className={`response-message ${resRes.status}`}>
            <p>{resRes.message}</p>
          </div>
        )}

        <ToastContainer />
      </Form>
    </div>
  );
};

export default StudentSignupForm;

import { Input, Table } from "antd";
import { Row, Col, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Api from "../../../../Api";
import moment from "moment";
import "../../../../Components/Css/Admin/StudentList.scss";
// import Button from 'react-bootstrap/Button';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { TiTick } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TbRectangleVertical } from "react-icons/tb";
import { HiPencilSquare } from "react-icons/hi2";
import { BsDownload } from "react-icons/bs";
import Tooltip from "react-bootstrap/Tooltip";
import { ToastContainer, toast } from "react-toastify";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import 'aos/dist/aos.css';
import AOS from 'aos';
import UsersTab from "../AdminSidebar/Users/UsersTab";

const StudentList = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState([]);
  const [viewProfile, setViewProfile] = useState();
  console.log("viewProfile", viewProfile);
  const navigate = useNavigate();


  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',    
    });
  }, []);

  // function createdAtSorter(a, b) {
  //     if (a.createdAt < b.createdAt) {
  //         return -1;
  //     }
  //     if (a.createdAt > b.createdAt) {
  //         return 1;
  //     }
  //     return 0;
  // }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body style={{backgroundColor:'royalblue'}}>
        <Row >
          <Col className="pe-3">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Change user Stauts</Tooltip>
              }
            >
              <a href="#">
                <TiTick alt className="tickIcon" style={{ color: "white", fontSize: "22px", width: "25px", }}/>
              </a>
            </OverlayTrigger>
          </Col> 
          <Col>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">View Profile</Tooltip>}
            >
              <a href={`/admin/StudentList/details/${viewProfile}`}>
                <MdOutlineRemoveRedEye className="tickIcon" style={{ color: "white", fontSize: "22px", width: "25px", }}/>
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="pe-3">
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Delete Profile</Tooltip>}
            >
              <RiDeleteBin5Line className="tickIcon"
       onClick={() => getDeletedata(viewProfile)} style={{ color: "white", fontSize: "20px", width: "25px",}}
              />
              {/* </a> */}
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Edit Package</Tooltip>}
            >
              <a href="#">
                <TbRectangleVertical className="tickIcon" style={{color: "white",  fontSize: "20px",  width: "25px", }}/>
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="pe-3">
            {" "}
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Edit Profile</Tooltip>}
            >
              <a href="#">
                <HiPencilSquare className="tickIcon" style={{ color: "white", fontSize: "20px",  width: "25px"}}/>{" "}
              </a>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Download Profile</Tooltip>
              }
            >
              <a href="#">
                <BsDownload  className="tickIcon" style={{ color: "white", fontSize: "20px", width: "25px",  }} />
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <OverlayTrigger 
              overlay={<Tooltip id="tooltip-disabled">Report</Tooltip>}
            >
              <a 
                href="#" className="tickIcon"  style={{ color: "white", fontSize: "14px", fontWeight: "bolder", padding: "3px 6px ",
                  borderRadius: "3px", }} >  Report{" "}
                <span style={{ color: "yellow", fontSize: "12px" }}>(0)</span>
              </a>
            </OverlayTrigger>
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );
  const columns = [
    {
      title: "Employee Id",
      dataIndex: "RegistrationId",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Phone  ",
      dataIndex: "phone",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Add Date ",
      dataIndex: "addDate",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Package",
      dataIndex: "package",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    // WDD
    // {
    //     title: 'Created At',
    //     dataIndex: 'createdAt',
    //     sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    //     render: (text, record) => new Date(record.createdAt).toLocaleDateString('en-GB')
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record, index) => (
        <div >
          {console.log("rrrr", record._id)}
          <OverlayTrigger trigger="click" placement="left" overlay={popover}>
            <Link data-aos="zoom-in"data-aos-delay={index * 100}
              type=""
              className="data-list-button"
              onClick={(e) => {
                console.log("llll", record._id);
                setViewProfile(record._id);
                navigate(`/admin/StudentList/details/${record?._viewProfile}`);
              }}
            >
              Click Here
            </Link>
          </OverlayTrigger>
        </div>
      ),
    },
  ];

  const getData = async () => {
    await Api.get("signup/getall").then((res) => {
      setData(res.data.data);
    });
    console.log("setData :>> ", typeof data.map((item) => item?.phone));
  };

  const getDeletedata = async (viewProfile) => {
    try {
      const deleteApi1 = Api.delete(`signup/delete/${viewProfile}`);
      const deleteApi2 = Api.delete(`student/delete/userdb/${viewProfile}`);
      const [res1, res2] = await Promise.all([deleteApi1, deleteApi2]);

      console.log("Response 1:", res1);
      console.log("Response2", res2);
      getData();

      // Check if both deletions were successful
      if (res1.status === 200 && res2.status === 200) {
        toast.success("Deletion successful", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        // If any deletion fails, show an error message
        toast.error("Deletion failed", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      // Show an error toast message if there's an exception
      toast.error("An error occurred", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    getData();
    const results = data.filter(
      (item) =>
        item?.firstName
          ?.toLowerCase()
          .includes(searchText.toLowerCase().trim()) ||
        item?.RegistrationId?.toLowerCase().includes(
          searchText.toLowerCase().trim()
        ) ||
        item?.email?.toLowerCase().includes(searchText.toLowerCase().trim()) ||
        item?.phone?.toString().includes(searchText)
    );
    setSearch(results);
  }, [searchText]);

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  let dataCount = data.length;

  return (
    <div>
      <UsersTab/>
      <div className="data-list-main">
        <h4 className="data-list-header pages-title-common">Students List</h4>
        <h3 className="pages-title">Total Records: {dataCount}</h3>
        <div className="data-list-top mt-4 py-2 px-3">
          <Input
            className="data-list-search ps-2"
            placeholder="search here"
            onChange={handleChange}
            enterButton
          />
        </div>
        {searchText ? (
          <div className="data-list-table">
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={search}
              //  pagination={false}
              className="mt-2"
            />
          </div>
        ) : (
          <div className="data-list-table">
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={data}
              // pagination={false}
              className="mt-2"
            />
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default StudentList;

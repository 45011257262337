import React, { useState } from "react";
import { Row, Card, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Api from "../../Api";
import "../../Components/Css/Professional/professionalstyle.scss";

const Offer = () => {
  const [data, setData] = useState([]);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const handleOfferSubmit = async () => {
    const OfferDetails = {
      Promocode: getValues().Promocode,
      Category: getValues().Category,
      StartDate: getValues().StartDate,
      EndDate: getValues().EndDate,
      Description: getValues().Description,
    };
    console.log("OfferDetails", OfferDetails);
    await Api.post(`Offer/createOffer`, OfferDetails).then((resp) => {
      console.log("resp.data", resp.data);
    });
  };

  return (
    <div
      className="container"
      style={{
        backgroundColor: "transparent",
        width: "85%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <h4
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "red",
          fontWeight: "bold",
          margin: "3%",
        }}
      >
        Create Offer
      </h4>
      <div className="p-4">
        <form onSubmit={handleSubmit(handleOfferSubmit)}>
          <Row className="gx-3 gy-3">
            <Col lg={6} md={12} xs={12} className="px-4 py-2">
              <label className="input-title">
                Promo Code <sup className="required_column">*</sup>
              </label>
              <input
                className="Professional__UpdateProfile__Input"
                type="text"
                {...register("Promocode", { required: true })}
                placeholder="Enter Promo Code"
              />
              {errors.Promocode && (
                <p className="error-text-color">Promocode is required</p>
              )}
            </Col>

            <Col lg={6} md={12} xs={12} className="px-4  py-2">
              <label className="input-title">
                Category <sup className="required_column">*</sup>
              </label>
              <input
                className="Professional__UpdateProfile__Input"
                type="text"
                {...register("Category", { required: true })}
                placeholder="Enter Category"
              />
              {errors.Category && (
                <p className="error-text-color">Category is required</p>
              )}
            </Col>

            <Col lg={6} md={12} xs={12} className="px-4 py-2">
              <label className="input-title">
                Start Date <sup className="required_column">*</sup>
              </label>
              <input
                className="Professional__UpdateProfile__Input"
                type="date"
                {...register("StartDate", { required: true })}
              />
              {errors.StartDate && (
                <p className="error-text-color">Start Date is required</p>
              )}
            </Col>

            <Col lg={6} md={12} xs={12} className="px-4 py-2">
              <label className="input-title">
                End Date <sup className="required_column">*</sup>
              </label>
              <input
                className="Professional__UpdateProfile__Input"
                type="date"
                {...register("EndDate", { required: true })}
              />
              {errors.EndDate && (
                <p className="error-text-color">End Date is required</p>
              )}
            </Col>

            <Col lg={12} md={12} xs={12} className="px-4 py-2">
              <label className="input-title">
                Description <sup className="required_column">*</sup>
              </label>
              <textarea
                className="Professional__UpdateProfile__Input"
                {...register("Description", { required: true })}
                placeholder="Enter Description"
                rows={3}
              />
              {errors.Description && (
                <p className="error-text-color">Description is required</p>
              )}
            </Col>
          </Row>
          <div className="d-flex justify-content-center mt-4">
            <button className="button1" type="submit">
              Submit
            </button>
            <button className="button2 ms-2" type="button">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Offer;

import React, { useEffect, useState } from "react";
import "../../../Components/Css/Employer/OurProfile.scss";
import { Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

import Api from "../../../Api";
import { Dropdown } from "primereact/dropdown";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";

function OurProfile() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const [countryList, setcountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  const [cityList, setcityList] = useState([]);
  const [cityValue, setCityValue] = useState();
  const [domainList, setDomainList] = useState([]);
  const [domainValue, setDomainValue] = useState();
  const [subDomainList, setSubDomainList] = useState([]);
  const [subDomainValue, setSubDomainValue] = useState();
  const [companyType, setCompanyType] = useState([]);
  const [logo, setLogo] = useState();
  const [userData, setUserData] = useState([]);

  const [ddd, setValll] = useState();
  const [dddd, setVallls] = useState();

  useEffect(() => {
    setValll(domainList.find((item) => item._id === domainValue));
  }, [domainValue, subDomainValue]);
  useEffect(() => {
    setVallls(subDomainList.find((item) => item._id === subDomainValue));
  }, [domainValue, subDomainValue]);
  const [selectImage, setSelectImage] = useState();

  const setImage = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const selectedDomain = domainList.find((item) => item._id === domainValue);
  const selectedSubDomain = subDomainList.find(
    (item) => item._id === subDomainValue
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleFormSubmit = async () => {
    const formData = getValues();
    console.log("hghjvhjvhj", formData);
    const updatedData = { ...userData, ...formData };
    let countryName;
    let stateName;
    let districtName;
    let cityName;

    if (countryValue !== userData?.country) {
      countryName = countryList.find((country) => country.id === countryValue);
    } else {
      countryName = userData?.country;
    }
    if (stateValue !== userData?.state) {
      stateName = stateList.find((state) => state.id === stateValue);
    } else {
      stateName = userData?.state;
    }

    if (districtValue !== userData?.district) {
      districtName = districtList.find(
        (district) => district.id === districtValue
      ).name;
    } else {
      districtName = userData?.district;
    }

    if (cityValue !== userData?.city) {
      cityName = cityList.find((city) => city._id === cityValue).cityName;
    } else {
      cityName = userData?.city;
    }

    const collectDetails = {
      ...updatedData,
      userId: userId,
      companyName: formData.companyname,
      companyType: companyType,
      established: formData.established,
      field: formData.field,
      domain: selectedDomain?.domain,
      subDomain: selectedSubDomain?.subDomain,
      country: countryName,
      state: stateName,
      district: districtName,
      city: cityName,
      pincode: formData.pincode,
      website: formData.website,
      Linkedin: formData.Linkedin,
      facebook: formData.facebook,
      youtube: formData.youtube,
      skype: formData.skype,
      team: formData.team,
      instagram: formData.instagram,
      twitter: formData.twitter,
      address: formData.address,
      companyLogo: "",
    };
    console.log("getValues(", getValues());
    const data = new FormData();
    data.append("file", selectImage);
    data.append("upload_preset", "darshan");

    const response = await fetch(
      "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
      {
        method: "POST",
        body: data,
      }
    );

    const cloudinaryData = await response.json();
    collectDetails.companyLogo = cloudinaryData.secure_url;

    await Api.put(`employer/Employer_update/${userId}`, collectDetails).then(
      (resp) => {
        console.log("resp.data", resp.data);
      }
    );
  };
  useEffect(() => {
    getCountry();
    getDomain();
    getData();
  }, []);

  const getDomain = async () => {
    await Api.get("domainProfile/getDomain").then((res) => {
      setDomainList(res.data.data);
      console.log(res.data.data);
    });
  };

  const getSubDomain = (domainId) => {
    setDomainValue(domainId);
    Api.get(`subDomainProfile/domainById/${domainId}`).then((res) => {
      setSubDomainList(res.data.data);
      console.log("Subdomains fetched:", res.data.data);
    });
  };

  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    console.log("stateList", country_id);
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
    });
  };
  const id = localStorage.getItem("regid");
  const userId = localStorage.getItem("userId");
  console.log("ididid", userId);

  const getData = async () => {
    try {
      const res = await Api.get(`employer/Employer_getone/${userId}`);
      setUserData(res.data.data);
      console.log("res", res);
    } catch (err) {
      console.log(err);
    }
  };
  console.log("userData", userData);
  const formattedDate = userData?.established
    ? new Date(userData.established).toISOString().split("T")[0]
    : "";
  console.log("formattedDate", formattedDate);

  useEffect(() => {
    if (userData) {
      setValue("companyLogo", userData.companyLogo);
      setSelectImage(userData.companyLogo);
      setValue("companyname", userData.companyName);
      setValue("established", formattedDate);
      setValue("domain", userData.domain);
      setValue("subDomain", userData.subDomain);
      setCountryValue(userData.country);
      setStateValue(userData.state);
      setDistrictValue(userData.district);
      setCityValue(userData.city);
      setValue("pincode", userData.pincode);
      setValue("address", userData.address);
      setValue("Linkedin", userData.Linkedin);
      setValue("facebook", userData.facebook);
      setValue("youtube", userData.youtube);
      setValue("skype", userData.skype);
      setValue("team", userData.team);
      setValue("instagram", userData.instagram);
      setValue("twitter", userData.twitter);
      setValue("website", userData.website);
      setDomainValue(userData?.domain);
      setCompanyType(userData.companyType);
      setCountryValue(userData.country);
      setStateValue(userData.state);
      setDistrictValue(userData.district);
      setCityValue(userData.city);
    }
  }, [userData]);

  const getCity = (districtId) => {
    setDistrictValue(districtId);
    Api.get(`city/cityById/${districtId}`).then((res) => {
      setcityList(res.data.data);
    });
  };

  const types = [
    { name: "Private Ltd", value: "Private Limited" },
    { name: "Coperation", value: "Coperation" },
    { name: "Partnership", value: "Partnership" },
    { name: "Training Institute", value: "Training Institute" },
    {
      name: "Background Verification company",
      value: "Background Verification company",
    },
  ];

  const [file, setFile] = useState();

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <div className="content-profile">
      <Row style={{ marginLeft: "5%" }}>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <h4 className="mt-3">Our Profile:</h4>
          <Row className="mb-4">
            <Col sm={24} md={24} xs={8} className="px-4 ms-5">
              <div>
                {selectImage && (
                  <img
                    src={selectImage}
                    style={{
                      width: "200px",
                      height: "200px",
                    }}
                  />
                )}
              </div>
            </Col>
            <Col sm={24} md={8} xs={24} className="px-4 mx-5 ">
              <br />
              <label className="profile-file input-title">
                Upload Your Logo
              </label>
              <input
                className="profile-file"
                type="file"
                accept=".png,.jpg,.jpeg,.pdf"
                // {...register("companyLogo", { required: true })}
                onChange={handleFileChange}
                // onChanges={(e) => {
                //   setLogo(e.target.files[0]);
                //   console.log("e.target.files[0]", e.target.files[0]);
                // }}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col sm={12} lg={11}>
              <h5 className="p-2 heading-profile">General Information:</h5>
            </Col>
          </Row> */}
          <Row className="headings-for-catagory">
            <h5 className="p-2 heading-profile">General Information:</h5>
          </Row>
          <Row className="mt-3">
            <Col lg={4}>
              <label className="profile-title">Company Name</label>
              <input
                // defaultValue={userData?.companyName}
                {...register("companyname", { required: true })}
                className="employer-input-field"
                placeholder="Enter Company Name"
              />
              {errors.companyname && (
                <p className="text-danger">Name is required</p>
              )}
            </Col>
            <Col sm={24} md={8} lg={4} className="pe-5">
              <label className="profile-title">Company Type</label>
              <Select
                showSearch
                className="input-field"
                value={companyType}
                onChange={(value) => {
                  setCompanyType(value);
                }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
                optionLabelProp="label"
                placeholder="Select Company Type"
              >
                {types.map((country) => (
                  <Option
                    key={country._id}
                    value={country.name}
                    label={country.name}
                  >
                    {country.name}
                  </Option>
                ))}
              </Select>

              {errors.companyType && (
                <p className="text-danger">Type is required</p>
              )}
            </Col>
            <Col lg={4}>
              <label className="profile-title">Established Year</label>
              <input
                // defaultValue={userData?.establishedYear}
                type="date"
                {...register("established", { required: true })}
                className="employer-input-field"
                placeholder="Select Company Type"
              />
              {errors.established && (
                <p className="text-danger">Established is required</p>
              )}
            </Col>
          </Row>
          <Row
            className=" mt-3"
            style={{
              justifyContent: "flex-start",
            }}
          >
            <Col lg={4} className="pe-5">
              <label className="profile-title">Domain</label>
              <Select
                showSearch
                className="input-field"
                value={domainValue}
                onChange={(value) => {
                  getSubDomain(value);
                }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
                optionLabelProp="label"
                placeholder="Select Domain"
              >
                {domainList.map((country) => (
                  <Option
                    key={country._id}
                    value={country._id}
                    label={country.domain}
                  >
                    {country.domain}
                  </Option>
                ))}
              </Select>

              {errors.domainValue && (
                <p className="text-danger">Domain is required</p>
              )}
            </Col>
            <Col lg={4} className="pe-5">
              <label className="profile-title">Sub-Domain</label>
              <Select
                showSearch
                className="input-field"
                value={subDomainValue}
                onChange={(value) => {
                  getSubDomain(value);
                  setSubDomainValue(value);
                }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
                optionLabelProp="label"
                placeholder="Select SubDomain"
              >
                {subDomainList.map((country) => (
                  <Option
                    key={country._id}
                    value={country._id}
                    label={country.subDomain}
                  >
                    {country.subDomain}
                  </Option>
                ))}
              </Select>
              {errors.subDomainValue && (
                <p className="text-danger">Sub-Domain is required</p>
              )}
            </Col>
            <Col lg={4}></Col>
          </Row>
          <Row className="mt-3 headings-for-catagory">
            <h5 className="p-2 heading-profile">Company Address:</h5>
          </Row>
          <Row className="mt-2">
            <Col lg={4} className="pe-5">
              <label className="profile-title">Country</label>
              {/* <input
                defaultValue={userData?.country}
                {...register("country", { required: true })}
                className="input-field"
              /> */}
              {/* <Dropdown
                filter
                className="input-field"
                // defaultValue={userData?.country}
                value={countryValue}
                options={countryList}
                {...register("country", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getState(e.value)}
              /> */}
              <Select
                showSearch
                className="input-field"
                value={countryValue}
                onChange={(value) => {
                  setCountryValue(value);
                  getState(value);
                }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
                optionLabelProp="label"
              >
                {countryList.map((country) => (
                  <Option
                    key={country.id}
                    value={country.id}
                    label={country.name}
                  >
                    {country.name}
                  </Option>
                ))}
              </Select>
              {errors.country && (
                <p className="error-text-color">country is required</p>
              )}
            </Col>
            <Col lg={4} className="pe-5">
              <label>State</label>
              {/* <Dropdown
                filter
                className="input-field"
                value={stateValue}
                options={stateList}
                {...register("State", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getDistrict(e.value)}
              /> */}
              <Select
                showSearch
                className="input-field"
                value={stateValue}
                onChange={(value) => getDistrict(value)}
                placeholder="Select a State"
                style={{ border: "none" }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {stateList.map((country) => (
                  <Option
                    key={country.id}
                    value={country.id}
                    label={country.name}
                  >
                    {country.name}
                  </Option>
                ))}
              </Select>
              {errors.State && (
                <p className="error-text-color">State is required</p>
              )}
            </Col>
            <Col lg={4} className="pe-5">
              <label>District</label>
              {/* <Dropdown
                filter
                className="input-field"
                value={districtValue}
                options={districtList}
                {...register("district", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getCity(e.value)}
              /> */}
              <Select
                showSearch
                className="input-field"
                value={districtValue}
                onChange={(value) => getCity(value)}
                placeholder="Select a Gender"
                style={{ border: "none" }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {districtList.map((country) => (
                  <Option
                    key={country.id}
                    value={country.id}
                    label={country.name}
                  >
                    {country.name}
                  </Option>
                ))}
              </Select>

              {errors.district && (
                <p className="error-text-color">District is required</p>
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={4} className="pe-5">
              <label>City</label>
              {/* <Dropdown
                className="input-field"
                value={cityValue}
                options={cityList}
                {...register("city", { required: true })}
                optionLabel={"cityName"}
                optionValue={"_id"}
                onChange={(e) => setCityValue(e.value)}
                placeholder="Select a city"
              /> */}
              <Select
                showSearch
                className="input-field"
                value={cityValue}
                onChange={(value) => setCityValue(value)}
                placeholder="Select a City"
                style={{ border: "none" }}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {cityList.map((country) => (
                  <Option
                    key={country._id}
                    value={country._id}
                    label={country.cityName}
                  >
                    {country.cityName}
                  </Option>
                ))}
              </Select>

              {errors.city && <p className="text-danger">City is required</p>}
            </Col>
            <Col lg={4}>
              <label className="profile-title">Pincode</label>
              <input
                // defaultValue={userData?.pincode}
                {...register("pincode", { required: true })}
                className="employer-input-field"
                placeholder="Enter Pin Code"
              />
              {errors.pincode && (
                <p className="text-danger">Pincode is required</p>
              )}
            </Col>
            <Col lg={4}>
              <label className="profile-title">Address</label>
              <input
                // defaultValue={userData?.address}
                {...register("address", { required: true })}
                className="employer-input-field"
                placeholder="Enter Address"
              />
              {errors.address && (
                <p className="text-danger">Address is required</p>
              )}
            </Col>
          </Row>
          <Row className="mt-2 ">
            <h5 className="p-2  heading-profile">Social Media:</h5>
          </Row>
          <Row className="mt-2 form-space-alignment">
            <Col lg={4}>
              <label className="profile-title">Linkedin</label>
              <input
                {...register("Linkedin", { required: false })}
                className="employer-input-field"
                placeholder="Enter LinkedIn Link"
              />
            </Col>
            <Col lg={4}>
              <label className="profile-title">Facebook</label>
              <input
                {...register("facebook", { required: false })}
                className="employer-input-field"
                placeholder="Enter FaceBook Link"
              />
            </Col>
            <Col lg={4}>
              <label className="profile-title">Youtube</label>
              <input
                {...register("youtube", { required: false })}
                className="employer-input-field"
                placeholder="Enter Youtube Link"
              />
            </Col>
            <Col lg={4}>
              <label className="profile-title">Skype</label>
              <input
                {...register("skype", { required: false })}
                className="employer-input-field"
                placeholder="Enter Skype Link"
              />
            </Col>
            <Col lg={4}>
              <label className="profile-title">Telegram</label>
              <input
                {...register("team", { required: false })}
                className="employer-input-field"
                placeholder="Enter Telegram Link"
              />
            </Col>
            <Col lg={4}>
              <label className="profile-title">Instagram</label>
              <input
                {...register("instagram", { required: false })}
                className="employer-input-field"
                placeholder="Enter Instagram Link"
              />
            </Col>
            <Row
              style={{
                justifyContent: "flex-start",
              }}
            >
              <Col lg={4}>
                <label className="profile-title">Website</label>
                <input
                  {...register("website", { required: false })}
                  className="employer-input-field"
                  placeholder="Enter Website Link"
                />
              </Col>
              <Col lg={4}>
                <label className="profile-title">Twitter</label>
                <input
                  {...register("twitter", { required: false })}
                  className="employer-input-field"
                  placeholder="Enter Twitter Link"
                />
              </Col>
            </Row>
          </Row>
          <div className="submitbuttons p-2">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </Form>
      </Row>
    </div>
  );
}

export default OurProfile;

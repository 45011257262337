import React from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Api from "../../../Api";

function QualificationAdded() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const handleCreateFormSubmit = async () => {
    const userDetails = {
      qualificationName: getValues().qualificationName,
    };
    await Api.post(`fields/createqualification`, userDetails).then((resp) => {
      console.log("resp.data", resp.data);
    });
  };

  return (
    <div>
      <Container className=" content-style " style={{ width: '90%' }}>
        <Row >
          <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
            <div className="pages-title mt-3">Add Qualification</div>
            <Row className="mt-2" >
              <Col xs={12} sm={12} md={12} lg={6} className="px-5 py-3">
                <label className="create-title">Qualification Field</label>
                <br />
                <input
                  placeholder="Enter Qualification"
                  {...register("qualificationName", { required: true })}
                  className="Create-input"
                  style={{ width: '100%' }}
                />
                {errors.qualificationName && (
                  <p className="error-text-color">
                    qualificationName is required
                  </p>
                )}
              </Col>
            </Row>
            <div className="submitbuttons px-5 py-2 d-flex " style={{ justifyContent: 'flex-start' }}>
              <button
                className="button1 m-2 p-2"
                type="submit"
              // onClick={handleCreateFormSubmit}
              >
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
        </Row>
      </Container>
    </div>
  );
}

export default QualificationAdded;

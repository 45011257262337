import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Modal,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Table } from "antd";
import { MdAdd, MdDelete, MdEdit, MdSearch } from "react-icons/md";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Api from "../../../Api";
import { BsFillPersonPlusFill } from "react-icons/bs";
import "../../Exam/Technology/Technology.scss";

function AddCategoryTable() {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log("selectedCategoryTable", selectedCategory);
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setFilteredCategories(
        categories.filter((category) =>
          category.categoryName
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredCategories(categories);
    }
  }, [searchQuery, categories]);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await Api.get("coursecategory/getAllCategories");
    //   console.log("response", response);
      setCategories(response.data.data || []);
      setFilteredCategories(response.data.data || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (record) => {
    navigate("/admin/category", {
      state: {
        categoryName: record.categoryName,
        sectorName: record.sectorName,
        domainName: record.domainName,
        selectedCategoryId: record._id,
      },
    });
    console.log("tablerecordid", record._id);
  };

  const handleDelete = async () => {
    try {
      await Api.delete(
        `coursecategory/deleteoneCategory/${selectedCategory._id}`
      );
      fetchCategories();
      alert("Category deleted successfully");
    } catch (error) {
      console.error(
        "Error deleting category:",
        error.response?.data || error.message
      );
      alert("Failed to delete the category.");
    } finally {
      setIsDeleteConfirmVisible(false);
      setSelectedCategory(null);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await Api.put(
        `coursecategory/updateoneCategory/${selectedCategory._id}`,
        {
          categoryName: selectedCategory.categoryName,
        }
      );
      fetchCategories();
      setIsModalVisible(false);
    } catch (error) {
      console.error(
        "Error updating category:",
        error.response?.data || error.message
      );
      alert("Failed to update the category.");
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedCategory(null);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Sector",
      dataIndex: "sectorName",
      key: "sectorName",
      align: "center",
    },
    {
      title: "Domain",
      dataIndex: "domainName",
      key: "domainName",
      align: "center",
    },
    {
      title: "Categories",
      dataIndex: "categoryName",
      key: "categoryName",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <div>
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={() => handleEdit(record)}
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            style={{ paddingLeft: "10px", cursor: "pointer" }}
            onClick={() => {
              setSelectedCategory(record);
              setIsDeleteConfirmVisible(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        marginTop: "10px",
        maxWidth: "90%",
        margin: "0 auto",
        padding: "0 20px",
      }}
    >
      <div style={{ marginTop: "60px" }}>
        <Container>
          <div className="px-2">
            <h4 className="data-list-header">Category List</h4>
            <div
              className="data-list-top mb-4 mt-4 py-4 px-3 "
              style={{ backgroundColor: "#c5c5c5" }}
            >
              <input
                className="data-list-search ps-2"
                placeholder="search"
                columns={columns}
                // dataList={techList}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                // enterButton
              />

              <Button
                className="data-list-button"
                size="small"
                style={{ background: "white", color: "black" }}
                onClick={() => navigate("/admin/category")}
              >
                <BsFillPersonPlusFill />
                &nbsp;Add New
              </Button>
            </div>
            <Table
              columns={columns}
              dataSource={filteredCategories || []}
              pagination={{ pageSize: 5 }}
              rowKey={(record) => record._id || record.id}
              style={{ textAlign: "center" }}
            />
          </div>
        </Container>
      </div>

      <Modal
        show={isDeleteConfirmVisible}
        onHide={() => setIsDeleteConfirmVisible(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ border: "none" }}>
          <p>Are you sure you want to delete this category?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setIsDeleteConfirmVisible(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddCategoryTable;

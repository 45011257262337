import React, { useEffect } from "react";
import { Accordion, Button, Container, Row, Col } from "react-bootstrap";
import "../FAQ/FAQ.css";
import Layout from "../../Components/Layout";
import { useNavigate } from "react-router-dom";

function CareerBlitzFAQ() {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const navigate = useNavigate();

  return (
    <Layout>
      <div className="faq-page mt-4" style={{ overflow: "hidden" }}>
        <Container className="faq-container my-5">
          <h2 className="faq-title mb-4 text-center">
            Frequently Asked Questions
          </h2>
          <Row>
            <Col md={6} sm={6} className="faq-section">
              <Container>
                <Accordion className="faq-accordion">
                  <Accordion.Item eventKey="0" className="faq-item">
                    <Accordion.Header className="faq-question">
                      What types of training do you offer?
                    </Accordion.Header>
                    <Accordion.Body className="faq-answer">
                      We offer comprehensive training in IT, AR/VR, software
                      development, TNPSC, and state and central government
                      exams.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1" className="faq-item">
                    <Accordion.Header className="faq-question">
                      What is the duration of the TNPSC training course?
                    </Accordion.Header>
                    <Accordion.Body className="faq-answer">
                      The TNPSC training course duration varies depending on the
                      module but typically lasts between 6 to 12 months.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2" className="faq-item">
                    <Accordion.Header className="faq-question">
                      Do you provide online training for AR and VR?
                    </Accordion.Header>
                    <Accordion.Body className="faq-answer">
                      Yes, we offer both online and offline AR/VR training
                      programs, ensuring flexible learning options.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3" className="faq-item">
                    <Accordion.Header className="faq-question">
                      What software development skills will I learn in your
                      training program?
                    </Accordion.Header>
                    <Accordion.Body className="faq-answer">
                      Our software training includes programming languages like
                      Python, Java, C++, web development, mobile app
                      development, and more.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Container>
            </Col>

            <Col
              md={6}
              sm={6}
              className="FAQ-question-section text-center my-2"
            >
              <img
                src="https://cdni.iconscout.com/illustration/premium/thumb/faq-illustration-download-in-svg-png-gif-file-formats--analysis-book-browser-searching-information-pack-business-illustrations-2837641.png?f=webp"
                // src="https://as1.ftcdn.net/v2/jpg/02/58/91/96/1000_F_258919696_SjmTayivBN7TgpoBeme3LwOA0YhjL3nd.jpg"
                alt="Question Illustration"
                className="question-illustration mb-4"
              />
              <h3 className="FAQ-question-title">Any Question?</h3>
              <p className="FAQ-question-description">You can ask anything .</p>

              <Button
                variant="primary"
                className="FAQ-navigateBTN"
                onClick={() => navigate("/contactus")}
              >
                Get in touch
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <br />
      <br />
    </Layout>
  );
}

export default CareerBlitzFAQ;

import React from "react";
import AdminDashboard from "../Pages/Admin/AdminDashboard";
import CreateJobForm from "../Pages/Employer/CreateJobForm";
import OurProfile from "../Pages/Employer/OurProfile/OurProfile";
import TabsProfile from "../Pages/Employer/OurProfile/TabsProfile";
import EmployerInternship from "../Pages/Employer/EmployerIntership/index";
import EmployerProject from "../Pages/Employer/EmployerProject/EmployerProject";
import ScheduleForm from "../Pages/Employer/CreateJobForm/ScheduleForm";
import ViewJob from "../Pages/Employer/CreateJobForm/ViewJob";
import EmployerResume from "../Pages/Employer/EmployerResume/EmployerResume";
import ResumeDetail from "../Pages/Employer/EmployerResume/ResumeDetail";
import CreateSeminarRequest from "../Pages/Employer/seminar/EmployerSeminar";
import ViewSeminar from "../Components/seminar/ViewSeminar";
import EmployerDetail from "../Pages/Employer/EmployerDetail/EmployerDetail";
import ReviewPage from "../Pages/Employer/CreateJobForm/ReviewPage";
// import SubscriptionPage from "../Pages/Employer/SubscriptionPage";
import BackgroundVarifList from "../Pages/Employer/Backgroundverification/BgvList";
import BgvForm from "../Pages/Employer/Backgroundverification/BgvForm";
import BgvDetails from "../Pages/Employer/Backgroundverification/BgvDetails";
import StaffPost from "../Pages/Employer/ContractStaffing/StaffPost";
import ViewInnerPage from "../Pages/Employer/CreateJobForm/ViewInnerPage";
import SeminarRequest from "../Pages/Employer/seminar/SeminarRequest";
import SeminarInnerPage from "../Pages/Employer/seminar/SeminarInnerPage";
import Request from "../Pages/Employer/Request";
import Response from "../Pages/Employer/Response";
import Home from "../Pages/Employer/Home/index";
import RequestProject from "../Pages/Employer/projectpost/RequestProject";
import JobRequest from "../Pages/Employer/CreateJobForm/JobRequest";
import ViewProject from "../Pages/Employer/projectpost/ViewProject";
import JobRequestInnerPage from "../Pages/Employer/CreateJobForm/JobRequestInnerPage";
import ProjectRequestInnerPage from "../Pages/Employer/projectpost/ProjectRequestInnerPage";
import Vendor from "../Pages/Employer/ContractStaffing/Vendor";
import VendorInnerPage from "../Pages/Employer/ContractStaffing/RequestAndResponse.js/VendorInnerPage";
import ContractApprovedList from "../Pages/Employer/ContractStaffing/Approved/ContractApprovedList";
import Index from "../Pages/Employer/ContractStaffing/RequestAndResponse.js/Index";
import StaffRequiredInnerPage from "../Pages/Employer/ContractStaffing/RequestAndResponse.js/StaffRequiredInnerPage";
import Indexs from "../Pages/Employer/ContractStaffing/RequestPage/Indexs";
import StaffRequiredInnerPages from "../Pages/Employer/ContractStaffing/RequestPage/StaffRequiredInnerPage";
import ContractJobInnerPages from "../Pages/Employer/ContractStaffing/RequestPage/ContractJobInnerPages";
import ContractApproved from "../Pages/Employer/ContractStaffing/Approved/Index";
// import ApplicantDetails from "../Pages/Employer/CreateJobForm/ApplicantListDetails";
import EmployerDashboard from "../Pages/Employer/EmployerDashboard/EmployerDashboard";
// import Vendor from "../Pages/Employer/ContractStaffing/Vendor";
import Projectpost from "../Pages/Employer/projectpost/Projectpost";
import RequestRedirect from "../Pages/Employer/RequestRedirect";
import Details from "../Pages/Employer/ContractStaffing/Details";
import Training from "../Pages/Employer/Training/CourseInformation";
import InterviewPage from "../Pages/Employer/CreateJobForm/InterviewPage";
import ViewSchedule from "../Pages/Employer/CreateJobForm/ViewSchedule";
import JobList from "../Pages/Employer/CreateJobForm/Viewjobs";
import SelectedList from "../Pages/Employer/InterviewList/SelectedList";
import HoldList from "../Pages/Employer/InterviewList/HoldList";
import RejectedList from "../Pages/Employer/InterviewList/RejectedList";
import RejectForm from "../Pages/Employer/InterviewList/RejectForm";
import HoldForm from "../Pages/Employer/InterviewList/HoldForm";
import SelectForm from "../Pages/Employer/InterviewList/SelectForm";
import DetailsForm from "../Pages/Employer/CreateJobForm/DetailsForm";
import Internship from "../Pages/Employer/EmployerIntership/Internship";
import Createinternship from "../Pages/Employer/EmployerIntership/Createinternship";
import InternshipRequest from "../Pages/Employer/EmployerIntership/InternshipRequest";
import EmployerRegistration from "../Pages/Employer/EmployerRegistration/EmployerRegistration";
import { Navigate } from "react-router-dom";
import ViewApplicants from "../Pages/Employer/CreateJobForm/ViewApplicants";
import ViewJobInnerPage from "../Pages/Employer/CreateJobForm/ViewJobInnerPage";
// import Calendar from "../Pages/Employee/Calender"
import LeaveCalendar from "../Pages/Employer/Calendar/LeaveCalendar";
import ApprovedProject from "../Pages/Employer/projectpost/ApprovedProject";
import ViewRequest from "../Pages/Employer/EmployerIntership/ViewRequest";

const role = localStorage.getItem("role");

const EmployerRoutes = [
  {
    path: "/employer/dashboard",
    name: "employerdashboard",
    element:
      role === "employer" ? <EmployerDashboard /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/home",
    name: "EmployerHome",
    element: role === "employer" ? <Home /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/employerdetail",
    name: "EmployerDetail",
    element:
      role === "employer" ? <EmployerDetail /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/emplyerregistration",
    name: "EmployerRegistration",
    element:
      role === "employer" ? <EmployerRegistration /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/CreateJobForm",
    name: "Employerform",
    element: role === "employer" ? <CreateJobForm /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/details",
    name: "selected",
    element: role === "employer" ? <DetailsForm /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/form",
    name: "Employerform",
    element: role === "employer" ? <ScheduleForm /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/OurProfile",
    name: "profileform",
    element: role === "employer" ? <TabsProfile /> : <Navigate to="/login" />,
  },
  // {
  //   path: "/employer/:name/Calendar",
  //   name: "",
  //   element: role === "employer" ? <Calendar /> : <Navigate to="/login"/>
  // },
  {
    path: "/employer/:name/Calendar",
    name: "",
    element: role === "employer" ? <LeaveCalendar /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/Resume",
    name: "",
    element:
      role === "employer" ? <EmployerResume /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/Project",
    name: "",
    element:
      role === "employer" ? <EmployerProject /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/interviewinner",
    name: "interview",
    element: role === "employer" ? <InterviewPage /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/selected",
    name: "selected",
    element: role === "employer" ? <SelectedList /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/hold",
    name: "hold",
    element: role === "employer" ? <HoldList /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/rejected",
    name: "rejected",
    element: role === "employer" ? <RejectedList /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/internship",
    name: "internship",
    element: role === "employer" ? <Internship /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/createinternship",
    name: "createinternship",
    element:
      role === "employer" ? <Createinternship /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/viewrequest",
    name: "internshiprequest",
    element: role === "employer" ? <ViewRequest /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/internshiprequest",
    name: "internshiprequest",
    element:
      role === "employer" ? <InternshipRequest /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/selectform",
    name: "selectform",
    element: role === "employer" ? <SelectForm /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/holdform",
    name: "holdform",
    element: role === "employer" ? <HoldForm /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/rejectform",
    name: "rejectform",
    element: role === "employer" ? <RejectForm /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/ViewSchedule/:id",
    name: "viewschedule",
    element: role === "employer" ? <ViewSchedule /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/response",
    name: "",
    element:
      role === "employer" ? <EmployerProject /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/Internshipreq",
    name: "profileform",
    element:
      role === "employer" ? <EmployerInternship /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/createSeminarRequest",
    name: "Create Seminar Request",
    element:
      role === "employer" ? <CreateSeminarRequest /> : <Navigate to="/login" />,
  },
  // {
  //   path: "/employer/:name/subscription",
  //   name: "sebscription page",
  //   element:role === "employer" ?  <SubscriptionPage /> : <Navigate to="/login"/>
  // },
  {
    path: "/employer/:name/SeminarRequest",
    name: "SeminarRequest",
    element:
      role === "employer" ? <SeminarRequest /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/SeminarRequest/SeminarInnerPage",
    name: "SeminarInnerPage",
    element:
      role === "employer" ? <SeminarInnerPage /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/viewjob",
    name: "viewjob",
    element: role === "employer" ? <JobList /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/jobrequest/:id",
    name: "jobrequest",
    element: role === "employer" ? <JobRequest /> : <Navigate to="/login" />,
  },
  // {
  //   path: "/employer/:name/jobrequest/:id/jobrequestinnerpage",
  //   name: "jobrequestinnerpage",
  //   element:
  //     role === "employer" ? <JobRequestInnerPage /> : <Navigate to="/login" />,
  // },
  {
    path: "/employer/:name/ViewApplicants",
    name: "jobrequestinnerpage",
    element: <ViewApplicants />,
    // element: role === "employer" ? <JobRequestInnerPage /> : <Navigate to="/login"/>
  },
  {
    path: "/employer/:name/viewjob/innerpage",
    name: "",
    element: role === "employer" ? <ViewInnerPage /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/request/requestRedirect",
    name: "",
    element:
      role === "employer" ? <RequestRedirect /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/reviewpage",
    element: role === "employer" ? <ReviewPage /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/Bgv",
    element:
      role === "employer" ? <BackgroundVarifList /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/Bgvform/:id",
    element: role === "employer" ? <BgvForm /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/BgvDetails/:id",
    element: role === "employer" ? <BgvDetails /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/staff_requirement",
    name: "staff requirement",
    element: role === "employer" ? <StaffPost /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/staff_search",
    name: "staff search",
    element: role === "employer" ? <Index /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/staff_vendor",
    name: "vendor page",
    element: role === "employer" ? <Vendor /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/approved",
    name: "jobrequestinnerpage",
    element:
      role === "employer" ? <ContractApproved /> : <Navigate to="/login" />,
  },
  {
    path: "employer/:name/staff_details",
    name: "",
    element: role === "employer" ? <Details /> : <Navigate to="/login" />,
  },
  // {
  //   path: "/employer/project_post",
  //   name: "projectpost",
  //   element: <Projectpost />,
  // },
  {
    path: "/employer/:name/ViewProject",
    name: "ViewProject",
    element: role === "employer" ? <ViewProject /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/projetrequest",
    name: "projectRequest",
    element:
      role === "employer" ? <RequestProject /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/projectapproved",
    name: "ApprovedProject",
    element:
      role === "employer" ? <ApprovedProject /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/project_post",
    name: "projectpost",
    element: role === "employer" ? <Projectpost /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/projetrequest/projectinnerpage",
    name: "projectinnerpage",
    element:
      role === "employer" ? (
        <ProjectRequestInnerPage />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/employer/:name/vendorpost",
    name: "Vendor",
    element: role === "employer" ? <Vendor /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/Contractinnerpage",
    name: "Contractinnerpage",
    element:
      role === "employer" ? <VendorInnerPage /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/Contractpostinnerpage",
    name: "StaffRequiredInnerPage",
    element:
      role === "employer" ? (
        <StaffRequiredInnerPage />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/employer/:name/contractPageinnerpages",
    name: "StaffRequiredInnerPage",
    element:
      role === "employer" ? (
        <StaffRequiredInnerPages />
      ) : (
        <Navigate to="/login" />
      ),
  },
  {
    path: "/employer/:name/ContratjobInnerPage",
    name: "StaffRequiredInnerPage",
    element:
      role === "employer" ? (
        <ContractJobInnerPages />
      ) : (
        <Navigate to="/login" />
      ),
  },

  {
    path: "/employer/:name/contractrequest",
    name: "contractrequest",
    element: role === "employer" ? <Indexs /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/training",
    name: "training",
    element: role === "employer" ? <Training /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/internship",
    name: "internship",
    element: role === "employer" ? <Internship /> : <Navigate to="/login" />,
  },
  {
    path: "/employer/:name/resumeDetail",
    name: "resumeDetail",
    element: role === "employer" ? <ResumeDetail /> : <Navigate to="login" />,
  },
  {
    path: "/employer/:name/getjobrequest/:id",
    name: "resumeDetail",
    element:
      role === "employer" ? <ViewJobInnerPage /> : <Navigate to="login" />,
  },
  {
    path: "/employer/:name/getjobrequest/:id/jobrequestinnerpage",
    name: "jobrequestinnerpage",
    element:
      role === "employer" ? <JobRequestInnerPage /> : <Navigate to="/login" />,
  },
];
export default EmployerRoutes;

import React, { useState, useEffect } from "react";
import { Input, Button, Select, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Api from "../../../Api"; 

const { Option } = Select;

function DomainForm() {
  const [domainName, setDomainName] = useState("");
  const [sectorId, setSectorId] = useState("");
  const [sectors, setSectors] = useState([]);
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sectorLoading, setSectorLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { sectorId: querySectorId, domainId: queryDomainId } = location.state || {}; 

  useEffect(() => {
    fetchSectors(); 

    if (querySectorId) {
      setSectorId(querySectorId); 
      fetchDomainsBySector(querySectorId); 
    }
  }, [querySectorId]); 

  const fetchSectors = async () => {
    setSectorLoading(true);
    try {
      const res = await Api.get("/coursesector/getsector");
      console.log("Fetched Sectors:", res.data);
      setSectors(res.data);
    } catch (err) {
      console.error("Error fetching sectors:", err);
      message.error("Failed to fetch sectors");
    } finally {
      setSectorLoading(false);
    }
  };


  const fetchDomainsBySector = async (sectorId) => {
    setLoading(true);
    try {
      const res = await Api.get(`/coursedomain/getdomain/${sectorId}`);
      console.log(`Fetched Domains for sector ${sectorId}:`, res.data); 
      if (res.data.length > 0) {
        setDomains(res.data);
        setDomainName(res.data[0].domainName);
      } else {
        message.warning("No domains found for this sector");
      }
    } catch (err) {
      console.error("Error fetching domains:", err);
      message.error("Failed to fetch domains");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    console.log("Form Data:", { domainName, sectorId });
  
    if (!domainName || !sectorId) {
      message.error("Please fill in all fields");
      return;
    }
  
    setLoading(true);
  
    try {
      let res;

      if (queryDomainId) {
        res = await Api.put(`/coursedomain/updatedomain/${queryDomainId}`, {
          domainName,
          sectorId,
        });
  
        console.log("Domain updated successfully:", res.data); 
        message.success("Domain updated successfully");
      } else {
        res = await Api.post("/coursedomain/createdomain", {
          domainName,
          sectorId,
        });
  
        console.log("Domain added successfully:", res.data); 
        message.success("Domain added successfully");
      }
  
      navigate("/admin/domain");
    } catch (err) {
      console.error("Error handling domain:", err);
      message.error("Failed to save domain");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="container domain-form p-3">
    <h4 className="page-title mb-4">Add Domain</h4>
    <form>
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="sector" className="form-label">
            Select Sector
          </label>
          <select
            id="sector"
            className="form-domain-select-admin"
            style={{width:"90%",height:"40px"}}
            value={sectorId}
            onChange={(e) => {
              const value = e.target.value;
              console.log("Sector Selected:", value);
              setSectorId(value);
            }}
          >
            <option value="" disabled>
              Select Sector
            </option>
            {sectors.map((sector) => (
              <option key={sector._id} value={sector._id}>
                {sector.sectorName}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6">
          <label htmlFor="domainName" className="form-label">
            Domain Name
          </label>
          <input
            type="text"
            id="domainName"
            className="form-control"
            placeholder="Enter Domain Name"
            value={domainName}
            onChange={(e) => {
              console.log("Domain Name Input Changed:", e.target.value);
              setDomainName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Add Domain"}
          </button>
        </div>
      </div>
    </form>
  </div>
  
  );
}

export default DomainForm;

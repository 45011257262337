import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./RoleForm.css";
import AOS from "aos";
import "aos/dist/aos.css";
import StudentSignupForm from "./StudentSignupForm";
import CollegeSignupForm from "./CollegeSignupForm";
import VendorSignupForm from "./VendorSignupForm";
import TrainerSignupForm from "./TrainerSignupForm";
import EmployerSignupForm from "./EmployerSignupForm";
import ProfessionalSignupForm from "./ProfessionalSignupForm";
import Layout from "../Components/Layout";

const RoleForm = () => {
  const roles = [
    "Student",
    "Professional",
    "Trainer/Counsellor",
    // "Vendor",
    "School/College/Institude",
    "Company/Employer",
  ];
  const [selectedRole, setSelectedRole] = useState(roles[0]);
  const [showForm, setShowForm] = useState(false);

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    setShowForm(false);
  };

  const handleNext = () => {
    // if (selectedRole !== "Student") {
    //   alert("Coming Soon");
    //   return;
    // }
    setShowForm(true);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  return (
    <Layout>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-6 right-role-cont">
            <h4 style={{ textAlign: "center" }} className="im-text">
              {`I'm ${selectedRole}`}
            </h4>
            <div className="row">
              {roles.map((role, index) => (
                <div className="col-md-6 role-content" key={index}>
                  <div
                    className={`d-flex align-items-center border rounded p-2 mb-3 
                  ${selectedRole === role ? "bg-primary text-white" : ""}`}
                    style={{
                      height: "60px",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRoleChange(role)}
                  >
                    <input
                      type="radio"
                      className="form-check-input position-absolute"
                      name="role"
                      value={role}
                      checked={selectedRole === role}
                      onChange={() => handleRoleChange(role)}
                      style={{
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                      }}
                    />
                    <span className="ms-4 roles">
                      {role.charAt(0).toUpperCase() + role.slice(1)}
                    </span>
                  </div>
                </div>
              ))}
              <span className="border-signup"></span>
            </div>
          </div>

          <div className="col-md-6 role-content left">
            {showForm ? (
              <>
                {selectedRole === "Student" && <StudentSignupForm />}
                {selectedRole === "Professional" && <ProfessionalSignupForm />}
                {selectedRole === "Trainer/Counsellor" && <TrainerSignupForm />}
                {selectedRole === "Vendor" && <VendorSignupForm />}
                {selectedRole === "School/College/Institude" && (
                  <CollegeSignupForm />
                )}
                {selectedRole === "Company/Employer" && <EmployerSignupForm />}
              </>
            ) : (
              <div className="role-cont">
                {selectedRole === "Student" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0, textAlign: "justify" }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <span
                          className="number-circle"
                          data-aos="fade-up"
                          data-aos-delay="100"
                        >
                          1
                        </span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Explore a Wide Range of Courses
                          </h4>
                          <p className="feature-sub-content">
                            "Dive into our catalog offering courses from basics
                            to advanced skills across various fields. Whether
                            you're a beginner or seeking to upskill, we have
                            something for everyone."
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Earn Industry-Recognized Certifications
                          </h4>
                          <p className="feature-sub-content">
                            "Complete courses to earn certifications recognized
                            by top companies. These certifications boost your
                            resume and improve job prospects in a competitive
                            market."
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="1200"
                      >
                        <span className="number-circle">3</span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Engage with a Career-Focused Curriculum
                          </h4>
                          <p className="feature-sub-content">
                            "Our courses equip you with in-demand skills
                            employers seek. We also provide job placement
                            assistance to launch your career."
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}

                {selectedRole === "Professional" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0 }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="300"
                      >
                        <span className="number-circle">1</span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Diverse Job Opportunities
                          </h4>
                          <p className="feature-sub-content">
                            Explore diverse job listings, from internships to
                            advanced roles, across industries. Connect with
                            candidates to find the perfect fit for your
                            organization's needs.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Personalized Job Recommendations
                          </h4>
                          <p className="feature-sub-content">
                            Receive tailored job suggestions based on
                            candidates' skills and career goals. This targeted
                            approach helps you match the right talent to your
                            job openings, making the hiring process more
                            efficient and effective.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        {/* <span className="line-connector line-connector-long-3"></span> */}
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Comprehensive Application Support
                          </h4>
                          <p className="feature-sub-content">
                            Equip candidates with tools and expert advice to
                            craft standout resumes and cover letters, boosting
                            application quality and simplifying top talent
                            identification.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {selectedRole === "School/College/Institude" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0 }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <span className="number-circle">1</span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Streamline Application Management
                          </h4>
                          <p className="feature-sub-content">
                            Receive and process student applications seamlessly
                            through our platform. Manage the entire admissions
                            process from one dashboard, making it easier to
                            track, review, and respond to applications.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Engage Students with Blogs
                          </h4>
                          <p className="feature-sub-content">
                            Keep your student body informed and engaged by
                            posting blogs about campus events, educational
                            content, and institutional news. This builds a
                            stronger connection with your students and attracts
                            new applicants.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        {/* <span className="line-connector line-connector-long-3"></span> */}
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Advertise Jobs and Internships
                          </h4>
                          <p className="feature-sub-content">
                            Easily post job openings for faculty, staff, and
                            internships. Reach potential candidates directly and
                            streamline your recruitment process by managing
                            applications through your portal.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {selectedRole === "Company/Employer" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0 }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="300"
                      >
                        <span className="number-circle">1</span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Attract Top Talent
                          </h4>
                          <p className="feature-sub-content">
                            Post job openings on our platform to attract
                            qualified candidates and fill positions with top
                            talent across various domains, keeping your company
                            competitive.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Collaborate on Projects
                          </h4>
                          <p className="feature-sub-content">
                            Post projects to engage freelancers or external
                            teams, leveraging specialized skills for
                            project-based tasks to boost productivity and
                            innovation.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        {/* <span className="line-connector line-connector-long-3"></span> */}
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Simplify Hiring and Onboarding
                          </h4>
                          <p className="feature-sub-content">
                            Simplify your hiring process by reviewing
                            applications, conducting interviews, and managing
                            onboarding through our platform. This streamlined
                            approach saves time and enhances the candidate
                            experience.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {selectedRole === "Trainer/Counsellor" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0 }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <span className="number-circle">1</span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Expand Your Reach
                          </h4>
                          <p className="feature-sub-content">
                            Leverage our platform to reach a wider audience of
                            learners. By offering courses online, you can
                            connect with students globally, increasing your
                            visibility and potential for higher enrollments.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Enhance Your Teaching Portfolio
                          </h4>
                          <p className="feature-sub-content">
                            Create and showcase a diverse range of courses that
                            demonstrate your expertise. Having a robust
                            portfolio not only attracts more students but also
                            establishes your credibility in your field.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-up"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        {/* <span className="line-connector line-connector-long-3"></span> */}
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Access to Tools and Resources
                          </h4>
                          <p className="feature-sub-content">
                            Utilize our platform’s tools for course creation,
                            management, and analytics. Access valuable resources
                            that help you design engaging content, track student
                            progress, and enhance the learning experience.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {selectedRole === "Vendor" && (
                  <div className="role-cont">
                    <ul
                      className="list-unstyled"
                      style={{ width: "90%", padding: 0 }}
                    >
                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="300"
                      >
                        <span className="number-circle">1</span>
                        <span className="line-connector line-connector-long-1"></span>
                        <div className="feature-content">
                          <h4 className="mb-3 feature-title">
                            Showcase Your Products and Services
                          </h4>
                          <p className="feature-sub-content">
                            Utilize our platform to display your products and
                            services to a broader audience. With enhanced
                            visibility, you can attract potential clients and
                            customers effectively, boosting your sales and brand
                            recognition.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="600"
                      >
                        <span className="number-circle">2</span>
                        <span className="line-connector line-connector-long-2"></span>
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Access to Targeted Marketing Tools
                          </h4>
                          <p className="feature-sub-content">
                            Utilize our vendor-focused marketing tools to run
                            targeted campaigns, reach your ideal audience, and
                            track performance to maximize results.
                          </p>
                        </div>
                      </li>

                      <li
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-aos="fade-down"
                        data-aos-delay="900"
                      >
                        <span className="number-circle">3</span>
                        {/* <span className="line-connector line-connector-long-3"></span> */}
                        <div className="feature-content">
                          <h4 className="feature-title">
                            Gain Insights Through Analytics
                          </h4>
                          <p className="feature-sub-content">
                            Access in-depth analytics to understand customer
                            behavior and preferences. Use insights to refine
                            offerings, boost engagement, and make data-driven
                            decisions for growth
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                <div className="role-section">
                  {selectedRole === "Student" ? (
                    <button className="btn btn-primary" onClick={handleNext}>
                      Next
                    </button>
                  ) : (
                    <button className="btn btn-secondary" disabled>
                      Coming Soon
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RoleForm;

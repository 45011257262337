import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineDelete } from "react-icons/ai";
import "../../../Components/Css/Professional/professionalstyle.scss";
import Api from "../../../Api.js";

function QualificationDetails() {
  const [userData, setUserData] = useState([]);
  const [Check, setCheck] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const userId = localStorage.getItem("userId");
  console.log("userData", userData);
  useEffect(() => {
    const getDetails = async () => {
      try {
        const res = await Api.get(`employeeUser/employee_getone/${userId}`);
        setUserData(res.data.data.Qualification);
      } catch (err) {
        console.log("Fetch Error:", err);
      }
    };
    getDetails();
  }, [userId]);

  const handleFormSubmit = async (data) => {
    const details = {
      ...data,
      Qualification: {
        ...data.Qualification,
        degrees: data.degrees,
        // Add other fields as necessary
      },
    };

    const resp = await Api.put(`employee/Employee_update/${userId}`, details);
    console.log("resp.data", resp.data);
  };
  useEffect(() => {
    if (userData) {
      setValue("xboard", userData.schoolX);
      setValue("xmajor", userData.majorX);
      setValue("xper", userData.percentageX);
      setValue("xyop", userData.yearX);
      setValue("xiiboard", userData.schoolXII);
      setValue("xiimajor", userData.majorXII);
      setValue("xiiper", userData.percentageXII);
      setValue("xiiyop", userData.yearXII);
      setValue("gboard", userData.ugcollegeName);
      setValue("gmajor", userData.ugcollegeMajor);
      setValue("gper", userData.ugcollegePercentage);
      setValue("gyop", userData.ugcollegeYear);
      setValue("mboard", userData.pgcollegeName);
      setValue("mmajor", userData.pgcollegeMajor);
      setValue("mper", userData.pgcollegePercentage);
      setValue("myop", userData.pgcollegeYear);
    }
  }, [userData]);
  const [degrees, setDegrees] = useState([{ degree: "", board: "", major: "", percentage: "", yop: "" }]);
  
  const addDegreeRow = () => {
    setDegrees([...degrees, { degree: "", board: "", major: "", percentage: "", yop: "" }]);
  };

  const removeDegreeRow = (index) => {
    setDegrees(degrees.filter((_, i) => i !== index));
  };
  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="pages-title mb-3">Qualification Details</div>
        <div className="Stud-qual-table">
          <tr>
            <div>
              <label className="mb-2">Qualification</label>
            </div>
            <td>
              <div>
                <table style={{ borderSpacing: "10px", borderCollapse: "separate" }}>
                  <tr>
                    <td align="center">
                      <b>Sl.No.</b>
                    </td>
                    <td align="center">
                      <b>Examination</b>
                    </td>
                    <td align="center">
                      <b>Board/Institution</b>
                    </td>
                    <td align="center">
                      <b>Major</b>
                    </td>
                    <td align="center">
                      <b>Percentage</b>
                    </td>
                    <td align="center">
                      <b>Year of Passing</b>
                    </td>
                  </tr>

                  <tr>
                    <td>1</td>
                    <td>Class X</td>
                    <td>
                      <input
                        type="text"
                        name="xboard"
                        className="input-field"
                        {...register("xboard", { required: true })}
                        maxlength="30"
                      />
                      <br />
                      {errors.xboard && (
                        <span className="Stud-qual-error">
                          X_Board is required
                        </span>
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="ClassX_major"
                        maxlength="30"
                        className="input-field"
                        {...register("xmajor", { required: true })}
                      />
                      <br />
                      {errors.xmajor && (
                        <span className="Stud-qual-error">
                          X_major is required
                        </span>
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="ClassX_Percentage"
                        maxlength="30"
                        className="input-field"
                        {...register("xper", { required: true })}
                      />
                      <br />
                      {errors.xper && (
                        <span className="Stud-qual-error">
                          X_Percentage is required
                        </span>
                      )}
                    </td>
                    <td>
                      <input
                        type="number"
                        name="ClassX_YrOfPassing"
                        maxlength="30"
                        className="input-field"
                        {...register("xyop", { required: true })}
                      />
                      <br />
                      {errors.xyop && (
                        <span className="Stud-qual-error">
                          X_YrOfPassing is required
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>2</td>
                    <td>Class XII</td>
                    <td>
                      <input
                        type="text"
                        name="ClassXII_Board"
                        maxlength="30"
                        className="input-field"
                        {...register("xiiboard", { required: true })}
                      />
                      <br />
                      {errors.xiiboard && (
                        <span className="Stud-qual-error">
                          ClassXII_Board is required
                        </span>
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="ClassXii_major"
                        maxlength="30"
                        className="input-field"
                        {...register("xiimajor", { required: true })}
                      />
                      <br />
                      {errors.xiimajor && (
                        <span className="Stud-qual-error">
                          ClassXII_major is required
                        </span>
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        name="ClassXII_Percentage"
                        maxlength="30"
                        className="input-field"
                        {...register("xiiper", { required: true })}
                      />
                      <br />
                      {errors.xiiper && (
                        <span className="Stud-qual-error">
                          ClassXII_Percentage is required
                        </span>
                      )}
                    </td>
                    <td>
                      <input
                        type="number"
                        name="ClassXII_YrOfPassing"
                        maxlength="30"
                        className="input-field"
                        {...register("xiiyop", { required: true })}
                      />
                      <br />
                      {errors.xiiyop && (
                        <span className="Stud-qual-error">
                          ClassXII_YrOfPassing is required
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>3</td>
                    <td>Graduation</td>
                    <td>
                      <input
                        type="text"
                        name="Graduation_Board"
                        {...register("gboard", { required: false })}
                        maxlength="30"
                        className="input-field"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="Classg_major"
                        maxlength="30"
                        className="input-field"
                        {...register("gmajor", { required: false })}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="Graduation_Percentage"
                        maxlength="30"
                        className="input-field"
                        {...register("gper", { required: false })}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="Graduation_YrOfPassing"
                        maxlength="30"
                        className="input-field"
                        {...register("gyop", { required: false })}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>4</td>
                    <td>Masters</td>
                    <td>
                      <input
                        type="text"
                        name="Masters_Board"
                        {...register("mboard", { required: false })}
                        maxlength="30"
                        className="input-field"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="Classm_major"
                        maxlength="30"
                        className="input-field"
                        {...register("mmajor", { required: false })}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="Masters_Percentage"
                        maxlength="30"
                        className="input-field"
                        {...register("mper", { required: false })}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="Masters_YrOfPassing"
                        maxlength="30"
                        className="input-field"
                        {...register("myop", { required: false })}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
          <br />
        </div>
        <div>
        <input type="checkbox" onChange={(e) => setCheck(e.target.checked)} />
          <label>Add Degree </label>
          {Check && (<button type="button" onClick={addDegreeRow} className="btn btn-secondary">
            Add More
          </button>)}
        </div>
        {Check && (
          <table className="ms-5">
            <thead>
              <tr>
                <th align="center">Degree</th>
                <th align="center">Board/Institution</th>
                <th align="center">Major</th>
                <th align="center">Percentage</th>
                <th align="center">Year of Passing</th>
              </tr>
            </thead>
            <tbody>
  {degrees.map((_, index) => (
    <tr key={index}>
      <td>
        <input
          type="text"
          {...register(`degrees.${index}.degree`, { required: "Degree is required" })}
          className="input-field"
        />
        {errors.degrees?.[index]?.degree && (
          <span className="Stud-qual-error">{errors.degrees[index].degree.message}</span>
        )}
      </td>
      <td>
        <input
          type="text"
          {...register(`degrees.${index}.board`, { required: "Institution is required" })}
          className="input-field"
        />
        {errors.degrees?.[index]?.board && (
          <span className="Stud-qual-error">{errors.degrees[index].board.message}</span>
        )}
      </td>
      <td>
        <input
          type="text"
          {...register(`degrees.${index}.major`, { required: "Major is required" })}
          className="input-field"
        />
        {errors.degrees?.[index]?.major && (
          <span className="Stud-qual-error">{errors.degrees[index].major.message}</span>
        )}
      </td>
      <td>
        <input
          type="text"
          {...register(`degrees.${index}.percentage`, { required: "Percentage is required" })}
          className="input-field"
        />
        {errors.degrees?.[index]?.percentage && (
          <span className="Stud-qual-error">{errors.degrees[index].percentage.message}</span>
        )}
      </td>
      <td>
        <input
          type="number"
          {...register(`degrees.${index}.yop`, { required: "Year of Passing is required" })}
          className="input-field"
        />
        {errors.degrees?.[index]?.yop && (
          <span className="Stud-qual-error">{errors.degrees[index].yop.message}</span>
        )}
      </td>
      <td>
        <button type="button" onClick={() => removeDegreeRow(index)} className="btn btn-link p-0">
          <AiOutlineDelete size={20} color="red" />
        </button>
      </td>
    </tr>
  ))}
</tbody>
          </table>
        )}
        <div className="submitbuttons p-2">
          <button className="button1 m-2 p-2" type="submit">
            Submit
          </button>
          <button className="button2 m-2 p-2" type="reset">
            Reset
          </button>
        </div>
      </form>
    </div>
  );
}

export default QualificationDetails;

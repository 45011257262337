import Addcategory from "../Pages/Vendor/Addcategory";
import Addlist from "../Pages/Vendor/Addlist";
import Advertisement from "../Pages/Vendor/Advertisement";
import Enquiry from "../Pages/Vendor/Enquiry";
import Myprofile from "../Pages/Vendor/Myprofile";
import Offer from "../Pages/Vendor/Offer";
import Packages from "../Pages/Vendor/Packages";
import { Navigate } from "react-router-dom";
import Packageoffers from "../Pages/Vendor/Packageoffers";

const role = localStorage.getItem("role");

const Routers = [
  {
    path: "/vendor",
    name: "Myprofile",
    element: 
    role === "Vendor" ?  <Myprofile /> : <Navigate to="/login" />,
   
  },
  { 
    path: "/vendor/addcategory",
    name: "Addcategory",
    element: 
    role === "Vendor" ?  <Addcategory />: <Navigate to="/login" />,
    
  },
  {
    path: "/vendor/advertisement",
    name: "Advertisement",
    element: 
    role === "Vendor" ?  <Advertisement /> : <Navigate to="/login" />,
    
  },
  {
    path: "/vendor/offer",
    name: "Offer",
    element: 
    role === "Vendor" ?  <Offer /> : <Navigate to="/login" />,
    
  },
  {
    path: "/vendor/addlist",
    name: "Addlist",
    element: 
    role === "Vendor" ?  <Addlist /> : <Navigate to="/login" />,
    
  },
  {
    path: "/vendor/enquiry",
    name: "Enquiry",
    element: 
    role === "Vendor" ?  <Enquiry /> : <Navigate to="/login" />,
    
  },
  {
    path: "/vendor/packages",
    name: "Packages",
    element: 
    role === "Vendor" ?  <Packages /> : <Navigate to="/login" />,
    
  },
  {
    path: "/vendor/packageoffers",
    name: "Packageoffers",
    element: 
    role === "Vendor" ?  <Packageoffers />: <Navigate to="/login" />,
    
  },
];

export default Routers;

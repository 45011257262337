import React, {useState, useEffect} from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Api from "../../../Api";
import { Dropdown } from "primereact/dropdown";

function CreateSchool() {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    getCountry();
  }, []);

  const schoolTypes = ["Elementary","Secondary", "Higher Secondary"];
  const schoolBoards = ["State", "CBSE", "CISCE", "NIOS", "IB", "CAIE"];

  const [countryList, setCountryList] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const [stateList, setStateList] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [districtList, setDistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  const [cityValue, setCityValue] = useState();
  const [cityList, setCityList] = useState([]);
  
  const handleCreateFormSubmit = async () => {
    const userDetails = {
      schoolName: getValues().schoolName,
      schoolId: getValues().schoolId,  
     SchoolType: getValues().SchoolType,
SchoolBoard: getValues().SchoolBoard,
      Country: getValues().Country,
      State: getValues().State,
      District: getValues().District,
      City: getValues().City,
      Address: getValues().Address,
      Pincode: getValues().Pincode,
      PhoneNumber: getValues().PhoneNumber,
      Email: getValues().Email,
      WebsiteLink: getValues().WebsiteLink,
    };
    await Api.post(`fields/createSchoolList`, userDetails).then((resp) => {
      console.log("resp.data", resp.data);
    });
  };
  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setCountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setStateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setDistrictList(res.data.data);
    });
  };

  const getCity = (districtId) => {
    setDistrictValue(districtId);
    Api.get(`city/cityById/${districtId}`).then((res) => {
      setCityList(res.data.data);
    });
  };

  const [schoolType, setSchoolType] = useState(null);
  const [schoolBoard, setSchoolBoard] = useState(null);
  return (
    <div>
      <Container className=" content-style ">
        <Row style={{ marginLeft: "5%" }}>
          <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
            <br />
            <div className="pages-title mt-3">Create School:</div>
            <Row className="mt-4" style={{justifyContent:"flex-start",marginLeft:"5%"}}>
              <Col sm={12} lg={4}>
                <label className="create-title">School Name</label>
       
                <input
                  {...register("schoolName", { required: true })}  // Changed to schoolName
                  className="Create-input"
                />
                {errors.schoolName && (
                  <p className="error-text-color">School Name is required</p>
                )}
              </Col>
       {/* School Type */}
       <Col sm={12} lg={4}>
                <div>
                  <label className="create-title">School Type</label>
                  <Dropdown
                    value={schoolType}
                    options={schoolTypes}
                    placeholder="Select Type"
                    onChange={(e) => {
                      setSchoolType(e.value); // Update local state
                      setValue("SchoolType", e.value); // Update react-hook-form value
                    }}
                    className="Create-input"
                  />
                  {errors.SchoolType && (
                    <p className="error-text-color">
                      School Type is required
                    </p>
                  )}
                </div>
              </Col>
                  {/* School Type */}
       <Col sm={12} lg={4}>
                <div>
                  <label className="create-title">School Board</label>
                  <Dropdown
                    value={schoolBoard}
                    options={schoolBoards}
                    placeholder="Select Type"
                    onChange={(e) => {
                      setSchoolBoard(e.value); // Update local state
                      setValue("SchoolBoard", e.value); // Update react-hook-form value
                    }}
                    className="Create-input"
                  />
                  {errors.SchoolBoard && (
                    <p className="error-text-color">
                      School Board is required
                    </p>
                  )}
                </div>
              </Col>
         
                      {/* Country */}
                      <Col sm={12} lg={4}>
                <label className="mb-1">Country</label>
                <Dropdown
                  filter
                  className="Create-input"
              
                  value={countryValue}
                  options={countryList}
                  {...register("Country", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  onChange={(e) => getState(e.value)}
                />
                {errors.Country && (
                  <p className="error-text-color">Country is required</p>
                )}
              </Col>

              {/* State */}
              <Col sm={12} lg={4}>
                <label className="mb-1">State</label>
                <Dropdown
                  filter
                  className="Create-input"
                  value={stateValue}
                  options={stateList}
                  {...register("State", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  onChange={(e) => getDistrict(e.value)}
                />
                {errors.State && (
                  <p className="error-text-color">State is required</p>
                )}
              </Col>

              {/* District */}
              <Col sm={12} lg={4}>
                <label className="mb-1">District</label>
                <Dropdown
                  filter
                  className="Create-input"
                  value={districtValue}
                  options={districtList}
                  {...register("District", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  onChange={(e) => getCity(e.value)}
                />
                {errors.District && (
                  <p className="error-text-color">District is required</p>
                )}
              </Col>

              {/* City */}
              <Col sm={12} lg={4}>
                <label className="mb-1">City</label>
                <Dropdown
                  filter
                  className="Create-input"
                  value={cityValue}
                  options={cityList}
                  {...register("City", { required: true })}
                  optionLabel={"cityName"}
                  optionValue={"_id"}
                  onChange={(e) => setCityValue(e.value)}
                />
                {errors.City && (
                  <p className="error-text-color">City is required</p>
                )}
              </Col>

              {/* Address */}
              <Col sm={12} lg={4}>
                <label className="mb-1">Address</label>
                <input
                  type="text"
                  className="Create-input"
                  {...register("Address", { required: true })}
                />
                {errors.Address?.type === "required" && (
                  <p className="error-text-color">Address is required</p>
                )}
              </Col>

              {/* Pincode */}
              <Col sm={12} lg={4}>
                <label className="mb-1 font-sign-log">Pincode</label>
                <input
                  type="number"
                  className="Create-input"
                  {...register("Pincode", {
                    required: true,
                    minLength: 6,
                    maxLength: 6,
                  })}
                />
                {errors.Pincode?.type === "required" && (
                  <p className="error-text-color">Pincode is required</p>
                )}
                {errors.Pincode?.type === "minLength" && (
                  <p className="error-text-color">6 digit only</p>
                )}
                {errors.Pincode?.type === "maxLength" && (
                  <p className="error-text-color">6 digit only</p>
                )}
              </Col>

              {/* Phone Number */}
              <Col sm={12} lg={4}>
                <div>
                  <label className="create-title">Phone Number</label>
                  <input
                    type="tel"
                    {...register("PhoneNumber", {
                      required: true,
                      pattern: /^\d{10}$/,
                    })}
                    className="Create-input"
                  />
                  {errors.PhoneNumber && (
                    <p className="error-text-color">
                      Valid Phone Number is required
                    </p>
                  )}
                </div>
              </Col>

              {/* Email */}
              <Col sm={12} lg={4}>
                <div>
                  <label className="create-title">Email</label>
                  <input
                    type="email"
                    {...register("Email", { required: true })}
                    className="Create-input"
                  />
                  {errors.Email && (
                    <p className="error-text-color">Valid Email is required</p>
                  )}
                </div>
              </Col>

              {/* Website Link */}
              <Col sm={12} lg={4}>
                <div>
                  <label className="create-title">Website Link</label>
                  <input
                    type="url"
                    {...register("WebsiteLink", { required: true })}
                    className="Create-input"
                  />
                  {errors.WebsiteLink && (
                    <p className="error-text-color">Valid URL is required</p>
                  )}
                </div>
              </Col>
            </Row>
            <br />
            <div className="submitbuttons p-2">
              <button
                className="button1 m-2 p-2"
                type="submit"
              >
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
        </Row>
      </Container>
    </div>
  );
}

export default CreateSchool;

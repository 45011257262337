// import React, { useState, useEffect, useContext } from "react";
// import { NavLink, Link, useNavigate } from "react-router-dom";
// import { HiUserCircle } from "react-icons/hi";
// import { Switch } from "antd";
// import logo from "../../Images/Careerblitz logo.png";
// import "../Css/HeaderNavbar.scss";
// import { ThemeContext } from "../../HomePageContent/ThemeContext";

// const HeaderNavbar = () => {
//   const navigate = useNavigate();
//   const [isOpen, setIsOpen] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [auth, setAuth] = useState(localStorage.getItem("USER_AUTH_STATE"));
//   const { themeKey, changeTheme } = useContext(ThemeContext);

//   const handleThemeChange = (checked) => {
//     changeTheme(checked ? "dark" : "light");
//   };

//   const role = localStorage.getItem("role");
//   const companyName = localStorage.getItem("companyName");

//   const logoutHandler = (e) => {
//     e.preventDefault();
//     localStorage.clear();
//     setAuth(null);
//     setIsOpen(false);
//     navigate("/login");
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.pageYOffset !== 0);
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     setAuth(localStorage.getItem("USER_AUTH_STATE"));
//   }, []);

//   return (
//     <div className={`navbar-container ${isScrolled ? "scrolled" : ""}`}>
//       <nav className="navbar desktop-navbar">
//         <div className="brand">
//           <Link to="/">
//             <img className="logo" src={logo} alt="Company Logo" />
//           </Link>
//         </div>
//         <div className="nav-links">
//           <NavLink to="/aboutus" className="nav-hover-link">
//             About Us
//           </NavLink>
//           <NavLink to="/contactus" className="nav-hover-link">
//             Contact Us
//           </NavLink>
//           <NavLink to="/courses" className="nav-hover-link">
//             Courses
//           </NavLink>
//         </div>

//         <div className="auth-buttons">
//           <div className="theme-select">
//             <Switch
//               checkedChildren={<b>Dark</b>}
//               unCheckedChildren={<b>Light</b>}
//               checked={themeKey === "dark"}
//               onChange={handleThemeChange}
//             />
//           </div>
//           {!auth ? (
//             <>
//               <Link to="/signup">
//                 <button className="btns">Sign Up</button>
//               </Link>
//               <Link to="/login">
//                 <button className="btns primary">Log In</button>
//               </Link>
//             </>
//           ) : (
//             <div className="user-icon">
//               <HiUserCircle
//                 style={{ fontSize: 40, cursor: "pointer", color: "grey" }}
//                 onClick={() => setIsOpen(!isOpen)}
//               />
//               {isOpen && (
//                 <div className="dropdown-menu">
//                   <Link
//                     to={`/${role}${
//                       role === "employer" ? `/${companyName}` : ""
//                     }`}
//                     onClick={() => setIsOpen(false)}
//                   >
//                     Dashboard
//                   </Link>
//                   <hr />
//                   <Link to="#" onClick={logoutHandler}>
//                     Log Out
//                   </Link>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       </nav>

//       <nav className="navbar mobile-navbar">
//         <div className="brand">
//           <Link to="/">
//             <img className="logo" src={logo} alt="Company Logo" />
//           </Link>
//         </div>
//         <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
//           &#9776;
//         </button>
//         {isOpen && (
//           <div className={`offcanvas ${isOpen ? "show" : ""}`}>
//             <div className="nav-links">
//               <NavLink
//                 to="/aboutus"
//                 className="nav-link"
//                 onClick={() => setIsOpen(false)}
//               >
//                 About Us
//               </NavLink>
//               <NavLink
//                 to="/contactus"
//                 className="nav-link"
//                 onClick={() => setIsOpen(false)}
//               >
//                 Contact Us
//               </NavLink>
//               <NavLink
//                 to="/courses"
//                 className="nav-link"
//                 onClick={() => setIsOpen(false)}
//               >
//                 Courses
//               </NavLink>
//               {!auth ? (
//                 <>
//                   <Link to="/signup/Student" onClick={() => setIsOpen(false)}>
//                     <button className="btn">Sign Up</button>
//                   </Link>
//                   <Link to="/login" onClick={() => setIsOpen(false)}>
//                     <button className="btn primary">Log In</button>
//                   </Link>
//                 </>
//               ) : (
//                 <div className="auth-links">
//                   <Link
//                     to={`/${role}${
//                       role === "employer" ? `/${companyName}` : ""
//                     }`}
//                     onClick={() => setIsOpen(false)}
//                   >
//                     <button className="btn">Dashboard</button>
//                   </Link>
//                   <button className="btn logout" onClick={logoutHandler}>
//                     Log Out
//                   </button>
//                 </div>
//               )}
//             </div>
//             <button className="close-button" onClick={() => setIsOpen(false)}>
//               Close
//             </button>
//           </div>
//         )}
//       </nav>
//     </div>
//   );
// };

// export default HeaderNavbar;

import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { HiUserCircle } from "react-icons/hi";
import { Switch } from "antd";
import logo from "../../Images/Careerblitz logo.png";
import "../Css/HeaderNavbar.scss";
import { ThemeContext } from "../../HomePageContent/ThemeContext";

const HeaderNavbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  console.log("step1", isOpen);
  const [isScrolled, setIsScrolled] = useState(false);
  const [auth, setAuth] = useState(localStorage.getItem("USER_AUTH_STATE"));
  const { themeKey, changeTheme } = useContext(ThemeContext);

  const role = localStorage.getItem("role");
  const companyName = localStorage.getItem("companyName");

  const handleThemeChange = (checked) => {
    changeTheme(checked ? "dark" : "light");
  };

  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.clear();
    setAuth(null);
    setIsOpen(false);
    navigate("/login");
  };

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.pageYOffset !== 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setAuth(localStorage.getItem("USER_AUTH_STATE"));
  }, []);

  const renderDesktopNavLinks = () => (
    <div className="nav-links">
      <NavLink to="/aboutus" className="nav-hover-link">
        About Us
      </NavLink>
      <NavLink to="/courses" className="nav-hover-link">
        Courses
      </NavLink>
      <NavLink to="/career" className="nav-hover-link">
        Careers
      </NavLink>
      <NavLink to="/blogs" className="nav-hover-link">
        Blog
      </NavLink>
      <NavLink to="/events" className="nav-hover-link">
        Event
      </NavLink>
      <NavLink to="/contactus" className="nav-hover-link">
        Contact Us
      </NavLink>
    </div>
  );

  const renderAuthButtons = () =>
    !auth ? (
      <>
        <Link to="/signup">
          <button className="btns">Sign Up</button>
        </Link>
        <Link to="/login">
          <button className="btns primary">Log In</button>
        </Link>
      </>
    ) : (
      <div className="user-icon">
        <HiUserCircle
          style={{ fontSize: 40, cursor: "pointer", color: "grey" }}
          onClick={() => {
            setIsOpen(!isOpen);
            console.log("step1 toggled:", !isOpen);
          }}
        />
        {isOpen && (
          <div className="navBar_popupCls">
            <Link
              to={`/${role}${role === "employer" ? `/${companyName}` : ""}`}
              onClick={() => setIsOpen(false)}
            >
              Dashboard
            </Link>
            <hr />
            <Link to="#" onClick={logoutHandler}>
              Log Out
            </Link>
          </div>
        )}
      </div>
    );

  const renderMobileNavLinks = () => (
    <div className="nav-links">
      <NavLink
        to="/aboutus"
        className="nav-link"
        onClick={() => setIsOpen(false)}
      >
        About Us
      </NavLink>
      <NavLink
        to="/courses"
        className="nav-link"
        onClick={() => setIsOpen(false)}
      >
        Courses
      </NavLink>
      <NavLink
        to="/career"
        className="nav-link"
        onClick={() => setIsOpen(false)}
      >
        Careers
      </NavLink>
      <NavLink
        to="/blogs"
        className="nav-link"
        onClick={() => setIsOpen(false)}
      >
        Blogs
      </NavLink>
      {/* <NavLink
        to="/events"
        className="nav-link"
        onClick={() => setIsOpen(false)}
      >
        Events
      </NavLink> */}
      <NavLink
        to="/contactus"
        className="nav-link"
        onClick={() => setIsOpen(false)}
      >
        Contact Us
      </NavLink>
      {!auth ? (
        <>
          <Link to="/signup/Student" onClick={() => setIsOpen(false)}>
            <button className="btn">Sign Up</button>
          </Link>
          <Link to="/login" onClick={() => setIsOpen(false)}>
            <button className="btn primary">Log In</button>
          </Link>
        </>
      ) : (
        <div className="auth-links">
          <Link
            to={`/${role}${role === "employer" ? `/${companyName}` : ""}`}
            onClick={() => setIsOpen(false)}
          >
            <button className="btn">Dashboard</button>
          </Link>
          <button className="btn logout" onClick={logoutHandler}>
            Log Out
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className={`navbar-container ${isScrolled ? "scrolled" : ""}`}>
      <nav className="navbar desktop-navbar">
        <div className="brand">
          <Link to="/">
            <img className="logo" src={logo} alt="Company Logo" />
          </Link>
        </div>
        {renderDesktopNavLinks()}
        <div className="auth-buttons">
          {/* <div className="theme-select">
            <Switch
              checkedChildren={<b>Dark</b>}
              unCheckedChildren={<b>Light</b>}
              checked={themeKey === "dark"}
              onChange={handleThemeChange}
            />
          </div> */}
          {renderAuthButtons()}
        </div>
      </nav>

      <nav className="navbar mobile-navbar">
        <div className="brand">
          <Link to="/">
            <img className="logo" src={logo} alt="Company Logo" />
          </Link>
        </div>
        <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
          &#9776;
        </button>
        {isOpen && (
          <div className={`offcanvas ${isOpen ? "show" : ""}`}>
            {renderMobileNavLinks()}
            <button className="close-button" onClick={() => setIsOpen(false)}>
              Close
            </button>
          </div>
        )}
      </nav>
    </div>
  );
};

export default HeaderNavbar;

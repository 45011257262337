// import React from "react";
// import { Col, Row, Card } from "antd";
// import "../../../Components/Css/Dashboard.scss";
// import { AiOutlineUser } from "react-icons/ai";
// import { MdOutlinePersonPin } from "react-icons/md";
// import { FaSchool, FaGraduationCap } from "react-icons/fa";
// import { BiBuildings, BiBuilding } from "react-icons/bi";
// import { useNavigate } from "react-router-dom";

// export default function AdminDashboard() {
//   const navigate = useNavigate();
//   return (
//     <div className=" ">
//       <Row className="admin-row">
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards" onClick={() => navigate("/admin/score")}>
//             <div className="card-field">
//             <div>
//               <FaGraduationCap className="fieldicon1"/> {" "}
//             </div>
//               <div>
//                 <h4 className="fieldsname">Students</h4>
//                 <h4 className="fieldscount">200</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards">
//             <div className="card-field">
//             <div>
//               <MdOutlinePersonPin className="fieldicon2"/>
//             </div>
//               <div>
//                 <h4 className="fieldsname">Professional</h4>
//                 <h4 className="fieldscount">100</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards">
//             <div className="card-field">
//             <div>
//               <AiOutlineUser className="fieldicon3"/>
//             </div>
//               <div>
//                 <h4 className="fieldsname">Employer</h4>
//                 <h4 className="fieldscount">100</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards">
//             <div className="card-field">
//             <div>
//               <BiBuildings className="fieldicon4"/>
//             </div>
//               <div>
//                 <h4 className="fieldsname">Colleges</h4>
//                 <h4 className="fieldscount">100</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards">
//             <div className="card-field">
//             <div>
//               <FaSchool className="fieldicon5"/>
//             </div>
//               <div>
//                 <h4 className="fieldsname">Schools</h4>
//                 <h4 className="fieldscount">100</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//         <Col sm={24} md={12} lg={8} className="dash-col">
//           <Card className="Dash-cards">
//             <div className="card-field">
//             <div>
//               <BiBuilding className="fieldicon6"/>
//             </div>
//               <div>
//                 <h4 className="fieldsname">Training institution</h4>
//                 <h4 className="fieldscount">100</h4>
//               </div>
//             </div>
//           </Card>
//         </Col>
//       </Row>
//       <div className="colors"></div>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import "../../../Components/Css/Dashboard.scss";
import { FaGraduationCap } from "react-icons/fa";
import Api from "./../../../Api";
import { useNavigate } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";

export default function AdminDashboard() {
  const [studentCount, setStudentCount] = useState([]);
  const [professionalCount, setProfessionalCount] = useState([]);
  const [employerCount, setEmployerCount] = useState([]);
  const [institutionCount, setInstitutionCount] = useState([]);
  const [vendorCount, setVendorCount] = useState([]);
  const [collegeCount, setCollegeCount] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: "ease-in-out", // Animation easing
    });
  }, []);

  useEffect(() => {
    getCountStudent();
    getCountProfessional();
    getCountEmployer();
    getCountinstitutionCount();
    getCollege();
    getCountVendor();
  }, []);
  // Students
  const getCountStudent = async () => {
    await Api.get("/signup/getAll").then((res) => {
      setStudentCount(res.data.data.length);
    });
  };
  // professional
  const getCountProfessional = async () => {
    await Api.get("/employee/Employee_get").then((res) => {
      setProfessionalCount(res.data.data.length);
    });
  };
  // Employer
  const getCountEmployer = async () => {
    await Api.get("/employer/Employer_get").then((res) => {
      setEmployerCount(res.data.data.length);
      console.log("empcount", res);
    });
  };
  //vendor
  const getCountVendor = async () => {
    await Api.get("/vendor/getAll").then((res) => {
      setVendorCount(res.data.data.length);
      console.log("empcount", res);
    });
  };
  // Institution
  const getCountinstitutionCount = async () => {
    await Api.get("/institute/institute_get").then((res) => {
      setInstitutionCount(res.data.data.length);
    });
  };
  // college
  const getCollege = async () => {
    await Api.get("/college/college_get").then((res) => {
      setCollegeCount(res.data.data.length);
    });
  };

  const AdminCardStyle = {
    borderRadius: "8px",
    padding: "15px 0px",
    width: "200px",
    height: "165px",
    cursor: "pointer",
  };

  return (
    <div className=" " style={{ backgroundColor: "#eaeef3" }}>
      <Row className="admin-row">
        <Col
          sm={24}
          md={12}
          lg={6}
          className="dash-col"
          style={{ padding: "20px 25px 25px" }}
        >
          <div>
            <Card
              data-aos="flip-left"
              style={{ ...AdminCardStyle, backgroundColor: "#a979d1" }}
              onClick={() => navigate("/admin/score")}
            >
              <Row>
                <Col sm={16} lg={16} style={{ textAlign: "center" }}>
                  <FaGraduationCap className="Afieldicon1" />
                </Col>
                <Col sm={8} lg={8} style={{ textAlign: "center" }}>
                  <p className="fieldscount1">{studentCount}</p>
                </Col>
              </Row>
              <div style={{ textAlign: "center" }}>
                {" "}
                <h7 className="fieldsname1">Students</h7>{" "}
              </div>
            </Card>
          </div>
        </Col>
        <Col
          sm={24}
          md={12}
          lg={6}
          className="dash-col"
          style={{ padding: "20px 25px 25px" }}
        >
          <Card
            data-aos="flip-left"
            style={{ ...AdminCardStyle, backgroundColor: "#FFB100" }}
            onClick={() => navigate("/admin/")}
          >
            <Row>
              <Col sm={12} lg={12} style={{ textAlign: "center" }}>
                <FaGraduationCap className="Afieldicon1" />
              </Col>
              <Col sm={12} lg={9} style={{ textAlign: "center" }}>
                <p className="fieldscount1">{professionalCount}</p>
              </Col>
            </Row>
            <div style={{ textAlign: "center" }}>
              {" "}
              <h7 className="fieldsname1">Professionals</h7>{" "}
            </div>
          </Card>
        </Col>
        <Col
          sm={24}
          md={12}
          lg={6}
          className="dash-col"
          style={{ padding: "20px 25px 25px" }}
        >
          <Card
            data-aos="flip-left"
            style={{
              ...AdminCardStyle,
              backgroundColor: "#2DCDDF",
            }}
            onClick={() => navigate("/admin/")}
          >
            <Row>
              <Col sm={12} lg={12} style={{ textAlign: "center" }}>
                <FaGraduationCap className="Afieldicon1" />
              </Col>
              <Col sm={12} lg={9} style={{ textAlign: "center" }}>
                <p className="fieldscount1">{employerCount}</p>
              </Col>
            </Row>
            <div style={{ textAlign: "center" }}>
              {" "}
              <h7 className="fieldsname1">Employers</h7>{" "}
            </div>
          </Card>
        </Col>
        <Col
          sm={24}
          md={12}
          lg={6}
          className="dash-col"
          style={{ padding: "20px 25px 25px" }}
        >
          <Card
            data-aos="flip-left"
            style={{
              ...AdminCardStyle,
              backgroundColor: "#c32143",
            }}
            onClick={() => navigate("/admin/")}
          >
            <Row>
              <Col sm={12} lg={12} style={{ textAlign: "center" }}>
                <FaGraduationCap className="Afieldicon1" />
              </Col>
              <Col sm={12} lg={9} style={{ textAlign: "center" }}>
                <p className="fieldscount1">{collegeCount}</p>
              </Col>
            </Row>
            <div style={{ textAlign: "center" }}>
              {" "}
              <h7 className="fieldsname1">Colleges</h7>{" "}
            </div>
          </Card>
        </Col>
        <Col
          sm={24}
          md={12}
          lg={6}
          className="dash-col"
          style={{ padding: "20px 25px 25px" }}
        >
          <Card
            data-aos="flip-left"
            style={{
              ...AdminCardStyle,
              backgroundColor: "#e91e63",
            }}
            onClick={() => navigate("/admin/")}
          >
            <Row>
              <Col sm={12} lg={12} style={{ textAlign: "center" }}>
                <FaGraduationCap className="Afieldicon1" />
              </Col>
              <Col sm={12} lg={9} style={{ textAlign: "center" }}>
                <p className="fieldscount1">{institutionCount}</p>
              </Col>
            </Row>
            <div style={{ textAlign: "center" }}>
              {" "}
              <h7 className="fieldsname1">Institution</h7>{" "}
            </div>
          </Card>
        </Col>
        <Col
          sm={24}
          md={12}
          lg={6}
          className="dash-col"
          style={{ padding: "20px 25px 25px" }}
        >
          <Card
            data-aos="flip-left"
            style={{
              ...AdminCardStyle,
              backgroundColor: "#2196F3",
            }}
            onClick={() => navigate("/admin/")}
          >
            <Row>
              <Col sm={12} lg={12} style={{ textAlign: "center" }}>
                <FaGraduationCap className="Afieldicon1" />
              </Col>
              <Col sm={12} lg={9} style={{ textAlign: "center" }}>
                <p className="fieldscount1">{vendorCount}</p>
              </Col>
            </Row>
            <div style={{ textAlign: "center" }}>
              {" "}
              <h7 className="fieldsname1">vendors</h7>{" "}
            </div>
          </Card>
        </Col>
        <Col
          sm={24}
          md={12}
          lg={6}
          className="dash-col"
          style={{ padding: "20px 25px 25px" }}
        >
          <Card
            data-aos="flip-left"
            style={{
              ...AdminCardStyle,
              backgroundColor: "#E67D21",
            }}
            onClick={() => navigate("/admin/")}
          >
            <Row>
              <Col sm={12} lg={12} style={{ textAlign: "center" }}>
                <FaGraduationCap className="Afieldicon1" />
              </Col>
              <Col sm={12} lg={9} style={{ textAlign: "center" }}>
                <p className="fieldscount1">{institutionCount}</p>
              </Col>
            </Row>
            <div style={{ textAlign: "center" }}>
              {" "}
              <h7 className="fieldsname1">Over All Revenue</h7>{" "}
            </div>
          </Card>
        </Col>
        <Col
          sm={24}
          md={12}
          lg={6}
          className="dash-col"
          style={{ padding: "20px 25px 25px" }}
        >
          <Card
            data-aos="flip-left"
            style={{
              ...AdminCardStyle,
              backgroundColor: "#5CB85C",
            }}
            onClick={() => navigate("/admin/")}
          >
            <Row>
              <Col sm={12} lg={12} style={{ textAlign: "center" }}>
                <FaGraduationCap className="Afieldicon1" />
              </Col>
              <Col sm={12} lg={9} style={{ textAlign: "center" }}>
                <p className="fieldscount1">{institutionCount}</p>
              </Col>
            </Row>
            <div style={{ textAlign: "center" }}>
              {" "}
              <h7 className="fieldsname1">Our Employees</h7>{" "}
            </div>
          </Card>
        </Col>
      </Row>
      <div className="colors"></div>
    </div>
  );
}

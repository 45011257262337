import React, { useState, useEffect } from "react";
import { Table, Input, Button, Popconfirm, message } from "antd";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../../Components/Css/Table.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan, faUserPlus } from "@fortawesome/free-solid-svg-icons";

function ManageDomains() {
  const [domains, setDomains] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out" });
    fetchSectors(); 
    fetchDomains(); 
  }, []);

  const fetchSectors = async () => {
    try {
      const res = await Api.get("/coursesector/getsector");
      setSectors(res.data); 
    } catch (err) {
      console.error(err);
      message.error("Failed to fetch sectors");
    }
  };


  const fetchDomains = async () => {
    try {
      setLoading(true);
      const res = await Api.get("/coursedomain/getalldomains");
      setDomains(res.data); 
    } catch (err) {
      console.error(err);
      message.error("Failed to fetch domains");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDomain = async (id) => {
    try {
      setLoading(true);
      await Api.delete(`/coursedomain/deletedomain/${id}`);
      setDomains(domains.filter((domain) => domain._id !== id));
      message.success("Domain deleted successfully");
    } catch (err) {
      console.error(err);
      message.error("Failed to delete domain");
    } finally {
      setLoading(false);
    }
  };

  const filteredDomains = domains.filter((domain) =>
    domain.domainName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getSectorName = (sectorId) => {
    const sector = sectors.find((s) => s._id === sectorId);
    return sector ? sector.sectorName : "Unknown";
  };

  const handleEditDomain = (record) => {
    navigate(`/admin/editdomain/${record._id}`, {
      state: { domainId: record._id, sectorId: record.sectorId },
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {index + 1}
        </div>
      ),
    },
    {
      title: "Sector",
      dataIndex: "sectorName",
      key: "sectorName",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {getSectorName(record.sectorId)} 
        </div>
      ),
    },
    {
      title: "Domain Name",
      dataIndex: "domainName",
      key: "domainName",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record, index) => (
        <div data-aos="zoom-in" data-aos-delay={index * 100}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={() => handleEditDomain(record)}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDeleteDomain(record._id)}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="data_list_main p-3">
      <h4 className="data_list_header">Manage Domains</h4>
      <div
        className="data_list_top mt-4 py-4 px-3"
        style={{ backgroundColor: "#c5c5c5" }}
      >
        <Input
          className="data_list_search ps-2"
          placeholder="Search by domain name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="data_list_button"
          onClick={() => navigate("/admin/adddomainform")} 
        >
          Add Domain
        </Button>
      </div>
      <div className="Datalist-Table mt-2">
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={filteredDomains.map((domain, index) => ({
            ...domain,
            key: domain._id,
            index,
          }))}
          loading={loading}
          pagination={{
            pageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20'],
          }}
          className="domains-table"
        />
      </div>
    </div>
  );
}

export default ManageDomains;

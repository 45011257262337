import React, { useCallback, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Container } from "react-bootstrap";
// import "./AdminBlogstyle.scss";
import { useNavigate, useLocation } from "react-router-dom";
import Api from "../../../Api";
// import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { Radio } from 'antd';


function TechnologyCreate() {
  const navigate = useNavigate();
  const toast = useRef(null);
  const { state: locationState } = useLocation();
  console.log(locationState, "loca");

  const [radio, setRadio] = useState('');
  const [logo, setLogo] = useState('');
  const [filePath, setFilePath] = useState("");
  console.log("ppppp", logo)

  const handleChange = (e) => {
    const file = e.target.files;
    setLogo(file);
    const path = URL.createObjectURL(file);
    setFilePath(path);
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: locationState ? locationState.ele : {} });

  const handleFormSubmit = (data) => {
    try {

      if (locationState) {
        //update
        Api.put(`/Technology/update/${locationState.ele.id}`, data).then((res) => {
          navigate(-1)
        })
      } else {
        //create
        Api.post(`Technology/create`, data)
          .then((resp) => {
            navigate(-1)
            console.log("111111", resp);
          })
          .catch((resp) => {
            console.log("error");
          });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong,please try again!!!",
        life: 3000,
      });
    }
  };


  const plainOptions = ['Yes', 'No'];


  const onChange1 = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setRadio(value);


  };


  // const SelectFile = async (event) => {
  //   const file = event.target.files[0];
  //   const type = file?.type?.split("/")[0];
  //   const base64 = await convertBase64(file);
  //   setLogo(base64);
  // };
  // const convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  // console.log(SelectFile, "sssss")

  return (
    <div>
      <Container style={{ width: '90%' }}>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="Blog-form px-2 ">
            <div className="pages-title mb-3">Add Technology</div>
            <Row >
              <Col xs={12} sm={12} md={12} lg={6} className="px-5 py-3">
                <label className="input-title pe-5">Technology Name</label>
                <input
                  placeholder="Enter Technology Name"
                  className="technolgyInput"
                  {...register("Technology", { required: true })}
                />
                {errors.Technology && (
                  <span className="Blog-error">Technology is required</span>
                )}
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="px-5 py-3">
                <label className="input-title pe-5">Position</label>
                <input
                  placeholder="Enter Position"
                  className="technolgyInput"
                  {...register("position", { required: true })}
                />
                {errors.position && (
                  <span className="Blog-error">Position is required</span>
                )}
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="px-5 py-3">
                <label className="input-title pe-5">version</label>
                <input
                  style={{ width: '90%' }}
                  placeholder="Enter version"
                  type="number" step="any"
                  className="technolgyInput"
                  {...register("version", { required: false })}
                />
                {errors.version && (
                  <span className="Blog-error">Version is required</span>
                )}
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="px-5 py-3">
                <label className="input-title pe-5">Description</label>
                <input
                  placeholder="Write Description"
                  className="technolgyInput"
                  {...register("description", { required: true })}
                />
                {errors.description && (
                  <span className="Blog-error">Description is required</span>
                )}
              </Col>
              {/* <Col md={24} lg={10} className="p-2">
              <label className="input-title pe-5">Logo</label>
              <br />
              <Form.Group controlId="logo" className="">
                <Form.Label>Upload Logo</Form.Label>
                <Form.Control
                  accept=".png, .jpg, .jpeg"
                  // className="technolgyInput"
                  type="file"
                  {...register("logo", { required: false })}
                  onChange={(e) => {
                    const file = e.target.files;
                    setLogo(e.target.files[0]);
                    // SelectFile(e);
                    const filePath = (file);
                    console.log("logoPath", filePath)
                    // Use the filePath variable to store the path of the uploaded file
                  }}
                />
              </Form.Group>
              <br />
              {errors.logo && (
                <span className="Blog-error">Logo is required</span>
              )}
            </Col> */}
            </Row>
            <Col xs={12} sm={12} md={12} lg={6} className="px-5 py-3">
              <h3>Do you want to add frame Work
                <Radio.Group
                  style={{ paddingLeft: '20px' }}
                  options={plainOptions}
                  onChange={onChange1}
                  value={radio}
                />
                <br />
                {/* <Radio.Group options={optionsWithDisabled} onChange={onChange2} value={value2} /> */}

              </h3>
            </Col>

            {radio === "Yes" &&
              <Col md={24} lg={12} className="p-2">
                <label className="input-title pe-5">frameWork</label>
                <br />
                <input placeholder="you can add multiple FrameWork"
                  className="technolgyInput"
                  {...register("frameWork[]", { required: true })}
                  multiple
                />
                <br />
                {errors.frameWork && (
                  <span className="Blog-error">frameWork is required</span>
                )}
              </Col>}

            <div className="submitbuttons p-2">
              <button
                className="button1 m-2 p-2"
                type="submit"
              // onClick={() => navigate(-1)}
              >
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </div>
        </Form>
      </Container>
    </div>
  );
}

export default TechnologyCreate;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  MdPerson,
  MdCategory,
  MdBusiness,
  MdLocalOffer,
  MdOutlineListAlt,
  // MdPackage,
  MdOutlineLocalOffer,
  MdLogout,
} from "react-icons/md";
import { LuLayoutList } from "react-icons/lu";
import { CgProfile } from "react-icons/cg";
// import "../../../Components/Css/Sidebar.scss";
import '../../Components/Css/Sidebar.scss'
// import SidebarToggle from "./SidebarToggle";
import  SidebarToggle from  "../Student/DefaultLayout/SidebarToogle"

function Index() {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleRightSidebar = () => {
    setIsRightSidebarCollapsed((prev) => !prev);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  const handleSubmenuClick = (submenu) => {
    if (submenu === activeSubmenu) {
      setActiveSubmenu(null);
      setIsRightSidebarCollapsed(true);
    } else {
      setActiveSubmenu(submenu);
      setIsRightSidebarCollapsed(false);
    }
  };

  const logoutHandler = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <div className={`sidebar-container ${isSidebarCollapsed ? "collapsed" : ""}`}>
      <SidebarToggle toggleSidebar={toggleSidebar} isSidebarCollapsed={isSidebarCollapsed} />

      {!isSidebarCollapsed && (
        <div className="sidebar-content">
          <div className="nav-list-sidebar">
            <NavLink
              to="/vendor"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <CgProfile className="nav-icon" />
              <span className="side-title">My Profile</span>
            </NavLink>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("category")}
            >
              <MdCategory className="nav-icon" />
              <span className="side-title">Category</span>
            </div>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("business")}
            >
              <MdBusiness className="nav-icon" />
              <span className="side-title">Business</span>
            </div>
            <NavLink
              to="/vendor/packages"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <LuLayoutList className="nav-icon" />
              <span className="side-title">Packages</span>
            </NavLink>
            <NavLink
              to="/vendor/packageoffers"
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdOutlineLocalOffer className="nav-icon" />
              <span className="side-title">Package Offers</span>
            </NavLink>
            <div
              className="nav-item"
              onClick={logoutHandler}
            >
              <MdLogout className="nav-icon" />
              <span className="side-title">Logout</span>
            </div>
          </div>
        </div>
      )}

      {activeSubmenu === "category" && (
        <div
          className={`right-sidebar ${
            isRightSidebarCollapsed ? "collapsed" : ""
          }`}
        >
          <div className="right-sidebar-header">
            <div className="collapse-btn" onClick={toggleRightSidebar}>
              {isRightSidebarCollapsed ? ">" : "<"}
            </div>
          </div>
          <NavLink to="/vendor/addcategory" className="submenu-item">
            <MdCategory className="nav-sub-icon" />
            <span className="sub-side-title">Add Category</span>
          </NavLink>
          <NavLink to="/vendor/addlist" className="submenu-item">
            <MdOutlineListAlt className="nav-sub-icon" />
            <span className="sub-side-title">Add List</span>
          </NavLink>
        </div>
      )}

      {activeSubmenu === "business" && (
        <div
          className={`right-sidebar ${
            isRightSidebarCollapsed ? "collapsed" : ""
          }`}
        >
          <div className="right-sidebar-header">
            <div className="collapse-btn" onClick={toggleRightSidebar}>
              {isRightSidebarCollapsed ? ">" : "<"}
            </div>
          </div>
          <NavLink to="/vendor/advertisement" className="submenu-item">
            <MdBusiness className="nav-sub-icon" />
            <span className="sub-side-title">Advertisement</span>
          </NavLink>
          <NavLink to="/vendor/offer" className="submenu-item">
            <MdLocalOffer className="nav-sub-icon" />
            <span className="sub-side-title">Offer</span>
          </NavLink>
          <NavLink to="/vendor/enquiry" className="submenu-item">
            <MdOutlineListAlt className="nav-sub-icon" />
            <span className="sub-side-title">Enquiry</span>
          </NavLink>
        </div>
      )}
    </div>
  );
}

export default Index;

import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal, Select, Tabs } from "antd";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCan,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import "../../../Components/Css/Table.scss";
import { CloseOutlined } from "@ant-design/icons";
import "aos/dist/aos.css";
import AOS from "aos";
import ExamsTabs from "../../Admin/Dashboard/AdminSidebar/ExamsTabs/ExamsTabs";
import { Container } from "react-bootstrap";

const { TabPane } = Tabs;

function CourseList() {
  const [techList, setTechList] = useState([]);
  const [getData, setGetData] = useState([]);
  const [search, setSearch] = useState("");
  const [trainerList, setTrainerList] = useState([]);
  const [selectedTrainers, setSelectedTrainers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [filteredTrainerList, setFilteredTrainerList] = useState([]);
  const [skillSearch, setSkillSearch] = useState("");
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getCourse();
    getTrainers();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  const addNew = () => {
    navigate("create", { state: { mode: "add", formData: {} } });
  };

  const edit = (course) => {
    navigate("create", { state: { mode: "edit", course } });
  };
  const getCourse = async () => {
    try {
      const res = await Api.get("course/getAllCourses");
      setTechList(res.data);
      setGetData(res.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const getTrainers = async () => {
    try {
      const res = await Api.get("/trainer/Trainerget");
      if (res.data && Array.isArray(res.data.data)) {
        setTrainerList(res.data.data);
        setFilteredTrainerList(res.data.data);
      } else {
        console.error("Trainer List is not an array:", res.data);
        setTrainerList([]);
      }
    } catch (error) {
      console.error("Error fetching trainers:", error);
      setTrainerList([]);
    }
  };

  const deleteCourse = async (id) => {
    try {
      await Api.delete(`course/deletecourse/${id}`);
      getCourse();
    } catch (error) {
      console.error("Error deleting course:", error);
    }
  };

  const update = (course) => {
    navigate("create", { state: { course } });
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearch(query);
    const filteredData = techList.filter((course) =>
      course.title.toLowerCase().includes(query)
    );
    setGetData(filteredData);
  };

  const showAssignModal = (course) => {
    setSelectedCourse(course);
    setIsModalVisible(true);
  };

  const handleAssign = async () => {
    if (selectedCourse && selectedTrainers.length > 0) {
      try {
        await Api.put(`/courses/assignTrainers/${selectedCourse._id}`, {
          trainers: selectedTrainers.map((trainerId) => trainerId.toString()),
        });
        setIsModalVisible(false);
        setSelectedTrainers([]);
        getCourse(); // Refresh the course list
      } catch (error) {
        console.error("Error assigning trainers:", error);
      }
    }
  };

  const handleSkillSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSkillSearch(query);
    const filteredTrainers = trainerList.filter((trainer) =>
      trainer.professionalDetails?.skills
        ?.join(", ")
        .toLowerCase()
        .includes(query)
    );
    setFilteredTrainerList(filteredTrainers);
  };

  const columns = [
    {
      title: "Course Name",
      dataIndex: "title",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },

    {
      title: "Course Mode",
      dataIndex: "mode",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record, index) => (
        <div data-aos="zoom-in" data-aos-delay={index * 100}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={() => edit(record)}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteCourse(record._id)}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
          <FontAwesomeIcon
            icon={faUserPlus}
            style={{ paddingLeft: "10px", cursor: "pointer" }}
            onClick={() => showAssignModal(record)}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <ExamsTabs />
      <Container>
        <div className="data_list_main">
          <h4 className="data_list_header">Course List</h4>
          <div
            className="data_list_top mt-4 py-4 px-3"
            style={{ backgroundColor: "#c5c5c5" }}
          >
            <Input
              className="data_list_search ps-2"
              placeholder="Search by course name"
              value={search}
              onChange={handleSearch}
            />
            <Button className="data_list_button" onClick={addNew}>
              <BsFillPersonPlusFill />
              &nbsp;Add New
            </Button>
          </div>
          <div className="Datalist-Table">
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={getData}
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50"],
              }}
              className="mt-2"
              rowKey="_id"
            />
          </div>
        </div>
      </Container>
      <Modal
        title={<div className="modal-title">Assign Trainers</div>}
        visible={isModalVisible}
        onOk={handleAssign}
        onCancel={() => setIsModalVisible(false)}
        closeIcon={<CloseOutlined className="custom-close-icon" />}
        className="custom-modal"
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Assign Existing Trainers" key="1">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select trainers"
              value={selectedTrainers}
              onChange={setSelectedTrainers}
              options={trainerList.map((trainer) => ({
                label: `${trainer.firstName} ${trainer.LastName} (${
                  trainer.professionalDetails?.skills?.join(", ") ||
                  "No skills listed"
                })`,
                value: trainer._id,
              }))}
              className="custom-select"
            />
          </TabPane>
          <TabPane tab="Match Trainers by Skills" key="2">
            <Input
              placeholder="Search by skills"
              value={skillSearch}
              onChange={handleSkillSearch}
              style={{ marginBottom: "16px" }}
            />
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select trainers"
              value={selectedTrainers}
              onChange={setSelectedTrainers}
              options={filteredTrainerList.map((trainer) => ({
                label: `${trainer.firstName} ${trainer.LastName} (${
                  trainer.professionalDetails?.skills?.join(", ") ||
                  "No skills listed"
                })`,
                value: trainer._id,
              }))}
              className="custom-select"
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
}

export default CourseList;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Button } from "antd";
import "../../../Components/Css/Professional/professionalstyle.scss";
import Api from "../../../Api";
import { Select } from "antd";

const { Option } = Select;

function ProfessionalBlog() {
  const questionsvalue = {
    question: "",
  };
  const [questions, setquestions] = useState([questionsvalue]);
  const [blogs, setBlogs] = useState();
  const userId = localStorage.getItem("userId");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger, 
  } = useForm();
  
  const handleFormSubmit = async (data) => {
    const details = {
      domainName: data.domainName,
      subdomainName: data.subdomainName,
      description: data.description,
      tag: data.tag,
    };
    console.log("details", details);
    try {
      const response = await Api.post(`professional/createdBlog`, details);
      setBlogs(response);
      console.log("Blog submitted successfully;", blogs);
    } catch (error) {
      console.error("Error submitting Blog:", error);
    }
  };

  const handleDomainChange = (value) => {
    setValue("domainName", value);
    trigger("domainName"); 
  };

  const handleSubDomainChange = (value) => {
    setValue("subdomainName", value); 
    trigger("subdomainName"); 
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="personal-informations p-5">
          <div className="pages-title mb-3">Blog</div>

          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <label className="input-title">
                Domain
                <Select
                  className="domaindropdown input-field"
                  placeholder="Select Domain"
                  {...register("domainName", { required: "Domain is required" })}
                  onChange={handleDomainChange}
                >
                  <Option value="InformationTechnology">
                    Information Technology
                  </Option>
                  <Option value="Finance">Finance</Option>
                  <Option value="Civil">Civil</Option>
                </Select>
              </label>
              {errors.domainName && (
                <span className="input-error">{errors.domainName.message}</span>
              )}
            </Col>

            <Col xs={24} md={12}>
              <style>
                {`
                   .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
                    padding: 4px;
                    border-radius: 5px;
                    }
                `}
              </style>
              <label className="input-title">
                Sub-Domain
                <Select
                  style={{ padding: "0px 5px" }}
                  mode="multiple"
                  placeholder="Select Sub-Domain"
                  {...register("subdomainName", { required: "Sub-domain is required" })}
                  onChange={handleSubDomainChange}
                >
                  <Option value="Software">Software</Option>
                  <Option value="Hardware">Hardware</Option>
                  <Option value="Database">Database</Option>
                  <Option value="Tech-support">Tech-support</Option>
                  <Option value="HR">HR</Option>
                </Select>
              </label>
              {errors.subdomainName && (
                <span className="input-error">{errors.subdomainName.message}</span>
              )}
            </Col>

            <Col xs={24} md={12}>
              <label className="input-title">
                Blog Description
                <textarea
                  rows="3"
                  className="input-field"
                  {...register("description", { required: "Description is required" })}
                />
              </label>
              {errors.description && (
                <span className="input-error">{errors.description.message}</span>
              )}
            </Col>

            <Col xs={24} md={12}>
              <label className="input-title">
                Tag
                <input
                  type="text"
                  className="input-field"
                  {...register("tag", { required: "Tag is required" })}
                />
              </label>
              {errors.tag && (
                <span className="input-error">{errors.tag.message}</span>
              )}
            </Col>

            <Col xs={24} md={12}>
              <label className="input-title">
                Image
                <input
                  type="file"
                  className="input-field"
                  {...register("image", { required: "Image is required" })}
                />
              </label>
              {errors.image && (
                <span className="input-error">{errors.image.message}</span>
              )}
            </Col>
          </Row>

          <div className="submitbuttons p-2">
            <Button
              type="primary"
              htmlType="submit"
              className="custom-button m-2"
            >
              Submit
            </Button>
            <Button
              type="default"
              htmlType="reset"
              className="custom-button m-2"
            >
              Reset
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ProfessionalBlog;

import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Modal } from "antd";
import "../../../Components/Css/Table.scss";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Api";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "aos/dist/aos.css";
import AOS from "aos";
import ExamsTabs from "../../Admin/Dashboard/AdminSidebar/ExamsTabs/ExamsTabs";
import { Container } from "react-bootstrap";
// import DataListTable from "../datalistTable";

function QuestionBank() {
  const [techList, setTechList] = useState([]);
  console.log("techList", techList);
  const [dailogVal, setDailogVal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getData, setGetData] = useState();
  console.log("getData", getData);
  const [search, setSearch] = useState();
  const [upadteValue, setUpadteValue] = useState([]);
  // console.log(getData,"ghjhjhgjh")

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({});

  useEffect(() => {
    getPattern();
  }, []);

  const getPattern = async () => {
    await Api.get("QuestionBank/get").then((res) => {
      console.log(res.data); // Ensure _id exists in the fetched data
      setTechList(res.data);
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: "ease-in-out", // Animation easing
    });
  }, []);

  const update = (ele) => {
    navigate("qcreate", { state: { ele } });
  };

  const deleteQuestion = (id) => {
    console.log(id); // Check if id is correct
    if (!id) {
      console.error("ID is undefined!");
      return;
    }

    Api.delete(`QuestionBank/delete/${id}`)
      .then((resp) => {
        getPattern();
      })
      .catch((error) => {
        console.error("Error deleting question:", error);
      });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const Technologys = e.target.value;
    console.log(typeof Technologys);

    const newData = techList.filter((list) =>
      list.Technology.toLowerCase().includes(Technologys.toLowerCase())
    );

    setTechList(newData);
  };
  console.log(search, "get");

  const handleModalprops = (id) => {
    const filterdVal = techList.filter((ele) => {
      return ele._id === id;
    });
    setDailogVal(filterdVal[0]);
    return;
  };

  const columns = [
    {
      title: "Technology",
      dataIndex: "Technology",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record, index) => (
        <div data-aos="zoom-in">
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={() => update(record)}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              console.log(record); // Log record to check what fields are available
              deleteQuestion(record._id || record.id); // Use correct identifier
            }}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onSearch = "";
  const navigate = useNavigate();
  return (
    <div>
      <ExamsTabs />
      <Container>
        <div className="data_list_main">
          <h4 className="data_list_header">Question bank </h4>
          <div
            className="data_list_top mt-4 py-4 px-3"
            style={{ backgroundColor: "#c5c5c5" }}
          >
            <input
              className="data_list_search ps-2"
              placeholder="search"
              columns={columns}
              // dataList={techList}
              value={search}
              onChange={handleSearch}
              // enterButton
            />

            <Button
              className="Datalist-Table"
              onClick={() => navigate("qcreate")}
            >
              <BsFillPersonPlusFill />
              &nbsp;Add New
            </Button>
          </div>
          <div className="Datalist-Table">
            <Table
              columns={columns}
              dataSource={techList}
              pagination={true}
              className="mt-2"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default QuestionBank;

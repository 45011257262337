import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import Api from "../../../Api";
import { notification } from "antd";
import "./CourseCreate.css";

const CourseCreateForm = () => {
  const [courseData, setCourseData] = useState({
    title: "",
    description: "",
    course_overview: [""],
    what_you_will_learn: [""],
    original_price: "",
    offer_price: "",
    sector: "",
    domain: "",
    category: "",
    subcategory: "",
    level: "",
    syllabus: [
      {
        section_title: "",
        lessons: [{ lesson_title: "" }],
      },
    ],
    learning_objectives: [""],
    prerequisites: [""],
    image: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleImageUpload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "darshan");

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
        {
          method: "POST",
          body: data,
        }
      );

      const cloudinaryData = await response.json();
      if (cloudinaryData.secure_url) {
        setCourseData((prevData) => ({
          ...prevData,
          image: cloudinaryData.secure_url,
        }));
        return cloudinaryData.secure_url;
      } else {
        throw new Error("Cloudinary upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      notification.error({
        message: "Error",
        description: "Failed to upload image.",
      });
      return null;
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const uploadedImageUrl = await handleImageUpload(file);
      if (!uploadedImageUrl) {
        notification.error({
          message: "Error",
          description: "Failed to upload image. Please try again.",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourseData({ ...courseData, [name]: value });
  };

  const handleArrayChange = (e, fieldName, index) => {
    const updatedArray = [...courseData[fieldName]];
    updatedArray[index] = e.target.value;
    setCourseData({ ...courseData, [fieldName]: updatedArray });
  };

  const handleSyllabusChange = (e, sectionIndex, lessonIndex) => {
    const { name, value } = e.target;
    const updatedSyllabus = [...courseData.syllabus];

    if (name === "section_title") {
      updatedSyllabus[sectionIndex].section_title = value;
    } else if (name === "lesson_title") {
      updatedSyllabus[sectionIndex].lessons[lessonIndex].lesson_title = value;
    }
    setCourseData({ ...courseData, syllabus: updatedSyllabus });
  };

  const addField = (fieldName) => {
    setCourseData({
      ...courseData,
      [fieldName]: [...courseData[fieldName], ""],
    });
  };

  const removeField = (fieldName, index) => {
    const updatedArray = [...courseData[fieldName]];
    updatedArray.splice(index, 1);
    setCourseData({ ...courseData, [fieldName]: updatedArray });
  };

  const addSection = () => {
    setCourseData({
      ...courseData,
      syllabus: [
        ...courseData.syllabus,
        { section_title: "", lessons: [{ lesson_title: "" }] },
      ],
    });
  };

  const removeSection = (index) => {
    const updatedSyllabus = [...courseData.syllabus];
    updatedSyllabus.splice(index, 1);
    setCourseData({ ...courseData, syllabus: updatedSyllabus });
  };

  const addLesson = (sectionIndex) => {
    const updatedSyllabus = [...courseData.syllabus];
    updatedSyllabus[sectionIndex].lessons.push({ lesson_title: "" });
    setCourseData({ ...courseData, syllabus: updatedSyllabus });
  };

  const removeLesson = (sectionIndex, lessonIndex) => {
    const updatedSyllabus = [...courseData.syllabus];
    updatedSyllabus[sectionIndex].lessons.splice(lessonIndex, 1);
    setCourseData({ ...courseData, syllabus: updatedSyllabus });
  };

  const validateForm = () => {
    const requiredFields = [
      "title",
      "description",
      "original_price",
      "offer_price",
      "image",
    ];
    return requiredFields.every((field) => courseData[field]);
  };

  const populateForm = (data) => {
    setCourseData({
      title: data.title || "",
      description: data.description || "",
      course_overview: data.course_overview || [""],
      what_you_will_learn: data.what_you_will_learn || [""],
      original_price: data.original_price || "",
      offer_price: data.offer_price || "",
      sector: data.sector || "",
      domain: data.domain || "",
      category: data.category || "",
      subcategory: data.subcategory || "",
      level: data.level || "",
      syllabus: data.syllabus?.map((section) => ({
        section_title: section.section_title || "",
        lessons: section.lessons?.map((lesson) => ({
          lesson_title: lesson.lesson_title || "",
        })) || [{ lesson_title: "" }],
      })) || [
        { section_title: "", lessons: [{ lesson_title: "" }] },
      ],
      learning_objectives: data.learning_objectives || [""],
      prerequisites: data.prerequisites || [""],
      image: data.image || "",
    });
  };

  const handleJsonUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const parsedData = JSON.parse(event.target.result);
          populateForm(parsedData);
        } catch (error) {
          notification.error({
            message: "Error",
            description: "Invalid JSON file.",
          });
        }
      };
      reader.readAsText(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setMessage("Please fill in all the required fields.");
      setSuccess(false);
      return;
    }

    setLoading(true);
    setMessage("");
    setSuccess(false);

    try {
      console.log("Submitting course data:", courseData);
      const response = await Api.post("/course/coursecreate", courseData);

      if (response.status === 201) {
        setSuccess(true);
        setMessage("Course created successfully!");
      } else {
        setSuccess(false);
        setMessage(`Error creating course: ${response.data?.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error creating course:", error);
      setSuccess(false);
      setMessage("Error creating course.");
    } finally {
      setLoading(false);
    }  };

  return (
    <Container className="course-create-container">
      <h2>Create a New Course</h2>
      {message && (
        <Alert variant={success ? "success" : "danger"}>{message}</Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter course title"
                name="title"
                value={courseData.title}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                style={{ height: "100px" }}
                placeholder="Enter course description"
                name="description"
                value={courseData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="formCourseImage">
              <Form.Label>Course Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleImageChange}
              />
            </Form.Group>
          </Col>
          <Form.Group>
          <Form.Label>Upload Course JSON</Form.Label>
          <Form.Control
            type="file"
            accept=".json"
            onChange={handleJsonUpload}
          />
        </Form.Group>
          <Col md={6}>
            <Form.Group controlId="formSector">
              <Form.Label>Sector</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter sector"
                name="sector"
                value={courseData.sector}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formDomain">
              <Form.Label>Domain</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter domain"
                name="domain"
                value={courseData.domain}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formCategory">
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category"
                name="category"
                value={courseData.category}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formSubcategory">
              <Form.Label>Subcategory</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter subcategory"
                name="subcategory"
                value={courseData.subcategory}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formOriginalPrice">
              <Form.Label>Original Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter original price"
                name="original_price"
                value={courseData.original_price}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formOfferPrice">
              <Form.Label>Offer Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter offer price"
                name="offer_price"
                value={courseData.offer_price}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="formLevel">
              <Form.Label>Level</Form.Label>
              <Form.Control
                as="select"
                name="level"
                value={courseData.level}
                onChange={handleChange}
              >
                <option value="">Select Level</option>
                <option value="Basic">Basic</option>
                <option value="Advanced">Advanced</option>
                <option value="Basic to Advanced">Basic to Advanced</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group>
          <Form.Label>Course Overview</Form.Label>
          {courseData.course_overview.map((value, index) => (
            <Row key={index} className="mb-2">
              <Col md={11}>
                <Form.Control
                  type="text"
                  style={{ height: "45px" }}
                  placeholder={`Enter overview ${index + 1}`}
                  value={value}
                  onChange={(e) =>
                    handleArrayChange(e, "course_overview", index)
                  }
                />
              </Col>
              <Col md={1}>
                <Button
                  variant="danger"
                  style={{ display: "flex", columnGap: "10px" }}
                  onClick={() => removeField("course_overview", index)}
                >
                  Delete
                  <FaTrash />
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            variant="primary"
            onClick={() => addField("course_overview")}
            style={{ display: "flex", columnGap: "10px" }}
          >
            <AiOutlinePlus /> Add Overview
          </Button>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>What You Will Learn</Form.Label>
          {courseData.what_you_will_learn.map((value, index) => (
            <Row key={index} className="mb-2">
              <Col md={11}>
                <Form.Control
                  type="text"
                  style={{ height: "45px" }}
                  placeholder={`Enter learning point ${index + 1}`}
                  value={value}
                  onChange={(e) =>
                    handleArrayChange(e, "what_you_will_learn", index)
                  }
                />
              </Col>
              <Col md={1}>
                <Button
                  variant="danger"
                  style={{ display: "flex", columnGap: "10px" }}
                  onClick={() => removeField("what_you_will_learn", index)}
                >
                  Delete
                  <FaTrash />
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            variant="primary"
            style={{ display: "flex", columnGap: "10px" }}
            onClick={() => addField("what_you_will_learn")}
          >
            {" "}
            <AiOutlinePlus />
            Add Learning Point
          </Button>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Learning Objectives</Form.Label>
          {courseData.learning_objectives.map((value, index) => (
            <Row key={index} className="mb-1">
              <Col md={11}>
                <Form.Control
                  type="text"
                  style={{ height: "45px" }}
                  placeholder={`Enter learning objective ${index + 1}`}
                  value={value}
                  onChange={(e) =>
                    handleArrayChange(e, "learning_objectives", index)
                  }
                />
              </Col>
              <Col md={1}>
                <Button
                  variant="danger"
                  onClick={() => removeField("learning_objectives", index)}
                  style={{ display: "flex", columnGap: "10px" }}
                >
                  Delete
                  <FaTrash />
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            variant="primary"
            onClick={() => addField("learning_objectives")}
            style={{ display: "flex", columnGap: "10px" }}
          >
            <AiOutlinePlus /> Add Learning Objective
          </Button>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Prerequisites</Form.Label>
          {courseData.prerequisites.map((value, index) => (
            <Row key={index} className="mb-2">
              <Col md={11}>
                <Form.Control
                  type="text"
                  style={{ height: "45px" }}
                  placeholder={`Enter prerequisite ${index + 1}`}
                  value={value}
                  onChange={(e) => handleArrayChange(e, "prerequisites", index)}
                />
              </Col>
              <Col md={1}>
                <Button
                  variant="danger"
                  style={{ display: "flex", columnGap: "10px" }}
                  onClick={() => removeField("prerequisites", index)}
                >
                  Delete
                  <FaTrash />
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            className="mb-2"
            variant="primary"
            style={{ display: "flex", columnGap: "10px" }}
            onClick={() => addField("prerequisites")}
          >
            <AiOutlinePlus /> Add Prerequisite
          </Button>
        </Form.Group>

        <Form.Group>
          <Form.Label>Syllabus</Form.Label>
          {courseData.syllabus.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-3">
              <Form.Control
                type="text"
                style={{ height: "45px", width: "83%" }}
                placeholder="Section Title"
                name="section_title"
                value={section.section_title}
                onChange={(e) => handleSyllabusChange(e, sectionIndex)}
              />
              {section.lessons.map((lesson, lessonIndex) => (
                <Row key={lessonIndex} className="mt-2">
                  <Col md={11}>
                    <Form.Control
                      type="text"
                      style={{ height: "45px" }}
                      placeholder="Lesson Title"
                      name="lesson_title"
                      value={lesson.lesson_title}
                      onChange={(e) =>
                        handleSyllabusChange(e, sectionIndex, lessonIndex)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Button
                      variant="danger"
                      onClick={() => removeLesson(sectionIndex, lessonIndex)}
                      style={{ display: "flex", columnGap: "10px" }}
                    >
                      Delete
                      <FaTrash />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col md={6}>
                  <Button
                    variant="primary"
                    className="mt-2"
                    style={{ display: "flex", columnGap: "10px" }}
                    onClick={() => addLesson(sectionIndex)}
                  >
                    {" "}
                    <AiOutlinePlus />
                    Add Lesson
                  </Button>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    className="mt-2 ml-2"
                    onClick={() => removeSection(sectionIndex)}
                  >
                    Remove Section
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Button variant="primary" className="mt-2 mb-2" onClick={addSection}>
            Add Section
          </Button>
        </Form.Group>

        <Button variant="success" type="submit" disabled={loading}>
          {loading ? "Creating..." : "Create Course"}
        </Button>
      </Form>
    </Container>
  );
};

export default CourseCreateForm;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Api from "../../Api.js";
import ExamsTabs from "../Admin/Dashboard/AdminSidebar/ExamsTabs/ExamsTabs.js";
import { Container } from "react-bootstrap";

const CoursesList = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await Api.get("/course/getAllCourses");
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, []);

  const handleViewStudents = (courseId) => {
    navigate(`/admin/batchbycourse/${courseId}`);
  };
  

  return (
    <div className="container my-5">
      <ExamsTabs />
      <Container>
        <h1 className="text-center mb-4">Courses</h1>
        <div
          className="row"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1.5rem",
          }}
        >
          {courses.map((course) => (
            <div
              className="col"
              key={course._id}
              style={{
                flex: "1 1 calc(33.33% - 1.5rem)",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                width: "calc(33.33% - 1.5rem)",
              }}
            >
              <div
                className="card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "400px",
                }}
              >
                <img
                  src={course.image}
                  className="card-img-top"
                  alt={course.title}
                  style={{
                    objectFit: "cover",
                    height: "200px",
                    width: "100%",
                  }}
                />
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <h5
                    className="card-title"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                  >
                    {course.title}
                  </h5>
                  <p className="card-text" style={{ flexGrow: 1 }}>
                    {course.description}
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleViewStudents(course._id)}
                  >
                    View Applied Students
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default CoursesList;

import React from "react";
import { NavLink } from "react-router-dom";
import {
  MdOutlineDashboardCustomize,
  MdDomainAdd,
  MdOutlineSubdirectoryArrowRight,
  MdOutlineCategory,
  MdOutlineManageAccounts,
  MdLocationCity,
  MdOutlineAddBusiness,
  MdOutlineFactory,
  MdLanguage,
  MdOutlinePostAdd,
} from "react-icons/md";
import { PiStudentDuotone } from "react-icons/pi";
import { TiChartAreaOutline } from "react-icons/ti";
import { GrTechnology } from "react-icons/gr";
import { TbStatusChange, TbUniverse, TbLayersDifference } from "react-icons/tb";
import { FaBuildingWheat } from "react-icons/fa6";
import { CiLocationArrow1, CiTextAlignLeft } from "react-icons/ci";
import { SiScikitlearn, SiMoneygram } from "react-icons/si";
import { RiAdvertisementFill } from "react-icons/ri";
import { FcAdvertising } from "react-icons/fc";
import { LiaProjectDiagramSolid } from "react-icons/lia";
import {
  FaBlogger,
  FaGraduationCap,
  FaUsers,
  FaResolving,
  FaBlog,
} from "react-icons/fa";
import { AiFillProject } from "react-icons/ai";
import { BiSolidSchool } from "react-icons/bi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { GiOfficeChair } from "react-icons/gi";
import { PiExam } from "react-icons/pi";
import "../../../../Components/Css/Sidebar.scss";
import { Menu } from "antd";
import { CgProfile } from "react-icons/cg";
// import link from "../../../../assets/accessories/Icon_pmg-removebg-preview,png"
import avathar from "../../../../assets/accessories/Icon_pmg-removebg-preview.png";

const { SubMenu } = Menu;

function Sidebar({ collapsed }) {
  const onClick = (e) => {
    console.log("click ", e);
  };
  const role = localStorage.getItem("name");
  const id = localStorage.getItem("regid");
  return (
    <div className={collapsed === true ? "sidebarcontent open" : "d-none"}>
      <Menu onClick={onClick} mode="inline" className="nav-list">
        <NavLink to="">
          <div className="Nav-Icon2">
            {/* <CgProfile /> */}
            <img
              src={avathar}
              alt="Admin_image"
              style={{ height: "100px" }}
            ></img>
          </div>
          <div className="name-tag">{role}</div>
          {/* <div className="name-tag">{id}</div> */}
        </NavLink>
        <NavLink to="">
          <MdOutlineDashboardCustomize className="Nav-Icon" />
          Dashboard
        </NavLink>
        {/* <SubMenu
          icon={<AiFillProject siz={20} className="Nav-Icon" />}
          title="Course Fields"
        >
          <NavLink to="/admin/managesector">
          Sector
          </NavLink>
          <NavLink to="/admin/domain">
          Domain
          </NavLink>
          <NavLink to="/admin/categoryTable">
          Category
          </NavLink>
          <NavLink to="/admin/subcategory">
          SubCategory
          </NavLink>
        </SubMenu> */}
        {/* <SubMenu
          icon={<AiFillProject size={20} className="Nav-Icon" />}
          title="Users"
        > */}
        <NavLink to="/admin/StudentList">
          <FaUsers className="Nav-Icon" />
          Users
        </NavLink>
        {/* <NavLink to="ProfessionalList">
            <MdOutlinePersonPin className="Nav-Icon" />
            Job Seeker
          </NavLink>
          <NavLink to="EmployerList">
            <AiOutlineUser className="Nav-Icon" />
            Employers
          </NavLink> */}
        {/* <NavLink to="Companylist">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            company List
          </NavLink>
          <NavLink to="TrainingInstitutionList">
            <FaSchool className="Nav-Icon" />
            Institute
          </NavLink> */}
        {/* <NavLink to="SchoolList">
            <BiBuilding className="Nav-Icon" />
            School
          </NavLink> */}
        {/* <NavLink to="CollegeList">
            <BiBuildings className="Nav-Icon" />
            College
          </NavLink>
          <NavLink to="/admin/Users/Vendor">
            <BiBuildings className="Nav-Icon" />
            Vendors
          </NavLink>
          <NavLink to="/admin/Users/Trainers">
            <BiBuildings className="Nav-Icon" />
            Trainers
          </NavLink>
          <NavLink to="SchoolList">
            <BiBuilding className="Nav-Icon" />
            Councillor
          </NavLink>
          <NavLink to="#">
            <BiBuildings className="Nav-Icon" />
            Over All Revenue
          </NavLink> */}
        {/* <NavLink to="/admin/Users/OurEmployees">
            <BiBuildings className="Nav-Icon" />
            Our Employees
          </NavLink> */}

        {/* <NavLink to="companylogo">
            <FaGraduationCap className="Nav-Icon" />
            Logo
          </NavLink> */}
        {/* </SubMenu> */}
        {/* <NavLink to="Companylist">
          <MdOutlineDashboardCustomize className="Nav-Icon" />
          company List
        </NavLink> */}
        <NavLink to="/admin/StudentList">
          <SiScikitlearn className="Nav-Icon" />
          Learning
        </NavLink>
        <SubMenu
          icon={<FcAdvertising size={20} className="Nav-Icon1" />}
          title="Advertisement"
        >
          <NavLink to="/admin/manageads">
            <RiAdvertisementFill className="Nav-Icon" />
            Main Advertisement
          </NavLink>
          <NavLink to="/admin/displayads">
            <RiAdvertisementFill className="Nav-Icon" />
            Sub advertisement
          </NavLink>
        </SubMenu>

        {/* <NavLink to="/admin/managecourses">
          <FaGraduationCap className="Nav-Icon" />
          manage courses
        </NavLink>
        <NavLink to="/admin/displayads">
          <FaGraduationCap className="Nav-Icon" />
          Display
        </NavLink> */}
        <SubMenu icon={<PiExam size={20} className="Nav-Icon1" />} title="Exam">
          <NavLink to="exampattern">
            <CiTextAlignLeft className="Nav-Icon" />
            ExamPattern
          </NavLink>
          {/* <NavLink to="Technology">
            <FaBlogger className="Nav-Icon" />
            Add Technology
          </NavLink> */}
          {/* <NavLink to="curriculamcourse">
            <FaBlogger className="Nav-Icon" />
            Add Curriculam
          </NavLink>

          <NavLink to="examcourse">
            <FaBlogger className="Nav-Icon" />
            Add Course
          </NavLink>
          <NavLink to="trainer">
            <FaBlogger className="Nav-Icon" />
            Add Trainer
          </NavLink>
          <NavLink to="Batch">
            <FaBlogger className="Nav-Icon" />
            Add Batch
          </NavLink>
          <NavLink to="aplliedcourses">
            <FaBlogger className="Nav-Icon" />
            View Apllied 
          </NavLink> */}
        </SubMenu>

        <SubMenu
          icon={<FaResolving size={20} className="Nav-Icon1" />}
          title="Resource"
        >
          <SubMenu
            icon={<FaResolving size={20} className="Nav-Icon1" />}
            title="Our Employees"
          >
            <NavLink to="/admin/Resource/OurEmployess">
              <MdOutlineDashboardCustomize className="Nav-Icon" />
              Our Employees list
            </NavLink>
            {/* <NavLink to="admin/Resource/OurEmployee/ActiveEmployee">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            Active Employee
          </NavLink>
          <NavLink to="admin/Resource/OurEmployee/InActiveEmployee">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
         Inactive Employee
          </NavLink> */}
          </SubMenu>
          {/* <SubMenu
            icon={<FaResolving size={20} className="Nav-Icon1" />}
            title="Contactor Staffing"
          >
            <NavLink to="/admin/Resource/ContactorStaffing">
              <MdOutlineDashboardCustomize className="Nav-Icon" />
              Contactor Staffing list
            </NavLink>
            {/* <NavLink to="admin/Resource/ContactorStaffing/ActiveClients">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            Active Client
          </NavLink> */}
          {/* <NavLink to="admin/Resource/ContactorStaffing/InActiveClients">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            Inactive Client
          </NavLink> */}
          {/* </SubMenu> */}
        </SubMenu>
        <SubMenu
          icon={<GiOfficeChair size={20} className="Nav-Icon1" />}
          title="careerblitz"
        >
          {/* <NavLink to="/admin/users/careerblitz/Freelancing">
            <FaBlogger className="Nav-Icon" />
            Freelancing
          </NavLink> */}

          <NavLink to="/admin/users/careerblitz/Jobpost">
            <MdOutlinePostAdd className="Nav-Icon" />
            Job Post
          </NavLink>
          <NavLink to="/admin/users/careerblitz/ProjectPost">
            <LiaProjectDiagramSolid className="Nav-Icon" />
            Project Post
          </NavLink>
          {/* <SubMenu
            icon={<RiSuitcaseLine size={20} className="Nav-Icon1" />}
            title=" Contactor Staffing"
          >
            <NavLink to="/admin/users/careerblitz/ContactorStaffingRequestTable">
              <FaBlogger className="Nav-Icon" />
              Request
            </NavLink>
            <NavLink to="/admin/users/careerblitz/ContactorStaffing/PostTable">
              <FaBlogger className="Nav-Icon" />
              Post
            </NavLink>
          </SubMenu> */}
        </SubMenu>

        <SubMenu
          icon={<AiFillProject size={20} className="Nav-Icon1" />}
          title="Fields"
        >
          <NavLink to="/admin/managesector">
            <FaBlogger className="Nav-Icon" />
            Sector
          </NavLink>
          <NavLink to="/admin/domain">
            <MdDomainAdd className="Nav-Icon" />
            Domain
          </NavLink>
          <NavLink to="/admin/categoryTable">
            <MdOutlineSubdirectoryArrowRight className="Nav-Icon" />
            Sub Domain
          </NavLink>

          <NavLink to="/admin/createSchoolList">
            <BiSolidSchool className="Nav-Icon" />
            Add School
          </NavLink>
          <NavLink to="collegename">
            <FaBuildingWheat className="Nav-Icon" />
            Add College
          </NavLink>
          <NavLink to="universityname">
            <TbUniverse className="Nav-Icon" />
            Add University
          </NavLink>

          <NavLink to="/admin/locationadded">
            <CiLocationArrow1 className="Nav-Icon" />
            Location
          </NavLink>
          <NavLink to="/admin/users/fields/district">
            <MdLocationCity className="Nav-Icon" />
            City
          </NavLink>
          <NavLink to="/admin/users/fields/area">
            <TiChartAreaOutline className="Nav-Icon" />
            Area
          </NavLink>
          <NavLink to="/admin/languageadded">
            <MdLanguage className="Nav-Icon" />
            Language
          </NavLink>
          <NavLink to="/admin/qualificationadded">
            <TbStatusChange className="Nav-Icon" />
            Qualification
          </NavLink>
          <NavLink to="/admin/categoryadded">
            <MdOutlineCategory className="Nav-Icon" />
            Category
          </NavLink>

          <NavLink to="Technology">
            <GrTechnology className="Nav-Icon" />
            Add Technology
          </NavLink>
        </SubMenu>
        <SubMenu
          icon={<SiMoneygram size={20} className="Nav-Icon1" />}
          title="Packages"
        >
          <NavLink to="student">
            <PiStudentDuotone className="Nav-Icon" />
            Student
          </NavLink>
          <NavLink to="professional">
            <MdOutlineManageAccounts className="Nav-Icon" />
            Professional
          </NavLink>
          <NavLink to="company">
            <MdOutlineFactory className="Nav-Icon" />
            Company
          </NavLink>
          <NavLink to="vendor">
            <MdOutlineAddBusiness className="Nav-Icon" />
            Vendor
          </NavLink>
        </SubMenu>
        <SubMenu
          icon={<HiOutlineOfficeBuilding size={20} className="Nav-Icon1" />}
          title="Blogs & Events"
        >
          <NavLink to="Blogs">
            <FaBlogger className="Nav-Icon" />
            Blog
          </NavLink>
          <NavLink to="studentsBlog">
            <FaBlog className="Nav-Icon" />
            Students Blog
          </NavLink>
        </SubMenu>
        <SubMenu
          icon={<TbLayersDifference size={20} className="Nav-Icon1" />}
          title="Others"
        >
          {/* <NavLink to="Jobpostlist">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            Jobpostlist
          </NavLink> */}

          {/* <NavLink to="Aboutus">
            <MdOutlineDashboardCustomize className="Nav-Icon" />
            AboutUs
          </NavLink> */}
          <NavLink to="companylogo">
            <FaGraduationCap className="Nav-Icon" />
            Logo
          </NavLink>
          {/* <SubMenu
            icon={<AiFillProject size={20} className="Nav-Icon1" />}
            title="Exam"
          >
            <NavLink to="Question">
              <FaBlogger className="Nav-Icon" />
              QuestionBank
            </NavLink>
            <NavLink to="exampattern">
              <FaBlogger className="Nav-Icon" />
              ExamPattern
            </NavLink>

            <NavLink to="examcourse">
              <FaBlogger className="Nav-Icon" />
              Add Course
            </NavLink>
            <NavLink to="trainer">
              <FaBlogger className="Nav-Icon" />
              Add Trainer
            </NavLink>
            <NavLink to="Batch">
              <FaBlogger className="Nav-Icon" />
              Add Batch
            </NavLink>
          </SubMenu> */}
          {/* <SubMenu
            icon={<AiFillProject size={20} className="Nav-Icon1" />}
            title="Advertisement"
          >
            <NavLink to="Advertisementlist">
              <MdOutlineDashboardCustomize className="Nav-Icon" />
              Advertisementlist
            </NavLink>
            <NavLink to="homecarousellist">
              <MdOutlineDashboardCustomize className="Nav-Icon" />
              Home Carousel
            </NavLink>
            <NavLink to="homestudentlist">
              <MdOutlineDashboardCustomize className="Nav-Icon" />
              Home Student
            </NavLink>
          </SubMenu> */}
        </SubMenu>

        {/* <NavLink to="Aboutus">
          <MdOutlineDashboardCustomize className="Nav-Icon" />
          AboutUs
        </NavLink> */}
      </Menu>
    </div>
  );
}
export default Sidebar;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Offcanvas,
  Carousel,
  Form,
} from "react-bootstrap";
import { FaLocationArrow, FaSearch, FaMapMarkerAlt } from "react-icons/fa";
import {
  UserOutlined,
  HomeOutlined,
  AppstoreOutlined,
  SettingOutlined,
  BookOutlined,
  MessageOutlined,
  BellOutlined,
  FileOutlined,
  TeamOutlined,
  GlobalOutlined,
  CalendarOutlined,
  CameraOutlined,
  CloudOutlined,
  DatabaseOutlined,
  EditOutlined,
  GiftOutlined,
  HeartOutlined,
  LaptopOutlined,
  LockOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import Api from "../Api.js";

import "./HomepageFilter.css";
import Image1 from "../Images/institute.jpg";
import Image2 from "../Images/imga22.png";
import Image3 from "../Images/student.jpg";
import { message } from "antd";

const HomepageFilter = () => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [ads, setAds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchAds = async () => {
      try {
        setLoading(true);
        const response = await Api.get("/adadmin/getalladss");
        console.log("API Response:", response.data);
        if (response.data.success) {
          setAds(response.data.data);
        } else {
          message.error("Failed to fetch ads");
        }
      } catch (error) {
        console.error("Error fetching ads:", error);
        message.error("Error fetching ads");
      } finally {
        setLoading(false);
      }
    };
    fetchAds();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await Api.get("/displayadmin/getalladss"); // Replace with your backend endpoint
        if (response.data.success) {
          setCategories(response.data.data); // Assuming the backend response has a 'data' array
        } else {
          message.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        message.error("Error fetching categories");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const toggleOffCanvas = () => setShowOffCanvas(!showOffCanvas);
  const images = [Image1, Image2, Image3];

  const iconCategories = [
    { icon: <HomeOutlined />, label: "Home" },
    { icon: <AppstoreOutlined />, label: "Apps" },
    { icon: <SettingOutlined />, label: "Settings" },
    { icon: <BookOutlined />, label: "Books" },
    { icon: <MessageOutlined />, label: "Messages" },
    { icon: <BellOutlined />, label: "Notifications" },
    { icon: <FileOutlined />, label: "Files" },
    { icon: <TeamOutlined />, label: "Team" },
    { icon: <GlobalOutlined />, label: "Global" },
    { icon: <UserOutlined />, label: "Profile" },
    { icon: <CalendarOutlined />, label: "Calendar" },
    { icon: <CameraOutlined />, label: "Camera" },
    { icon: <CloudOutlined />, label: "Cloud" },
    { icon: <DatabaseOutlined />, label: "Database" },
    { icon: <EditOutlined />, label: "Edit" },
    { icon: <GiftOutlined />, label: "Gifts" },
    { icon: <HeartOutlined />, label: "Favorites" },
    { icon: <LaptopOutlined />, label: "Laptop" },
    { icon: <LockOutlined />, label: "Security" },
    { icon: <PhoneOutlined />, label: "Contact" },
  ];
  const staticText = "Search across";
  const dynamicTexts = [
    "1000 + courses",
    "top universities",
    "varied categories",
    "expert instructors",
  ];
  const [currentText, setCurrentText] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prev) => (prev + 1) % dynamicTexts.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [dynamicTexts.length]);

  const handleCategoryClick = (category) => {
    navigate(`/courses`, { state: { category } }); // Navigate to /courses with category details
  };

  return (
    <Container fluid style={{ padding: "1rem 5rem" }}>
      <div className="changing-text-container">
        <span style={{ fontFamily: "poppins" }}>{staticText}</span>
        <span className="dynamic-text" style={{ fontFamily: "poppins" }}>
          {dynamicTexts[currentText]}
        </span>
      </div>
      {/* <Button className="button-filter">Iam school Student</Button>
      <Button className="button-filter-2">Iam college Student</Button> */}
      {/* <Row className="d-flex justify-content-between top-flex">
        <Col md={8} className="top-search-filter-flex">
          <Row className="top-filter-container">
            <Col md={4}>
              <div className="input-filter-category-container">
                <Form.Control
                  type="text"
                  placeholder="Location"
                  className="ps-4 category-filter-input1"
                />
                <FaMapMarkerAlt className="location-icon1" />
              </div>
            </Col>
            <Col md={5}>
              <div className="input-filter-category-container">
                <Form.Control
                  type="text"
                  placeholder="Search categories"
                  className="ps-4 category-filter-input1"
                />
                <FaSearch className="search-icon1" />
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Col>
      </Row> */}
      <Row>
        <Col
          md={6}
          className="carousal-column"
          style={{ marginBottom: "20px" }}
        >
          <Carousel className="carousel-zindex">
            {ads.map((ad, index) => (
              <Carousel.Item key={ad._id || index}>
                <div className="carousel-image-wrapper">
                  <a
                    href={ad.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "block" }}
                  >
                    <img
                      className="carousel-image"
                      src={ad.imageUrl}
                      alt={ad.name || `Ad ${index + 1}`}
                    />
                  </a>
                  {/* <div className="carousel-caption">
                    <a
                      href={ad.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "underline",
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    >
                      Visit Website
                    </a>
                  </div> */}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
        <Col md={6} className="">
          <Row className="d-flex justify-content-between">
            {categories.slice(0, 4).map((item, index) => (
              <Col md={3} sm={3} key={index} className="mb-3">
                <div
                  className="category-image-container-primary"
                  onClick={() => handleCategoryClick(item)}
                  style={{
                    height: "280px",
                    width: "90%",
                    border: "1px solid #dfd8d8",
                    backgroundImage: `url(${item.imageUrl})`, // Replace with the correct property name for the image URL
                    backgroundSize: "cover",
                    objectFit: "cover",
                    backgroundPosition: "center",
                    borderRadius: "8px",
                  }}
                >
                  <div className="category-image-overlay">
                    <h5
                      style={{
                        fontFamily: "poppins",
                        color: "white",
                        fontSize: "18px",
                      }}
                      className="category-title-home"
                    >
                      {item.name}{" "}
                      {/* Replace with the correct property name for the category name */}
                    </h5>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      {/* <Row className="mb-4"> */}
      {/* <Row className="category-filter-container-cont">
          {iconCategories.map((item, index) => (
            <Col key={index} className="mb-3 small-category-flex">
              <div className="hamburger-container">{item.icon}</div>
              <h5 className="category-title-home">{item.label}</h5>
            </Col>
          ))}

          <div className="hamburger-container">
            <Button
              variant="primary"
              onClick={toggleOffCanvas}
              className="rounded-circle"
              style={{ width: "50px", height: "50px" }}
            >
              ☰
            </Button>
          </div>
        </Row> */}
      {/* </Row> */}

      {/* <Offcanvas
        show={showOffCanvas}
        onHide={toggleOffCanvas}
        placement="end"
        className="offcanvas-custom"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>All Categories</Offcanvas.Title>
          <div className="input-filoter-category-container">
            <Form.Control
              type="text"
              placeholder="Search categories"
              className="ps-4 category-filter-input"
              style={{
                width: "200px",
                marginTop: "10px",
                marginRight: "10px",
                zIndex: 1050,
              }}
            />
            <FaSearch
              className="search-icon"
              style={{ color: "#6c757d", cursor: "pointer" }}
            />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body">
          <Row>
            {iconCategories.map((item, index) => (
              <Col
                md={3}
                sm={6}
                xs={12}
                key={index}
                className="text-center mb-4"
              >
                <div className="flex-category">
                  {item.icon}
                  <p className="mb-0">{item.label}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Offcanvas.Body>
      </Offcanvas> */}
    </Container>
  );
};

export default HomepageFilter;

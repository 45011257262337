import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, notification } from "antd";
import Api from "../Api"; 
import "../../../Components/Css/Students/Student.scss";

function CourseInfo() {
  const [textbox, setTextBox] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  
  const check = () => {
    return (
      <Col sm={24} md={8} className="p-2">
        <label className="input-title">Fees Details per annum</label>
        <br />
        <input className="input-field" {...register("feesPerAnnum")} />
        <br />
        {errors.feesPerAnnum && (
          <span className="input-error">Fees Details are required</span>
        )}
      </Col>
    );
  };

  const display = (e) => {
    if (e.target.checked) {
      setTextBox(check());
      setIsChecked(true); 
    } else {
      setTextBox(null);
      setIsChecked(false); 
    }
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const fetchCourseInfo = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const response = await Api.get(`/courseinfo/courseinfo/${userId}`);
        const courseData = response?.data?.data;
        console.log(courseData);

        if (courseData) {
          setValue("graduateLevel", courseData.graduateLevel || "");
          setValue("category", courseData.category || "");
          setValue("major", courseData.major || "");
          setValue("description", courseData.description || "");
          setValue("modeOfStudy", courseData.modeOfStudy || "");
          setValue("courseDuration", courseData.courseDuration || "");
          setValue("feesPerAnnum", courseData.feesPerAnnum || "");
          setValue("isChecked", courseData.isChecked || false);
          if (courseData.isChecked) {
            setTextBox(check());
            setIsChecked(true);
          } else {
            setTextBox(null);
            setIsChecked(false); 
          }
        }
      } catch (error) {
        console.error("Error fetching course info:", error);
        notification.error({
          message: "Error",
          description: "Failed to fetch course information.",
        });
      }
    };

    fetchCourseInfo();
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const userId = localStorage.getItem("userId");
      data.isChecked = isChecked; 

      let response;
      if (userId) {
        response = await Api.put(`/courseinfo/courseinfo/${userId}`, data);
        notification.success({
          message: "Success",
          description: "Course info updated successfully.",
        });
      } else {
        response = await Api.post(`/courseinfo/courseinfo/${userId}`, data);
        notification.success({
          message: "Success",
          description: "Course info created successfully.",
        });
      }

      if (response?.data?.data) {
        setValue("graduateLevel", response.data.data.graduateLevel);
        setValue("category", response.data.data.category);
        setValue("major", response.data.data.major);
        setValue("description", response.data.data.description);
        setValue("modeOfStudy", response.data.data.modeOfStudy);
        setValue("courseDuration", response.data.data.courseDuration);
        setValue("feesPerAnnum", response.data.data.feesPerAnnum);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      notification.error({
        message: "Error",
        description: "Failed to save course information.",
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="personal-informations p-4">
          <div className="">Course Information</div>

          <Row className="mb-3">
            <Col sm={24} md={8} className="p-2">
              <label className="input-title">Graduate Level</label>
              <select
                className="input-field"
                {...register("graduateLevel", { required: true })}
              >
                <option value="">Select Level</option>
                <option value="ug">UG/Bachelor</option>
                <option value="pg">PG/Master's</option>
                <option value="diploma">Diploma</option>
              </select>
              <br />
              {errors.graduateLevel && (
                <span className="input-error">Graduate level is required</span>
              )}
            </Col>

            <Col sm={24} md={8} className="p-2">
              <label className="input-title">Category</label>
              <input
                className="input-field"
                {...register("category", { required: true })}
              />
              {errors.category && (
                <span className="input-error">Category is required</span>
              )}
            </Col>

            <Col sm={24} md={8} className="p-2">
              <label className="input-title">Major</label>
              <input
                className="input-field"
                {...register("major", { required: true })}
              />
              <br />
              {errors.major && (
                <span className="input-error">Major is required</span>
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={24} md={8} className="p-2">
              <label className="input-title">Description</label>
              <textarea
                className="input-field"
                style={{height:"100px"}}
                {...register("description", { required: true })}
              />
              {errors.description && (
                <span className="input-error">Description is required</span>
              )}
            </Col>

            <Col sm={24} md={8} className="p-2">
              <label className="input-title">Mode of Study</label>
              <select
                className="input-field"
                {...register("modeOfStudy", { required: true })}
              >
                <option value="">Select study mode</option>
                <option value="fullTime">Full Time</option>
                <option value="partTime">Part Time</option>
                <option value="longDistance">Long-distance</option>
              </select>
              {errors.modeOfStudy && (
                <span className="input-error">Mode of Study is required</span>
              )}
            </Col>

            <Col sm={24} md={8} className="p-2">
              <label className="input-title">Course Duration</label>
              <input
                className="input-field"
                type="text"
                {...register("courseDuration", { required: true })}
              />
              {errors.courseDuration && (
                <span className="input-error">
                  Duration of course is required
                </span>
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={24} md={8} className="p-2">
              <input
                type="checkbox"
                onClick={display}
                checked={isChecked}
                {...register("isChecked")}
              />
              <label className="input-title">
                {" "}
                Wish to disclose Fees Details
              </label>
              {errors.isChecked && (
                <span className="input-error">This is required</span>
              )}
            </Col>

            {textbox}
          </Row>

          <div className="submitbuttons p-2">
            <button className="button1 m-2 p-2" type="submit">
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CourseInfo;

import React from "react";
import { NavLink, Routes, Route, Navigate } from "react-router-dom";
import StudentList from "../../StudentList/StudentList";
import ProfessionaList from "../../ProfessionalList/ProfessionaList";
import EmployerList from "../../EmployerList";
import CollegeList from "../../CollegeList/CollegeList";
import VendorList from "../../VendorList/VendorList";
import TrainerList from "../../TrainersList/TrainersList";
import ApprovedSchool from "../../SchoolList/index";
import "./UsersTab.css";

const UsersTab = () => {
  const basePath = "/admin";

  const tabs = [
    {
      id: 1,
      name: "Students",
      path: `${basePath}/StudentList`,
      component: StudentList,
    },
    {
      id: 2,
      name: "Professionals",
      path: `${basePath}/ProfessionalList`,
      component: ProfessionaList,
    },
    {
      id: 3,
      name: "Employers",
      path: `${basePath}/EmployerList`,
      component: EmployerList,
    },
    {
      id: 4,
      name: "Colleges",
      path: `${basePath}/CollegeList`,
      component: CollegeList,
    },
    {
      id: 5,
      name: "Vendors",
      path: `${basePath}/Users/Vendor`,
      component: VendorList,
    },
    {
      id: 6,
      name: "Trainers",
      path: `${basePath}/Users/Trainers`,
      component: TrainerList,
    },
    {
      id: 7,
      name: "Schools",
      path: `${basePath}/SchoolList`,
      component: ApprovedSchool,
    },
  ];

  return (
    <div className="userstab-container">
      <div className="tabs">
        {tabs.map((tab) => (
          <NavLink
            key={tab.id}
            to={tab.path}
            className="tab-link"
            style={({ isActive }) => ({
              backgroundColor: isActive ? "#007BFF" : "#f0f0f0",
              color: isActive ? "white" : "#007BFF",
            })}
          >
            {tab.name}
          </NavLink>
        ))}
      </div>
      <Routes>
        {tabs.map((tab) => (
          <Route key={tab.id} path={tab.path} element={<tab.component />} />
        ))}
      </Routes>
    </div>
  );
};

export default UsersTab;

import React, { useState, useEffect } from "react";
import Api from "../../../Api.js";
import { TimePicker } from "antd"; 
import moment from "moment";
import './Batch.scss' 
import { Form, Button, Row, Col, Alert } from "react-bootstrap";

const CreateBatchForm = () => {
  const [formData, setFormData] = useState({
    batch_name: "",
    course_id: "",
    trainer: "",
    start_date: "",
    end_date: "",
    language: "",
    booking_last_date: "",
    batch_schedule: "",
    start_time: "",
    end_time: "",
    mode: "Online",
    max_enrollments: 0,
    country: "",
    state: "",
    city: "",
    district: "",
  });

  const [courses, setCourses] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCoursesAndTrainers = async () => {
      try {
        setLoading(true);

        const courseResponse = await Api.get("/course/getallcourses");
        const trainerResponse = await Api.get("/trainer/Trainerget");

        console.log("Fetched Courses:", courseResponse.data);
        console.log("Fetched Trainers:", trainerResponse.data);
        if (trainerResponse.data.data) {
          setTrainers(trainerResponse.data.data);
        } else {
          setError("No trainers available.");
        }
        if (courseResponse.data) {
          setCourses(courseResponse.data);
        } else {
          setError("No courses available.");
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch courses or trainers.");
      } finally {
        setLoading(false);
      }
    };

    fetchCoursesAndTrainers();
  }, []);

  useEffect(() => {
    console.log("Trainers state:", trainers);
  }, [trainers]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleTimeChange = (time, timeString, field) => {
    setFormData({
      ...formData,
      [field]: timeString,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSubmit =
      formData.mode === "Online"
        ? { ...formData, country: "", state: "", city: "", district: "" }
        : { ...formData };

    try {
      const response = await Api.post(
        `/course/createbatch/${dataToSubmit.course_id}`,
        dataToSubmit
      );
      if (response.status === 201) {
        setSuccess(true);
        setError("");
        setFormData({
          batch_name: "",
          course_id: "",
          trainer: "",
          start_date: "",
          end_date: "",
          language: "",
          booking_last_date: "",
          batch_schedule: "",
          start_time: "",
          end_time: "",
          mode: "Online",
          max_enrollments: 0,
          country: "",
          state: "",
          city: "",
          district: "",
        });
      }
    } catch (err) {
      setError(err.response?.data?.message || "Failed to create batch.");
    }
  };

  if (loading) {
    return (
      <div className="container mt-4">
        <Alert variant="info">Loading courses and trainers...</Alert>
      </div>
    );
  }

  return (
    <div className="container mt-4 batch-create-container">
      <h3>Create New Batch</h3>
      {success && <Alert variant="success">Batch created successfully!</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      {courses.length === 0 && trainers.length === 0 && !loading && (
        <Alert variant="danger">No courses or trainers available.</Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="batch_name" className="mb-3">
              <Form.Label>Batch Name</Form.Label>
              <Form.Control
                type="text"
                name="batch_name"
                className="batch-create-form"
                value={formData.batch_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="course_id" className="mb-3">
              <Form.Label>Course</Form.Label>
              <Form.Select
                name="course_id"
                value={formData.course_id}
                className="batch-create-form"
                style={{width:"92%",height:"45px"}}
                onChange={handleChange}
                required
              >
                <option value="">Select Course</option>
                {courses.length > 0 ? (
                  courses.map((course) => (
                    <option key={course._id} value={course._id}>
                      {course.title}
                    </option>
                  ))
                ) : (
                  <option disabled>No courses available</option>
                )}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="trainer" className="mb-3">
              <Form.Label>Trainer</Form.Label>
              <Form.Select
                name="trainer"
                value={formData.trainer}
                className="batch-create-form"
                onChange={handleChange}
                style={{width:"92%",height:"45px"}}
                required
              >
                <option value="">Select Trainer</option>
                {trainers.length > 0 ? (
                  trainers.map((trainer) => (
                    <option key={trainer._id} value={trainer._id}>
                      {trainer.firstName}
                    </option>
                  ))
                ) : (
                  <option disabled>No trainers available</option>
                )}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="language" className="mb-3">
              <Form.Label>Language</Form.Label>
              <Form.Select
                name="language"
                value={formData.language}
                className="batch-create-form"
                style={{width:"92%",height:"45px"}}
                onChange={handleChange}
                required
              >
                <option value="">Select Language</option>
                <option value="Tamil">Tamil</option>
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
                <option value="Telugu">Telugu</option>
                <option value="Malayalam">Malayalam</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="start_date" className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                className="batch-create-form"
                name="start_date"
                value={formData.start_date}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="end_date" className="mb-3">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                className="batch-create-form"
                name="end_date"
                value={formData.end_date}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="booking_last_date" className="mb-3">
              <Form.Label>Booking Last Date</Form.Label>
              <Form.Control
                type="date"
                className="batch-create-form"
                name="booking_last_date"
                value={formData.booking_last_date}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="batch_schedule" className="mb-3">
              <Form.Label>Batch Schedule</Form.Label>
              <Form.Select
                name="batch_schedule"
                className="batch-create-form"
                value={formData.batch_schedule}
                onChange={handleChange}
                style={{width:"92%",height:"45px"}}
                required
              >
                <option value="">Select Schedule</option>
                <option value="Monday to Friday">Monday to Friday</option>
                <option value="Monday to Sunday">Monday to Sunday</option>
                <option value="Weekdays">Weekdays</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="start_time" className="mb-3">
              <Form.Label>Start Time</Form.Label>
              <TimePicker
                value={
                  formData.start_time
                    ? moment(formData.start_time, "h:mm A")
                    : null
                }
                onChange={(time, timeString) =>
                  handleTimeChange(time, timeString, "start_time")
                }
                format="h:mm A" 
                use12Hours
                style={{width:"92%"}}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="end_time" className="mb-3">
              <Form.Label>End Time</Form.Label>
              <TimePicker
                value={
                  formData.end_time ? moment(formData.end_time, "h:mm A") : null
                }
                onChange={(time, timeString) =>
                  handleTimeChange(time, timeString, "end_time")
                }
                format="h:mm A" 
                use12Hours
                style={{width:"92%"}}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Col md={6}>
          <Form.Group controlId="max_enrollments" className="mb-3">
            <Form.Label>max_enrollments</Form.Label>
            <Form.Control
              type="number"
              name="max_enrollments"
              className="batch-create-form"
              value={formData.max_enrollments}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Mode of Training</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Online"
                  name="mode"
                  value="Online"
                  checked={formData.mode === "Online"}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Offline"
                  name="mode"
                  value="Offline"
                  checked={formData.mode === "Offline"}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        {formData.mode === "Offline" && (
          <>
            <Row>
              <Col md={4}>
                <Form.Group controlId="country" className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    className="batch-create-form"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="state" className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    className="batch-create-form"
                    value={formData.state}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="city" className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    className="batch-create-form"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Form.Group controlId="district" className="mb-3">
                  <Form.Label>District</Form.Label>
                  <Form.Control
                    type="text"
                    className="batch-create-form"
                    name="district"
                    value={formData.district}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}

        <Button type="submit" variant="primary">
          Create Batch
        </Button>
      </Form>
    </div>
  );
};

export default CreateBatchForm;

import React, { useState, useEffect } from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";

function District() {
  // const [institute, setInstitute] = useState([]);
  const [instituteValue, setInstituteValue] = useState();
  const [countryList, setcountryList] = useState();
  const [countryValue, setCountryValue] = useState();
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState();
  const [stateList, setstateList] = useState();
  const [districtValue, setDistrictValue] = useState();
  const [cityList, setCityList] = useState();
  const [cityvalue, setCityValue] = useState();
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: "", code: "" },
    { name: "Ambattur", code: "Am" },
    { name: "Chromepet", code: "RM" },
    { name: "Triplicane", code: "LDN" },
    { name: "Vadapalani", code: "IST" },
    { name: "Perambur", code: "Pr" },
  ];

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getCountry();
  }, []);
  // console.log("institute", institute);
  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    console.log("stateList", country_id);
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
    });
  };
  const [districtId, setDistrictid] = useState();
  const getValue = (districtId) => {
    setDistrictid(districtId);
    console.log("districtId", districtId);
  };

  const getCity = (districtId) => {
    setDistrictValue(districtId);
  };
  console.log("districtValue", districtValue);

  const handleCreateFormSubmit = () => {
    const details = {
      cityName: getValues().cityName,
      districtId: districtValue,
    };
    Api.post("/city/createCity", details).then((e) => {
      console.log("e", e);
    });
  };

  return (
    <div>
      <Container className=" content-style " style={{ width: '90%' }}>
        <Row >
          <form onSubmit={handleSubmit()}>
            <div className="pages-title mt-3">Add City</div>
            <Row className="mt-4" >
              <Col xs={12} sm={12} md={6} lg={6} className="px-5 py-3">
                <label className="create-title">Country</label>
                <Dropdown
                  className="input-field"
                  style={{ border: '1px solid #ced4da ' }}
                  filter
                  value={countryValue}
                  options={countryList}
                  placeholder="Select a Country"
                  {...register("country", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  onChange={(e) => getState(e.value)}
                />
                {errors.country && (
                  <p className="error-text-color">country is required</p>
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="px-5 py-3">
                <label className="create-title">State</label>
                <Dropdown
                  filter
                  style={{ border: '1px solid #ced4da ' }}
                  className="input-field"
                  value={stateValue}
                  options={stateList}
                  {...register("State", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  placeholder="Select a State"
                  onChange={(e) => getDistrict(e.value)}
                />
                {errors.State && (
                  <p className="error-text-color">State is required</p>
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="px-5 py-3">
                <label className="create-title">District</label>
                <Dropdown
                  style={{ border: '1px solid  #ced4da' }}
                  className="input-field"
                  filter
                  value={districtValue}
                  options={districtList}
                  {...register("district", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  placeholder="Select a District"
                  onChange={(e) => getCity(e.value)}
                />

                {errors.district && (
                  <p className="error-text-color">District is required</p>
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="px-5 py-3">
                <label className="create-title">City</label>
                {/* <Dropdown
                  filter
                  className="input-field"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.value)}
                  options={cities}
                  optionLabel="name"
                  placeholder="Select a City"
                /> */}
                <input
                  style={{ width: '100%', padding: '22px 15px' }}
                  {...register("cityName", { required: true })}
                  type="text"
                  placeholder="Enter City Name"
                  className="input-field"
                />
                {errors.cityName && (
                  <p className="error-text-color">cityName is required</p>
                )}
                {/* <Dropdown
                  className="input-field"
                  type="text"
                  {...register("cityName", { required: true })}
                /> */}
                {/* {errors.cityName && (
                  <p className="error-text-color">City is required</p>
                )} */}
              </Col>
            </Row>
            <div className="submitbuttons p-2">
              <button
                className="button1 m-2 p-2"
                type="submit"
                onClick={handleCreateFormSubmit}
              >
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
        </Row>
      </Container>
    </div>
  );
}

export default District;

import CollegeDashboard from "../Pages/Institute-College/instituteDashboard/index";
import MyProfile from "../Pages/Institute-College/MyProfile/index";
import ApprovedList from "../Pages/Institute-College/application/ApprovedList";
import ApplicationResult from "../Pages/Institute-College/application/ApplicationResult";
import Blog from "../Pages/Institute-College/blog/BlogCollege";
import Seminar from "../Pages/Institute-College/Seminar/Seminar";
import InternshipCreate from "../Pages/Institute-College/internShip/InternShipCreate";
import CollegeHome from "../Pages/Institute-College/Home/CollegeHome";
import { Navigate } from "react-router-dom";
import CreateJobCollege from "../Pages/Institute-College/Jobs/CreateJob";
import Viewjob from "../Pages/Institute-College/Jobs/ViewJob";
import JobRequest from "../Pages/Institute-College/Jobs/JobRequest";
import SeminarRequest from "../Pages/Institute-College/Seminar/SeminarRequest";
import SeminarsInnerPage from "../Pages/Student/Seminar/SeminarInnerPage";
import ScheduleForm from "../Pages/Institute-College/Jobs/ScheduleForm";
import JobRequestInnerPage from "../Pages/Institute-College/Jobs/JobRequeststInner";
import ViewApplicants from "../Pages/Employer/CreateJobForm/ViewApplicants";
import DetailsForm from "../Pages/Employer/CreateJobForm/DetailsForm";
import ViewSchedule from "../Pages/Institute-College/Jobs/ViewSchedule";
import SelectForm from "../Pages/Employer/InterviewList/SelectForm";
import RejectForm from "../Pages/Employer/InterviewList/RejectForm";
import HoldForm from "../Pages/Employer/InterviewList/HoldForm";
import RejectedList from "../Pages/Employer/InterviewList/RejectedList";
import HoldList from "../Pages/Employer/InterviewList/HoldList";
import SelectedList from "../Pages/Employer/InterviewList/SelectedList";
import PersonalInfo from "../Pages/Institute-College/MyProfile/InstituteInfo";
import CourseInfo from "../Pages/Institute-College/MyProfile/CourseInfo";

const role = localStorage.getItem("role");

const Routers = [
  {
    path: "/college",
    name: "Dashboard",
    element:
      role === "college" ? <CollegeDashboard /> : <Navigate to="/login" />,
  },
  {
    path: "/college/myprofile",
    name: "Our Profile",
    element: role === "college" ? <MyProfile /> : <Navigate to="/login" />,
  },
  {
    path: "/college/companyhome",
    name: "CollegeHome",
    element: role === "college" ? <CollegeHome /> : <Navigate to="/login" />,
  },
  {
    path: "/college/application",
    name: "Application",
    element:
      role === "college" ? <ApplicationResult /> : <Navigate to="/login" />,
  },
  {
    path: "/college/approved",
    name: "Approved List",
    element: role === "college" ? <ApprovedList /> : <Navigate to="/login" />,
  },
  {
    path: "/college/blog",
    name: "College BLog",
    element: role === "college" ? <Blog /> : <Navigate to="/login" />,
  },
  {
    path: "/college/seminar",
    name: "Seminar",
    element: role === "college" ? <Seminar /> : <Navigate to="/login" />,
  },
  {
    path: "/college/requestseminar",
    name: "Seminar",
    element: role === "college" ? <SeminarRequest /> : <Navigate to="/login" />,
  },
  {
    path: "/college/requestseminar/Seminarinnerpage",
    name: "Seminar",
    element:
      role === "college" ? <SeminarsInnerPage /> : <Navigate to="/login" />,
  },
  {
    path: "/college/intern",
    name: "intern",
    element:
      role === "college" ? <InternshipCreate /> : <Navigate to="/login" />,
  },
  {
    path: "/college/CreateJob",
    name: "CreateJob",
    element:
      role === "college" ? <CreateJobCollege /> : <Navigate to="/login" />,
  },
  {
    path: "/college/viewjob",
    name: "viewjob",
    element: role === "college" ? <Viewjob /> : <Navigate to="/login" />,
  },
  // {
  //   path: "/college/jobrequest",
  //   name: "jobrequest",
  //   element: role === "college" ? <JobRequest /> : <Navigate to="/login" />,
  // },
  {
    path: "/college/viewjob/jobrequest/:id",
    name: "jobrequest",
    element: role === "college" ? <JobRequest /> : <Navigate to="/login" />,
  },
  {
    path: "/college/viewjob/jobrequest/jobrequestinnerpage",
    name: "jobrequestinnerpage",
    element: <JobRequestInnerPage />,
  },
  {
    path: "/college/form",
    name: "jobrequest",
    element: role === "college" ? <ScheduleForm /> : <Navigate to="/login" />,
  },
  {
    path: "/college/form/ViewApplicants",
    name: "jobrequestinnerpage",
    element: <ViewApplicants />,
    // element: role === "employer" ? <JobRequestInnerPage /> : <Navigate to="/login"/>
  },
  {
    path: "/college/form/details",
    name: "selected",
    element: <DetailsForm />,
  },
  {
    path: "/college/form/ViewSchedule/:id",
    name: "selected",
    element: <ViewSchedule />,
  },
  {
    path: "/college/interview/selected",
    name: "selected",
    element: <SelectedList />,
  },
  {
    path: "/college/interview/hold",
    name: "selected",
    element: <HoldList />,
  },
  {
    path: "/college/interview/rejected",
    name: "selected",
    element: <RejectedList />,
  },
  {
    path: "/college/interview/selectform",
    name: "selectform",
    element: <SelectForm />,
  },
  {
    path: "/college/interview/holdform",
    name: "holdform",
    element: <HoldForm />,
  },
  {
    path: "/college/interview/rejectform",
    name: "rejectform",
    element: <RejectForm />,
  },
  {
    path: "/college/myprofile/InstituteInfo",
    name: "Instituteinfo",
    element:<PersonalInfo/>
  },
  {
    path: "/college/myprofile/courseinfo",
    name: "Instituteinfo",
    element:<CourseInfo/>
  },
];
export default Routers;

import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "../../../../Components/Css/Students/Studintdetails.scss";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Api from "../../../../Api";
import { useNavigate } from "react-router-dom";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = eventKey === activeEventKey;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#a6d5fa",
        paddingTop: "12px",
      }}
    >
      <p onClick={decoratedOnClick}>
        {children}
        {isCurrentEventKey ? <FiChevronUp /> : <FiChevronDown />}
      </p>
    </div>
  );
}

function JobRequestProfessional() {
  const [createForm, setcreateForm] = useState([]);
  const navigate = useNavigate();
  const id = localStorage.getItem("userId");

  useEffect(() => {
    getCreateForm();
  }, []);
  const getCreateForm = async () => {
    await Api.get(`professional/getoneuser/${id}`).then((res) => {
      setcreateForm(res.data.data);
    });
  };
  console.log("createForm", createForm);

  const handleSubmit = (data) => {
    navigate("/professional/learing", { state: data });
  };
  // const handleSubmit = (data) => {
  //   navigate("jobrequestinnerpage", { state: data });
  // };

  return (
    <div className="p-4">
      <div className="pages-title">Request</div>
      <Row className="p-3">
        {createForm.map((data, i) => (
          <Col sm={24} md={12} lg={8} className="p-2">
            <Accordion defaultActiveKey="0">
              <Card
                className="Int-card"
              // onClick={() => {
              //   handleSubmit(data);
              // }}
              >
                <Card.Body>
                  <h4>{data.jobTitle}</h4>

                  <Card.Text>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p>
                          <strong>Technology:</strong>{" "}
                          {data.technology.join(", ")}
                        </p>
                        <p>
                          <strong>Experience:</strong> {data.experience}
                        </p>
                        <p>
                          <strong>Qualification:</strong>{" "}
                          {data.qualification.join(", ")}
                        </p>
                        <p>
                          <strong>Job Description:</strong>{" "}
                          {data.jobDescription}
                        </p>
                        <p>
                          <strong>Skills:</strong> {data.skill.join(", ")}
                        </p>
                        <p>
                          <strong>Email:</strong> {data.userEmail}
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card.Text>
                  <ContextAwareToggle eventKey="1"> More </ContextAwareToggle>
                </Card.Body>
              </Card>
            </Accordion>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default JobRequestProfessional;

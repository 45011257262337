import React, { useState, useEffect } from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";

function Area() {
  // const [institute, setInstitute] = useState([]);
  const [instituteValue, setInstituteValue] = useState();
  const [countryList, setcountryList] = useState();
  const [countryValue, setCountryValue] = useState();
  const [stateValue, setStateValue] = useState();
  const [districtList, setdistrictList] = useState();
  const [stateList, setstateList] = useState();
  const [districtValue, setDistrictValue] = useState();

  const [cityList, setCityList] = useState();
  const [cityvalue, setCityValue] = useState();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getCountry();
  }, []);
  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    console.log("stateList", country_id);
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
    });
  };

  const getDistrict = (state_id) => {
    setStateValue(state_id);
    Api.get(`district/districtById/${state_id}`).then((res) => {
      setdistrictList(res.data.data);
    });
  };

  const getCity = (districtId) => {
    setDistrictValue(districtId);
    Api.get(`city/cityById/${districtId}`).then((res) => {
      setCityList(res.data.data);
    });
  };
  // const [areaList, setAreaList] = useState();
  // const [areavalue, setAreaValue] = useState();
  // const getArea = (cityId) => {
  //   setCityValue(cityId);
  //   Api.get(`area/AreaById/${cityId}`).then((res) => {
  //     setAreaList(res.data.data);
  //   });
  // };
  const getArea = (cityId) => {
    setCityValue(cityId);
    console.log("cityId", cityId);
  };
  const handleCreateFormSubmit = () => {
    const details = {
      areaName: getValues().areaName,
      cityId: cityvalue,
    };
    Api.post("/area/createArea", details).then((e) => {
      console.log("e", e);
    });
  };

  return (
    <div>
      <Container className=" content-style " style={{ width: '90%' }}>
        <Row >
          <form onSubmit={handleSubmit()}>
            <div className="pages-title mt-3">Add Area</div>
            <Row className="mt-4" >
              <Col xs={12} sm={12} md={6} lg={6} className="px-5 py-3">
                <label className="create-title">Country</label>
                <Dropdown
                  style={{ border: '1px solid #ced4da ' }}
                  className="input-field"
                  filter
                  placeholder="Select a Country"
                  value={countryValue}
                  options={countryList}
                  {...register("country", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  onChange={(e) => getState(e.value)}
                />
                {errors.country && (
                  <p className="error-text-color">country is required</p>
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="px-5 py-3">
                <label className="create-title">State</label>
                <Dropdown
                  filter
                  style={{ border: '1px solid #ced4da ' }}
                  placeholder="Select a State"
                  className="input-field"
                  value={stateValue}
                  options={stateList}
                  {...register("State", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  onChange={(e) => getDistrict(e.value)}
                />
                {errors.State && (
                  <p className="error-text-color">State is required</p>
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="px-5 py-3">
                <label className="create-title">District</label>

                <Dropdown
                  placeholder="Select a District"
                  style={{ border: '1px solid #ced4da ' }}
                  className="input-field"
                  filter
                  value={districtValue}
                  options={districtList}
                  {...register("district", { required: true })}
                  optionLabel={"name"}
                  optionValue={"id"}
                  onChange={(e) => getCity(e.value)}
                />

                {errors.district && (
                  <p className="error-text-color">District is required</p>
                )}
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="px-5 py-3">
                <label className="mb-1">City</label>
                <Dropdown
                  filter
                  style={{ border: '1px solid #ced4da ' }}
                  placeholder="Select a City"
                  className="input-field"
                  value={cityvalue}
                  options={cityList}
                  {...register("City", { required: true })}
                  optionLabel={"cityName"}
                  optionValue={"_id"}
                  onChange={(e) => getArea(e.value)}
                />
                {errors.City && (
                  <p className="error-text-color">City is required</p>
                )}
              </Col>

              {/* <Col className="create-title" lg={4} sm={12}>
                <label className="mb-1">City</label>
                <br />
                <Dropdown
                  filter
                  className="input-field"
                  value={areavalue}
                  options={areaList}
                  {...register("City", { required: true })}
                  optionLabel={"areaName"}
                  optionValue={"_id"}
                  onChange={(e) => setAreaValue(e.value)}
                />
                {errors.City && (
                  <p className="error-text-color">City is required</p>
                )}
              </Col> */}
              <Col xs={12} sm={12} md={6} lg={6} className="px-5 py-3">
                <label className="create-title">Area</label>
                <input
                  style={{ width: '100%', padding: '24px 15px !important' }}
                  placeholder="Enter Area Name"
                  className="input-field"
                  type="text"
                  {...register("areaName", { required: true })}
                />
                {errors.areaName && (
                  <p className="error-text-color">Area is required</p>
                )}
              </Col>
            </Row>
            <br />
            <div className="submitbuttons p-2">
              <button
                className="button1 m-2 p-2"
                type="submit"
                onClick={handleCreateFormSubmit}
              >
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
        </Row>
      </Container>
    </div>
  );
}

export default Area;

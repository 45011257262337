import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";
import "../../../Components/seminar/viewSeminar.scss";
import { FaAnglesRight, FaFilter } from "react-icons/fa6";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { FaCalendarAlt, FaChalkboardTeacher, FaClock, FaPhone, FaUser } from "react-icons/fa";

export default function Seminar() {
  const [createForm, setCreateForm] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    title: '',
    technology: '',
    audience: '',
    SeminarMode: ''
  });
  const [dropdownOptions, setDropdownOptions] = useState({
    technology: [],
    audience: [],
    SeminarMode: []
  });
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      delay:'300',
    });
  }, []);

  useEffect(() => {
    getCreateForm();
  }, []);

  useEffect(() => {
    setFilteredData(createForm);
    generateDropdownOptions(createForm); 
  }, [createForm]);

  const getCreateForm = async () => {
    try {
      const response = await Api.get("seminar/getSeminar");
      setCreateForm(response.data.data);
      console.log("API Response:", response); 
      console.log("Seminar Data:", response.data.data);
    } catch (error) {
      console.error("Error fetching seminar data:", error);
    }
  };

  const generateDropdownOptions = (data) => {
    const technologyOptions = [...new Set(data.map(item => item.technology).flat())];
    const audienceOptions = [...new Set(data.map(item => item.audience))];
    const seminarModeOptions = [...new Set(data.map(item => item.SeminarMode))];

    setDropdownOptions({
      technology: technologyOptions,
      audience: audienceOptions,
      SeminarMode: seminarModeOptions
    });
  };

  const navigate = useNavigate();

  const onSubmit = (data) => {
    navigate("/student/SeminarInnerPage", { state: data });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const applyFilters = () => {
    const filtered = createForm.filter((data) => {
      return (
        (filters.title === '' || data.seminarTitle.toLowerCase().includes(filters.title.toLowerCase())) &&
        (filters.technology === '' || data.technology.includes(filters.technology)) &&
        (filters.audience === '' || data.audience === filters.audience) &&
        (filters.SeminarMode === '' || data.SeminarMode === filters.SeminarMode)
      );
    });
    setFilteredData(filtered);
  };

  const resetFilters = () => {
    setFilters({
      title: '',
      technology: '',
      audience: '',
      SeminarMode: ''
    });
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  return (
    <div className="seminar-container">
      <div className="project-heading">
        <p>Seminar List</p>
      </div>
      <div className="row seminar-flex">
       <div className="col-9 col-xxl-9"><Button variant="primary" onClick={() => setShowFilterModal(true)} className="filter-button">
        <FaFilter /> Filter
      </Button></div>
      </div>
    
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Filter Seminars</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control className="seminar-input"
              type="text"
              placeholder="Filter by Title"
              name="title"
              value={filters.title}
              onChange={handleFilterChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Technology</Form.Label>
            <Form.Select className="seminar-select"
              name="technology"
              value={filters.technology}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              {dropdownOptions.technology.map((tech, index) => (
                <option key={index} value={tech}>{tech}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Audience</Form.Label>
            <Form.Select className="seminar-select"
              name="audience"
              value={filters.audience}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              {dropdownOptions.audience.map((aud, index) => (
                <option key={index} value={aud}>{aud}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Mode</Form.Label>
            <Form.Select className="seminar-select"
              name="SeminarMode"
              value={filters.SeminarMode}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              {dropdownOptions.SeminarMode.map((mode, index) => (
                <option key={index} value={mode}>{mode}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Button variant="secondary" onClick={resetFilters}>
              Reset Filters
            </Button>
            <Button variant="primary" onClick={() => setShowFilterModal(false)}>
              Apply Filters
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Row className="card-row" xs={1} sm={2} md={3} xl={4} data-aos="fade-left">
        {filteredData.map((data, i) => (
          <Col className="semi-card-col mb-4 " key={i}>
            <Card className="seminar-cards">
              <Card.Header className="semi-title">
                <div className="semi-title-div">
                  <div>
                    <h4 className="seminar-card-title">
                      {data.seminarTitle}
                    </h4>
                  </div>
                  <div>
                    <p style={{ color: "rgb(220, 221, 222)" }}>
                      {data.technology.join(", ")}
                    </p>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="semi-card-body">
                <div style={{ display: "flex", flexDirection: "column",rowGap:"7px" }}>
                  <div className="seminar-card-flex">
                    <FaCalendarAlt className="seminar-icons" /> <label>Date: &nbsp;</label>
                    {data.fromdate} - {data.todate}
                  </div>
                  <div className="seminar-card-flex">
                    <FaClock className="seminar-icons" /> <label>Time: &nbsp;</label>
                    <span>{data.time}</span>
                  </div>
                  <div className="seminar-card-flex">
                    <FaUser className="seminar-icons"  /> <label>Access: &nbsp;</label>
                    {data.audience}
                  </div>
                  <div className="seminar-card-flex">
                    <FaChalkboardTeacher  className="seminar-icons" /> <label>Mode: &nbsp;</label>
                    {data.SeminarMode}
                  </div>
                  <div className="seminar-card-flex">
                    <FaUser className="seminar-icons" /> <label>Contact Person: &nbsp;</label>
                    {data.contactPerson}
                  </div>
                  <div className="seminar-card-flex">
                    <FaPhone className="seminar-icons" /> <label>Contact Number: &nbsp;</label>
                    {data.contactNumber}
                  </div>
                </div>
              </Card.Body>
              <Card.Footer className="seminar-card-footer">
                <button
                  className="button-seminar"
                  onClick={() => {
                    onSubmit(data);
                  }}
                >
                  View Details <FaAnglesRight />{" "}
                </button>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row> */}
       <Row className="card-row g-4"  data-aos="fade-left">
      {filteredData.map((data, i) => (
        <Col className="semi-card-col" xs={12} sm={6} md={4} xl={3}  key={i}>
          <Card className="seminar-cards">
            <Card.Header className="semi-title">
              <div className="semi-title-div">
                <div>
                  <h4 className="seminar-card-title">{data.seminarTitle}</h4>
                </div>
                <div>
                  <p style={{ color: "rgb(220, 221, 222)" }}>
                    {data.technology.join(", ")}
                  </p>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="semi-card-body">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "7px",
                }}
              >
                <div className="seminar-card-flex">
                  <FaCalendarAlt className="seminar-icons" />{" "}
                  <label>Date: &nbsp;</label>
                  {data.fromdate} - {data.todate}
                </div>
                <div className="seminar-card-flex">
                  <FaClock className="seminar-icons" />{" "}
                  <label>Time: &nbsp;</label>
                  <span>{data.time}</span>
                </div>
                <div className="seminar-card-flex">
                  <FaUser className="seminar-icons" />{" "}
                  <label>Access: &nbsp;</label>
                  {data.audience}
                </div>
                <div className="seminar-card-flex">
                  <FaChalkboardTeacher className="seminar-icons" />{" "}
                  <label>Mode: &nbsp;</label>
                  {data.SeminarMode}
                </div>
                <div className="seminar-card-flex">
                  <FaUser className="seminar-icons" />{" "}
                  <label>Contact Person: &nbsp;</label>
                  {data.contactPerson}
                </div>
                <div className="seminar-card-flex">
                  <FaPhone className="seminar-icons" />{" "}
                  <label>Contact Number: &nbsp;</label>
                  {data.contactNumber}
                </div>
              </div>
            </Card.Body>
            <Card.Footer className="seminar-card-footer">
              <button
                className="button-seminar"
                onClick={() => {
                  onSubmit(data);
                }}
              >
                View Details <FaAnglesRight />{" "}
              </button>
            </Card.Footer>
          </Card>
        </Col>
      ))}
    </Row>
    </div>
  );
}

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Css/Footer/footer.scss";
import img from "../../Images/Careerblitz logo.png";
import {
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer text-light mt-5">
      <Container className="footer-container" fluid>
        <Row className="footer-top align-items-center p-3">
          <Col md={4} className="footer-logo text-center">
            <img src={img} alt="Logo" className="footer-logo-img mb-3" />

            {/* <h5 style={{fontFamily:"poppins",color:"white",marginTop:"10px"}}>Powered by Trimatis Tech Fusion Pvt Ltd</h5> */}
          </Col>

          <Col md={4} className="footer-links">
            <h5
              className="footer-heading"
              style={{ display: "flex", marginRight: "63px" }}
            >
              Quick Links
            </h5>
            <ul className="footer-list">
              {/* <li><NavLink to="/pricing" className="footer-link">Pricing</NavLink></li> */}
              <li>
                <NavLink to="/privacypolicy" className="footer-link">
                  Privacy Policy
                </NavLink>
              </li>

              <li>
                <NavLink to="/refundpolicy" className="footer-link">
                  Refund Policy
                </NavLink>
              </li>
              <li>
                <NavLink to="/cancellationpolicy" className="footer-link">
                  Cancellation Policy
                </NavLink>
              </li>
              <li>
                <NavLink to="/termsandconditions" className="footer-link">
                  Terms and Conditions
                </NavLink>
              </li>
              <li>
                <NavLink to="/FAQ" className="footer-link">
                  FAQ
                </NavLink>
              </li>
            </ul>
          </Col>

          <Col md={4} className="footer-contact text-center">
            {/* <h5 className="footer-heading">Contact Us</h5>
            <ul className="footer-list">
              <li><a href="mailto:info@careerblitz.com" className="footer-link">Email Us</a></li>
              <li><NavLink className="footer-link" to="/contactus">Contact Us</NavLink></li>
              <li><NavLink className="footer-link" to="#visit">Visit Us</NavLink></li>
            </ul> */}
            <p className="footer-description">
              <strong>Connect with Us</strong>
            </p>
            <div className="social-links d-flex justify-content-center gap-4">
              <a href="https://instagram.com" className="social-icon-link">
                <InstagramOutlined className="social-icon" />
              </a>
              <a href="https://whatsapp.com" className="social-icon-link">
                <WhatsAppOutlined className="social-icon" />
              </a>
              <a href="https://facebook.com" className="social-icon-link">
                <FacebookOutlined className="social-icon" />
              </a>
              <a href="https://twitter.com" className="social-icon-link">
                <TwitterOutlined className="social-icon" />
              </a>
              <a href="https://linkedin.com" className="social-icon-link">
                <LinkedinOutlined className="social-icon" />
              </a>
            </div>
          </Col>
        </Row>
        <center>
          {" "}
          {/* <Row className="footer-bottom text-center mt-2">
            <Col className="mt-3">
              <NavLink to="#privacy-policy" className="footer-bottom-link">
                Privacy Policy
              </NavLink>
              <span className="mx-2">|</span>
              <NavLink to="#user-agreement" className="footer-bottom-link">
                User Agreement
              </NavLink>
              <span className="mx-2">|</span>
              <NavLink to="#terms-of-service" className="footer-bottom-link">
                Terms of Service
              </NavLink>
            </Col>
          </Row>
          */}
          <Row className="footer-bottom text-center mt-2">
            <Col className="text-center">
              <p>
                © 2025 Careerblitz. All rights reserved by Aroganam Technology
                Private Limited
              </p>
            </Col>
          </Row>
        </center>
      </Container>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Divider } from 'antd';
import { Link, useLocation } from "react-router-dom";
// import "../../../Components/Css/Students/Student.scss";
import Api from "../../../Api";
import "./Internship.css";
import internpng from "../../../assets/internship removebg.webp"
import { FaLocationDot } from "react-icons/fa6";
import { FaLanguage } from "react-icons/fa";
// import { MdNotStarted } from "react-icons/md";
import { IoGlobeSharp } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { AiFillThunderbolt, AiFillSafetyCertificate } from "react-icons/ai";
import { GrUserWorker } from "react-icons/gr";
import {
  FaCalendarAlt,
  FaRegMoneyBillAlt,
} from "react-icons/fa";

function InternshipPage() {
  const location = useLocation();
  const viewIntern = location.state;
  const [createForm, setCreateForm] = useState();
  const userEmail = localStorage.getItem("email");
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");
  const id = viewIntern._id;
  console.log('viewIntern', viewIntern)
  useEffect(() => {
    getCreateForm();
  }, []);

  const getCreateForm = async () => {
    const response = await Api.get(`createForm/getForm/${id}`);
    setCreateForm([response?.data?.data]);
  };

  const onSubmit = () => {
    const details = {
      role: role,
      UserID: userId,
      userEmail: userEmail,
      jobTitle: viewIntern.jobTitle,
      jobRole: viewIntern.jobRole,
      jobMode: viewIntern.jobMode,
      careerLevel: viewIntern.careerLevel,
      companyWebsite: viewIntern.companyWebsite,
      companyMailId: viewIntern.companyMailId,
      email: viewIntern.email,
      experience: viewIntern.experience,
      jobId: viewIntern.jobId,
      jobType: viewIntern.jobType,
      languagePreference: viewIntern.languagePreference,
      noticePeriod: viewIntern.noticePeriod,
      internshipPeriod: viewIntern.internshipPeriod,
      locations: viewIntern.locations,
      jobDescription: viewIntern.jobDescription,
      qualification: viewIntern.qualification,
      salaryRange: viewIntern.salaryRange,
      skill: viewIntern.skill,
      technology: viewIntern.technology,
    };
    Api.post("blog/createinternship", details).then((res) => {
      console.log("Response:", res);
    });
  };

  return (

    <Container style={{ width: '90%' , marginTop:'20px', paddingTop:'0px'}}>
      <div className="Internship">
        <h3>Internship</h3>
        <img src={internpng} className="internpng" />
      </div>
      <h5 className="internship-heading">{viewIntern.jobTitle}</h5>
      <div className="internship-content">
        <div>
          <h5 style={{ fontWeight: 'bold', marginBottom: '10px' }}>{viewIntern.jobRole}</h5>
          <p><span style={{ color: '#808080' }}><FaLocationDot /></span>{viewIntern.location}</p>
        </div>
        <div>
          <Row>
            {/* <Col>
            <MdNotStarted/><span > START DATE</span><br/>
            <p></p>
            </Col> */}
            <Col className="p-2">
              <p className="internship-miniheads"><GrUserWorker /><span > MODE</span></p>
              <p className="internship-mini">{viewIntern.jobMode}</p>
            </Col>
            <Col className="p-2">
              <p className="internship-miniheads"><FaCalendarAlt /><span > JOINING</span></p>
              <p className="internship-mini">{viewIntern.noticePeriod}</p>
            </Col>
            <Col className="p-2">
              <p className="internship-miniheads"><GrUserWorker /><span > JOB TYPE</span></p>
              <p className="internship-mini">{viewIntern.jobType}</p>
            </Col>
            <Col className="p-2">
              <p className="internship-miniheads"><FaRegMoneyBillAlt /><span > EXPERIENCE</span></p>
              <p className="internship-mini">{viewIntern.experience}</p>
            </Col>

          </Row>
        </div>
        <div>
          <p ><AiFillThunderbolt style={{ backgroundColor: 'orange', color: 'white', borderRadius: '50%', padding: '3px', fontSize: '20px' }} />
            <span
              style={{
                color: 'black',
                paddingLeft: '5px'
              }}
            >Be An Early Applicant</span></p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingInlineEnd:'50px' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div><IoGlobeSharp size={35} style={{ paddingRight: '5px', paddingTop: '5px' }} /></div>
            <div>For more Details <br />
              <a href={viewIntern.companyWebsite} target="_blank" rel="noopener noreferrer" >
                {viewIntern.companyWebsite}
              </a>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row'  }}>
            <div><IoIosMail size={35} style={{ paddingRight: '5px', paddingTop: '5px' }} /></div>
            <div>Contact <br />
              <a href={`mailto:${viewIntern.companyMailId}`}>
                {viewIntern.companyMailId}
              </a>
            </div>
          </div>
        </div>
        <Divider style={{ padding: '2px', width: '80%', color: 'black' }}></Divider>
        <div>
          <h5 className="internship-Subhead">About the Internship</h5>
          <p className="internship-description">{viewIntern.jobDescription}</p>
        </div>
        <div>
          <h5 className="internship-Subhead">Skill(s) Required</h5>

          {/*  <ul className="skills-list">
              {viewIntern.skill.map((skill, index) => (
                 <li key={index} className="skill-item">
                   {skill}
                </li>
               ))}
             </ul> */}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {viewIntern.skill && viewIntern.skill.length > 0 ? (
              viewIntern.skill.map((skill, index) => (
                <div key={index} style={{ padding: '0.5rem' }}>
                  <p className="intern-skills">{skill}</p>
                </div>
              ))
            ) : (
              <p className="internship-mini">{viewIntern.skill || "No skills specified"}</p>
            )}
          </div>
          <div>
            <h5 className="internship-Subhead">Other Requirements</h5>
            <p className="internship-description"><AiFillSafetyCertificate style={{ padding: '5px', fontSize: '30px' }} /><strong>Education : </strong> {viewIntern.qualification}</p>
            <p className="internship-description"><FaLanguage size={30} style={{ padding: '5px' }} /><strong>Language Preference : </strong> {viewIntern.languagePreference}</p>
          </div>
        </div>
      </div>

    </Container>
  );
}

export default InternshipPage;

import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "../../../Components/Css/Students/Student.scss";
import Api from "../../../Api";
import "aos/dist/aos.css";
import AOS from "aos";

function Kycvendor() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      delay: "300",
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const [PanCard, setPanCard] = useState({});
  const userId = localStorage.getItem("userId");
  const [userData, setUserData] = useState([]);
  const [file, setFile] = useState();

  function handleChange(e) {
    setFile(e.target.files[0]);
  }
  console.log("file", file);
  const handleFormSubmit = async (data) => {
    try {
      const Details = {
        id: userId,
        kycDetails: {
          aadharCard: data.aadharcardNumber,
          panCard: data.pancardNumber,
          panCardImage: file,
          addressProof: data.addressProof,
          bankName: data.bankName,
          accountNumber: data.accountNumber,
          ifscCode: data.ifscCode,
        },
      };

      const response = await Api.put(`trainer/trainerkyc/${userId}`, Details);
      console.log("Response", response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      const res = await Api.get(`trainer/Trainergetone/${userId}`);
      setUserData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  console.log("userData", userData);
  useEffect(() => {
    if (userData) {
      setValue("aadharcardNumber", userData?.KYCDetails?.aadharCard);
      setValue("pancardNumber", userData?.KYCDetails?.panCard);
      setValue("addressProof", userData?.KYCDetails?.addressProof);
    }
  }, [userData]);

  return (
    <div>
      <Container data-aos="fade-down">
        <h4 className="pages-title mt-3">KYC Complaince</h4>
        <br />
        <p style={{ backgroundColor: "#fccc55", width: "40%" }}>
          Your details are required to meet kyc Complaince
        </p>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
            <Col sm={12} lg={4}>
              <label className="input-title">Pan or Adhar Upload Anyone: </label>
              <input
                className="kyc-input"
                style={{ width: "85%", outline: "none" }}
                type="file"
                onChange={handleChange}
              />
              <p>
                Only 1 document in pdf,jpeg and png format with maximum 5Mb
                uploaded
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6} xs={12}>
              <label className="input-title">PanCard Number: </label>
              <input
                {...register("pancardNumber", { required: true })}
                className="Professional__UpdateProfile__Input"
              />
              {errors.pancardNumber && (
                <p className="text-danger">pancard number is required</p>
              )}
            </Col>
            <Col sm={12} lg={4}>
              <label className="input-title">AadharCard Number: </label>
              <input
                {...register("aadharcardNumber", { required: true })}
                className="Professional__UpdateProfile__Input"
              />
              {errors.aadharcardNumber && (
                <p className="text-danger">AadharCard number is required</p>
              )}
            </Col>
            <Col sm={12} lg={4}>
              <label className="input-title">Address Proof: </label>
              <input
                {...register("addressProof", { required: true })}
                className="Professional__UpdateProfile__Input"
              />
              {errors.addressProof && (
                <p className="text-danger">Address is required</p>
              )}
            </Col>
          </Row>
          
          
          <br />
<Row style={{ width: '90%' }} className='px-4'>
  <Col lg={4} md={6} xs={12}>
    <div className="mb-3">
      <label className="input-title" htmlFor="bankName">Bank Name</label>
      <input
        type="text"
        className="Professional__UpdateProfile__Input"
        id="bankName"
        required
      />
    </div>
  </Col>
  <Col lg={4}>
    <div className="mb-3">
      <label className="input-title" htmlFor="accountNumber">Account Number</label>
      <input
        type="text"
        className="Professional__UpdateProfile__Input"
        id="accountNumber"
        required
      />
    </div>
  </Col>
  <Col lg={4}>
            <div className="mb-3">
              <label className="input-title" htmlFor="confirmAccountNumber">Confirm Account Number</label>
              <input
                type="password"
                className="Professional__UpdateProfile__Input"
                id="confirmAccountNumber"
                required
              />
            </div>
          </Col>
</Row>
<Row style={{ width: '90%' }} className='px-4'>
  <Col lg={4}>
    <div className="mb-3">
      <label className="input-title" htmlFor="ifscCode">IFSC Code</label>
      <input
        type="text"
        className="Professional__UpdateProfile__Input"
        id="ifscCode"
        required
      />
    </div>
  </Col>
</Row>
          <div className="submitbuttons px-4">
            <button
              className="stud-profil-btn1 m-2 p-2"
              type="submit"
              onClick={handleFormSubmit}
            >
              Submit
            </button>
            <button className="button2 m-2 p-2" type="reset">
              cancel
            </button>
          </div>
        </form>
      </Container>
      {/* </Card> */}
    </div>
  );
}

export default Kycvendor;

import React, { useState, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import Api from "../../../Api.js";
import axios from "axios";
import { notification } from "antd";
import "./Qualificationinfo.scss";

function QualificationInfo() {
  const [data, setData] = useState({});

  const [currentStandard, setCurrentStandard] = useState("");

  // PhD Details
  const [phdDegreeName, setPhdDegreeName] = useState("");
  const [phdMajor, setPhdMajor] = useState("");
  const [phdUniversity, setPhdUniversity] = useState("");
  const [phdYearOfCompletion, setPhdYearOfCompletion] = useState("");

  // Postgraduate Details
  const [postgraduateCgpa, setPostgraduateCgpa] = useState("");
  const [postgraduateCollegeName, setPostgraduateCollegeName] = useState("");
  const [postgraduateDegreeName, setPostgraduateDegreeName] = useState("");
  const [postgraduateMajor, setPostgraduateMajor] = useState("");
  const [postgraduateUniversity, setPostgraduateUniversity] = useState("");

  // School Details
  const [schoolBoard, setSchoolBoard] = useState("");
  const [currentSchoolName, setCurrentSchoolName] = useState("");

  // Eleventh Details
  const [eleventhBoard, setEleventhBoard] = useState("");
  const [eleventhPercentage, setEleventhPercentage] = useState("");
  const [eleventhSchoolName, setEleventhSchoolName] = useState("");
  const [eleventhYearOfPassing, setEleventhYearOfPassing] = useState("");

  //student detials

  const [resentStandard, setRecentStandard] = useState("");
  const [resentSchoolName, setresentSchoolName] = useState("");
  const [resentLocation, setResentLocation] = useState("");
  const [motherTongue, setMotherTongue] = useState("");
  const [knownLanguages, setknownLanguages] = useState("");
  const [additionalInput, setAdditionalInput] = useState("");
  const [areaofInterest, setAreaofInterst] = useState("");

// update collge details
const [showInput, setShowInput] = useState(false);
const [inputValue, setInputValue] = useState("");
const [countries, setCountries] = useState([]);
const [states, setStates] = useState([]);
const [districts, setDistricts] = useState([]);
const [cities, setCities] = useState([]);
const [schools, setSchools] = useState([]);

const [selectedCountry, setSelectedCountry] = useState("");
const [selectedState, setSelectedState] = useState("");
const [selectedDistrict, setSelectedDistrict] = useState("");
const [selectedCity, setSelectedCity] = useState("");
const [enteredArea, setEnteredArea] = useState(""); // For manual input of Area
const [selectedSchool, setSelectedSchool] = useState("");

  // Tenth Details
  const [tenthBoard, setTenthBoard] = useState("");
  const [tenthPercentage, setTenthPercentage] = useState("");
  const [tenthSchoolName, setTenthSchoolName] = useState("");
  const [tenthYearOfPassing, setTenthYearOfPassing] = useState("");

  // Twelfth Details
  const [twelfthBoard, setTwelfthBoard] = useState("");
  const [twelfthPercentage, setTwelfthPercentage] = useState("");
  const [twelfthSchoolName, setTwelfthSchoolName] = useState("");
  const [twelfthYearOfPassing, setTwelfthYearOfPassing] = useState("");

  // Undergraduate Details
  const [undergraduateCgpa, setUndergraduateCgpa] = useState("");
  const [undergraduateCollegeName, setUndergraduateCollegeName] = useState("");
  const [undergraduateDegreeName, setUndergraduateDegreeName] = useState("");
  const [undergraduateMajor, setUndergraduateMajor] = useState("");
  const [undergraduateUniversity, setUndergraduateUniversity] = useState("");

  const [ugDegrees, setUgDegrees] = useState([]);
  const [postgraduateIsChecked, setPostgraduateIsChecked] = useState(false);
  const [phdIsChecked, setPhdIsChecked] = useState(false);

  const [pgDegrees, setPgDegrees] = useState([]);
  const [phdDegrees, setPhdDegrees] = useState([]);
  const [majors, setMajors] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState("");
  const [filteredMajors, setFilteredMajors] = useState([]);
  const [pgDegree, setPgDegree] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [pgFilteredMajors, setPgFilteredMajors] = useState([]);
  const [phdDegree, setPhdDegree] = useState("");
  const [filteredPhdMajor, setFilteredPhdMajor] = useState([]);
  const userId = localStorage.getItem("userId");

  
  useEffect(() => {
    if (data) {
      setCurrentStandard(data?.currentStandard || "");
      setPhdDegreeName(data?.phdDetails?.phdDegreeName || "");
      setPhdIsChecked(data?.phdDetails?.isChecked || false);
      setPhdMajor(data?.phdDetails?.phdMajor || "");
      setPhdUniversity(data?.phdDetails?.phdUniversity || "");
      setPhdYearOfCompletion(data?.phdDetails?.phdYearOfCompletion || "");
      setPostgraduateCgpa(data?.postgraduateDetails?.cgpa || "");
      setPostgraduateIsChecked(data?.postgraduateDetails?.isChecked || false);
      setPostgraduateCollegeName(data?.postgraduateDetails?.collegeName || "");
      setPostgraduateDegreeName(data?.postgraduateDetails?.degreeName || "");
      setPostgraduateMajor(data?.postgraduateDetails?.major || "");
      setPostgraduateUniversity(data?.postgraduateDetails?.university || "");
      setSchoolBoard(data?.schoolDetails?.board || "");
      setCurrentSchoolName(data?.schoolDetails?.currentSchoolName || "");
      setEleventhBoard(data?.schoolDetails?.eleventhDetails?.board || "");
      setEleventhPercentage(
        data?.schoolDetails?.eleventhDetails?.percentage || ""
      );
      setEleventhSchoolName(
        data?.schoolDetails?.eleventhDetails?.schoolName || ""
      );
      setEleventhYearOfPassing(
        data?.schoolDetails?.eleventhDetails?.yearOfPassing || ""
      );
      setTenthBoard(data?.schoolDetails?.tenthDetails?.board || "");
      setTenthPercentage(data?.schoolDetails?.tenthDetails?.percentage || "");
      setTenthSchoolName(data?.schoolDetails?.tenthDetails?.schoolName || "");
      setTenthYearOfPassing(
        data?.schoolDetails?.tenthDetails?.yearOfPassing || ""
      );
      setTwelfthBoard(data?.schoolDetails?.twelfthDetails?.board || "");
      setTwelfthPercentage(
        data?.schoolDetails?.twelfthDetails?.percentage || ""
      );
      setTwelfthSchoolName(
        data?.schoolDetails?.twelfthDetails?.schoolName || ""
      );
      setTwelfthYearOfPassing(
        data?.schoolDetails?.twelfthDetails?.yearOfPassing || ""
      );
      setUndergraduateCgpa(data?.undergraduateDetails?.cgpa || "");
      setUndergraduateCollegeName(
        data?.undergraduateDetails?.collegeName || ""
      );
      setUndergraduateDegreeName(data?.undergraduateDetails?.degreeName || "");
      setUndergraduateMajor(data?.undergraduateDetails?.major || "");
      setUndergraduateUniversity(data?.undergraduateDetails?.university || "");
    }
  }, [data]);

  useEffect(() => {
    if (!userId) {
      console.error("User ID not found in localStorage");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await Api.get(`qualify/${userId}`);
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const handleCheckboxChange = () => {
    setPostgraduateIsChecked(!postgraduateIsChecked);
  };
  const handlephdCheckboxChange = () => {
    setPhdIsChecked(!phdIsChecked);
  };
  const fetchUGDegree = async () => {
    try {
      const response = await Api.get(`degree/degrees/UG`);
      const degreeData = response.data;
      setUgDegrees(degreeData);

      const preselectedDegree = degreeData.find((degree) => degree.preselected);
      if (preselectedDegree) {
        setUndergraduateDegreeName(preselectedDegree.degreeName);
        setFilteredMajors(preselectedDegree.majors);
        setUndergraduateMajor(preselectedDegree.preselectedMajor || "");
      }
    } catch (error) {
      console.error("Error fetching UG degrees:", error);
    }
  };

  useEffect(() => {
    fetchUGDegree();
  }, []);

  useEffect(() => {
    const selectedDegree = ugDegrees.find(
      (degree) => degree.degreeName === undergraduateDegreeName
    );

    if (selectedDegree) {
      setFilteredMajors(selectedDegree.majors);
      if (!selectedDegree.majors.includes(undergraduateMajor)) {
        setUndergraduateMajor(selectedDegree.majors[0] || "");
      }
    } else {
      setFilteredMajors([]);
      setUndergraduateMajor("");
    }
  }, [undergraduateDegreeName, ugDegrees]);

  const fetchPGDegree = async () => {
    try {
      const response = await Api.get(`degree/degrees/PG`);
      const degreeData = response.data;
      setPgDegrees(degreeData);
      const preselectedDegree = degreeData.find((degree) => degree.preselected);
      if (preselectedDegree) {
        setPostgraduateDegreeName(preselectedDegree.degreeName);
        setPgFilteredMajors(preselectedDegree.majors);
        setPostgraduateMajor(preselectedDegree.preselectedMajor || "");
      }
    } catch (error) {
      console.error("Error fetching PG degrees:", error);
    }
  };

  useEffect(() => {
    fetchPGDegree();
  }, []);

  useEffect(() => {
    const selectedDegree = pgDegrees.find(
      (degree) => degree.degreeName === postgraduateDegreeName
    );

    if (selectedDegree) {
      setPgFilteredMajors(selectedDegree.majors);
      if (!selectedDegree.majors.includes(postgraduateMajor)) {
        setPostgraduateMajor(selectedDegree.majors[0] || "");
      }
    } else {
      setPgFilteredMajors([]);
      setPostgraduateMajor("");
    }
  }, [postgraduateDegreeName, pgDegrees]);

  const fetchPHDDegree = async () => {
    try {
      const response = await Api.get(`degree/degrees/PhD`);
      const degreeData = response.data;
      setPhdDegrees(degreeData);

      const preselectedDegree = degreeData.find((degree) => degree.preselected);
      if (preselectedDegree) {
        setPhdDegreeName(preselectedDegree.degreeName);
        setFilteredPhdMajor(preselectedDegree.majors);
        setPhdMajor(preselectedDegree.preselectedMajor || "");
      }
    } catch (error) {
      console.error("Error fetching PhD degrees:", error);
    }
  };

  useEffect(() => {
    fetchPHDDegree();
  }, []);

  useEffect(() => {
    const selectedDegree = phdDegrees.find(
      (degree) => degree.degreeName === phdDegreeName
    );

    if (selectedDegree) {
      setFilteredPhdMajor(selectedDegree.majors);
      if (!selectedDegree.majors.includes(phdMajor)) {
        setPhdMajor(selectedDegree.majors[0] || "");
      }
    } else {
      setFilteredPhdMajor([]);
      setPhdMajor("");
    }
  }, [phdDegreeName, phdDegrees]);

  useEffect(() => {
    if (phdDegreeName && phdMajor) {
      const selectedDegree = phdDegrees.find(
        (degree) => degree.degreeName === phdDegreeName
      );

      if (selectedDegree && !selectedDegree.majors.includes(phdMajor)) {
        setPhdMajor("");
      }
    }
  }, [phdMajor, phdDegreeName, phdDegrees]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    const payload = {
      currentStandard: currentStandard,
      phdDetails: {
        phdDegreeName: phdDegreeName,
        phdMajor: phdMajor,
        isChecked: phdIsChecked,
        phdUniversity: phdUniversity,
        phdYearOfCompletion: phdYearOfCompletion,
      },
      postgraduateDetails: {
        cgpa: postgraduateCgpa,
        isChecked: postgraduateIsChecked,
        collegeName: postgraduateCollegeName,
        degreeName: postgraduateDegreeName,
        major: postgraduateMajor,
        university: postgraduateUniversity,
      },
      schoolDetails: {
        board: schoolBoard,
        currentSchoolName: currentSchoolName,
        eleventhDetails: {
          board: eleventhBoard,
          percentage: eleventhPercentage,
          schoolName: eleventhSchoolName,
          yearOfPassing: eleventhYearOfPassing,
        },

        studentDetails: {
          standard: resentStandard,
          resentschool: resentSchoolName,
          location: resentLocation,
          motherTongue: motherTongue,
          knownLanguages: knownLanguages,
          areasOfInterest: areaofInterest,
        },
        tenthDetails: {
          board: tenthBoard,
          percentage: tenthPercentage,
          schoolName: tenthSchoolName,
          yearOfPassing: tenthYearOfPassing,
        },
        twelfthDetails: {
          board: twelfthBoard,
          percentage: twelfthPercentage,
          schoolName: twelfthSchoolName,
          yearOfPassing: twelfthYearOfPassing,
        },
      },
      undergraduateDetails: {
        cgpa: undergraduateCgpa,
        collegeName: undergraduateCollegeName,
        degreeName: undergraduateDegreeName,
        major: undergraduateMajor,
        university: undergraduateUniversity,
      },
    };

    try {
      const response = await Api.post(`qualify/${userId}`, payload);

      if (response.status === 200 || response.status === 201) {
        notification.success({
          message: "Success",
          description: "Data submitted successfully!",
          placement: "topRight",
        });
      }
    } catch (error) {
      setError("Failed to submit data. Please try again.");
      console.error("Error submitting data:", error);
      notification.error({
        message: "Error",
        description: "Failed to submit data. Please try again.",
        placement: "topRight",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const storedPgDegree = localStorage.getItem("pgDegree");
    if (storedPgDegree) {
      setPgDegree(storedPgDegree);
    }

    const storedPhdDegree = localStorage.getItem("phdDegree");
    if (storedPhdDegree) {
      setPhdDegree(storedPhdDegree);
    }
  }, []);

  const handlePgCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = isChecked ? "M.A." : "";

    setPgDegree(value);
    localStorage.setItem("pgDegree", value);
  };

  const handlePhdCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = isChecked ? "PhD" : "";

    setPhdDegree(value);
    localStorage.setItem("phdDegree", value);
  };
  const [student, setStudent] = useState("");
  const [userData, setUserData] = useState("");
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = async () => {
    try {
      const res = await Api.get(`signup/student_getone/${userId}`);
      console.log("API Response:", res.data.data.educationLevel); // Confirm the response

      setUserData(res?.data?.data?.educationLevel || ""); // Safely set the value
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };


  return (
    <div className="container-fluid qua-info">
      <Form>
        <Row className="gap-style">
          <h5 className="label-for-student"> Student Details</h5>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">
                  {userData ? userData : "Loading..."}{" "}
                  {/* Display userData or fallback */}
                </Form.Label>
                <Form.Select
                  className="student-personal-info"
                  name="standard"
                  value={resentStandard || ""}
                  onChange={(e) => setRecentStandard(e.target.value)}
                  style={{ height: "45px" }}
                >
                  <option value="">Select an option</option>
                  {userData === "school" ? (
                    Array.from({ length: 12 }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}standard
                      </option>
                    ))
                  ) : userData === "college" ? (
                    <>
                      <option>Bsc</option>
                      <option>Msc</option>
                      <option>Others</option>
                    </>
                  ) : null}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">
                  {userData === "school"
                    ? "School Name"
                    : userData === "college"
                    ? "College Name"
                    : "School/College Name"}
                </Form.Label>
                <Form.Select
                  className="student-personal-info"
                  name="standard"
                  value={resentSchoolName || ""}
                  onChange={(e) => setresentSchoolName(e.target.value)}
                  style={{ height: "45px" }}
                >
                  <option value="">
                    Select{" "}
                    {userData === "school"
                      ? "School Name"
                      : userData === "college"
                      ? "College Name"
                      : "Option"}
                  </option>
                  {userData === "school" ? (
                    <>
                      <option>Green Valley School</option>
                      <option>Chaitanya Shool</option>
                      <option>Narayana School</option>
                    </>
                  ) : userData === "college" ? (
                    <>
                      <option value="St. Xavier's College">
                        St. Xavier's College
                      </option>
                      <option value="Christ University">
                        Christ University
                      </option>
                      <option value="Loyola College">Loyola College</option>
                    </>
                  ) : null}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Location</Form.Label>
                <Form.Select
                  className="student-personal-info"
                  name="standard"
                  value={resentLocation || ""}
                  onChange={(e) => setResentLocation(e.target.value)}
                  style={{ height: "45px" }}
                >
                  <option value="">Select Location</option>

                  <option>Chennai East</option>
                  <option>Chennai West</option>
                  <option>Chennai North</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">motherTongue</Form.Label>
                <Form.Select
                  className="student-personal-info"
                  name="standard"
                  value={motherTongue || ""}
                  onChange={(e) => setMotherTongue(e.target.value)}
                  style={{ height: "45px" }}
                >
                  <option value="">Select Location</option>

                  <option>tamil</option>
                  <option>English</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>{" "}
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">
                  Known Languages
                </Form.Label>
                <Form.Control
                  className="student-personal-info"
                  name="standard"
                  placeholder="known languages"
                  value={knownLanguages || ""}
                  onChange={(e) => setknownLanguages(e.target.value)}
                  style={{ height: "45px" }}
                ></Form.Control>
              </Form.Group>
              {knownLanguages ? (
                <Form.Group className="mb-2">
                  <Form.Label className="student-info">
                    Additional Information for{" "}
                    {knownLanguages.charAt(0).toUpperCase() +
                      knownLanguages.slice(1)}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={additionalInput}
                    onChange={(e) => setAdditionalInput(e.target.value)}
                    placeholder="read write or speak"
                  />
                </Form.Group>
              ) : null}
            </Form>
          </Col>{" "}
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">
                  Area of Interest
                </Form.Label>
                <Form.Control
                  className="student-personal-info"
                  type="text"
                  name="schoolDetails.tenthDetails.schoolName"
                  value={areaofInterest || ""}
                  placeholder="School Name"
                  onChange={(e) => setAreaofInterst(e.target.value)}
                  style={{ height: "45px" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <p className="request-button" onClick={() => setShowInput(true)}>
        Click and Send a Request to Admin to Update Your School/College List
      </p>

      {showInput && (
        <div
          style={{
            marginTop: "10px",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            maxWidth: "400px",
            margin: "0 auto",
          }}
        >
          <select
            value={selectedSchool}
            onChange={(e) => setSelectedSchool(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <option value="">Select School/College</option>
            {schools.map((school, index) => (
              <option key={index} value={school.name}>
                {school.name}
              </option>
            ))}
          </select>

          <select
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <option value="">Select Country</option>
            {countries.map((country, index) => (
              <option key={index} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>

          <select
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <option value="">Select State</option>
            {states.map((state, index) => (
              <option key={index} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>

          <select
            value={selectedDistrict}
            onChange={(e) => setSelectedDistrict(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <option value="">Select District</option>
            {districts.map((district, index) => (
              <option key={index} value={district.name}>
                {district.name}
              </option>
            ))}
          </select>

          <select
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            <option value="">Select City</option>
            {cities.map((city, index) => (
              <option key={index} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>

          {/* Replace Area Dropdown with Input */}
          <input
            type="text"
            placeholder="Enter Area"
            value={enteredArea}
            onChange={(e) => setEnteredArea(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "14px",
              marginBottom: "10px",
            }}
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              style={{
                padding: "8px 16px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={handleSubmit}
            >
              Submit Request
            </button>

            <button
              style={{
                padding: "8px 16px",
                backgroundColor: "#dc3545",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={() => setShowInput(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
<h5 className="label-for-student">10th Standard Details</h5>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">
                  10th School Name
                </Form.Label>
                <Form.Control
                  className="student-personal-info"
                  type="text"
                  name="schoolDetails.tenthDetails.schoolName"
                  value={tenthSchoolName || ""}
                  placeholder="School Name"
                  onChange={(e) => setTenthSchoolName(e.target.value)}
                  style={{ height: "45px" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Board</Form.Label>
                <Form.Select
                  className="student-personal-info"
                  name="schoolDetails.tenthDetails.board"
                  value={tenthBoard || ""}
                  onChange={(e) => setTenthBoard(e.target.value)}
                  style={{ height: "45px" }}
                >
                  <option value="">Select Board</option>
                  <option value="10">State Board</option>
                  <option value="11">CBSE</option>
                  <option value="12">ICSE</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">
                  10th Percentage
                </Form.Label>
                <Form.Control
                  className="student-personal-info"
                  type="text"
                  name="schoolDetails.tenthDetails.percentage"
                  value={tenthPercentage || ""}
                  placeholder="Percentage"
                  onChange={(e) => setTenthPercentage(e.target.value)}
                  style={{ height: "45px" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-4">
                <Form.Label className="student-info">
                  10th Year of Passing
                </Form.Label>
                <Form.Control
                  className="student-personal-info"
                  type="text"
                  name="schoolDetails.tenthDetails.yearOfPassing"
                  value={tenthYearOfPassing || ""}
                  placeholder="Year of Passing"
                  onChange={(e) => setTenthYearOfPassing(e.target.value)}
                  style={{ height: "45px" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <h5 className="label-for-student">12th Standard Details</h5>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">
                  12th School Name
                </Form.Label>
                <Form.Control
                  className="student-personal-info"
                  type="text"
                  name="schoolDetails.twelfthDetails.schoolName"
                  value={twelfthSchoolName || ""}
                  placeholder="School Name"
                  onChange={(e) => setTwelfthSchoolName(e.target.value)}
                  style={{ height: "45px" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Board</Form.Label>
                <Form.Select
                  className="student-personal-info"
                  name="schoolDetails.twelfthDetails.board"
                  value={twelfthBoard || ""}
                  onChange={(e) => setTwelfthBoard(e.target.value)}
                  style={{ height: "45px" }}
                >
                  <option value="">Select Board</option>
                  <option value="10">State Board</option>
                  <option value="11">CBSE</option>
                  <option value="12">ICSE</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">
                  12th Percentage
                </Form.Label>
                <Form.Control
                  className="student-personal-info"
                  type="text"
                  name="schoolDetails.twelfthDetails.percentage"
                  value={twelfthPercentage || ""}
                  placeholder="Percentage"
                  onChange={(e) => setTwelfthPercentage(e.target.value)}
                  style={{ height: "45px" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-4">
                <Form.Label className="student-info">
                  12th Year of Passing
                </Form.Label>
                <Form.Control
                  className="student-personal-info"
                  type="text"
                  name="schoolDetails.twelfthDetails.yearOfPassing"
                  value={twelfthYearOfPassing || ""}
                  placeholder="Year of Passing"
                  onChange={(e) => setTwelfthYearOfPassing(e.target.value)}
                  style={{ height: "45px" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <h4 className="text-center my-3">Undergraduate (UG) Details</h4>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Degree Name</Form.Label>
                <Form.Select
                  value={undergraduateDegreeName}
                  onChange={(e) => setUndergraduateDegreeName(e.target.value)}
                  className="student-personal-info"
                  style={{ height: "45px" }}
                >
                  <option value="">Select Degree</option>
                  {ugDegrees.map((degree) => (
                    <option key={degree._id} value={degree.degreeName}>
                      {degree.degreeName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label className="student-info">Major</Form.Label>
                <Form.Select
                  value={undergraduateMajor}
                  onChange={(e) => setUndergraduateMajor(e.target.value)}
                  className="student-personal-info"
                  style={{ height: "45px" }}
                  disabled={!filteredMajors.length}
                >
                  <option value="">Select Major</option>
                  {filteredMajors.map((major, index) => (
                    <option key={index} value={major}>
                      {major}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2" controlId="ugCollegeName">
                <Form.Label className="student-info">
                  UG College Name
                </Form.Label>
                <Form.Control
                  className="student-personal-info"
                  type="text"
                  name="undergraduateDetails.collegeName"
                  value={undergraduateCollegeName || ""}
                  placeholder="Enter UG College Name"
                  onChange={(e) => setUndergraduateCollegeName(e.target.value)}
                  style={{ height: "45px" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group className="mb-2" controlId="ugCgpa">
                <Form.Label className="student-info">UG CGPA</Form.Label>
                <Form.Control
                  className="student-personal-info"
                  type="number"
                  name="undergraduateDetails.cgpa"
                  value={undergraduateCgpa || ""}
                  placeholder="Enter UG CGPA"
                  onChange={(e) => setUndergraduateCgpa(e.target.value)}
                  style={{ height: "45px" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} md={12}>
            <Form>
              <Form.Group className="mb-2" controlId="ugUniversity">
                <Form.Label className="student-info">UG University</Form.Label>
                <Form.Control
                  className="student-personal-info"
                  type="text"
                  name="undergraduateDetails.university"
                  value={undergraduateUniversity || ""}
                  placeholder="Enter UG University"
                  onChange={(e) => setUndergraduateUniversity(e.target.value)}
                  style={{ height: "45px", width: "95%" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <h5 className="label-for-student">
            Postgraduate (PG) Details (Optional)
          </h5>
          <div className="checkbox-container col-10">
            <label className="c-f-s">Do you have a Postgraduate degree?</label>
            <input
              className="checkbox-edu"
              type="checkbox"
              checked={postgraduateIsChecked}
              onChange={handleCheckboxChange}
            />
          </div>
          {postgraduateIsChecked && (
            <>
              <Col xs={12} md={6}>
                <Form>
                  <Form.Group className="mb-2">
                    <Form.Label className="student-info">
                      PG Degree Name
                    </Form.Label>
                    <Form.Select
                      value={postgraduateDegreeName}
                      onChange={(e) =>
                        setPostgraduateDegreeName(e.target.value)
                      }
                      className="student-personal-info"
                      style={{ height: "45px" }}
                    >
                      <option value="">Select Degree</option>
                      {pgDegrees.map((degree) => (
                        <option key={degree._id} value={degree.degreeName}>
                          {degree.degreeName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>

              <Col xs={12} md={6}>
                <Form>
                  <Form.Group className="mb-2">
                    <Form.Label className="student-info">PG Major</Form.Label>
                    <Form.Select
                      value={postgraduateMajor}
                      onChange={(e) => setPostgraduateMajor(e.target.value)}
                      className="student-personal-info"
                      style={{ height: "45px" }}
                      disabled={!pgFilteredMajors.length}
                    >
                      <option value="">Select Major</option>
                      {pgFilteredMajors.map((major, index) => (
                        <option key={index} value={major}>
                          {major}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>
              <Col xs={12} md={6}>
                <Form>
                  <Form.Group className="mb-2" controlId="pgUniversity">
                    <Form.Label className="student-info">
                      PG University
                    </Form.Label>
                    <Form.Control
                      className="student-personal-info"
                      type="text"
                      name="postgraduateDetails.university"
                      value={postgraduateUniversity || ""}
                      placeholder="Enter PG University"
                      onChange={(e) =>
                        setPostgraduateUniversity(e.target.value)
                      }
                      style={{ height: "45px" }}
                    />
                  </Form.Group>
                </Form>
              </Col>

              <Col xs={12} md={6}>
                <Form>
                  <Form.Group className="mb-2" controlId="pgCgpa">
                    <Form.Label className="student-info">PG CGPA</Form.Label>
                    <Form.Control
                      className="student-personal-info"
                      type="number"
                      name="postgraduateDetails.cgpa"
                      value={postgraduateCgpa || ""}
                      placeholder="Enter PG CGPA"
                      onChange={(e) => setPostgraduateCgpa(e.target.value)}
                      style={{ height: "45px" }}
                    />
                  </Form.Group>
                </Form>
              </Col>

              <Col xs={12} md={12}>
                <Form>
                  <Form.Group className="mb-2" controlId="pgCollegeName">
                    <Form.Label className="student-info">
                      PG College Name
                    </Form.Label>
                    <Form.Control
                      className="student-personal-info"
                      type="text"
                      name="postgraduateDetails.collegeName"
                      value={postgraduateCollegeName || ""}
                      placeholder="Enter PG College Name"
                      onChange={(e) =>
                        setPostgraduateCollegeName(e.target.value)
                      }
                      style={{ height: "45px", width: "95%" }}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </>
          )}
          <h5 className="label-for-student">PhD Details (Optional)</h5>
          <div className="checkbox-container col-10">
            <label className="c-f-s">Do you have a PhD degree?</label>
            <input
              className="checkbox-edu"
              type="checkbox"
              checked={phdIsChecked}
              onChange={handlephdCheckboxChange}
            />
          </div>
          {phdIsChecked && (
            <>
              <Col xs={12} md={6}>
                <Form>
                  <Form.Group className="mb-2">
                    <Form.Label className="student-info">
                      PhD Degree Name
                    </Form.Label>
                    <Form.Select
                      value={phdDegreeName}
                      onChange={(e) => setPhdDegreeName(e.target.value)}
                      className="student-personal-info"
                      style={{ height: "45px" }}
                    >
                      <option value="">Select Degree</option>
                      {phdDegrees.map((degree) => (
                        <option key={degree._id} value={degree.degreeName}>
                          {degree.degreeName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>

              <Col xs={12} md={6}>
                <Form>
                  <Form.Group className="mb-2">
                    <Form.Label className="student-info">PhD Major</Form.Label>
                    <Form.Select
                      value={phdMajor}
                      onChange={(e) => setPhdMajor(e.target.value)}
                      className="student-personal-info"
                      style={{ height: "45px" }}
                      disabled={!filteredPhdMajor.length}
                    >
                      <option value="">Select Major</option>
                      {filteredPhdMajor.map((major, index) => (
                        <option key={index} value={major}>
                          {major}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>

              <Col xs={12} md={6}>
                <Form>
                  <Form.Group className="mb-2" controlId="phdUniversity">
                    <Form.Label className="student-info">
                      PhD University
                    </Form.Label>
                    <Form.Control
                      className="student-personal-info"
                      type="text"
                      name="phdDetails.phdUniversity"
                      value={phdUniversity || ""}
                      placeholder="Enter PhD University"
                      onChange={(e) => setPhdUniversity(e.target.value)}
                      style={{ height: "45px" }}
                    />
                  </Form.Group>
                </Form>
              </Col>

              <Col xs={12} md={6}>
                <Form>
                  <Form.Group className="mb-2" controlId="phdYearOfCompletion">
                    <Form.Label className="student-info">
                      Year of Completion
                    </Form.Label>
                    <Form.Control
                      className="student-personal-info"
                      type="number"
                      name="phdDetails.phdYearOfCompletion"
                      value={phdYearOfCompletion || ""}
                      placeholder="Enter Year of Completion"
                      onChange={(e) => setPhdYearOfCompletion(e.target.value)}
                      style={{ height: "45px" }}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </>
          )}
        </Row>
      </Form>
      {/* </Tab>
      </Tabs> */}
      <Row className="mt-4">
        <Col className="text-center">
          <Button variant="success" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default QualificationInfo;

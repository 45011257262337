import React, { useState, useEffect } from "react";
import Api from "../../../Api";
import "../Profile/Training.css"; // Import the CSS file for styling

const Training = () => {
  const trainerId = localStorage.getItem("userId");

  const [trainingDetails, setTrainingDetails] = useState({
    totalyearsofexp: "",
    preferredmode: "",
    studentstrained: "",
    trainingtype: "",
  });
  const [fields, setFields] = useState([
    { areaOfInterest: "", description: "" },
  ]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Fetch the existing training details
  useEffect(() => {
    Api.get(`/trainer/Trainergetone/${trainerId}`)
      .then((response) => {
        console.log("training", response);
        const data = response?.data?.data?.professionalDetails || {};
        // Ensure technology is always an array
        setTrainingDetails({
          ...data,
          interest: Array.isArray(data.areaOfInterest) ? data.technology : [],
        });
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to fetch trainer details");
      });
  }, [trainerId]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrainingDetails({ ...trainingDetails, [name]: value });
  };
  const handleFieldChange = (index, name, value) => {
    const updatedFields = [...fields];
    updatedFields[index][name] = value;
    setFields(updatedFields);
  };

  const handleAddMore = () => {
    setFields([...fields, { areaOfInterest: "", description: "" }]);
  };

  const handleRemove = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };


  // Handle technology inputs changes
  const handleTechnologyChange = (index, event) => {
    const { name, value } = event.target;
    const updatedTechnologies = trainingDetails.technology.map((tech, idx) =>
      idx === index ? { ...tech, [name]: value } : tech
    );
    setTrainingDetails({ ...trainingDetails, technology: updatedTechnologies });
  };

  // Add a new technology field
  const addTechnology = () => {
    setTrainingDetails({
      ...trainingDetails,
      technology: [...trainingDetails.technology, { name: "", experience: "" }],
    });
  };

  // Remove a technology field
  const removeTechnology = (index) => {
    setTrainingDetails({
      ...trainingDetails,
      technology: trainingDetails.technology.filter((_, idx) => idx !== index),
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        ...trainingDetails,
        interest: fields, // Add fields array to the payload as "interest"
      };
      const response = await Api.put(
        `/trainer/trainerprofessionalinfo/${trainerId}`,
        payload
      );
      console.log(response);
      
      setMessage(response.data.message);
      setError("");
    } catch (error) {
      setError("Error updating training details");
      setMessage("");
    }
  };

  return (
    <div className="training-container">
      <h2>Update Training Details</h2>
      {message && <p className="success-message">{message}</p>}
      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-grid">
          <div className="form-group">
            <label>Total Years of Experience</label>
            <input
              type="text"
              name="totalyearsofexp"
              value={trainingDetails.totalyearsofexp || ""}
              onChange={handleChange}
              required
            />
          </div>
            <div className="form-group">
              <label>Sector</label>
              <input
                type="text"
                name="sector"
                value={trainingDetails.sector || ""}
                onChange={handleChange}
                required
              />
          </div>
          <div className="form-group">
            <label>Domain</label>
            <select
              name="preferredmode"
              value={trainingDetails.preferredmode || ""}
              onChange={handleChange}
              required
            >
              <option value="">Select Mode</option>
              <option value="online">Online</option>
              <option value="offline">Offline</option>
              <option value="both">Both</option>
            </select>
          </div>

          <div className="form-group">
            <label>Subdomain</label>
            <input
              type="text"
              name="studentstrained"
              value={trainingDetails.studentstrained || ""}
              onChange={handleChange}
            />
          </div>
          {/* 
          <div className="form-group">
            <label>Training Type</label>
            <select
              name="trainingtype"
              value={trainingDetails.trainingtype || ""}
              onChange={handleChange}
            >
              <option value="">Select Training Type</option>
              <option value="Corporate">Corporate</option>
              <option value="Individual">Individual</option>
              <option value="Both">Both</option>
            </select>
          </div> */}

{fields.map((field, index) => (
          <div key={index} className="field-group mb-3">
            <div className="form-group">
              <label>Area of Interest</label>
              <input
                type="text"
                name="areaOfInterest"
                value={field.areaOfInterest}
                onChange={(e) =>
                  handleFieldChange(index, "areaOfInterest", e.target.value)
                }
                className="training-input1"
                required
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                name="description"
                value={field.description}
                onChange={(e) =>
                  handleFieldChange(index, "description", e.target.value)
                }
                className="training-input1"
                required
              />
            </div>
            {fields.length > 1 && (
              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={() => handleRemove(index)}
              >
                Remove
              </button>
            )}
          </div>
        ))}
        
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddMore}
        >
          Add More
        </button>
        {/* <h3>Technologies</h3>
        {trainingDetails?.technology?.map((tech, index) => (
          <div className="technology-group" key={index}>
            <div className="form-group">
              <label>Technology Name</label>
              <input
                type="text"
                name="name"
                value={tech.name}
                onChange={(e) => handleTechnologyChange(index, e)}
              />
            </div>
            <div className="form-group">
              <label>Experience (Years)</label>
              <input
                type="number"
                name="experience"
                value={tech.experience}
                onChange={(e) => handleTechnologyChange(index, e)}
              />
            </div>
            <button
              type="button"
              className="remove-technology-button"
              onClick={() => removeTechnology(index)}
            >
              Remove
            </button>
          </div>
        ))} */}

        {/* <button
          type="button"
          className="add-technology-button"
          onClick={addTechnology}
        >
          Add Technology
        </button> */}

        <div className="submit-container">
          <button
            type="submit"
            className="submit-button"
            style={{ width: "220px" }}
          >
            Update Training Details
          </button>
        </div>
      </form>
    </div>
  );
};

export default Training;

import React, { useState, useEffect } from "react";
import "../Skilltest/skilltest.scss";
import { Row, Col, Input, Select, Card, Rate, Radio, Space, Modal } from "antd";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";
import "./Learning.scss";
import "aos/dist/aos.css";
import AOS from "aos";
import { Button } from "react-bootstrap";

const { Option } = Select;

function Learning() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      delay: "300",
    });
  }, []);

  const badgeStyle = {
    backgroundColor: "beige",
    color: "black",
    width: "100px",
    padding: "5px 10px",
    borderRadius: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0",
  };

  const navigate = useNavigate();
  const [allCourses, setAllCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [ratingRange, setRatingRange] = useState([0, 5]);
  const [radioValue, setRadioValue] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await Api.get("/course/getAllCourses");
        console.log("Fetched data: ", response.data); 
        if (response.data && Array.isArray(response.data)) {
          setAllCourses(response.data);
          setFilteredCourses(response.data); 
        } else {
          console.error("Invalid data format");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  const applyFilters = (title, duration, rating, courses) => {
    const filtered = courses.filter((course) => {
      const matchesTitle = course.title
        ? course.title.toLowerCase().includes(title.toLowerCase())
        : false;
      const matchesDuration = !duration || course.duration === duration;
      const matchesRating =
        course.rating >= rating[0] && course.rating <= rating[1];
      return matchesTitle && matchesDuration && matchesRating;
    });
    setFilteredCourses(filtered);
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setSearchTitle(newTitle);
    applyFilters(newTitle, selectedDuration, ratingRange, allCourses);
  };

  const handleDurationChange = (value) => {
    setSelectedDuration(value);
    applyFilters(searchTitle, value, ratingRange, allCourses);
  };

  const handleRatingChange = (value) => {
    setRatingRange(value);
    applyFilters(searchTitle, selectedDuration, value, allCourses);
  };

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    let newRatingRange;
    switch (e.target.value) {
      case 1:
        newRatingRange = [4.5, 5];
        break;
      case 2:
        newRatingRange = [4.0, 4.5];
        break;
      case 3:
        newRatingRange = [3.5, 4.0];
        break;
      case 4:
        newRatingRange = [3.0, 3.5];
        break;
      default:
        newRatingRange = [0, 5];
    }
    handleRatingChange(newRatingRange);
  };

  const handleResetFilters = () => {
    setSearchTitle("");
    setSelectedDuration(null);
    setRatingRange([0, 5]);
    setRadioValue(0);
    setFilteredCourses(allCourses);
  };

  const handleCardClick = (id) => {
    if (id) {
      navigate(`/batchespage`,{ state: { id:id } });
    } else {
      console.error("Course ID is undefined");
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="learning-container">
      <div className="pt-2">
        <h1 className="companyheading pt-5">Courses</h1>
        <div className="learning-course-filter">
          <Button className="filter-btn-course-search-page" onClick={showModal}>
            Filter
          </Button>
        </div>

        <Modal
          title="Filter Courses"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button
              className="learning-filter-reset"
              key="reset"
              onClick={handleResetFilters}
            >
              Reset Filters
            </Button>,
            <Button
              className="learning-filter-apply"
              key="submit"
              type="primary"
              onClick={handleOk}
            >
              Apply Filters
            </Button>,
          ]}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="mb-3">
              <label> Title </label>
              <br />
              <Input
                className="learning-page-input"
                placeholder="Search by title"
                value={searchTitle}
                onChange={handleTitleChange}
              />
            </div>

            <div className="mb-3">
              <label>Duration</label>
              <br />
              <Select
                placeholder="Select duration"
                style={{ width: "100%" }}
                value={selectedDuration}
                onChange={handleDurationChange}
              >
                <Option value="4 weeks">4 weeks</Option>
                <Option value="5 weeks">5 weeks</Option>
                <Option value="6 weeks">6 weeks</Option>
                <Option value="7 weeks">7 weeks</Option>
                <Option value="8 weeks">8 weeks</Option>
              </Select>
            </div>

            <div className="mb-3">
              <label>Rating</label>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Rating</Accordion.Header>
                  <Accordion.Body>
                    <Radio.Group
                      onChange={handleRadioChange}
                      value={radioValue}
                    >
                      <Space direction="vertical">
                        <Radio value={1}>
                          <Rate disabled defaultValue={5} />
                          &nbsp; 4.5 & up
                        </Radio>
                        <Radio value={2}>
                          <Rate disabled defaultValue={4.5} />
                          &nbsp; 4.0 & up
                        </Radio>
                        <Radio value={3}>
                          <Rate disabled defaultValue={4} />
                          &nbsp; 3.5 & up
                        </Radio>
                        <Radio value={4}>
                          <Rate disabled defaultValue={3.5} />
                          &nbsp; 3.0 & up
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </Modal>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            {filteredCourses.map((course) => (
              <div key={course.id}>
                <Card
                  style={{
                    width: "100%",
                    height: "auto",
                    marginTop: "10px",
                    border: "none",
                  }}
                  onClick={() => handleCardClick(course._id)}
                  className="courses-card"
                >
                  <Row>
                    <Col xs={24} sm={7} md={6} lg={5}>
                      <div>
                        <img
                            src={course.image} 
                            alt={course.title}
                          style={{
                            width: "100%",
                            height: "auto",
                            maxHeight: "175px",
                          }}
                          data-aos="flip-left"
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={14} md={13} lg={15}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0",
                          padding: "0 10px",
                        }}
                      >
                        <b style={{ fontSize: "18px", margin: "0" }}>
                          {course.title}
                        </b>
                        <p
                          className="description-clamp"
                          style={{ margin: "0", fontSize: "15px" }}
                        >
                          {course.description}
                        </p>
                        <p style={{ margin: "0px" }}>{course.duration}</p>
                        <p style={{ margin: "0" }}>
                          <b>{course.rating}</b>
                          <Rate
                            disabled
                            allowHalf
                            defaultValue={course.rating}
                            style={{ margin: "0" }}
                            className="course-rating"
                          />
                        </p>
                        {course.bestSeller && (
                          <p style={badgeStyle}>Best Seller</p>
                        )}
                      </div>
                    </Col>
                    <Col xs={24} sm={3} md={5} lg={4}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          paddingTop: "10px",
                        }}
                      >
                        <p style={{ margin: "0" }}>
                          <b>{course.price}</b>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Learning;

import React, { useContext, useEffect } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.css";
import AOS from "aos";
import "aos/dist/aos.css";
import img from "../Images/profile.png";
import { ThemeContext } from "./ThemeContext";
import { GoArrowRight } from "react-icons/go";

const testimonials = [
  {
    name: "John Doe",
    role: "Web Developer",
    feedback:
      "This platform has significantly improved my skills and helped me land my dream job!",
    image: img,
  },
  {
    name: "Jane Smith",
    role: "Graphic Designer",
    feedback:
      "I love the variety of courses offered. The instructors are highly knowledgeable and supportive.",
    image: img,
  },
  {
    name: "Mike Johnson",
    role: "Data Analyst",
    feedback:
      "The hands-on approach to learning was exactly what I needed to advance my career.",
    image: img,
  },
  {
    name: "Emily Davis",
    role: "Marketing Specialist",
    feedback:
      "An excellent platform that offers a wealth of knowledge and opportunities!",
    image: img,
  },
  {
    name: "Chris Lee",
    role: "Software Engineer",
    feedback:
      "The courses are well-structured and very engaging. Highly recommended!",
    image: img,
  },
];

const Testimonials = () => {
  const { theme } = useContext(ThemeContext);

  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  const trainingModes = [
    {
      id: 1,
      title: "Instructor-Led Training",
      image:
        "https://www.mazenet.com/corporate-training/img/instructor-led.png",
    },
    {
      id: 2,
      title: "Virtual Instructor-Led Training",
      image:
        "https://www.mazenet.com/corporate-training/img/virtual-traning.png",
    },
    {
      id: 3,
      title: "Digital Learning Platform",
      image:
        "https://www.mazenet.com/corporate-training/img/digital-training.png",
    },
    {
      id: 4,
      title: "Blended Training",
      image:
        "https://www.mazenet.com/corporate-training/img/blended-training.png",
    },
  ];
  return (
    <div>
      <Container style={{ padding: "20px" }}>
        <Row className="college-startnow-bg">
          <Col lg={12} className="college-startnow-des">
            <div
              style={{
                fontSize: "30px",
                fontWeight: "600",
                fontFamily: "sans-serif",
              }}
            >
              Struggling to prepare students for competitive college placements?
            </div>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "500",
                fontFamily: "sans-serif",

                padding: "25px",
                letterSpacing: "1px",
              }}
            >
              Let us simplify the process for you. With years of experience in
              college placement training, we provide tailored coaching to
              enhance students' skills in interviews, resumes, and aptitude. Our
              expert guidance ensures students are job-ready, with a focus on
              critical skills to meet industry demands.
            </div>{" "}
            <center>
              <Button className="college-btn-sttarnow">
                Get Started Now &nbsp;&nbsp;
                <GoArrowRight
                  className="arrow-up"
                  style={{ fontSize: "20px" }}
                />
                <GoArrowRight
                  className="arrow-down"
                  style={{ fontSize: "20px" }}
                />
              </Button>{" "}
            </center>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className={theme.testimonialContainer}
        style={{ marginTop: "100px" }}
      >
        <h1 className="text-center mb-4" style={{ fontWeight: "500" }}>
          What Our Users Say
        </h1>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
              data-aos-delay={`${index * 100}`}
            >
              <Card className="testimonial-card text-center shadow-sm border-0">
                <Card.Img
                  variant="top"
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="rounded-circle mx-auto"
                  style={{ width: "100px", height: "100px" }}
                />
                <Card.Body>
                  <Card.Text
                    style={{ fontStyle: "italic" }}
                    className="font-weight-light"
                  >
                    "{testimonial.feedback}"
                  </Card.Text>
                  <Card.Title className="font-weight-bold">
                    {testimonial.name}
                  </Card.Title>
                  <Card.Subtitle className="text-muted">
                    {testimonial.role}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Slider>
      </Container>

      <div className="mode-of-training" style={{ marginTop: "100px" }}>
        <div className="header-one">
          <h2 style={{ color: "white" }}>Mode of Studies</h2>
          <div className="underline"></div>
        </div>
        <Row className="training-container">
          {trainingModes.map((mode, index) => (
            <Col lg={12} className="training-box" key={index}>
              <div className="training-content">
                <div className="training-title">{mode.title}</div>
                <Image src={mode.image} alt={mode.title} className="icon-img" />
              </div>
              <div className="training-hover-content">
                {mode.id === 1 ? (
                  <p>
                    Instructor-Led Training (ILT) offers live, expert-guided
                    sessions that provide hands-on learning and real-time
                    interaction with trainers.
                  </p>
                ) : mode.id === 2 ? (
                  <p>
                    Virtual Instructor-Led Training (VILT) delivers live,
                    interactive training sessions online, combining expert
                    guidance with flexible virtual learning.
                  </p>
                ) : mode.id === 3 ? (
                  <p>
                    A Digital Learning Platform offers interactive, on-demand
                    courses and resources, enabling self-paced learning anytime,
                    anywhere.
                  </p>
                ) : (
                  <p>
                    Blended Training combines the best of in-person and online
                    learning, offering a flexible and engaging training
                    experience.
                  </p>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Testimonials;

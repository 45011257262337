// import React from "react";
// import "../Vendor/Package.css";
// import { useNavigate } from "react-router-dom";
// import { Col, Row, Card, Button, Container } from "react-bootstrap";
// import { Badge } from "antd";

// const CoursePackage = () => {
//   const navigate = useNavigate();
//   const packageplans =
//     [
//       {
//         "id": 1,
//         "title": "Classic",
//         "price": 349.92,
//         "oldPrice": 500,
//         "save": 150.08,
//         "features": 10
//       },
//       {
//         "id": 2,
//         "title": "Gold",
//         "price": 749.92,
//         "oldPrice": 1000,
//         "save": 250.08,
//         "features": 20
//       },
//       {
//         "id": 3,
//         "title": "Platinum",
//         "price": 2499.92,
//         "oldPrice": 3000,
//         "save": 500.08,
//         "features": 30
//       },
//       {
//         "id": 4,
//         "title": "Exclusive",
//         "price": 4999,
//         "oldPrice": 7000,
//         "save": 2001,
//         "features": 40
//       }
//     ]

//   return (
//     // <div className="course-package-container">
//     //   <Container>
//     //     <Row className="justify-content-center mt-5">
//     //       {[
//     //         { title: "Classic", price: 349.92, oldPrice: 500, save: 150.08, features: 10 },
//     //         { title: "Gold", price: 749.92, oldPrice: 1000, save: 250.08, features: 20 },
//     //         { title: "Platinum", price: 2499.92, oldPrice: 3000, save: 500.08, features: 30 },
//     //         { title: "Exclusive", price: 4999, oldPrice: 7000, save: 2001, features: 40 }
//     //       ].map((pkg, index) => (
//     //         <Col lg={3} md={6} sm={12} key={index} className="mb-4">
//     //           <Badge.Ribbon text={pkg.save > 100 ? "Best Value" : "Popular"} color="red">
//     //             <Card className="custom-card">
//     //               <Container>
//     //                 <div className="package-content">
//     //                   <center>
//     //                     <h4 className="package-title">{pkg.title}</h4>
//     //                   </center>
//     //                   <h3 className="package-price">
//     //                     ₹{pkg.price.toFixed(2)}{" "}
//     //                     <del className="package-del">₹{pkg.oldPrice}</del>/month
//     //                   </h3>
//     //                   <center>
//     //                     <div className="package-save">You Save ₹{pkg.save}</div>
//     //                   </center>
//     //                   <div className="d-flex justify-content-center">
//     //                     <Button className="upgrade-btn">Upgrade</Button>
//     //                   </div>
//     //                   <ul className="package-list">
//     //                     <li>{pkg.features} Job Posts</li>
//     //                     <li>{pkg.features} Freelancers</li>
//     //                     <li>{pkg.features} Contact Staffing</li>
//     //                     <li>{pkg.features} Vendor Lists</li>
//     //                     <li>{pkg.features} Employee Timesheet</li>
//     //                     <li>{pkg.features} Attendance Tracking</li>
//     //                     <li>{pkg.features} Interview Tracking</li>
//     //                   </ul>
//     //                 </div>
//     //               </Container>
//     //             </Card>
//     //           </Badge.Ribbon>
//     //         </Col>
//     //       ))}
//     //     </Row>
//     //     <div className="d-flex justify-content-center">
//     //       <Button className="back-btn" onClick={() => navigate("/vendordashboard")}>
//     //         Back
//     //       </Button>
//     //     </div>
//     //   </Container>
//     // </div>
//     <div className="Packageoffer">
//       <div className='container' style={{ backgroundColor: 'transparent', width: '90%' }}>
//         <h4>Our Packages</h4>
//         <Row className="mt-4">
//           {packageplans.map((plan) => (
//             <Card className="packageplan_card">
              
//             </Card>
//           ))}
//           <Col></Col>

//         </Row>
//       </div>
//     </div>
//   );
// };

// export default CoursePackage;

import React from "react";
import { Card, Button, Divider } from "antd";
import "./Packageoffers.css";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "react-bootstrap";

const packagePlans = [
  {
    id: 1,
    title: "Classic",
    price: 349.92,
    oldPrice: 500,
    save: 150.08,
    features: 10,
  },
  {
    id: 2,
    title: "Gold",
    price: 749.92,
    oldPrice: 1000,
    save: 250.08,
    features: 20,
  },
  {
    id: 3,
    title: "Platinum",
    price: 2499.92,
    oldPrice: 3000,
    save: 500.08,
    features: 30,
  },
  {
    id: 4,
    title: "Exclusive",
    price: 4999,
    oldPrice: 7000,
    save: 2001,
    features: 40,
  },
];

const PricingCards = () => {
  return (
    <div className="pricing-cards-container">
       <Row>
       {packagePlans.map((pkg, index) => (
        <Col lg={6} md={6} xs={12} className="px-4 py-4">
        <Card
          key={pkg.id}
          className={`pricing-card card-gradient-${index + 1}`}
          title={pkg.title}
        >
          <div className="pricing-details">
            <div className="price">
            ₹{pkg.price.toFixed(2)}
              <span className="old-price"> ₹{pkg.oldPrice}</span>
            </div>
            <div className="save-amount">Save: ₹{pkg.save.toFixed(2)}</div>
          </div>
          <ul className="package-list">
            <li>
              <CheckCircleOutlined className="list-icon" />
              {pkg.features} Job Posts
            </li>
            <li>
              <CheckCircleOutlined className="list-icon" />
              {pkg.features} Freelancers
            </li>
            <li>
              <CheckCircleOutlined className="list-icon" />
              {pkg.features} Contact Staffing
            </li>
            <li>
              <CheckCircleOutlined className="list-icon" />
              {pkg.features} Vendor Lists
            </li>
            <li>
              <CheckCircleOutlined className="list-icon" />
              {pkg.features} Employee Timesheet
            </li>
            <li>
              <CheckCircleOutlined className="list-icon" />
              {pkg.features} Attendance Tracking
            </li>
            <li>
              <CheckCircleOutlined className="list-icon" />
              {pkg.features} Interview Tracking
            </li>
          </ul>
          <div style={{display:'flex', justifyContent:'center'}}>
          <button className="know-more-btn">Know More</button>
          </div>
        </Card>
        </Col>
      ))}
      </Row>
    </div>
  );
};

export default PricingCards;


import React, { useState } from "react";
import { Card, Col, Row, Button, Collapse, Form } from "react-bootstrap";
import Select from "react-select";
import Layout from "../../Components/Layout";
import '../Events/CareerBlitxEvents.scss'

function CareerBlitzEvents() {
    const [opendate, setOpendate] = useState(false);
    const [opencategories, setOpencategories] = useState(false);
    const [eventDate, setEventDate] = useState("");
    const [category, setCategory] = useState(null);

    const categories = [
        { value: "tech", label: "Technology" },
        { value: "science", label: "Science" },
        { value: "art", label: "Art" },
        { value: "education", label: "Education" },
        { value: "sports", label: "Sports" },
        { value: "music", label: "Music" },
        { value: "culture", label: "Culture" },
        { value: "health", label: "Health" },
    ];



    const events = [
        {
            id: 1,
            name: "AI Workshop Chennai",
            date: "2025-01-25",
            address: "Tech Park, Chennai, Tamil Nadu",
            category: "Technology",
        },
        {
            id: 2,
            name: "National Science Fair",
            date: "2025-02-10",
            address: "Anna University, Chennai, Tamil Nadu",
            category: "Science",
        },
        {
            id: 3,
            name: "Art and Craft Exhibition",
            date: "2025-03-05",
            address: "Government Museum, Chennai, Tamil Nadu",
            category: "Art",
        },
        {
            id: 4,
            name: "Tamil Nadu School Olympiad",
            date: "2025-02-15",
            address: "Kumaraguru College, Coimbatore, Tamil Nadu",
            category: "Education",
        },
        {
            id: 5,
            name: "Marathon Chennai 2025",
            date: "2025-04-10",
            address: "Marina Beach, Chennai, Tamil Nadu",
            category: "Sports",
        },
        {
            id: 6,
            name: "Carnatic Music Concert",
            date: "2025-01-30",
            address: "Music Academy, Chennai, Tamil Nadu",
            category: "Music",
        },
    ];

    const filteredEvents = events.filter((event) => {
        const matchesCategory = category ? event.category === category.label : true;
        const matchesDate = eventDate ? event.date === eventDate : true;
        return matchesCategory && matchesDate;
    });
    return (
        <Layout>
            <div>
                <div className="container-fluid bg-light fullscreen">
                    <Row className="mt-4">
                        <Col md={4} lg={3}>
                            <div className="allEventsPage__leftSection">
                                <h2 className="title" style={{ marginTop: "15px" }}>
                                    Advance Search
                                </h2>

                                <div className="card1 mt-4">
                                    <div
                                        className="datecard p-2"
                                        style={{ backgroundColor: "lightgray", borderRadius: 8 }}
                                        onClick={() => setOpendate(!opendate)}
                                    >
                                        <span>Date</span>
                                    </div>
                                    <Collapse in={opendate}>
                                        <div className="mt-3">
                                            <Form.Control
                                                type="date"
                                                value={eventDate}
                                                onChange={(e) => setEventDate(e.target.value)}
                                            />
                                            <Button
                                                variant="link"
                                                className="mt-2"
                                                onClick={() => setEventDate("")}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Collapse>
                                </div>

                                <div className="card3 mt-3">
                                    <div
                                        className="categoriescard p-2"
                                        style={{ backgroundColor: "lightgray", borderRadius: 8 }}
                                        onClick={() => setOpencategories(!opencategories)}
                                    >
                                        <span>Categories</span>
                                    </div>
                                    <Collapse in={opencategories}>
                                        <div className="mt-3">
                                            <Select
                                                placeholder="Select Category"
                                                options={categories}
                                                value={category}
                                                onChange={(selectedOption) => setCategory(selectedOption)}
                                                isSearchable={false}
                                            />
                                            <Button
                                                variant="link"
                                                className="mt-2"
                                                onClick={() => setCategory(null)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Collapse>

                                </div>
                            </div>
                        </Col>

                        <Col md={8} lg={9} className="p-5 mt-3">
                            <h3 className="fw-bold">Events List</h3>
                            <Row>
                                {filteredEvents.length ? (
                                    filteredEvents.map((event) => (
                                        <Col xs={12} sm={6} md={4} key={event.id} className="px-3 py-3">
                                            <Card className="mb-4 eventCardss">
                                                <Card.Body>
                                                    <Card.Title className="eventTitle">{event.name}</Card.Title>
                                                    <Card.Text className="eventTextcard">{event.date}</Card.Text>
                                                    <Card.Text className="eventTextcard">{event.address}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                                ) : (
                                    <p>No events found</p>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </Layout>
    )
}

export default CareerBlitzEvents
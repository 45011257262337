import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Button, message, Input } from "antd";
import "../../../Components/Css/Professional/professionalstyle.scss";
import Api from "../../../Api";

function SemesterScores() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [userData, setUserData] = useState([]);
  const [semesterPercentages, setSemesterPercentages] = useState([
    { semesterPercentage: "" },
  ]);
  const userId = localStorage.getItem("userId");
  console.log("userData", userData);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const res = await Api.get(`employeeUser/employee_getone/${userId}`);
        setUserData(res.data.data.SemesterScores || []);
        const semesterData = res.data.data.SemesterScores.semesterPercentages;

        setSemesterPercentages(
          semesterData.map((score) => ({
            semesterPercentage: score.semesterPercentage,
          }))
        );
      } catch (err) {
        console.error("Fetch Error:", err);
      }
    };
    getDetails();
  }, [userId]);

  const handleFormSubmit = async (data) => {
    const details = {
      SemesterScores: {
        ...data.SemesterScores,
        CGPA: data.CGPA,
        semesterPercentages: data.semesterPercentages,
      },
    };
    console.log("Submitting Details:", details);
    try {
      const resp = await Api.put(`employee/Employee_update/${userId}`, details);
      message.success(resp.data.message || "Scores updated successfully!");
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to update score.");
      console.error("API Error:", error);
    }
  };
  useEffect(() => {
    const getDetails = async () => {
      try {
        const res = await Api.get(`employeeUser/employee_getone/${userId}`);
        const semesterData =
          res.data.data.SemesterScores?.semesterPercentages || [];
        setSemesterPercentages(
          semesterData.map((score) => ({
            semesterPercentage: score.semesterPercentage,
          }))
        );
        setValue("semesterPercentages", semesterData);
        setValue("CGPA", res.data.data.SemesterScores?.CGPA || "");
      } catch (err) {
        console.error("Fetch Error:", err);
      }
    };
    getDetails();
  }, [userId, setValue]);
  const handleAddSemester = () => {
    setSemesterPercentages([
      ...semesterPercentages,
      { semesterPercentage: "" },
    ]);
  };

  const handleRemove = (index) => {
    const updatedSemesters = semesterPercentages.filter((_, i) => i !== index);
    setSemesterPercentages(updatedSemesters);

    setValue(
      "semesterPercentages",
      updatedSemesters.map((item, idx) => ({
        semesterPercentage: item.semesterPercentage,
      }))
    );
  };

  return (
    <div className="personal-informations p-4">
      <div className="pages-title mb-3">Semester Scores</div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
          {semesterPercentages.map((item, index) => (
            <Col lg={12} md={12} xs={24} gutter={16}>
              <div key={index} className="semester">
                <label>Semester {index + 1} (in Percentage):</label>
                <input
                  type="number"
                  className="input-field"
                  style={{ width: "auto", marginBottom: "10px" }}
                  placeholder="Enter percentage"
                  defaultValue={item.semesterPercentage}
                  {...register(
                    `semesterPercentages.${index}.semesterPercentage`,
                    {
                      required: "Semester percentage is required",
                      min: {
                        value: 0,
                        message: "Percentage cannot be negative",
                      },
                      max: {
                        value: 100,
                        message: "Percentage cannot exceed 100",
                      },
                    }
                  )}
                />
                {errors.semesterPercentages?.[index]?.semesterPercentage && (
                  <p className="error-message">
                    {
                      errors.semesterPercentages[index].semesterPercentage
                        .message
                    }
                  </p>
                )}
                {index > 0 && (
                  <Button
                    type="danger"
                    style={{ margin: "5px" }}
                    onClick={() => handleRemove(index)}
                    className="remove-button"
                  >
                    Remove
                  </Button>
                )}
              </div>
            </Col>
          ))}
        </Row>
        <Button
          type="primary"
          onClick={handleAddSemester}
          style={{
            display: "inline",
            textAlign: "center",
            justifyContent: "center",
          }}
          className="add-button mt-3"
        >
          Add Semester
        </Button>
        <div className="input-subtitle mt-4">Overall CGPA:</div>
        <input
          type="number"
          className="input-field"
          style={{ width: "200px" }}
          {...register("CGPA", { required: "CGPA is required" })}
        />
        {errors.CGPA && <p className="error-message">{errors.CGPA.message}</p>}
        <div className="submitbuttons p-2">
          <button className="button1 m-2 p-2" type="submit">
            Submit
          </button>
          <button className="button2 m-2 p-2" type="reset">
            Reset
          </button>
        </div>
      </form>
    </div>
  );
}

export default SemesterScores;

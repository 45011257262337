import React, { useEffect, useState } from "react";
import { message, Spin, Card, Row, Col } from "antd";
import Api from "../../Api";
import { useNavigate } from "react-router-dom";
import { Header } from "antd/lib/layout/layout";
import HeaderNavbar from "../../Components/HeaderNavbar";
import Footer from "../../Components/Footer";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await Api.get("/adminblog/getallblogs");
        console.log("Response:", response);

        if (Array.isArray(response.data)) {
          setBlogs(response.data);
        } else {
          message.error(
            "Failed to fetch blogs. Data is not in expected format."
          );
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
        message.error("Error fetching blogs.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <Spin size="large" tip="Loading blogs..." />
      </div>
    );
  }

  return (
    <div>
      <HeaderNavbar />
      <div className="container">
        <h1>Blogs</h1>
        <div className="blog-cards-container">
          <Row gutter={[24, 24]}>
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <Col
                  key={blog._id}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  onClick={() => navigate(`/blogs/${blog._id}`)}
                >
                  <Card
                    hoverable
                    className="blog-card"
                    cover={
                      <img
                        alt={blog.title}
                        src={blog.blogImage}
                        className="blog-card-image"
                      />
                    }
                  >
                    <Card.Meta
                      title={blog.title}
                      description={
                        <>
                          <p>{blog.author}</p>
                          <p>{blog.description}</p>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))
            ) : (
              <p>No blogs found</p>
            )}
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;

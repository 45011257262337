import React, { useState } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
// import "./kycComplaince.scss";
import "../../Pages/Employer/OurProfile/KycComplaince/kycComplaince.css";
import Api from "../../Api";

function Kycvendor() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const [PanCard, setPanCard] = useState({});
  const [AddressProof, setAddressproof] = useState({});
  const userId = localStorage.getItem("userId");

  const handleFormSubmit = async () => {
    const Details = {
      pancardNumber: getValues().pancardNumber,
      aadharcardNumber: getValues().aadharcardNumber,
      gstNumber: getValues().gstNumber,
      addressProof: getValues().addressProof,
      // AddressProof:getValues().AddressProof,
    };
    console.log("first", Details);
     const data = new FormData();
     data.append("AddressProof", getValues().AddressProof[0]);
    //  data.append("AddressProof", getValues().addressProof[0]);
     await Api.put(`/vendor/addressproofImage/${userId}`, data).then(async (res) => {
      if (res.status == 201) {
         Details.AddressProof = res.data.path;
  //  Details.addressProof = res.data.path;
    await Api.put(`/vendor/vendorput/${userId}`, Details).then((resp) => {
      //  localStorage.setItem("personalId", resp.data.data._id);
    });
     }
     });
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ backgroundColor: "transparent", paddingLeft: "10px" }}>
        <h4 className="pages-title mt-3">KYC Compliance</h4>
        <br />
        <p style={{ backgroundColor: "#fccc55", width: "40%" }}>
          Your Company details are required to meet KYC Compliance
        </p>
        <br />
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Row className="gx-3 gy-3">
            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">PanCard Number:</label>
              <input
                {...register("pancardNumber", { required: true })}
                className="Professional__UpdateProfile__Input"
              />
              {errors.pancardNumber && (
                <p className="text-danger">PanCard number is required</p>
              )}
            </Col>
            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">AadharCard Number:</label>
              <input
                {...register("aadharcardNumber", { required: true })}
                className="Professional__UpdateProfile__Input"
              />
              {errors.aadharcardNumber && (
                <p className="text-danger">AadharCard number is required</p>
              )}
            </Col>
            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">GST Number:</label>
              <input
                {...register("gstNumber", { required: true })}
                className="Professional__UpdateProfile__Input"
              />
              {errors.gstNumber && (
                <p className="text-danger">GST number is required</p>
              )}
            </Col>
          </Row>
          <Row className="gx-3 gy-3">
            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">Pan or Aadhaar Upload:</label>
              <input
                type="file"
                {...register("AddressProof", { required: true })}
                className="Professional__UpdateProfile__Input"
                onChange={(e) => setPanCard(e.target.files[0])}
              />
              {errors.AddressProof && (
                <p className="text-danger">Document upload is required</p>
              )}
              <p>Only PDF, JPEG, PNG formats up to 5MB are allowed</p>
            </Col>
            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">Address Proof:</label>
              <input
                {...register("addressProof", { required: true })}
                className="Professional__UpdateProfile__Input"
              />
              {errors.addressProof && (
                <p className="text-danger">Address proof is required</p>
              )}
            </Col>
          </Row>
          <div className="submitbuttons px-4 mt-4">
            <Button className="button1 m-2 p-2" type="submit">
              Submit
            </Button>
            <Button className="button2 m-2 p-2" type="reset">
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Kycvendor;

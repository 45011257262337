import React, { useState, useEffect } from "react";
import { Table, Button, message } from "antd";
import { useLocation } from "react-router-dom";
import Api from "../../../../../Api.js";

const ViewAppliedStudents = () => {
  const [batchDetails, setBatchDetails] = useState(null);
  const [appliedStudents, setAppliedStudents] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const { batchId, courseId } = location.state || {};  

  useEffect(() => {
    if (!batchId) {
      return; 
    }

    const fetchBatchDetails = async () => {
      try {
        const response = await Api.get(`/course/getbatch/${batchId}`);
        setBatchDetails(response.data);
        const students = await Promise.all(
          response.data.appliedStudents.map(async (student) => {
            const studentDetails = await Api.get(`/signup/student_getone/${student.studentId}`);
            return { ...student, studentDetails: studentDetails.data };
          })
        );
        setAppliedStudents(students);
      } catch (error) {
        console.error("Error fetching batch details or student details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBatchDetails();
  }, [batchId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!batchDetails) {
    return <div>No batch found.</div>;
  }

  const handleStatusChange = async (studentId, status) => {
    try {
      const response = await Api.put('course/updatestatus', {
        userId: studentId,
        courseId: courseId,
        batchId: batchId,
        status: status,
      });
      message.success(`Student status updated to ${status}`);
      setAppliedStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.studentId === studentId
            ? { ...student, status: status }
            : student
        )
      );
    } catch (error) {
      message.error("Failed to update student status");
      console.error(error);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Student ID",
      dataIndex: "studentId",
      key: "studentId",
    },
    {
      title: "Student Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const { firstName, lastName } = record.studentDetails || {};
        return firstName && lastName ? `${firstName} ${lastName}` : "N/A";
      },
    },
    {
      title: "Applied Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Applied Date",
      dataIndex: "appliedDate",
      key: "appliedDate",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <Button
            onClick={() => handleStatusChange(record.studentId, "Approved")}
            type="primary"
            style={{ marginRight: 8 }}
         
          >
            Approve
          </Button>
          <Button
            onClick={() => handleStatusChange(record.studentId, "Rejected")}
            type="danger"
     
          >
            Reject
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="data_list_main p-3">
      <h5 className="mt-4">Applied Students</h5>  
      <div className="Datalist-Table mt-2">
        <Table
          columns={columns}
          dataSource={appliedStudents.map((student, index) => ({
            ...student,
            key: student.studentId,
            index,
          }))}
          pagination={{
            pageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20'],
          }}
          className="students-table"
        />
      </div>
    </div>
  );
};

export default ViewAppliedStudents;

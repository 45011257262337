import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { DatePicker, TimePicker, Checkbox, Radio, Input, message } from "antd";
import "./Training.css";
import Api from "../../../Api"; // Ensure correct API utility path
import moment from 'moment';

const { TextArea } = Input;

const Training = () => {
  const userId = localStorage.getItem("userId");
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      courseType: "",
      courseTitle: "",
      description: "",
      durationType: "weekly",
      months: "",
      weeks: "",
      days: [],
      price: "",
      mode: "",
    },
  });
  const [durationType, setDurationType] = useState("weekly");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await Api.get(`employeeUser/employee_getone/${userId}`);
        const user = res.data.data.Training;
  console.log('res.data', res.data)
        // Prefill the form fields
        if (user) {
          setValue("courseType", user.courseType || "");
          setValue("courseTitle", user.courseTitle || "");
          setValue("description", user.description || "");
          setValue("durationType", user.durationType || "weekly");
          setValue("weeks", user.weeks || "");
          setValue("months", user.months || "");
          setValue("days", user.days?.map((day) => day.day) || []);
          user.days?.forEach((day) => {
            setValue(`startTime_${day.day}`, day.startTime ? moment(day.startTime, "HH:mm") : null);
            setValue(`endTime_${day.day}`, day.endTime ? moment(day.endTime, "HH:mm") : null);
          });
          setValue("startDateMonthly", user.startDateMonthly ? moment(user.startDateMonthly, "YYYY-MM-DD") : null);
          setValue("endDateMonthly", user.endDateMonthly ? moment(user.endDateMonthly, "YYYY-MM-DD") : null);
          setValue("price", user.price || "");
          setValue("mode", user.mode || "");
        }
      } catch (err) {
        message.error("Failed to fetch user data");
      }
    };
  
    fetchUserData();
  }, [userId, setValue]);
  

  const onSubmit = async (data) => {
    console.log("data", data);
    try {
      // Log the incoming data for debugging
      console.log("Form data before processing:", data);

      // Prepare the formatted data based on the duration type
      const formattedData = {
        Training : 
        data.durationType === "weekly"
          ? {
              courseType: data.courseType,
              courseTitle: data.courseTitle,
              description: data.description,
              durationType: durationType,
              weeks: data.weeks,
              days: data.days?.map((day) => ({
                day,
                startTime: data[`startTime_${day}`]?.format("HH:mm") || null,
                endTime: data[`endTime_${day}`]?.format("HH:mm") || null,
              })),
              price: data.price,
              mode: data.mode,
            }
          : {
              courseType: data.courseType,
              courseTitle: data.courseTitle,
              description: data.description,
              durationType: durationType,
              months: data.months,
              startDate: data.startDateMonthly?.format("YYYY-MM-DD") || null,
              endDate: data.endDateMonthly?.format("YYYY-MM-DD") || null,
              price: data.price,
              mode: data.mode,
            }
          }
      // Debugging log for formatted data
      console.log("Submitting data:", formattedData);

      // API call
      await Api.put(`employee/Employee_update/${userId}`, formattedData);

      // Show success message
      message.success("Employee Details Updated Successfully.");
    } catch (err) {
      // Log and display error
      console.error("Update Error:", err.response?.data || err.message || err);
      message.error("Failed to Update Employee Details");
    }
  };

  const selectedDays = watch("days") || [];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="training-form">
      <div className="training-grid">
        {/* Course Type */}
        <div className="training-form-item">
          <label>Course Type</label>
          <Controller
            control={control}
            name="courseType"
            rules={{ required: "Course type is required" }}
            render={({ field }) => (
              <Radio.Group
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value); // Update react-hook-form state
                  setValue("courseType", e.target.value); // Ensure synchronization
                }}
                value={field.value}
              >
                <Radio value="seminar">Seminar</Radio>
                <Radio value="course">Course</Radio>
              </Radio.Group>
            )}
          />
          {errors.courseType && (
            <p className="training-error">{errors.courseType.message}</p>
          )}
        </div>
        {/* Course Title */}
        <div className="training-form-item">
          <label>Course Title</label>
          <input {...register("courseTitle", { required: true })} />
          {errors.courseTitle && (
            <p className="training-error">This field is required</p>
          )}
        </div>

        {/* Description */}
        <div className="training-form-item">
          <label>Description</label>
          <input {...register("description", { required: true })} />
          {errors.description && (
            <p className="training-error">This field is required</p>
          )}
        </div>

        {/* Duration Type */}
        <div className="training-form-item">
          <label>Duration</label>
          <Radio.Group
            {...register("durationType", { required: false })}
            onChange={(e) => setDurationType(e.target.value)}
            value={durationType}
          >
            <Radio value="weekly">Weekly</Radio>
            <Radio value="monthly">Monthly</Radio>
          </Radio.Group>
        </div>

        {/* Weekly Fields */}
        {durationType === "weekly" && (
          <><div className="training-form-item">
            <label>How Many Weeks?</label>
            <input {...register("weeks", { required: true })} />
          {errors.weeks && (
            <p className="training-error">This field is required</p>
          )}
          </div>
            <div className="training-form-item">
              <label>Days</label>
              <Controller
                control={control}
                name="days"
                render={({ field }) => (
                  <Checkbox.Group {...field}>
                    <Checkbox value="Monday">Monday</Checkbox>
                    <Checkbox value="Tuesday">Tuesday</Checkbox>
                    <Checkbox value="Wednesday">Wednesday</Checkbox>
                    <Checkbox value="Thursday">Thursday</Checkbox>
                    <Checkbox value="Friday">Friday</Checkbox>
                    <Checkbox value="Saturday">Saturday</Checkbox>
                    <Checkbox value="Sunday">Sunday</Checkbox>
                  </Checkbox.Group>
                )}
                rules={{ required: true }}
              />
              {errors.days && (
                <p className="training-error">This field is required</p>
              )}
            </div>

            {selectedDays.map((day) => (
              <div key={day} className="training-form-item">
                <label>{day} Time</label>
                <div className="training-time-group">
                  <Controller
                    control={control}
                    name={`startTime_${day}`}
                    render={({ field }) => <TimePicker {...field} />}
                    rules={{ required: true }}
                  />
                  {errors[`startTime_${day}`] && (
                    <p className="training-error">Start time is required</p>
                  )}
                  <Controller
                    control={control}
                    name={`endTime_${day}`}
                    render={({ field }) => <TimePicker {...field} />}
                    rules={{ required: true }}
                  />
                  {errors[`endTime_${day}`] && (
                    <p className="training-error">End time is required</p>
                  )}
                </div>
              </div>
            ))}
          </>
        )}

        {/* Monthly Fields */}
        {durationType === "monthly" && (
          <>
          <div className="training-form-item">
            <label>How Many Months?</label>
            <input {...register("months", { required: true })} />
          {errors.months && (
            <p className="training-error">This field is required</p>
          )}
          </div>
            <div className="training-form-item">
              <label>Start Date</label>
              <Controller
                control={control}
                name="startDateMonthly"
                rules={{
                  required: "Start date is required for monthly duration",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    onChange={(date) => field.onChange(date)}
                  />
                )}
              />
              {errors.startDateMonthly && (
                <p className="training-error">
                  {errors.startDateMonthly.message}
                </p>
              )}
            </div>

            <div className="training-form-item">
              <label>End Date</label>
              <Controller
                control={control}
                name="endDateMonthly"
                rules={{
                  required: "End date is required for monthly duration",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    onChange={(date) => field.onChange(date)}
                  />
                )}
              />
              {errors.endDateMonthly && (
                <p className="training-error">
                  {errors.endDateMonthly.message}
                </p>
              )}
            </div>
          </>
        )}

        {/* Price */}
        <div className="training-form-item">
          <label>Price</label>
          <input {...register("price", { required: true })} />
          {errors.price && (
            <p className="training-error">This field is required</p>
          )}
        </div>

        {/* Mode */}
        <div className="training-form-item">
          <label>Mode</label>
          <Controller
            control={control}
            name="mode"
            rules={{ required: "Mode is required" }}
            render={({ field }) => (
              <Radio.Group
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value); // Update react-hook-form state
                  setValue("mode", e.target.value); // Ensure synchronization
                }}
                value={field.value}
              >
                <Radio value="online">Online</Radio>
                <Radio value="offline">Offline</Radio>
              </Radio.Group>
            )}
          />
          {errors.mode && (
            <p className="training-error">{errors.mode.message}</p>
          )}
        </div>
      </div>
      {/* <button type="submit" className="training-submit-button">
        Submit
      </button> */}
       <div className="submitbuttons p-2">
          <button className="button1 m-2 p-2" type="submit">
            Submit
          </button>
          <button className="button2 m-2 p-2" type="reset">
            Reset
          </button>
        </div>
    </form>
  );
};

export default Training;

import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { GoLocation } from "react-icons/go";
import { RiSuitcaseLine } from "react-icons/ri";
import { CgNotes } from "react-icons/cg";
import { CiSaveDown2 } from "react-icons/ci";
import "../../Professional/ProfessionalDashboard/Jobs/Jobs.scss";
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import { MdOutlineDescription } from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";

function InternshipList() {
  const [createForm, setcreateForm] = useState([]);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState(null);
  const [location, setlocation] = useState(null);
  const [experience, setexperience] = useState(null);
  const [salary, setsalary] = useState(null);

  const getCreateForm = async () => {
    await Api.get("Internship/getInternship").then((res) => {
      setcreateForm(res.data.data);
    });
  };
  const navigate = useNavigate();

  const onSubmit = (data) => {
    navigate("/professional/InternshipPage", { state: data });
  };

  useEffect(() => {
    getCreateForm();
    getUsers(createForm);
  }, []);

  const getUsers = (createForm) => {
    setUsers(createForm);
  };
  const bylocation = (createForm, location) => {
    if (location) {
      return createForm.location === location;
    } else return createForm;
  };
  const byexperience = (createForm, experience) => {
    if (experience) {
      return createForm.experience === experience;
    } else return createForm;
  };
  const bysalary = (createForm, salary) => {
    if (salary) {
      return createForm.salaryRange === salary;
    } else return createForm;
  };
  const bySearch = (createForm, search) => {
    if (search) {
      return createForm.jobTitle.toLowerCase().includes(search.toLowerCase());
    } else return createForm;
  };
  const filteredList = (users, location, experience, search) => {
    return users
      .filter((createForm) => bylocation(createForm, location))
      .filter((createForm) => bySearch(createForm, search))
      .filter((createForm) => byexperience(createForm, experience))
      .filter((createForm) => bysalary(createForm, salary));
  };

  const resetFilters = () => {
    setSearch("");
    setlocation("");
    setexperience("");
    setsalary("");
  };


  return (
    <div className=" mt-5">
      <p className="ThirdCardPara">Internship</p>
      <h2 style={{ textAlign: "center", marginBottom: "1" ,fontSize:"2rem",fontWeight:"bold"}}>
        Choose your Internship profile
      </h2>
      {/* <div className="jobsearchbox mt-5">
          <input
            type="text"
            className="job-search1"
            placeholder="Search your job here"
          />

          <input type="text" className="job-search2" placeholder="location" />

          <button className="button1 jobsearchbutton">
            <span>Search</span>
          </button>
        </div> */}
      <Row>
        <Col md={4} lg={4}>
          <div className="DescriptionBoxFilter mt-0 "style={{position:'sticky', top:'120px'}}>
            <div className="pages-title mb-3 mt-2 ms-4 ">Filter</div>
            <div className="ms-4 mb-2">
              <label className="mt-4">Jobtitle</label>
              <input
                className="filter_input"
                type="search"
                placeholder="search..."
                onChange={(e) => setSearch(e.target.value)}
              />
              <br />

              <label className="mt-4">Location</label>
              <select
                className="filter_input"
                onChange={(e) => setlocation(e.target.value)}
              >
                {/* <option value={createForm.location}>
                  {createForm.location}
                </option> */}
                <option value="">Choose Location</option>
                <option value="Chennai">Chennai</option>
                <option value="Madurai">Madurai</option>
                <option value="Bangalore">Bangalore</option>
                <option value="coimbatore">coimbatore</option>
                <option value="other">Other</option>
              </select>

              <br />

              {/* <label className="mt-4">Experience</label>
                <br />
                <select
                  className="filter_input"
                  onChange={(e) => setexperience(e.target.value)}
                >
                  <option value="">Choose Experience</option>
                  <option value="Fresher">Fresher</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select> */}

              <label className="mt-4">Salary</label>
              <select
                className="filter_input"
                onChange={(e) => setsalary(e.target.value)}
              >
                <option value="">Choose Salary</option>
                <option value="20000">20000</option>
                <option value="33">20000-30000</option>
                <option value="2 ">2</option>
              </select>
              <br />
              <label className="mt-4"> Experience </label>
              <select
                className="filter_input"
                onChange={(e) => setexperience(e.target.value)}
              >
                <option value="">Choose Experience</option>
                <option value="0-1 Years"> 0-1 Years</option>
                <option value="2-3 Years"> 2-3 Years</option>
                <option value="3-4 Years"> 3-4 Years</option>
                <option value="5 Years above"> 5 Years above</option>
                
                
              </select>
              <button
                className="button1 mt-4"
                onClick={resetFilters}
                style={{
                  backgroundColor: "#e52727",
                  color: "#fff",
                  padding: "10px 20px",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                Reset
              </button>
              {/* <Slider
                min={0}
                max={30}
                // onChange={(e) => setexperience(e.target.value)}
              /> */}
            </div>
          </div>
        </Col>
        <Col md={7} lg={7}>
          {/* .........................................Job Card.................................................. */}
          <div>
            {filteredList(createForm, location, experience, search).map(
              (createForm) => (
               <Card
  className="jobscard mt-0 mb-5"
  onClick={() => onSubmit(createForm)}
  style={{
    background: "#f5f5f5",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: "20px",
    color: "#333",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    width: "70%",
  }}
  // onMouseEnter={(e) => {
  //   e.currentTarget.style.transform = "scale(1.05)";
  //   e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
  // }}
  // onMouseLeave={(e) => {
  //   e.currentTarget.style.transform = "scale(1)";
  //   e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
  // }}
>
  <h2 style={{ fontSize: "2.5rem", color: "#003f5c", marginBottom: "0.5rem" }}>
    {createForm.jobTitle}
  </h2>
  <h5 className="mb-1" style={{ color: "#007bff" }}>
    {createForm.compName}
  </h5>

  <div
    className="mt-1"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      margin: "10px 0",
    }}
  >
    <GoLocation style={{ color: "#FF6F61", fontSize: "1.5rem" }} />
    <h1 style={{ fontSize: "1.5rem", margin: 0 }}>{createForm.location}</h1>
  </div>

  <div
    className="mt-1"
    style={{ display: "flex", flexDirection: "column", gap: "10px" }}
  >
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <RiSuitcaseLine style={{ color: "#17a2b8", fontSize: "1.5rem" }} />
      <span>{createForm.experience} years</span>
    </div>
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <CgNotes style={{ color: "#ffc107", fontSize: "1.5rem" }} />
      <span>{createForm.skill}</span>
    </div>
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <MdOutlineDescription style={{ color: "#28a745", fontSize: "1.5rem" }} />
      <span>{createForm.jobDescription}</span>
    </div>
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <FaRegMoneyBillAlt style={{ color: "#e52727", fontSize: "1.5rem" }} />
      <span>{createForm.salaryRange}</span>
    </div>
  </div>

  <div
    className="save-icon"
    style={{
      textAlign: "right",
      width: "100%",
      marginTop: "20px",
    }}
  >
    <CiSaveDown2
      style={{ color: "#0056b3", fontSize: "2rem", cursor: "pointer" }}
    />
  </div>
</Card>

              )
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default InternshipList;

import React, { useState } from "react";
import Api from "../../../Api.js"; // Adjust the path as per your project structure
import { Form, Button, Container } from "react-bootstrap";

const BlogForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    author: "",
    tags: "",
    content: [
      {
        heading: "",
        subheading: "",
        text: "",
        images: "",
      },
    ],
    status: "draft",
    description: "",
    keywords: "",
    blogImage: "", // Added blogImage field
  });

  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleContentChange = (index, e) => {
    const { name, value } = e.target;
    const updatedContent = [...formData.content];
    updatedContent[index][name] = value;
    setFormData({ ...formData, content: updatedContent });
  };

  const addContentSection = () => {
    setFormData({
      ...formData,
      content: [
        ...formData.content,
        { heading: "", subheading: "", text: "", images: "" },
      ],
    });
  };

  const deleteContentSection = (index) => {
    const updatedContent = formData.content.filter((_, i) => i !== index);
    setFormData({ ...formData, content: updatedContent });
  };

  const handleImageUpload = async (file, index) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "darshan");

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
        {
          method: "POST",
          body: data,
        }
      );

      const cloudinaryData = await response.json();
      if (cloudinaryData.secure_url) {
        const updatedContent = [...formData.content];
        updatedContent[index].images = cloudinaryData.secure_url;
        setFormData({ ...formData, content: updatedContent });
      } else {
        throw new Error("Cloudinary upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setMessage("Failed to upload image.");
    }
  };

  const handleProfilePhotoUpload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "darshan");

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
        {
          method: "POST",
          body: data,
        }
      );

      const cloudinaryData = await response.json();
      if (cloudinaryData.secure_url) {
        setFormData({ ...formData, blogImage: cloudinaryData.secure_url }); // Update blogImage field in formData
      } else {
        throw new Error("Cloudinary upload failed");
      }
    } catch (error) {
      console.error("Error uploading blog image:", error);
      setMessage("Failed to upload blog image.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Api.post("adminblog/createblog", {
        ...formData,
        tags: formData.tags.split(","),
        keywords: formData.keywords.split(","), 
      });

      setMessage("Blog posted successfully!");
      setFormData({
        title: "",
        author: "",
        tags: "",
        content: [
          {
            heading: "",
            subheading: "",
            text: "",
            images: "",
          },
        ],
        status: "draft",
        description: "",
        keywords: "",
        blogImage: "",
      });
    } catch (error) {
      console.error("Error posting blog:", error);
      setMessage("Failed to post blog.");
    }
  };

  return (
    <Container style={{ maxWidth: "800px", marginTop: "50px" }}>
      <h2>Create Blog</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="author">
          <Form.Label>Author</Form.Label>
          <Form.Control
            type="text"
            name="author"
            value={formData.author}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="tags">
          <Form.Label>Tags (comma-separated)</Form.Label>
          <Form.Control
            type="text"
            name="tags"
            value={formData.tags}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="status">
          <Form.Label>Status</Form.Label>
          <Form.Control
            as="select"
            name="status"
            value={formData.status}
            onChange={handleInputChange}
          >
            <option value="draft">Draft</option>
            <option value="published">Published</option>
            <option value="archived">Archived</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="keywords">
          <Form.Label>Keywords (comma-separated)</Form.Label>
          <Form.Control
            type="text"
            name="keywords"
            value={formData.keywords}
            onChange={handleInputChange}
          />
        </Form.Group>

        <div>
          <h3>Content Sections</h3>
          {formData.content.map((section, index) => (
            <div key={index}>
              <Form.Group controlId={`heading-${index}`}>
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  name="heading"
                  value={section.heading}
                  onChange={(e) => handleContentChange(index, e)}
                />
              </Form.Group>

              <Form.Group controlId={`subheading-${index}`}>
                <Form.Label>Subheading</Form.Label>
                <Form.Control
                  type="text"
                  name="subheading"
                  value={section.subheading}
                  onChange={(e) => handleContentChange(index, e)}
                />
              </Form.Group>

              <Form.Group controlId={`text-${index}`}>
                <Form.Label>Text</Form.Label>
                <Form.Control
                  as="textarea"
                  name="text"
                  value={section.text}
                  onChange={(e) => handleContentChange(index, e)}
                />
              </Form.Group>

              <Form.Group controlId={`images-${index}`}>
                <Form.Label>Images</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => handleImageUpload(e.target.files[0], index)}
                />
                {section.images && (
                  <img
                    src={section.images}
                    alt="Section Image Preview"
                    style={{ width: "100px", marginTop: "10px" }}
                  />
                )}
              </Form.Group>

              <Button
                variant="danger"
                type="button"
                onClick={() => deleteContentSection(index)}
              >
                Delete Section
              </Button>
            </div>
          ))}
          <Button variant="primary" type="button" onClick={addContentSection}>
            Add Section
          </Button>
        </div>

        <Form.Group controlId="blogImage">
          <Form.Label>Blog Image</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => handleProfilePhotoUpload(e.target.files[0])}
          />
          {formData.blogImage && (
            <img
              src={formData.blogImage}
              alt="Blog Image Preview"
              style={{ width: "100px", marginTop: "10px" }}
            />
          )}
        </Form.Group>

        <Button variant="success" type="submit">
          Submit Blog
        </Button>
      </Form>

      {message && <p>{message}</p>}
    </Container>
  );
};

export default BlogForm;

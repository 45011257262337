import React, { useState, useEffect } from "react";
import Api from "../../../../../Api.js";
import { notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const DisplayEdit = () => {
  const [formData, setFormData] = useState({
    name: "",
    imageUrl: "",
  });
  const [loading, setLoading] = useState(false);
  const { adId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (adId) {
      fetchAdDetails(adId);
    }
  }, [adId]);

  const fetchAdDetails = async (id) => {
    try {
      setLoading(true);
      const response = await Api.get(`/displayadmin/getonead/${id}`);
      if (response.data.success) {
        const ad = response.data.data;
        setFormData({
          name: ad.name,
          imageUrl: ad.imageUrl,
        });
      } else {
        notification.error({
          message: "Error",
          description: "Failed to fetch ad details.",
        });
      }
    } catch (error) {
      console.error("Error fetching ad details:", error);
      notification.error({
        message: "Error",
        description: "Failed to fetch ad details.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "darshan");

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dzblzw7ll/image/upload",
        {
          method: "POST",
          body: data,
        }
      );

      const cloudinaryData = await response.json();
      if (cloudinaryData.secure_url) {
        setFormData((prevData) => ({
          ...prevData,
          imageUrl: cloudinaryData.secure_url,
        }));
        return cloudinaryData.secure_url;
      } else {
        throw new Error("Cloudinary upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      notification.error({
        message: "Error",
        description: "Failed to upload image. Please try again.",
      });
      return null;
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const uploadedImageUrl = await handleImageUpload(file);
      if (!uploadedImageUrl) {
        notification.error({
          message: "Error",
          description: "Failed to upload image. Please try again.",
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.imageUrl) {
      notification.error({
        message: "Validation Error",
        description: "All fields are required. Please fill them out.",
      });
      return;
    }

    try {
      setLoading(true);
      if (adId) {
        await Api.put(`/displayadmin/updateads/${adId}`, formData, {
          headers: { "Content-Type": "application/json" },
        });
        notification.success({
          message: "Success",
          description: "Display card updated successfully.",
        });
      } else {
        await Api.post("/displayadmin/postads", formData, {
          headers: { "Content-Type": "application/json" },
        });
        notification.success({
          message: "Success",
          description: "Display card uploaded successfully.",
        });
      }
      navigate("/admin/displayads");
    } catch (error) {
      console.error("Error submitting ad:", error);
      notification.error({
        message: "Error",
        description:
          (error.response && error.response.data.message) ||
          "Failed to submit display card.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "500px", margin: "auto", marginTop: "50px" }}>
      <h2 style={{ fontFamily: "poppins", fontSize: "32px" }}>
        {adId ? "Edit Display Card" : "Upload Display Card"}
      </h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Image:</label>
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={handleImageChange}
            required={!formData.imageUrl}
          />
        </div>
        {formData.imageUrl && (
          <div>
            <p>Uploaded Image Preview:</p>
            <img
              src={formData.imageUrl}
              alt="Uploaded Preview"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}
        <button
          style={{
            color: "#fff",
            backgroundColor: "#4CAF50",
            border: "none",
            borderRadius: "8px",
            padding: "10px 20px",
            fontSize: "16px",
            fontWeight: "bold",
            cursor: "pointer",
            transition: "all 0.3s ease",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          className="mt-3"
          type="submit"
          disabled={loading}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#45a049";
            e.target.style.transform = "scale(1.05)";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#4CAF50";
            e.target.style.transform = "scale(1)";
          }}
          onFocus={(e) => {
            e.target.style.outline = "3px solid #82c91e";
          }}
          onBlur={(e) => {
            e.target.style.outline = "none";
          }}
        >
          {adId ? "Update Display Card" : "Upload Display Card"}
        </button>
      </form>
    </div>
  );
};

export default DisplayEdit;

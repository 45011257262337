import React, { useState, useEffect, useRef, useContext } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { Carousel, Rate } from "antd";
import "../../Components/Css/Homepage.scss";
import recruit from "../../Images/announcement-bg.png";
import hello from "../../Images/hello.png";
import img from "../../Images/imgg.png";
import university from "../../Images/university.png";
import school from "../../Images/school.png";
import instructor from "../../Images/instructor.png";
import onlinecourses from "../../Images/onlinecourses.png";
import gymcoaching from "../../Images/gymcoaching.png";
import kindergarden from "../../Images/kindergarden.png";
import awards from "../../Images/awards.png";
import { FaCheck } from "react-icons/fa";
import { color, motion } from "framer-motion";
import { PiCoffeeFill } from "react-icons/pi";
import { FaStar } from "react-icons/fa6";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoThumbsUpOutline } from "react-icons/io5";
import { FiRefreshCcw } from "react-icons/fi";
import { GrDocumentPerformance } from "react-icons/gr";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { SiAltiumdesigner } from "react-icons/si";
import {
  HomeOutlined,
  SearchOutlined,
  UserOutlined,
  SettingOutlined,
  BellOutlined,
  MailOutlined,
  AppstoreOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import careerblitzLogo from "../../Images/Original Logo.png";
import celebration from "../../Images/celebration.png";
import {
  CiBullhorn,
  CiAlignBottom,
  CiDatabase,
  CiEdit,
  CiCircleList,
  CiLocationArrow1,
  CiExport,
  CiLink,
  CiUser,
} from "react-icons/ci";

import Layout from "../../Components/Layout";

import Api from "../../Api";
import "aos/dist/aos.css";
import AOS from "aos";
import JobGrid from "../../HomePageContent/CourseOfTheDay";
import BrowseByCategory from "../../HomePageContent/BrowseByCategory";
import StatsCounter from "../../HomePageContent/StatsCounter";
import BlogComponent from "../../HomePageContent/BlogComponent";
import SubscribeSection from "../../HomePageContent/SubscribeSection";
import HeaderComponent from "../../HomePageContent/Navbar";
import HeaderNavbar from "../../Components/HeaderNavbar";
import CourseOfTheDay from "../../HomePageContent/CourseOfTheDay";
import Features from "../../HomePageContent/Feature";
import JobSeekerFeatures from "../../HomePageContent/JobSeekerFeature";
import CollegeFeatures from "../../HomePageContent/CollegeFeatures";
import CompanyFeatures from "../../HomePageContent/CompanyFeatures";
import HomeTopContent from "../../HomePageContent/HomeTopContent";
import HomeTopCarousal from "../../HomePageContent/HomeTopCarousal";
import CarouselTop from "../../HomePageContent/Carousel";
import ContentData from "../../HomePageContent/ContentData";
import Testimonials from "../../HomePageContent/Testimonials";
import CourseCarousel from "../../HomePageContent/CourseCarousel";
import CounsellorCarousel from "../../HomePageContent/counsellorCarousel";
import { ThemeContext } from "../../HomePageContent/ThemeContext";
import HomepageFilter from "../../HomePageContent/HomepageFilter";

const Homepage = () => {
 
  const { theme } = useContext(ThemeContext);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const items = [
    {
      id: 1,
      title: "Accounting / Finance",
      text: "Accounting / Finance",
      image: <CiAlignBottom />,
    },
    {
      id: 2,
      title: "Humam Resource",
      text: "Development",
      image: <CiExport />,
    },
    {
      id: 3,
      title: "Development",
      text: "Customer Service",
      image: <CiLocationArrow1 />,
    },
    {
      id: 4,
      title: "Development",
      text: "Human Resource",
      image: <CiBullhorn />,
    },
    {
      id: 5,
      title: "Development",
      text: "Design",
      image: <CiEdit />,
    },
    {
      id: 6,
      title: "Development",
      text: "Automotive Job",
      image: <CiUser />,
    },
    {
      id: 7,
      title: "Development",
      text: "Project management",
      image: <CiDatabase />,
    },
    {
      id: 8,
      title: "Development",
      text: "Vendors",
      image: <CiLink />,
    },
    {
      id: 9,
      title: "Development",
      text: "Popular Course",
      image: <CiCircleList />,
    },
  ];

  const [companyList, setCompanyList] = useState([]);
  const [JobName, setJobName] = useState([]);
  const [CarouselList, setCarouselList] = useState([]);
  const [Student, setStudent] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [isStudent, setIsStudent] = useState(false);
  
  useEffect(() => {
    const user = localStorage.getItem("role"); // Assuming user info is stored in localStorage
    if (user === "student") {
      setIsStudent(true);
    }
  }, []);

  useEffect(() => {
    getCompany();
    getJobTitle();
    getCarousel();
    getStudent();
    getApproved();
    getBlogs();
  }, []);

  const getBlogs = async () => {
    await Api.get("blog/getBloglist/status").then((res) => {
      setBlogsData(res.data);
    });
  };

  const getCompany = async () => {
    await Api.get("companylist/getCompanyList").then((res) => {
      setCompanyList(res.data.data);
    });
  };
  const getJobTitle = async () => {
    await Api.get("/createForm/getForm").then((res) => {
      setJobName(res.data.data);
    });
  };
  const getCarousel = async () => {
    await Api.get("/aboutus/aboutus").then((res) => {
      setCarouselList(res.data.data);
    });
  };
  const getStudent = async () => {
    await Api.get("/homestudent/gethomestudent").then((res) => {
      setStudent(res.data.data);
    });
  };
  const [approved, setApproved] = useState([]);
  const getApproved = () => {
    Api.get("fields/getApproved").then((res) => {
      setApproved(res.data.data);
      console.log("res.data", approved);
    });
  };

  const settings = {
    infinite: true,
    dots: false,
    slidesToShow: 6,
    slidesToScroll: 3,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  const navigate = useNavigate();
  return (
    <Layout>
        <div className={theme.homeContainer}>
          {/* <HomeTopContent/> */}
          {/* <BrowseByCategory/> */}
          {/* <HomeTopCarousal  section1Ref={section1Ref}
      <div className="home-container">
        {/* <HomeTopContent/> */}
        {/* <BrowseByCategory/> */}
        {/* <HomeTopCarousal  section1Ref={section1Ref}
        section2Ref={section2Ref}
        section3Ref={section3Ref}/>
         
         <CourseOfTheDay />
         
        <BlogComponent/>
       
      
        
         <SubscribeSection/> */}
        {/* <CollegeFeatures sectionRef={section3Ref}/> */}
        {/* <JobSeekerFeatures sectionRef={section2Ref}/> */}
        {/* <Features sectionRef={section1Ref}/> */}

        {/* <CompanyFeatures/> */}
        <HomepageFilter/>
        {isStudent && <CounsellorCarousel />}
        <Testimonials />
        
        {/* <StatsCounter /> */}
      </div>
    </Layout>
  );
};

export default Homepage;

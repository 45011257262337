import React, { useState, useEffect, useRef } from "react";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import Api from "../../../Api";
import './Learning.scss'
import {
  CalendarOutlined,
  ClockCircleOutlined,
  GlobalOutlined,
  AudioOutlined,
  DesktopOutlined,
  DollarOutlined,
  FileDoneOutlined,
  CheckOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import AOS from "aos";
import "aos/dist/aos.css";

const CourseLandingPage = () => {
  const location = useLocation();
  const batchId = location.state?.batchId;
  const courseId = location.state?.courseId;
  const [course, setCourse] = useState(null);
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasApplied, setHasApplied] = useState(false);
  const studentId = localStorage.getItem("userId");
  const [activeTab, setActiveTab] = useState("overview");
  const [activeInx, setActiveInx] = useState(null);
  const contentRefs = useRef([]);
  const [contentHeights, setContentHeights] = useState([]);

  const toggleSection = (index) => {
    if (activeInx === index) {
      setActiveInx(null);
    } else {
      setActiveInx(index);
    }
  };

  const calculateHeight = (index) => {
    if (contentRefs.current[index]) {
      setContentHeights((prevHeights) => {
        const newHeights = [...prevHeights];
        newHeights[index] = contentRefs.current[index].scrollHeight;
        return newHeights;
      });
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      delay: 300,
    });
  }, []);

  useEffect(() => {
    const fetchCourseById = async () => {
      try {
        const courseResponse = await Api.get(`/course/getcourse/${courseId}`);
        setCourse(courseResponse.data);
        const appliedResponse = await Api.get(
          `signup/student_getone/${studentId}`
        );
        const studentData = appliedResponse.data.data;
        const applied = studentData.appliedCourses.some(
          (course) => course.courseId === courseId && course.batchId === batchId
        );
        setHasApplied(applied);
      } catch (error) {
        console.error(
          "Error fetching course or checking application status:",
          error
        );
      }
    };

    fetchCourseById();
  }, [courseId, studentId, batchId]);

  useEffect(() => {
    const fetchBatchById = async () => {
      try {
        const response = await Api.get(`/course/getbatch/${batchId}`);
        setBatch(response.data);
      } catch (error) {
        console.error("Error fetching batch data:", error);
      }
    };

    fetchBatchById();
  }, [batchId]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleApply = async () => {
    if (hasApplied) {
      message.warning("You have already applied to this batch.");
      return;
    }
  
    setLoading(true);
    try {
      const response = await Api.post("/course/enroll", {
        courseId: courseId,
        userId: studentId,
        batchId: batchId,
      });
  
      message.success("You have successfully applied to the course!");
      setHasApplied(true);
    } catch (error) {
      if (error.response?.status === 409) { 
        message.error("You have already applied to this batch.");
      } else {
        message.error("An error occurred while applying. Please try again.");
      }
      console.error("Error applying for course:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };
  
  if (!course || !batch) {
    return <div>Loading...</div>;
  }

  return (
    <div className="filterpage_skillset">
      <div className="col-12">
        <img
          src={course.image}
          alt="img"
          style={{ height: "60vh", width: "100vw" }}
        />
      </div>
      <div className="title-course">
        <h1>{course.title}</h1>
      </div>

      <div className="row course-details">
        <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7 course-detail-container">
          <div className="tab-titles">
            <div
              className={`tab-title ${
                activeTab === "overview" ? "active" : ""
              }`}
              onClick={() => handleTabClick("overview")}
            >
              Overview
            </div>
            <div
              className={`tab-title ${
                activeTab === "curriculum" ? "active" : ""
              }`}
              onClick={() => handleTabClick("curriculum")}
            >
              Curriculum
            </div>
            <div
              className={`tab-title ${
                activeTab === "instructor" ? "active" : ""
              }`}
              onClick={() => handleTabClick("instructor")}
            >
              Instructor
            </div>
            <div
              className={`tab-title ${
                activeTab === "what-you-learn" ? "active" : ""
              }`}
              onClick={() => handleTabClick("what-you-learn")}
            >
              What You Learn
            </div>
            <hr />
          </div>
          <div className="tab-contents">
            {activeTab === "overview" && (
              <div>
                <section className="mb-4">
                  <h3 className="titele-course">Course Description</h3>
                  <p className="course-description">{course.description}</p>
                </section>

                <section className="mb-4">
                  <h3 className="titele-course">Course Overview</h3>
                  <ul className="prereq-flex">
                    {course.course_overview.map((item, index) => (
                      <li key={index} className="prereq-point">
                        <i
                          className="fas fa-book-open prerequsite"
                          aria-hidden="true"
                          style={{ marginRight: "8px", color: "#3498db" }}
                        ></i>
                        {item}.
                      </li>
                    ))}
                  </ul>
                </section>

                <section className="mb-4">
                  <h3 className="titele-course">Learning Objectives</h3>
                  <ul className="prereq-flex">
                    {course.learning_objectives.map((item, index) => (
                      <li key={index} className="prereq-point">
                        <i
                          className="fas fa-lightbulb prerequsite"
                          aria-hidden="true"
                          style={{ marginRight: "8px", color: "#f39c12" }}
                        ></i>
                        {item}.
                      </li>
                    ))}
                  </ul>
                </section>

                <section className="mb-4">
                  <h3 className="titele-course">Prerequisites</h3>
                  <ul className="prereq-flex">
                    {course.prerequisites.map((item, index) => (
                      <li key={index} className="prereq-point">
                        <i
                          className="fas fa-check-circle prerequsite"
                          aria-hidden="true"
                          style={{ marginRight: "8px", color: "#28a745" }}
                        ></i>
                        {item}.
                      </li>
                    ))}
                  </ul>
                </section>
              </div>
            )}

            {activeTab === "curriculum" && (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="title-corriculam">Course Curriculum</h2>
                    <div>
                      {course.syllabus.map((curriculumItem, index) => (
                        <div key={index} className="curriculum-section mb-2">
                          <div
                            onClick={() => {
                              toggleSection(index);
                              calculateHeight(index);
                            }}
                            style={{
                              cursor: "pointer",
                              padding: "15px",
                              background: "#f8f9fa",
                              border: "1px solid #dee2e6",
                              borderRadius: "4px",
                            }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <h5 className="mb-0 curriculam-heading">
                                {curriculumItem.section_title}
                              </h5>
                              <Button
                                variant="link"
                                className="btn-icon"
                                style={{ padding: "0" }}
                              >
                                {activeInx === index ? (
                                  <UpOutlined
                                    style={{
                                      fontSize: "20px",
                                      color: "#007bff",
                                    }}
                                  />
                                ) : (
                                  <DownOutlined
                                    style={{
                                      fontSize: "20px",
                                      color: "#007bff",
                                    }}
                                  />
                                )}
                              </Button>
                            </div>
                          </div>
                          <div
                            ref={(el) => (contentRefs.current[index] = el)}
                            style={{
                              maxHeight:
                                activeInx === index
                                  ? `${contentHeights[index]}px`
                                  : "0px",
                              overflow: "hidden",
                              transition: "max-height 0.5s ease",
                            }}
                          >
                            <div
                              style={{ padding: "10px", background: "#e9ecef" }}
                            >
                              <ul className="sub-topic-style">
                                {curriculumItem.lessons.map(
                                  (lesson, subIndex) => (
                                    <li
                                      className="li-course-sub-topic"
                                      key={subIndex}
                                    >
                                      <FileDoneOutlined className="curriculam-icon" />
                                      {lesson.lesson_title}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "instructor" && <div></div>}

            {activeTab === "what-you-learn" && (
              <div>
                <section>
                  <h3 className="titele-course">What You Will Learn</h3>
                  <ul className="prereq-flex">
                    {course.what_you_will_learn.map((item, index) => (
                      <li key={index} className="prereq-point">
                        <CheckOutlined
                          style={{
                            color: "#28a745",
                            fontSize: "22px",
                            marginRight: "10px",
                          }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </section>
              </div>
            )}
          </div>
        </div>

        <div className="col-12 col-sm-9 col-md-12 col-lg-4 col-xl-4 col-xxl-4 apply-card">
          <img className="img-course" src={course.image} alt={course.title} />
          <div className="course-details">
            <div className="batch-names">
              <div
                style={{
                  fontWeight: "bold",
                  color: "#007bff",
                  marginBottom: "10px",
                }}
              >
                <h3>{batch.batch_name}</h3>
              </div>
            </div>
            <div className="batch-details">
              <p className="course-date">
                <CalendarOutlined className="date-icon" /> <b>Start Date:</b>{" "}
                {formatDate(batch.start_date)}
              </p>
              <p className="course-date">
                <CalendarOutlined className="date-icon" /> <b>End Date:</b>{" "}
                {formatDate(batch.end_date)}
              </p>
              <p className="course-date">
                <ClockCircleOutlined className="date-icon" /> <b>Start Time:</b>{" "}
                {batch.start_time}
              </p>
              <p className="course-date">
                <ClockCircleOutlined className="date-icon" /> <b>End Time:</b>{" "}
                {batch.end_time}
              </p>
              <p className="course-date">
                <GlobalOutlined className="date-icon" /> <b>Language:</b>{" "}
                {batch.language.join(", ")}
              </p>
              <p className="course-date">
                <DesktopOutlined className="date-icon" /> <b>Mode:</b>{" "}
                {batch.mode}
              </p>
              <p className="course-date">
                <DesktopOutlined className="date-icon" /> <b>Batch Schedule:</b>{" "}
                {batch.batch_schedule.join(", ")}
              </p>
              <p className="course-date">
                <DollarOutlined /> <b>Original Price:</b>{" "}
                <span style={{ textDecoration: "line-through" }}>
                  ₹{course.original_price}
                </span>
              </p>
              <p className="course-date">
                <DollarOutlined /> <b>Offer Price:</b> ₹{course.offer_price}
              </p>
              <p className="course-date">
                <AudioOutlined className="date-icon" /> <b>Last Date:</b>{" "}
                {formatDate(batch.booking_last_date)}
              </p>
            </div>
            <div className="apply-button-wrapper">
              <button
                className="landing-apply-btn"
                onClick={handleApply}
                disabled={loading || hasApplied}
              >
                {loading
                  ? "Applying..."
                  : hasApplied
                  ? "Already Applied"
                  : "Apply"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLandingPage;

import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import "../../../Components/Css/Sidebar.scss";
import { Suspense } from "react";
import { Link, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import "antd/dist/antd.css";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import careerblitzIcon from "../../../Images/Careerblitz logo.png";
import Api from "./../../../Api";

import { HiUserCircle } from "react-icons/hi";

const { Content } = Layout;

function Dashboard() {
  const [collapsed, setCollapsed] = useState(true);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const UserName = localStorage.getItem("name");

  const handleChange = () => {
    if (window.innerWidth < 720) {
      setCollapsed(!collapsed);
    } else {
      setCollapsed(collapsed);
    }
  };
  useEffect(() => {
    const fetchEmployerDetails = async () => {
      const storedId = localStorage.getItem("userId"); // Fetch the ID from localStorage
      if (storedId) {
        const employerDetails = await getEmployerById(storedId);
        if (employerDetails) {
          setUserId(employerDetails._id); // Example: setting the user ID
          // You can also set other state variables here based on the fetched details
        }
      }
    };

    fetchEmployerDetails();
  }, []);

  const getEmployerById = async (Id) => {
    try {
      const response = await Api.get(`/employer/Employer_get/${Id}`);
      if (response.status === 200) {
        localStorage.setItem("name", response.data.data.name);

        return response.data; // Return the fetched employer details
      }
    } catch (error) {
      console.error("Error fetching employer details by ID:", error);
      return null;
    }
  };

  const [logout, setLogout] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("resize", handleChange);
    if (!localStorage.getItem("USER_AUTH_STATE")) navigate("/login");
  }, [logout]);
  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("USER_AUTH_STATE");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("regid");
    localStorage.removeItem("userId");
    localStorage.removeItem("companyName");
    localStorage.removeItem("firstName");
    localStorage.removeItem("trainerId");
    setLogout(true);
  };

  // if (window.matchMedia("max-width:440px").matches) {
  //   setCollapsed(!collapsed);
  // }
  // Fetch user ID on mount if UserName exists

  return (
    <div>
      <div onChange={handleChange}>
        <div className="dashboards__headerNavs">
          <div className="dashboards__headerNavs--container">
            <div>
              <span
                className="navMenu p-2 "
                onClick={() => setCollapsed((prev) => !prev)}
              >
                {collapsed ? <AiOutlineClose /> : <GiHamburgerMenu />}
              </span>
              <img
                className="careerblitz_icon"
                src={careerblitzIcon}
                alt="add"
                onClick={() => navigate("/")}
              />
              <span className="navName" onClick={() => navigate("/")}>
                {/* {UserName} */}
              </span>
            </div>
            <div>
              <div className="logoutAvatar" onClick={logoutHandler}>
                {/* <Link to="/login">Logout</Link> */}
                <HiUserCircle
                  style={{ fontSize: 45, cursor: "pointer", color: "grey" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar collapsed={collapsed} />
      <div
        className={collapsed === true ? "main-content open" : "main-content"}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;

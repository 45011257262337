import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Row, Col, Input, Select, Card, Rate, Radio, Space } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Layout from "../../../Components/Layout";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MdCurrencyRupee } from "react-icons/md";

import "../Skilltest/skilltest.scss";
import "./Learning.scss";
import "aos/dist/aos.css";
import AOS from "aos";

function Curriculam() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Set animation duration in milliseconds
      easing: "ease-in-out", // Animation easing
      delay: "300",
    });
  }, []);

  const Courses = [
    {
      id: 1,
      title: "operating System",
      description:
        "Machine learning is a field and study of statistical algorithms that can learn from dataLearn the basics of React.",
      instructor: "John Doe",
      duration: "4 weeks",
      startDate: "2023-09-01",
      image:
        "https://img.freepik.com/free-vector/operating-system-concept-illustration_114360-2762.jpg?size=626&ext=jpg&ga=GA1.1.1729328857.1715275984&semt=ais_hybrid",
      rating: 4.5,
      originalPrice: "199",
      discountPrice: 99,
    },
    {
      id: 2,
      title: "Data Structure",
      description:
        "computer science, a data structure is a data organization, and storage format that is usually chosen for efficient access to data.",
      instructor: "Jane Smith",
      duration: "6 weeks",
      startDate: "2023-10-01",
      image:
        "https://img.freepik.com/free-vector/data-economy-isometric-composition-with-isolated-platforms-connected-with-wires-human-characters-computer-infrastructure-elements-vector-illustration_1284-79924.jpg?t=st=1723532146~exp=1723535746~hmac=db88b4a22f02c97792753a4f8dd03b4d4c73489b79e8b80866367562d97a1c0d&w=996",
      rating: 5,
      originalPrice: 199,
      discountPrice: 99,
    },
    {
      id: 3,
      title: "Machine Learning",
      description:
        "An operating system is system software that manages computer hardware and software resources, and provides common services for computer programs",
      instructor: "Mary Johnson",
      duration: "8 weeks",
      startDate: "2023-11-01",
      image:
        "https://as1.ftcdn.net/v2/jpg/08/41/35/16/1000_F_841351689_8shOxKABEfIMTmktwQSILxOIe62Z9iQP.jpg",
      rating: 3.8,
      originalPrice: 199,
      discountPrice: 99,
    },
    {
      id: 4,
      title: "software engineering",
      description:
        "Software engineering is an engineering approach to software development. A practitioner, called a software engineer, applies the engineering design process to develop software",
      instructor: "Mary Johnson",
      duration: "7 weeks",
      startDate: "2023-11-01",
      image:
        "https://www.clarkson.edu/sites/default/files/2023-06/Software-Engineering-Hero-1600x900.jpg",
      rating: 3.3,
      originalPrice: 199,
      discountPrice: 99,
    },
    {
      id: 5,
      title: "mathematical structure",
      description:
        "In mathematics, a structure is a set provided with some additional features on the set. Often, the additional features are attached or related to the set, so as to provide it with some additional meaning or significance",
      instructor: "Mary Johnson",
      duration: "3 weeks",
      startDate: "2023-11-01",
      image:
        "https://www.researchgate.net/publication/339015734/figure/fig3/AS:854701499224065@1580788125733/The-hierarchy-of-structures-in-the-Mathematical-Components-library-180-and-190-The.png",
      rating: 4.7,
      originalPrice: 199,
      discountPrice: 99,
    },
    {
      id: 6,
      title: "computer networks",
      description:
        "A computer network is a set of computers sharing resources located on or provided by network nodes. Computers use common communication protocols over digital interconnections to communicate with each other.",
      instructor: "Mary Johnson",
      duration: "8 weeks",
      startDate: "2023-11-01",
      image:
        "https://img.freepik.com/premium-vector/internet-icon-technology-vector-communication-illustration-business-web-network-online-com_1013341-139773.jpg?size=626&ext=jpg&ga=GA1.1.957273099.1723532135&semt=ais_hybrid",
      rating: 3.9,
      originalPrice: 199,
      discountPrice: 99,
    },
    {
      id: 7,
      title: "UNIX LAB",
      description:
        "Unix and Shell Programming. Intelligent Systems. Statistics and Numerical Techniques. Business Management. Management Accounting. Unix lab",
      instructor: "Mary Johnson",
      duration: "8 weeks",
      startDate: "2023-11-01",
      image:
        "https://logowik.com/content/uploads/images/unix-system-laboratories2415.logowik.com.webp",
      rating: 4.2,
      originalPrice: 199,
      discountPrice: 99,
    },
    {
      id: 8,
      title: "Cloud Computing",
      description:
        "DaCloud Computing is a method of organizing and compiling data into one database, whereas data mining deals with fetching important data from databases",
      instructor: "Mary Johnson",
      duration: "8 weeks",
      startDate: "2023-11-01",
      image:
        "https://as2.ftcdn.net/v2/jpg/02/25/32/77/1000_F_225327769_RiJACfN3PPkcRUNU6dhWwTTKPDHf40nM.jpg",
      rating: 4.9,
      originalPrice: 199,
      discountPrice: 99,
    },
  ];

  const navigate = useNavigate();
  const [filteredCourses, setFilteredCourses] = useState(Courses);
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  console.log("selectedDuration", selectedDuration);
  const [ratingRange, setRatingRange] = useState([0, 5]);

  const handleTitleChange = (e) => {
    setSearchTitle(e.target.value);
    applyFilters(e.target.value, selectedDuration, ratingRange);
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;
    setSelectedDuration(e.target.value);
    applyFilters(searchTitle, value, ratingRange);
  };

  const handleRatingChange = (value) => {
    setRatingRange(value);
    applyFilters(searchTitle, selectedDuration, value);
  };

  const applyFilters = (title, duration, rating) => {
    const filtered = Courses.filter((course) => {
      const matchesTitle = course.title
        .toLowerCase()
        .includes(title.toLowerCase());
      const matchesDuration = !duration || course.duration === duration;
      const matchesRating =
        course.rating >= rating[0] && course.rating <= rating[1];
      return matchesTitle && matchesDuration && matchesRating;
    });
    setFilteredCourses(filtered);
  };
  // useEffect(() => {
  //   applyFilters();
  // }, [searchTitle, selectedDuration, ratingRange]);

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    console.log(`checked = ${e.target.checked}`);
    setValue(e.target.value);
  };
  const handleReset = () => {
    setSearchTitle("");
    setSelectedDuration("");
  };
  return (
    <>
      <div>
        <div className="pt-5">
          <h1 className="companyheading pt-5">Carriculam Courses</h1>

          <Row>
            <Col md={8}>
              <form>
                <div className="filter-card p-4">
                  <h3>Filter Courses</h3>

                  <div className="mb-3">
                    <Row>
                      <Col lg={12}>
                        <label> Title </label>
                        <br></br>
                        <input
                          placeholder="Search by title"
                          className="title-input-stu"
                          value={searchTitle}
                          onChange={handleTitleChange}
                          // {...register("title", { required: true })}
                        />
                      </Col>
                      <Col
                        className="resetButtonCol"
                        lg={12}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Button
                          className="resetButton"
                          onClick={() => handleReset()}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  {/*  <div className="mb-3">
                  <label>Duration</label>
                  <br></br>
                <Select
                    placeholder="Select duration"
                    style={{ width: "300px" }}
                    value={selectedDuration}
                    onChange={handleDurationChange}
                  >
                    <Option value="4 weeks">4 weeks</Option>
                    <Option value="5 weeks">5 weeks</Option>
                    <Option value="6 weeks">6 weeks</Option>
                    <Option value="7 weeks">7 weeks</Option>
                    <Option value="8 weeks">8 weeks</Option>
                  </Select> */}
                </div>

                <Accordion
                  defaultActiveKey="0"
                  style={{ width: "51vh", marginLeft: "3vh" }}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Rating</Accordion.Header>
                    <Accordion.Body>
                      <Radio.Group onChange={onChange}>
                        <Space direction="vertical">
                          <Radio value={1}>
                            <Rate disabled defaultValue={5} />
                            &nbsp; 4.5 & up
                          </Radio>
                          <Radio value={2}>
                            <Rate disabled defaultValue={4.5} />
                            &nbsp; 4.0 & up
                          </Radio>
                          <Radio value={3}>
                            <Rate disabled defaultValue={4} />
                            &nbsp; 3.5 & up
                          </Radio>
                          <Radio value={4}>
                            <Rate disabled defaultValue={3.5} />
                            &nbsp; 3.0 & up
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Duration</Accordion.Header>
                    <Accordion.Body>
                      {/* <Checkbox 
                        value={selectedDuration}
                        onChange={(e)=>handleDurationChange(e)}
                      >
                        7 weeks
                      </Checkbox> */}
                      <Form.Check
                        type="checkbox"
                        label="4 weeks"
                        value="4 weeks"
                        onChange={handleDurationChange}
                      />
                      <Form.Check
                        type="checkbox"
                        label="5 weeks"
                        value="5 weeks"
                        onChange={handleDurationChange}
                      />
                      <Form.Check
                        type="checkbox"
                        label="6 weeks"
                        value="6 weeks"
                        onChange={handleDurationChange}
                      />
                      <Form.Check
                        type="checkbox"
                        label="7 weeks"
                        value="7 weeks"
                        onChange={handleDurationChange}
                      />
                      <Form.Check
                        type="checkbox"
                        label="8 weeks"
                        value="8 weeks"
                        onChange={handleDurationChange}
                      />{" "}
                      <br />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </form>
            </Col>

            <Col md={16}>
              <Row>
                {filteredCourses.map((course) => (
                  <Col md={24} key={course.id}>
                    <div>
                      <Card
                        style={{
                          width: "100vh",
                          height: "210px",
                          marginTop: "10px",
                          border: "none",
                        }}
                        onClick={() =>
                          navigate(`/student/OperatingSystem/${course.id}`)
                        }
                        className="courses-card"
                      >
                        <Row>
                          <Col lg={6}>
                            <div>
                              <img
                                src={course.image}
                                style={{ width: "200px", height: "160px" }}
                                alt={course.title}
                                data-aos="flip-left"
                              />
                            </div>
                          </Col>
                          <Col lg={14}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "2vh",
                              }}
                            >
                              <h3 style={{ fontSize: "20px" }}>
                                {course.title}
                              </h3>
                              <p style={{ margin: "0" }}>
                                {course.description}
                              </p>
                              <p style={{ margin: "0" }}>{course.duration}</p>
                              <p style={{}}>
                                <b>{course.rating}</b>&nbsp;
                                <Rate
                                  disabled
                                  allowHalf
                                  defaultValue={course.rating}
                                  style={{ fontSize: "10px" }}
                                  className="course-rating"
                                />
                              </p>
                            </div>
                          </Col>

                          <Col lg={4} data-aos="fade-left" delay="500">
                            <div className="priceContainer">
                              <div className="orginalPrice">
                                <MdCurrencyRupee />
                                <del>{course.originalPrice}</del>
                              </div>
                              <div className="discountPrice">
                                <MdCurrencyRupee />
                                {course.discountPrice}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <div
            className="viewMore-skills-learning mt-5
           mb-5"
          >
            <button
              className="viewMore-skills-button"
              onClick={() => navigate("/student/learning/OperatingSystem")}
            >
              View More
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Curriculam;

import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import "../../Components/Css/login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import google from "../../assets/google.png";
import HeaderNavbar from "../../Components/HeaderNavbar";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Col, Row, Toast, ToastContainer } from "react-bootstrap";
import Api from "../../Api";
import SignInWithGoogle from "../../Services/SignInWithGoogle";
import "aos/dist/aos.css";
import AOS from "aos";
import { GrFreebsd } from "react-icons/gr";
import login from "../../Images/card2.jpg";
import Layout from "../../Components/Layout";

const Login = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  const { state } = useLocation();
  const [role, setrole] = useState("");
  const [a, setA] = useState(state);
  const onChange = (e) => {
    setrole(e.target.value);
    setA(e.target.value);
  };
  const stateCheck = typeof state !== "object" ? (state ? a : role) : role;

  const [showToast, setShowToast] = useState(false);
  const [resres, setResres] = useState({
    status: null,
    message: null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    handleFormSubmit();
    setShowToast(true);
  };
  const [passwordShown, setpasswordShown] = useState(false);

  const handlePasswordShow = () => {
    setpasswordShown(!passwordShown);
  };
  const userId = localStorage.getItem("userId");

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    getName();
  }, [userId]);

  const getName = () => {
    Api.get(`trainer/Trainergetone/${userId}`)
      .then((res) => {
        console.log("rees", res);
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const roletype = userData.roletype;
  console.log("rolll", roletype);

  const navigate = useNavigate();
  const handleFormSubmit = async (data) => {
    const userDetails = {
      email: getValues().email,
      password: getValues().password,
    };

    await Api.post(`users/login`, userDetails)
      .then((response) => {
        console.log("response", response.data);
        setrole(roletype);
        setResres({
          status: response.data.status,
          message: response.data.message,
        });
        setShowToast(true); // Display toast on successful response
        if (response.data.token) {
          const role = response.data.data.role;
          const token = response.data.token;
          const firstName = response.data.data.firstName;
          const name = response.data.data.name;
          const email = response.data.data.email;
          const id = response.data.data._id;
          const regid = response.data.data.RegistrationId;
          const companyName = response.data.data.companyName;
          const userId = response.data.data.userId;
          const trainerId = response.data.data.trainerId;

          localStorage.setItem("USER_AUTH_STATE", true);
          localStorage.setItem("token", token);
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("name", name);
          localStorage.setItem("role", role);
          localStorage.setItem("email", email);
          localStorage.setItem("id", id);
          localStorage.setItem("regid", regid);
          localStorage.setItem("companyName", companyName);
          localStorage.setItem("userId", userId);
          localStorage.setItem("trainerId", trainerId);

          // navigate(
          //   `/${role}${
          //     role === "employer" && "employee" ? `/${companyName}` : ""
          //   }`
          // );
          setTimeout(() => {
            if (roletype === "trainer") {
              navigate(`/trainer`);
            } else if (role === "trainers") {
              navigate("/newtrainer");
            } else if (role === "employer" && companyName) {
              navigate("/employer/dashboard");
            } else if (role === "student") {
              navigate("/student");
            } else if (role === "counsellor") {
              navigate("/counsellor");
            } else {
              navigate(`/${role}`);
            }
            sessionStorage.setItem("USER_AUTH_STATE", true);
            window.location.reload();
          }, 5000);
        }
      })
      .catch((err) => {
        setResres({
          status: err?.response?.data?.status || "Error",
          message: err?.response?.data?.message || "An error occurred",
        });
        setShowToast(true); // Display toast on error
      });
  };

  const handleSignInWithGoogle = async (res) => {
    try {
      SignInWithGoogle()
        .then((res) => {
          console.log(res);
          const name = res.displayName;
          const email = res.email;
          const token = res.accessToken;
          const companyName = res.companyName;
          const role = "student";
          if (res) {
            localStorage.setItem("USER_AUTH_STATE", true);
            localStorage.setItem("name", name);
            localStorage.setItem("role", role);
            localStorage.setItem("email", email);
            localStorage.setItem("token", token);
            navigate(
              `/${role}${
                role === "employer" && "employee" ? `/${companyName}` : ""
              }`
            );
            sessionStorage.setItem("USER_AUTH_STATE", true);
            window.location.reload();
          }
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.log(error.data.message);
    }
  };

  return (
    <Layout>
      <div className="login-container">
        <ToastContainer
          position="top-end"
          className="p-3"
          style={{ zIndex: 1050 }} // Adjust the z-index if other elements overlap
        >
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={10000}
            autohide
            bg={resres.status}
          >
            <Toast.Header closeButton>
              <strong className="p-3">{resres.status}</strong>
            </Toast.Header>
            <Toast.Body>
              {resres.status === "Success"
                ? "Login successful!"
                : resres.message || "An error occurred"}
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <Row className="login-page">
          <Col
            sm={12}
            md={6}
            className="login-image-section"
            data-aos="fade-right"
          >
            <div className="image-container">
              <img src={login} className="login-image" alt="Login" />
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            className="login-form-section"
            data-aos="fade-left"
          >
            <Card className="form-card">
              <div className="login-title-div">
                <h4 className="login-title">
                  <span style={{ color: "blue" }}>CAREERBLITZ</span> Login
                </h4>
              </div>
              <div className="login-form-content">
                <div className="signup-link-container">
                  <h5>Don't have an account?</h5>
                  <Link to="/signup" className="signup-link" state={a}>
                    Signup Now
                  </Link>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group login-field">
                    <label>Email ID</label>
                    <input
                      className="form-control custom"
                      {...register("email", { required: true })}
                    />
                    {errors.email && (
                      <p className="error-text">Email is required</p>
                    )}
                  </div>
                  <div className="form-group login-field">
                    <label>Password</label>
                    <input
                      className="form-control custom"
                      type={passwordShown ? "text" : "password"}
                      {...register("password", { required: true })}
                    />
                    <FontAwesomeIcon
                      icon={passwordShown ? faEye : faEyeSlash}
                      onClick={handlePasswordShow}
                      className="password-toggle"
                    />
                    {errors.password && (
                      <p className="error-text">Password is required</p>
                    )}
                  </div>
                  <div className="form-footer">
                    <Link to="/forgetpassword" className="forgot-password-link">
                      Forget Your Password?
                    </Link>
                    <Button type="submit" className="login-button">
                      Submit
                    </Button>
                  </div>
                </form>
                <Divider>Or</Divider>
                <Button
                  className="google-login-btn"
                  onClick={handleSignInWithGoogle}
                >
                  <img src={google} alt="Google Icon" className="google-icon" />
                  Sign in with Google
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Login;

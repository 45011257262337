import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// LOGINS
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import AdminLogin from "./Pages/Login/AdminLogin";
import Forgetpassword from "./Pages/Login/Forgetpassword";

// HOME
import Homepage from "./Pages/Homepage";

//BLOG
// import BlogsPage from "./Pages/Blog/BlogsPage";
// import Blog from "./Pages/Blog";
// import CreateBlog from "./Pages/Blog/CreateBlog";

//ROUTES
import AdminRoutes from "./Routes/AdminRoutes";
import CollegeRoutes from "./Routes/CollegeRoutes";
import TrainingInstituteRoutes from "./Routes/TrainingInstituteRoutes";
import EmployeeRoutes from "./Routes/ProfessionalRoutes";
import StudentRoutes from "./Routes/StudentRoutes";
import EmployerRoutes from "./Routes/EmployerRoutes";
import EmployeeRoute from "./Routes/EmployeeRoute";
// import CategoryField from "../src/Pages/Admin/Fields/CategoryAdded.js";
// import JobRoutes from "./Routes/JobRoutes";
import VendorRouter from "./Routes/VendorRoutes";
// import careerblitzPGRoutes from "./Routes/careerblitzPgRoutes";

//DEFAULTS
import DefaultLayouts from "./Pages/Student/DefaultLayout";
import ProfessionalDefaultLayout from "./Pages/Professional/ProfessionalDefaultLayout";
import ErrorMessage from "./Components/Errorpage/ErrorMessage";
import Traning from "./Pages/Institute-Training/Layouts/Defaultlayout";
import College from "./Pages/Institute-College/DefaultLayout/index";
import Employer from "./Pages/Employer/Dashboard";
import Employee from "./Pages/Employee/Dashboard";

// FOOTER
import Aboutus from "./Pages/Aboutus/index";
import Contactus from "./Pages/Contactus/index";
// import Joblayout from "./Pages/joblist/DefaultLayout";
import Feedback from "./Components/Footer/feedback/feedback";
import TrustAndSafety from "./Components/Footer/TrustandSafety/TrustAndSafety";
import Term from "./Components/Footer/TermCondition/Term";
import Services from "./Pages/Services/Services";
import FraudAlert from "./Components/Footer/FraudAlert/FraudAlert";
import HelpCenter from "./Components/Footer/HelpCenter";
import Site from "./Components/Footer/SiteMap/Site";
import Career from "./Components/Footer/Career/Career";
import Client from "./Components/Footer/Clients/Client";

// DASHBOARD
import Dashboard from "./Pages/Admin/Dashboard/AdminSidebar";
import ProtectedRoute from "./Routes/ProtectedRoute";

// BACKGROUNG VERIFICATION
import BgvRoutes from "./Routes/BgvRoutes";
import BgvDashboard from "./Pages/Bgv/Dashboard";

// SKILLTEST
import Skilltest from "../src/Pages/Student/Skilltest/Skilltest";
import FilterPage from "../src/Pages/Student/Skilltest/FilterPage";
import FilterLandingPage from "./Pages/Student/Skilltest/FilterLandingPage";

//EXAM
import ExamRoutes from "./Routes/ExamRoutes";
import EmailVerify from "./Pages/Signup/EmailVerify";

import VendorSignup from "./Pages/Signup/VendorSignup";
//careerblitzS
import careerblitzPg from "./Pages/Feril-Pg/index";
import careerblitzLibrary from "./Pages/Feril-Library/index";
import PGBOYS from "./Pages/Feril-Pg/PGBOYS";
import PGGirls from "./Pages/Feril-Pg/PGGIRLS";
import PGPROF from "./Pages/Feril-Pg/PGPROF";
import PGSTUDENTS from "./Pages/Feril-Pg/PGSTUDENTS";

import careerblitzbooks from "./Pages/Feril-Library/FerilBooks";
// import Pen from "./Pages/careerblitz-stationary/Pen";
// import Art from "./Pages/careerblitz-stationary/Art";
// import Calculator from "./Pages/careerblitz-stationary/Cal";
// import Diary from "./Pages/careerblitz-stationary/Diary1";
// import Company from "./Pages/careerblitz-stationary/Company_new";
// import Home from "./Pages/careerblitz-stationary/Home";
// import Fancy from "./Pages/careerblitz-stationary/Fancy";
// import Gift from "./Pages/careerblitz-stationary/Gift";

import Project1 from "./Pages/Feril-Rental/Project1/Project1";
import AddList from "./Pages/Feril-Rental/AddList.js";
import Rent from "./Pages/Feril-Rental/Rent.js";

import AccessoriresItem from "./Pages/Feril-Accessories/AccessoriesItem";
import ListItem from "./Pages/Feril-Accessories/ListItem";
import Accessories from "./Pages/Feril-Accessories/Accessories";

import Stationary from "./Pages/Feril-stationary/filterpage";
import PlayerSection from "./Pages/Feril-Library/PlayerSection";

//FREELANCING

import Freelancing from "../src/Pages/Freelancing/Freelancing";
import FreelancingPost from "../src/Pages/Freelancing/FreelancingPost";
import FreelancingPay from "../src/Pages/Freelancing/FreelancingPay";
import FreelancingPay_Clickhere from "../src/Pages/Freelancing/FreelancingPay_Clickhere";
import FreelancingPost_AdminTable from "../src/Pages/Admin/FreelancingPost_AdminTable/FreelancingPost_AdminTable";
import VideoSection from "./Pages/Feril-Library/VideoSection";
import HomeJobLandingPage from "./Pages/Homepage/HomeJobLandingPage";

import Vendor from "../src/Pages/Vendor/DefaultLayout.js/index";
// import Vendor from "../src/Pages/Vendor/Index";
import Myprofile from "./Pages/Vendor/Myprofile";
import Addcategory from "./Pages/Vendor/Addcategory";
import Advertisement from "./Pages/Vendor/Advertisement";
import Offer from "./Pages/Vendor/Offer";
import Addlist from "./Pages/Vendor/Addlist";
import Enquiry from "./Pages/Vendor/Enquiry";
import Packages from "./Pages/Vendor/Packages";
import Packageoffers from "./Pages/Vendor/Packageoffers";
import Blog from "./Pages/Student/Blog/index.js";
import Blogs from "./Pages/Blog/blogs.js";

//Trainer
import TrainerDashboard from "./Pages/Trainer/TrainerDashboard/index.js";
import TrainerRoutes from "./Routes/TrainerRoutes.js";
import TrainerLayout from "./Pages/Trainer/TrainerDefaultLayout/index.js";
import CounselloLayout from "./Pages/Trainer/CounsellingDefaultLayout/Index.js";
import RefundPolicy from "./Footer Content/RefundPolicy.jsx";
import CancellationPolicy from "./Footer Content/CancellationPolicy.jsx";
import TermsAndConditions from "./Footer Content/TermsAndConditions.jsx";
import PricingPage from "./Footer Content/PricingTag.jsx";
import PricingTagLandingPage from "./Footer Content/PricingTagLandingPage.js";
import ContactUs from "./HomePageContent/ContactUs.jsx";
import AboutUs from "./HomePageContent/AboutUs.jsx";
import Courses from "./HomePageContent/Courses.js";
import PrivacyPolicy from "./Components/Footer/PrivacyPolicy/index.js";
import StudentSignupForm from "./SignUp/StudentSignupForm.jsx";
import SignupPage from "./SignUp/SignUpForm.jsx";
import RoleForm from "./SignUp/RoleForm.jsx";
import ExamFields from "./Pages/Admin/Fields/ExamFields.js";
import NewExam from "./Pages/Student/Exam/NewExam.js";
import { ThemeProvider } from "./HomePageContent/ThemeContext.jsx";
import SuccessPage from "./Footer Content/SuccessPage.js";
import Chat from "./Pages/Chat/Chat.jsx";
import Trainer from "./Pages/Trainer/NewPage/Trainer.js";
import CounsellerRoutes from "./Routes/CounsellerRoutes.js";
import CounsellorLandingPage from "./HomePageContent/CounsellorLandingPage.js";
import CourseLandingPage from "./HomePageContent/Courses/CourseLandingPage.js";
import BatchesPage from "./HomePageContent/Courses/BatchByCourse.js";
import BlogLandingPage from "./Pages/Blog/BlogLandingPage.js";

import CourseLanding from "./HomePageContent/Courses/CourseLanding.js";
import CareerBlitzFAQ from "./Footer Content/FAQ/CareerBlitzFAQ.js";
import CareerBlitzEvents from "./Pages/Events/CareerBlitzEvents.js";

const App = () => {
  // const student_token = localStorage.getItem("stu-token");
  // const pro_token = localStorage.getItem("pro-token");
  // const employer_token = window.localStorage.getItem("emplo-token");
  // const institute_token = window.localStorage.getItem("institute-token");
  // console.log("tokensssssss", pro_token);
  const role = localStorage.getItem("role");
  return (
    <Suspense fallback={<h1>Loading</h1>}>
      <ThemeProvider>
        <Routes>
          <Route path="/newtrainer" element={<Trainer />} />
          <Route path="/studentexam" element={<NewExam />} />
          <Route path="/dummyexam" element={<ExamFields />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/stest" element={<StudentSignupForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup/:role" element={<SignupPage />} />
          <Route path="/Signup" element={<RoleForm />} />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/batchespage" element={<BatchesPage />} />
          {/* <Route path="/category" element={<CategoryField />} /> */}
          {/* <Route path="/vendor" element={<VendorSignup />} /> */}
          {/* Footer Route */}
          <Route path="/pricingpayform" element={<PricingTagLandingPage />} />
          <Route path="/termsandcondition" element={<Term />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/cancellationpolicy" element={<CancellationPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/helpcenter" element={<HelpCenter />} />
          {/* <Route path="/aboutus" element={<Aboutus />} /> */}
          <Route path="/sitemap" element={<Site />} />
          <Route path="/career" element={<Career />} />
          <Route path="/client" element={<Client />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courseslandingpage" element={<CourseLandingPage />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          <Route path="/events" element={<CareerBlitzEvents />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogLandingPage />} />
          <Route path="/FAQ" element={<CareerBlitzFAQ />} />
          <Route path="/services" element={<Services />} />
          <Route
            path="/CounsellorLandingPage"
            element={<CounsellorLandingPage />}
          />
          {/* <Route path="/blogsPage" element={<BlogsPage />} />
        <Route path="/createblog" element={<CreateBlog />} /> */}
          <Route path="*" element={<ErrorMessage />} />
          <Route path="/forgetpassword" element={<Forgetpassword />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/trustsafety" element={<TrustAndSafety />} />
          <Route path="/fraudalert" element={<FraudAlert />} />
          <Route path="/skilltest" element={<Skilltest />} />
          <Route path="/skilltest/filterpage" element={<FilterPage />} />
          <Route
            path="/skilltest/filterpage/:_id"
            element={<FilterLandingPage />}
          />
          <Route path="/Homeinnepage" element={<HomeJobLandingPage />} />
          {/* careerblitz SERVICES */}
          <Route path="/innerProject" element={<PGBOYS />} />;
          <Route path="/innerProject1" element={<PGGirls />} />;
          <Route path="/innerProject2" element={<PGPROF />} />;
          <Route path="/innerProject33" element={<PGSTUDENTS />} />;
          <Route path="/rental" element={<Rent />} />
          <Route path="/Electronic" element={<Project1 />} />
          <Route path="/Bike" element={<Project1 />} />
          <Route path="/Furniture" element={<Project1 />} />
          <Route path="/Home-Appliances" element={<Project1 />} />
          <Route path="/electronics" element={<AddList />} />
          <Route path="/bikes" element={<AddList />} />
          <Route path="/furnitures" element={<AddList />} />
          <Route path="/home-appliances" element={<AddList />} />
          <Route path="/accessories" element={<Accessories />} />
          <Route path="/watch" element={<AccessoriresItem />} />
          <Route path="/jewellery" element={<AccessoriresItem />} />
          <Route path="/sunglass" element={<AccessoriresItem />} />
          <Route path="/handbag" element={<AccessoriresItem />} />
          <Route path="/watches-page" element={<ListItem />} />
          <Route path="/jewellery_page" element={<ListItem />} />
          <Route path="/sunglasses_page" element={<ListItem />} />
          <Route path="/handbags-page" element={<ListItem />} />
          <Route path="/chat" element={<Chat />} />
          {/* <Route path="/Pen" element={<Pen />} />
        <Route path="/art" element={<Art />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/diary" element={<Diary />} />
        <Route path="/company" element={<Company />} />
        <Route path="/home" element={<Home />} />
        <Route path="/fancy" element={<Fancy />} />
        <Route path="/gift" element={<Gift />} /> */}
          {/* FREELANCING */}
          <Route path="/freelancing" element={<Freelancing />} />
          <Route path="/freelancePost" element={<FreelancingPost />} />
          <Route path="/freelancingPay" element={<FreelancingPay />} />
          <Route path="/payment-success" element={<SuccessPage />} />
          {/* vendor */}
          <Route
            path="/freelancingPay/freelancingpay_Clickhere"
            element={<FreelancingPay_Clickhere />}
          />
          <Route
            path="/FreelancingPost_AdminTable"
            element={<FreelancingPost_AdminTable />}
          />
          {/* careerblitzS */}
          <Route path="/careerblitzpg" element={<careerblitzPg />} />
          <Route path="/careerblitzlibrary" element={<careerblitzLibrary />} />
          <Route
            path="/careerblitzlibrary/videosection"
            element={<VideoSection />}
          />
          <Route
            path="/careerblitzlibrary/videosection/abcd"
            element={<PlayerSection />}
          />
          <Route
            path="/careerblitzlibrary/careerblitzbooks"
            element={<careerblitzbooks />}
          />
          <Route path="/stationary" element={<Stationary />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute component={Dashboard} token={"admin-token"} />
            }
          >
            {AdminRoutes.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          <Route
            path="/student"
            element={
              <ProtectedRoute component={DefaultLayouts} token={"stu-token"} />
            }
          >
            {StudentRoutes.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          <Route
            path="/employee"
            element={
              <ProtectedRoute component={Employee} token={"pro_token"} />
            }
          >
            {EmployeeRoute.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          <Route
            path="/trainer"
            element={
              <ProtectedRoute component={TrainerLayout} token={"tra_token"} />
            }
          >
            {TrainerRoutes.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          <Route
            path="/counsellor"
            element={
              <ProtectedRoute
                component={CounselloLayout}
                token={"coun_token"}
              />
            }
          >
            {CounsellerRoutes.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          <Route
            path="/BgvHome"
            element={
              <ProtectedRoute component={BgvDashboard} token={"stu-token"} />
            }
          >
            {BgvRoutes.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          <Route
            path="/professional"
            element={
              <ProtectedRoute
                component={ProfessionalDefaultLayout}
                token={"pro_token"}
              />
            }
          >
            {EmployeeRoutes.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          <Route
            path="/employer"
            element={
              <ProtectedRoute component={Employer} token={"employer_token"} />
            }
          >
            {EmployerRoutes.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          {/* <Route path="/institute" element={<ProtectedRoute component={School} />}>
        {SchoolRoutes.map(({ path, element: Ele }, index) => (
          <Route key={index} path={path} element={Ele} />
        ))}
      </Route> */}
          <Route
            path="/college"
            element={
              <ProtectedRoute component={College} token={"college-token"} />
            }
          >
            {CollegeRoutes.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          <Route
            path="/institute"
            element={
              <ProtectedRoute component={Traning} token={"institute_token"} />
            }
          >
            {TrainingInstituteRoutes.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          <Route
            path="/vendor"
            element={<ProtectedRoute component={Vendor} token={"token"} />}
          >
            {VendorRouter.map(({ path, element: Ele }, index) => (
              <Route key={index} path={path} element={Ele} />
            ))}
          </Route>
          {/* <Route path="" element={<Joblayout />}>
          {JobRoutes.map(({ path, element: Ele }, index) => (
            <Route key={index} path={path} element={Ele} />
          ))}
        </Route> */}
          <Route path="/users/:id/verify/:token" element={<EmailVerify />} />
        </Routes>
      </ThemeProvider>
    </Suspense>
  );
};
export default App;

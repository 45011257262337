import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate, useLocation } from "react-router-dom";
import Api from '../../../Api';
import "./AddNewSubCategory.css";

function SubCategory() {
  const [sectors, setSectors] = useState([]);
  const [sectorId, setSectorId] = useState(null);
  const [domains, setDomains] = useState([]);
  const [domainId, setDomainId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState('');
  const [message, setMessage] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const { categoryName, sectorName, domainName, subCategoryName: initialSubCategoryName, selectedSubCategoryId } = location.state || {};

  useEffect(() => {
    Api.get('/coursesector/getsector')
      .then((res) => setSectors(res.data.map((sector) => ({ value: sector._id, label: sector.sectorName }))))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (sectorId) {
      Api.get(`/coursedomain/getdomain/${sectorId}`)
        .then((res) => setDomains(res.data.map((domain) => ({ value: domain._id, label: domain.domainName }))))
        .catch((err) => console.error(err));
    } else {
      setDomains([]);
    }
  }, [sectorId]);

  useEffect(() => {
    if (domainId) {
      Api.get(`/coursecategory/getcategory/${domainId}`)
        .then((res) => setCategories(res.data.map((category) => ({ value: category._id, label: category.categoryName }))))
        .catch((err) => console.error(err));
    } else {
      setCategories([]);
    }
  }, [domainId]);
  useEffect(() => {
    if (sectorName) {
      const selectedSector = sectors.find((sector) => sector.label === sectorName);
      if (selectedSector) {
        setSectorId(selectedSector.value);
      }
    }
  }, [sectorName, sectors]);

  useEffect(() => {
    if (sectorId) {
      Api.get(`/coursedomain/getdomain/${sectorId}`)
        .then((res) => {
          setDomains(res.data.map((domain) => ({ value: domain._id, label: domain.domainName })));
          if (domainName) {
            const selectedDomain = res.data.find((domain) => domain.domainName === domainName);
            if (selectedDomain) {
              setDomainId(selectedDomain._id);
            }
          }
        })
        .catch((err) => console.error(err));
    }
  }, [sectorId, domainName]);


  useEffect(() => {
    if (domainId) {
      Api.get(`/coursecategory/getcategory/${domainId}`)
        .then((res) => {
          setCategories(res.data.map((category) => ({ value: category._id, label: category.categoryName })));
          if (categoryName) {
            const selectedCategory = res.data.find((category) => category.categoryName === categoryName);
            if (selectedCategory) {
              setCategoryId(selectedCategory._id);
            }
          }
        })
        .catch((err) => console.error(err));
    }
  }, [domainId, categoryName]);

  useEffect(() => {
    if (initialSubCategoryName) {
      setSubCategoryName(initialSubCategoryName);
    }
  }, [initialSubCategoryName]);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (sectorId && domainId && categoryId && subCategoryName) {
      const selectedSector = sectors.find(sector => sector.value === sectorId);
      const selectedDomain = domains.find(domain => domain.value === domainId);
      const selectedCategory = categories.find(category => category.value === categoryId);
      const formData = {
        subCategoryName,
        categoryName: selectedCategory ? selectedCategory.label : "",
        domainName: selectedDomain ? selectedDomain.label : "",
        sectorName: selectedSector ? selectedSector.label : "",
        categoryId,
      };
  
      console.log('Form Data:', formData);
  
      if (selectedSubCategoryId) {
        // Update subcategory
        Api.put(`/coursesubcategory/${selectedSubCategoryId}`, formData)
          .then((res) => {
            setMessage('Sub-Category updated successfully.');
            setTimeout(() => {
              navigate('/admin/subcategory');
            }, 2000);
          })
          .catch((err) => {
            console.error('Error updating:', err.response || err.message);
            setMessage('Error updating sub-category.');
          });
      } else {
        // Create subcategory
        Api.post('/coursesubcategory/createsubcategory', formData)
          .then((res) => {
            setSubCategoryName('');
            setMessage('Sub-Category added successfully.');
            setTimeout(() => {
              navigate('/admin/subcategory');
            }, 2000);
          })
          .catch((err) => {
            console.error('Error creating:', err.response || err.message);
            setMessage('Error adding sub-category.');
          });
      }
    } else {
      setMessage('Please complete all fields before submitting.');
    }
  };
  
  
  return (
    <div className="subcategory-form-container">
      <h2 className="subcategory-form-title">{selectedSubCategoryId ? 'Edit Sub-Category' : 'Add Sub-Category'}</h2>
      <div className="Blog-form p-2 ms-3">
        <form onSubmit={handleSubmit}>
          <Row className="subcategory-form-row">
            <Col md={12} lg={6} className="subcategory-form-col">
              <label className="subcategory-form-label">Select Sector</label>
              <Select
                isSearchable={true}
                options={sectors}
                onChange={(selectedOption) => setSectorId(selectedOption?.value)}
                placeholder="Select Sector"
                value={sectors.find((option) => option.value === sectorId) || null}
              />
            </Col>
            <Col md={12} lg={6} className="subcategory-form-col">
              <label className="subcategory-form-label">Select Domain</label>
              <Select
                options={domains}
                onChange={(selectedOption) => setDomainId(selectedOption?.value)}
                placeholder="Select Domain"
                isDisabled={!sectorId}
                value={domains.find((option) => option.value === domainId) || null}
              />
            </Col>
            <Col md={12} lg={6} className="subcategory-form-col">
              <label className="subcategory-form-label">Select Category</label>
              <Select
                options={categories}
                onChange={(selectedOption) => setCategoryId(selectedOption?.value)}
                placeholder="Select Category"
                isDisabled={!domainId}
                value={categories.find((option) => option.value === categoryId) || null}
              />
            </Col>
            <Col md={12} lg={6} className="subcategory-form-col">
              <label className="subcategory-form-label">Sub-Category Name</label>
              <input
                type="text"
                style={{ height: "50px" }}
                value={subCategoryName}
                onChange={(e) => setSubCategoryName(e.target.value)}
                placeholder="Enter Sub-Category Name"
                disabled={!categoryId}
              />
            </Col>
          </Row>
          <div className="submitbuttons p-2">
            <button
              className="button1 m-2 p-2"
              type="submit"
              disabled={!sectorId || !domainId || !categoryId || !subCategoryName}
            >
              {selectedSubCategoryId ? 'Update' : 'Submit'}
            </button>
            <button
              className="button2 m-2 p-2"
              type="reset"
              onClick={() => {
                setSectorId(null);
                setDomainId(null);
                setCategoryId(null);
                setSubCategoryName('');
                setMessage('');
              }}
            >
              Reset
            </button>
          </div>
        </form>
        {message && <div className="subcategory-form-message">{message}</div>}
      </div>
    </div>
  );
}

export default SubCategory;

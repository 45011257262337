import React, { useState } from 'react';
import DataListTable from '../DataListTable';
import SchoolListActions from './SchoolListActions';
import UsersTab from '../AdminSidebar/Users/UsersTab';


const SchoolApprovalList = () => {
    const [filteredData, setFilteredData] = useState([
        {
            key: 1,
            name: "xxx",
            phno: 854365346476
          },
          {
            key: 2,
            name: "yyy",
            phno: 854365346476
          },
          {
              key: 3,
              name: "zzz",
              phno: 854365346476
            },
    ])
    
    const handleSearch = e => {
        const name = e.target.value;
        const newData = filteredData.filter(list => list.name.toLowerCase().includes(name.toLowerCase()));
        setFilteredData(newData);
        if (name === "") {
            setFilteredData(filteredData);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Phone Number",
            dataIndex: "phno",
        },
        {
            title: "Action",
            dataIndex: "",
            key: "x",
            render: (_, record) => (<SchoolListActions record={record} />),
        },
    ];

    return (
        <div>
        <UsersTab/>
        <DataListTable
            showAddNewBtn={false}
            columns={columns}
            dataList={filteredData}
            handleSearch={handleSearch}
            title="School List"
        />
        </div>
    );
};

export default SchoolApprovalList;
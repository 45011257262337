// import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
// import { Row, Col, Radio } from "antd";
// import { Container } from "react-bootstrap";
// import Api from "../../../Api";
// import { Dropdown } from "primereact/dropdown";

// function AddDetails() {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     getValues,
//     setValue,
//   } = useForm({});

//   const [experienceChecked, setExperienceChecked] = useState(false);
//   const [notice, setNotice] = useState();
//   const [noticeList, setNoticeList] = useState([]);
//   const [isInterested, setIsInterested] = useState("");
//   const userId = localStorage.getItem("userId");

//   useEffect(() => {
//     getNoticePeriod();
//   }, []);

//   const getNoticePeriod = async () => {
//     const res = await Api.get("noticeperiod/getForm");
//     setNoticeList(res.data.data);
//   };

//   const handleFormSubmit = async (data) => {
//     const Details = {
//       ...data,
//       Details: {
//         ...data.Details,
//       isInterested: isInterested,
//     },
//     };

//     const resp = await Api.put(`employee/Employee_update/${userId}`, Details);
//     console.log("resp.data", resp.data);
//   };

//   const noticeOptions =
//     noticeList.map((list) => ({
//       label: list?.noticePeriod,
//       value: list?.noticePeriod,
//     }));

//   return (
//     <div>
//       <h5 className="mt-3">
//         <input
//           type="checkbox"
//           onChange={(e) => setExperienceChecked(e.target.checked)}
//         />
//         &nbsp;&nbsp;&nbsp;Experience Candidates
//       </h5>
//       {experienceChecked && (
//         <div>
//           <form onSubmit={handleSubmit(handleFormSubmit)}>
//             <Container>
//               <Row className="ms-5 mt-5">
//                 <Col sm={24} md={8} xs={24} className="px-4">
//                   <label className="input-title">Company Name</label>
//                   <br />
//                   <input
//                     className="Professional__UpdateProfile__Input"
//                     {...register("Details.companyName", { required: true })}
//                   />
//                   {errors.companyName && (
//                     <span className="input-error">Company Name is required</span>
//                   )}
//                 </Col>
//                 <Col sm={24} md={8} xs={24} className="px-4">
//                   <label className="input-title">Designation</label>
//                   <br />
//                   <input
//                     className="Professional__UpdateProfile__Input"
//                     {...register("Details.designation", { required: true })}
//                   />
//                   {errors.designation && (
//                     <span className="input-error">Designation is required</span>
//                   )}
//                 </Col>
//                 <Col sm={24} md={8} xs={24} className="px-4">
//                   <label className="input-title">From date </label>
//                   <input
//                     className="Professional__UpdateProfile__Input"
//                     type="date"
//                     {...register("Details.fromDate", { required: true })}
//                   />
//                   {errors.fromDate && (
//                     <span className="input-error">From Date is required</span>
//                   )}
//                 </Col>
//                 <Col sm={24} md={8} xs={24} className="px-4 mt-3">
//                   <label className="input-title">To date </label>
//                   <input
//                     className="Professional__UpdateProfile__Input"
//                     type="date"
//                     {...register("Details.toDate", { required: true })}
//                   />
//                   {errors.toDate && (
//                     <span className="input-error">To Date is required</span>
//                   )}
//                 </Col>
//                 <Col sm={24} md={8} xs={24} className="px-4 mt-3">
//                   <label className="input-title">Current Salary</label>
//                   <br />
//                   <input
//                     className="Professional__UpdateProfile__Input"
//                     {...register("Details.currentSalary", { required: true })}
//                   />
//                   {errors.currentSalary && (
//                     <span className="input-error">Current Salary is required</span>
//                   )}
//                 </Col>
//                 <Col sm={24} md={8} xs={24} className="px-4 mt-3">
//                   <label className="input-title">Expected Salary</label>
//                   <br />
//                   <input
//                     className="Professional__UpdateProfile__Input"
//                     {...register("Details.expectedSalary", { required: true })}
//                   />
//                   {errors.expectedSalary && (
//                     <span className="input-error">Expected Salary is required</span>
//                   )}
//                 </Col>
//                 <Col sm={24} md={8} xs={24} className="px-4 mt-3">
//                   <label>Notice Period</label>
//                   <br />
//                   <Dropdown
//                     name="noticePeriod"
//                     className="input-field"
//                     value={notice}
//                     options={noticeOptions}
//                     placeholder="Select a Notice Period"
//                     {...register("Details.noticePeriod", {
//                       required: true,
//                       onChange: (e) => {
//                         setNotice(e.target.value);
//                       },
//                     })}
//                   />
//                   <br />
//                   {errors.noticePeriod && (
//                     <p className="text-danger">Notice Period is required</p>
//                   )}
//                 </Col>
//                 <Col sm={24} md={8} xs={24} className="px-4 mt-3">
//                   <label>Reason to Change the job</label>
//                   <textarea
//                     rows="3"
//                     className="Stud-blog-input"
//                     {...register("Details.reason", { required: true })}
//                   />
//                   {errors.reason && (
//                     <span className="input-error">Reason is required</span>
//                   )}
//                 </Col>
//               </Row>
//               <Row className="ms-5 mt-5">
//                 <Col sm={24} md={8} xs={24} className="px-4">
//                   <h5>Are you interested in taking a class or seminar?</h5>
//                   <Radio.Group
//                     onChange={(e) => setIsInterested(e.target.value)}
//                     value={isInterested}
//                   >
//                     <Radio value="yes">Yes</Radio>
//                     <Radio value="no">No</Radio>
//                   </Radio.Group>
//                 </Col>
//               </Row>
//             </Container>
//             <div className="submitbuttons p-2">
//               <button className="button1 m-2 p-2" type="submit">
//                 Submit
//               </button>
//               <button className="button2 m-2 p-2" type="reset">
//                 Reset
//               </button>
//             </div>
//           </form>
//         </div>
//       )}
//       <h5 className="mt-3">
//         <input
//           type="checkbox"
//           onChange={(e) => setExperienceChecked(false)}
//         />
//         &nbsp;&nbsp;&nbsp;Students/Fresher
//       </h5>
//     </div>
//   );
// }

// export default AddDetails;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Radio } from "antd";
import { Container } from "react-bootstrap";
import Api from "../../../Api";
import { Dropdown } from "primereact/dropdown";

function AddDetails() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [experienceChecked, setExperienceChecked] = useState(false);
  console.log("experienceChecked", experienceChecked);
  const [notice, setNotice] = useState();
  const [noticeList, setNoticeList] = useState([]);
  console.log("object", noticeList);
  const [isInterested, setIsInterested] = useState("");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    getNoticePeriod();
    fetchUserDetails();
  }, []);

  const getNoticePeriod = async () => {
    const res = await Api.get("noticeperiod/getForm");
    setNoticeList(res.data.data);
  };

  const fetchUserDetails = async () => {
    try {
      const res = await Api.get(`employeeUser/employee_getone/${userId}`);
      const userData = res.data.data;

      if (userData?.Details) {
        const details = userData.Details;
        // Prefill the form fields
        setValue("Details.companyName", details.companyName);
        setValue("Details.designation", details.designation);
        setValue("Details.fromDate", details.fromDate.split("T")[0]); // Format date
        setValue("Details.toDate", details.toDate.split("T")[0]); // Format date
        setValue("Details.currentSalary", details.currentSalary);
        setValue("Details.expectedSalary", details.expectedSalary);
        setValue("Details.noticePeriod", details.noticePeriod);
        setValue("Details.reason", details.changeJob || ""); // Optional field
        setIsInterested(details.isInterested);
        setNotice(details.noticePeriod);
        setExperienceChecked(details.experienceCheckBox);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleFormSubmit = async (data) => {
    const Details = {
      ...data,
      Details: {
        ...data.Details,
        isInterested: isInterested,
        experienceCheckBox: experienceChecked,
      },
    };

    const resp = await Api.put(`employee/Employee_update/${userId}`, Details);
    console.log("resp.data", resp.data);
  };

  const noticeOptions = noticeList.map((list) => ({
    label: list?.noticePeriod,
    value: list?.noticePeriod,
  }));

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <h5 className="mt-3">
          <input
            type="checkbox"
            checked={experienceChecked}
            onChange={(e) => setExperienceChecked(e.target.checked)}
          />
          &nbsp;&nbsp;&nbsp;Experience Candidates
        </h5>
        {experienceChecked && (
          <div>
            <Container>
              <Row className="ms-5 mt-5">
                <Col sm={24} md={8} xs={24} className="px-4">
                  <label className="input-title">Company Name</label>
                  <br />
                  <input
                    className="Professional__UpdateProfile__Input"
                    {...register("Details.companyName", { required: true })}
                  />
                  {errors.companyName && (
                    <span className="input-error">
                      Company Name is required
                    </span>
                  )}
                </Col>
                <Col sm={24} md={8} xs={24} className="px-4">
                  <label className="input-title">Designation</label>
                  <br />
                  <input
                    className="Professional__UpdateProfile__Input"
                    {...register("Details.designation", { required: true })}
                  />
                  {errors.designation && (
                    <span className="input-error">Designation is required</span>
                  )}
                </Col>
                <Col sm={24} md={8} xs={24} className="px-4">
                  <label className="input-title">From date </label>
                  <input
                    className="Professional__UpdateProfile__Input"
                    type="date"
                    {...register("Details.fromDate", { required: true })}
                  />
                  {errors.fromDate && (
                    <span className="input-error">From Date is required</span>
                  )}
                </Col>
                <Col sm={24} md={8} xs={24} className="px-4 mt-3">
                  <label className="input-title">To date </label>
                  <input
                    className="Professional__UpdateProfile__Input"
                    type="date"
                    {...register("Details.toDate", { required: true })}
                  />
                  {errors.toDate && (
                    <span className="input-error">To Date is required</span>
                  )}
                </Col>
                <Col sm={24} md={8} xs={24} className="px-4 mt-3">
                  <label className="input-title">Current Salary</label>
                  <br />
                  <input
                    className="Professional__UpdateProfile__Input"
                    {...register("Details.currentSalary", { required: true })}
                  />
                  {errors.currentSalary && (
                    <span className="input-error">
                      Current Salary is required
                    </span>
                  )}
                </Col>
                <Col sm={24} md={8} xs={24} className="px-4 mt-3">
                  <label className="input-title">Expected Salary</label>
                  <br />
                  <input
                    className="Professional__UpdateProfile__Input"
                    {...register("Details.expectedSalary", { required: true })}
                  />
                  {errors.expectedSalary && (
                    <span className="input-error">
                      Expected Salary is required
                    </span>
                  )}
                </Col>
                <Col sm={24} md={8} xs={24} className="px-4 mt-3">
                  <label>Notice Period</label>
                  <br />
                  <Dropdown
                    name="noticePeriod"
                    className="input-field"
                    value={notice}
                    options={noticeOptions}
                    placeholder="Select a Notice Period"
                    {...register("Details.noticePeriod", { required: true })}
                    onChange={(e) => setNotice(e.target.value)}
                  />
                  <br />
                  {errors.noticePeriod && (
                    <p className="text-danger">Notice Period is required</p>
                  )}
                </Col>
                <Col sm={24} md={8} xs={24} className="px-4 mt-3">
                  <label>Reason to Change the job</label>
                  <textarea
                    rows="3"
                    className="Stud-blog-input"
                    {...register("Details.reason", { required: true })}
                  />
                  {errors.reason && (
                    <span className="input-error">Reason is required</span>
                  )}
                </Col>
              </Row>
              <Row className="ms-5 mt-5">
                <Col sm={24} md={8} xs={24} className="px-4">
                  <h5>Are you interested in taking a class or seminar?</h5>
                  <Radio.Group
                    onChange={(e) => setIsInterested(e.target.value)}
                    value={isInterested}
                  >
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Container>
            <div className="submitbuttons p-2">
              <button className="button1 m-2 p-2" type="submit">
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </div>
        )}
        <h5 className="mt-3">
          <input
            type="checkbox"
            checked={!experienceChecked}
            onChange={(e) => setExperienceChecked(!e.target.checked)}
          />{" "}
          &nbsp;&nbsp;&nbsp;Students/Fresher
        </h5>
      </form>
    </div>
  );
}

export default AddDetails;

import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import "./ContactUs.scss";
import Layout from "../Components/Layout";
import axios from "axios";
import { MdLocationCity } from "react-icons/md";
import { GiModernCity } from "react-icons/gi";
import { RiUserLocationLine } from "react-icons/ri";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:4000/api/contactus/create",
        formData
      );
      console.log("API Response:", response);
      if (response.status === 201) {
        setResponseMessage("Message sent successfully!");
        console.log("setResponseMessage", response.data.data);
        setFormData({ name: "", email: "", message: "" });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setResponseMessage("Failed to send message. Please try again later.");
    }
  };
  return (
    <Layout>
      <Container fluid className="unique-contact-page py-5">
        <Container className="contactus-container">
          <h2 className="text-center get-in-touch mb-5">Get In Touch</h2>

          <Row className="mb-5">
            <Col md={4} className="mb-4 d-flex justify-content-center">
              <Card className="unique-contact-card">
                <Card.Body className="text-center unique-card-body">
                  <FaPhoneAlt size={40} className="mb-3 unique-contact-icon" />
                  <Card.Title>Phone</Card.Title>
                  <Card.Text className="card-text-contact">
                    +91 96065 18855
                  </Card.Text>
                  <Card.Text className="card-text-contact">
                    Mon-Fri: 9 AM - 6 PM EST
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4 d-flex justify-content-center">
              <Card className="unique-contact-card">
                <Card.Body className="text-center unique-card-body">
                  <FaEnvelope size={40} className="mb-3 unique-contact-icon" />
                  <Card.Title>Email</Card.Title>
                  <Card.Text className="card-text-contact">
                    <a href="mailto:info@careerblitz.com">
                      business@cognextech.com
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} className="mb-4 d-flex justify-content-center">
              <Card className="unique-contact-card">
                <Card.Body className="text-center unique-card-body">
                  <FaMapMarkerAlt
                    size={40}
                    className="mb-3 unique-contact-icon"
                  />
                  <Card.Title>Location</Card.Title>
                  <Card.Text className="card-text-contact">
                    <span>
                      <MdLocationCity
                        style={{
                          fontSize: "25px",
                          marginRight: "8px",
                          color: "yellow",
                        }}
                      />
                    </span>
                    F1, Jayanthy Park, 43 Srinivasa Nagar, Kandanchavadi,
                    Chennai 600096
                  </Card.Text>
                  <Card.Text className="card-text-contact">
                    <span>
                      <GiModernCity
                        style={{
                          fontSize: "25px",
                          marginRight: "8px",
                          color: "yellow",
                        }}
                      />
                    </span>{" "}
                    Hubstairs CoWorks, No 9, 2nd Floor, 27th Main, 100 Feet Ring
                    Rd, above TATA Motors, BTM 1st Stage, Bengaluru, Karnataka
                    560068
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mx-auto">
              <h4 className="text-center contact-heading mb-4">
                Send Us a Message
              </h4>
              <Form onSubmit={handleFormSubmit}>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="name">
                      <Form.Label className="contactus-label">
                        Your Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="email">
                      <Form.Label className="contactus-label">
                        Your Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        style={{ width: "80%" }}
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} style={{ marginTop: "-25px" }}>
                    <Form.Group className="mb-3" controlId="message">
                      <Form.Label className="contactus-label">
                        Your Message
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        style={{ height: "40%" }}
                        rows={5}
                        placeholder="Enter your message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-center mt-4">
                  <Button variant="primary" type="submit" className="px-5">
                    Send Message
                  </Button>
                </div>
              </Form>
              {responseMessage && (
                <p className="text-center mt-3">{responseMessage}</p>
              )}
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
};

export default ContactUs;

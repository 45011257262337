import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Card, Col } from "react-bootstrap";
import "../Vendor/Index.css";
import Api from "../../Api";
import "../../Components/Css/Professional/professionalstyle.scss";

function Advertisement() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFilePreview(URL.createObjectURL(selectedFile));
  };

  const handleAddSubmit = async () => {
    const AddDetails = {
      companyName: getValues().companyName,
      websiteLink: getValues().websiteLink,
      advertisementTag: file,
    };
    console.log("AddDetails", AddDetails);
    await Api.post(`Advertisement/createAdvertisement`, AddDetails).then(
      (resp) => {
        console.log("resp.data", resp.data);
      }
    );
  };

  return (
    <div className="container" style={{backgroundColor:'transparent', width:"85%", marginLeft:'auto',marginRight:'auto'}}>
      <div className="advertisement">
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "red",
            fontWeight: "bold",
            margin: "4%",
          }}
        >
          Create New Advertisement
        </h4>
        <Row className="gx-3 gy-3">
          <Col lg={6} md={6} xs={12}>
            <form onSubmit={handleSubmit(handleAddSubmit)}>
              <Row className="gx-3 gy-3">
                <Col lg={12} md={12} xs={12} className="px-4 py-2">
                  <label className="input-title">Company Name</label>
                  <input
                    className="Professional__UpdateProfile__Input"
                    type="text"
                    placeholder="Name"
                    {...register("companyName", { required: true })}
                  />
                  {errors.companyName && (
                    <p className="error-text-color">
                      Company Name is required
                    </p>
                  )}
                </Col>
                <Col lg={12} md={12} xs={12} className="px-4 py-2">
                  <label className="input-title">Website Link</label>
                  <input
                    className="Professional__UpdateProfile__Input"
                    type="text"
                    placeholder="Website Link"
                    {...register("websiteLink", { required: true })}
                  />
                  {errors.websiteLink && (
                    <p className="error-text-color">
                      Website Link is required
                    </p>
                  )}
                </Col>
                <Col lg={12} md={12} xs={12} className="px-4 py-2">
                  <label className="input-title">Advertisement Tag</label>
                  <input
                    className="Professional__UpdateProfile__Input"
                    type="file"
                    onChange={handleChange}
                  />
                  <p>
                    <span style={{ color: "red" }}>Note:</span> Photos must be
                    uploaded with width: 400px to 3000px & height: 400px to
                    4000px
                  </p>
                </Col>
              </Row>
              <div className="addbutton_column mt-4">
                <button className="button1" type="submit">
                  Submit
                </button>
                <button className="button2 ms-2" type="button">
                  Back
                </button>
              </div>
            </form>
          </Col>
          <Col lg={4} md={6} xs={12} className="d-flex justify-content-center align-items-center">
            {filePreview ? (
              <img
                src={filePreview}
                alt="Selected Advertisement"
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: "10px",
                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                }}
              />
            ) : (
              <p>No Image Selected</p>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Advertisement;

import React, { useState, useEffect } from "react";
import "../Skilltest/skilltest.scss";
import { Row, Col, Card, Rate, Button, Tabs } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import Api from "../../../Api";
import "./Learning.scss";

function ApplicationStatus() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
          console.error("User ID not found in localStorage.");
          return;
        }

        const response = await Api.get(`course/users/${userId}/appliedcourses`);
        const validCourses = response.data.filter(
          (course) =>
            course.courseId && course.courseId._id && course.courseId.title
        );
        setCourses(validCourses);
      } catch (error) {
        console.error("Error fetching applied courses:", error);
      }
    };

    fetchCourses();
  }, []);
  const filterCoursesByStatus = (status) => {
    return courses.filter((course) => course.status === status);
  };

  const items = [
    {
      key: "1",
      label: "Applied",
      children: (
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            {filterCoursesByStatus("Applied").map((course) => (
              <div key={course._id}>
                <Card
                  style={{
                    width: "100%",
                    height: "auto",
                    marginTop: "10px",
                    border: "none",
                  }}
                  // onClick={() => handleCardClick(course._id)}
                  className="courses-card"
                >
                  <Row>
                    <Col xs={24} sm={7} md={6} lg={5}>
                      <div>
                        <img
                          src={course.courseId.image || ""}
                          alt={course.courseId.title}
                          style={{
                            width: "100%",
                            height: "auto",
                            maxHeight: "175px",
                          }}
                          data-aos="flip-left"
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={14} md={13} lg={15}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0",
                          padding: "0 10px",
                        }}
                      >
                        <b style={{ fontSize: "18px", margin: "0" }}>
                          {course.courseId.title}
                        </b>
                        <p
                          className="description-clamp"
                          style={{ margin: "0", fontSize: "15px" }}
                        >
                          {course.courseId.description}
                        </p>
                        <p style={{ margin: "0px" }}>{course.classTime?.[0]}</p>
                        <p style={{ margin: "0" }}>
                          <b>{course.courseId.rating}</b>
                          <Rate
                            disabled
                            allowHalf
                            defaultValue={course.courseId.rating}
                            style={{ margin: "0" }}
                            className="course-rating"
                          />
                        </p>
                        {course.courseId.bestSeller && (
                          <p style={{ color: "green", fontWeight: "bold" }}>
                            Best Seller
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col xs={24} sm={3} md={5} lg={4}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          paddingTop: "10px",
                        }}
                      >
                        <p style={{ margin: "0" }}>
                          <b>{course.courseId.offer_price}</b>
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24} style={{ marginTop: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          type="default"
                          style={{
                            width: "auto",
                            backgroundColor:
                              course.status === "Applied"
                                ? "#0092ff"
                                : course.status === "Rejected"
                                ? "red"
                                : "#28a745",
                            borderColor:
                              course.status === "Applied"
                                ? "#0092ff"
                                : course.status === "Rejected"
                                ? "red"
                                : "#28a745",
                            color: "white",
                          }}
                        >
                          {course.status}
                        </Button>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "12px",
                            color: "gray",
                          }}
                        >
                          Applied on:{" "}
                          {new Date(course.appliedDate).toLocaleDateString()}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: "Approved",
      children: (
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            {filterCoursesByStatus("Approved").map((course) => (
              <div key={course._id}>
                <Card
                  style={{
                    width: "100%",
                    height: "auto",
                    marginTop: "10px",
                    border: "none",
                  }}
                  // onClick={() => handleCardClick(course._id)}
                  className="courses-card"
                >
                  <Row>
                    <Col xs={24} sm={7} md={6} lg={5}>
                      <div>
                        <img
                          src={course.courseId.image || ""}
                          alt={course.courseId.title}
                          style={{
                            width: "100%",
                            height: "auto",
                            maxHeight: "175px",
                          }}
                          data-aos="flip-left"
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={14} md={13} lg={15}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0",
                          padding: "0 10px",
                        }}
                      >
                        <b style={{ fontSize: "18px", margin: "0" }}>
                          {course.courseId.title}
                        </b>
                        <p
                          className="description-clamp"
                          style={{ margin: "0", fontSize: "15px" }}
                        >
                          {course.courseId.description}
                        </p>
                        <p style={{ margin: "0px" }}>{course.classTime?.[0]}</p>
                        <p style={{ margin: "0" }}>
                          <b>{course.courseId.rating}</b>
                          <Rate
                            disabled
                            allowHalf
                            defaultValue={course.courseId.rating}
                            style={{ margin: "0" }}
                            className="course-rating"
                          />
                        </p>
                        {course.courseId.bestSeller && (
                          <p style={{ color: "green", fontWeight: "bold" }}>
                            Best Seller
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col xs={24} sm={3} md={5} lg={4}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          paddingTop: "10px",
                        }}
                      >
                        <p style={{ margin: "0" }}>
                          <b>{course.courseId.offer_price}</b>
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24} style={{ marginTop: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          type="default"
                          style={{
                            width: "auto",
                            backgroundColor:
                              course.status === "Applied"
                                ? "#0092ff"
                                : course.status === "Rejected"
                                ? "red"
                                : "#28a745",
                            borderColor:
                              course.status === "Applied"
                                ? "#0092ff"
                                : course.status === "Rejected"
                                ? "red"
                                : "#28a745",
                          }}
                        >
                          {course.status}
                        </Button>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "12px",
                            color: "gray",
                          }}
                        >
                          Applied on:{" "}
                          {new Date(course.appliedDate).toLocaleDateString()}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
          </Col>
        </Row>
      ),
    },
    {
      key: "3",
      label: "Rejected",
      children: (
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            {filterCoursesByStatus("Rejected").map((course) => (
              <div key={course._id}>
                <Card
                  style={{
                    width: "100%",
                    height: "auto",
                    marginTop: "10px",
                    border: "none",
                  }}
                  // onClick={() => handleCardClick(course._id)}
                  className="courses-card"
                >
                  <Row>
                    <Col xs={24} sm={7} md={6} lg={5}>
                      <div>
                        <img
                          src={course.courseId.image || " "}
                          alt={course.courseId.title}
                          style={{
                            width: "100%",
                            height: "auto",
                            maxHeight: "175px",
                          }}
                          data-aos="flip-left"
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={14} md={13} lg={15}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0",
                          padding: "0 10px",
                        }}
                      >
                        <b style={{ fontSize: "18px", margin: "0" }}>
                          {course.courseId.title}
                        </b>
                        <p
                          className="description-clamp"
                          style={{ margin: "0", fontSize: "15px" }}
                        >
                          {course.courseId.description}
                        </p>
                        <p style={{ margin: "0px" }}>{course.classTime?.[0]}</p>
                        <p style={{ margin: "0" }}>
                          <b>{course.courseId.rating}</b>
                          <Rate
                            disabled
                            allowHalf
                            defaultValue={course.courseId.rating}
                            style={{ margin: "0" }}
                            className="course-rating"
                          />
                        </p>
                        {course.courseId.bestSeller && (
                          <p style={{ color: "green", fontWeight: "bold" }}>
                            Best Seller
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col xs={24} sm={3} md={5} lg={4}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "14px",
                          paddingTop: "10px",
                        }}
                      >
                        <p style={{ margin: "0" }}>
                          <b>{course.courseId.offer_price}</b>
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24} style={{ marginTop: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          type="default"
                          style={{
                            width: "auto",
                            backgroundColor:
                              course.status === "Applied"
                                ? "#0092ff"
                                : course.status === "Rejected"
                                ? "red"
                                : "#28a745",
                            borderColor:
                              course.status === "Applied"
                                ? "#0092ff"
                                : course.status === "Rejected"
                                ? "red"
                                : "#28a745",
                          }}
                        >
                          {course.status}
                        </Button>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "12px",
                            color: "gray",
                          }}
                        >
                          Applied on:{" "}
                          {new Date(course.appliedDate).toLocaleDateString()}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
          </Col>
        </Row>
      ),
    },
  ];

  return <Tabs centered items={items} />;
}

export default ApplicationStatus;

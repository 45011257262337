import React from "react";
import QuestionBank from "../../../../Exam/QuestionBank/QuestionBank";
import ExamPatternList from "../../../../Exam/Exampattern/ExamPattern";
import Curriculam from "../../../../Exam/Curriculam/Curriculam";
import CourseList from "../../../../Exam/Course/Course";
import TrainerList from "../../../../Exam/Trainer/Trainer";
import BatchList from "../../../../Exam/Batch/BatchList";
import AppliedStudents from "../../../../Exam/AplliedStudents";
import { NavLink, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../Users/UsersTab.css";

function ExamsTabs() {
  const basePath = "/admin";

  const tabs = [
    {
      id: 4,
      name: "Course",
      path: `${basePath}/examcourse`,
      component: CourseList,
    },
    {
      id: 3,
      name: "Curriculam",
      path: `${basePath}/curriculamcourse`,
      component: Curriculam,
    },
    {
      id: 6,
      name: "Batch List",
      path: `${basePath}/Batch`,
      component: BatchList,
    },
    {
      id: 2,
      name: "Exam Pattern",
      path: `${basePath}/exampattern`,
      component: ExamPatternList,
    },

    {
      id: 1,
      name: "Questionbank",
      path: `${basePath}/Question`,
      component: QuestionBank,
    },
    // {
    //   id: 5,
    //   name: "Trainer",
    //   path: `${basePath}/trainer`,
    //   component: TrainerList,
    // },
    // {
    //   id: 7,
    //   name: "View Apllied",
    //   path: `${basePath}/aplliedcourses`,
    //   component: AppliedStudents,
    // },
  ];

  return (
    <div>
      <div className="userstab-container">
        <div className="tabs">
          {tabs.map((tab) => (
            <NavLink
              key={tab.id}
              to={tab.path}
              className="tab-link"
              style={({ isActive }) => ({
                backgroundColor: isActive ? "#007BFF" : "#f0f0f0",
                color: isActive ? "white" : "#007BFF",
              })}
            >
              {tab.name}
            </NavLink>
          ))}
        </div>
        <Routes>
          {tabs.map((tab) => (
            <Route key={tab.id} path={tab.path} element={<tab.component />} />
          ))}
        </Routes>
      </div>
    </div>
  );
}

export default ExamsTabs;

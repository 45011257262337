// import React, { useState, useEffect } from "react";
// import { Tabs, Spin, message } from "antd"; // Added Spin for loading
// import { useForm } from "react-hook-form";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import Api from "../../../Api"; // Assuming you're handling your API requests here
// import "./Exam.css";

// function Exam() {
//   const [questionBank, setQuestionBank] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const {
//     register,
//     handleSubmit,
//     getValues,
//     formState: { errors },
//   } = useForm();

//   // Fetch Question Bank
//   const getQuestionBank = async () => {
//     try {
//       const response = await Api.get("QuestionBank/get");
//       setQuestionBank(response.data); // Assuming API returns an array of questions
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching question bank:", error);
//       message.error("Failed to fetch questions. Please try again.");
//       setLoading(false);
//     }
//   };

//   const [examData, setExamData] = useState([]);
//   const questionId = localStorage.getItem("questionId");

//   const getDetails = async () => {
//     try {
//       const res = await Api.get(`QuestionBank/get/${questionId}`);
//       setExamData(res.data.data);
//     } catch (error) {
//       console.error("Error fetching exam details:", error);
//       message.error("Failed to fetch exam details.");
//     }
//   };

//   // Handle form submission
//   const onSubmit = async () => {
//     const details = {
//       ...examData,
//       Technology: getValues().Technology,
//       question: getValues().question,
//       quest: getValues().quest,
//       choice: getValues().choice,
//       option: getValues().option,
//       correctOption: getValues().correctOption,
//       week: getValues().week,
//     };
//     try {
//       const response = await Api.post("QuestionBank/createSchoolList", details);
//       message.success("Form submitted successfully!");
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       message.error("Submission failed. Please try again.");
//     }
//   };

//   // Initialize AOS and fetch questions on component mount
//   useEffect(() => {
//     AOS.init({
//       duration: 1000,
//       easing: "ease-in-out",
//       delay: 300,
//     });
//     getQuestionBank();
//   }, []);

//   // Rendering individual week tabs
//   const renderWeekTabContent = (week) => {
//     const filteredQuestions = questionBank.filter((q) => q.week === week);
//     if (loading) {
//       return <Spin size="large" />;
//     }
//     return (
//       <ul>
//         {filteredQuestions.length ? (
//           filteredQuestions.map((question) => (
//             <li key={question.question_id}>{question.question}</li>
//           ))
//         ) : (
//           <p>No questions available for Week {week}</p>
//         )}
//       </ul>
//     );
//   };

//   return (
//     <>
//       <div className="exam_head">
//         <h3>Full Stack Developer</h3>
//       </div>

//       <div>
//         {/* Tabs for Weekly Content */}
//         <Tabs
//           defaultActiveKey="1"
//           centered
//           items={new Array(6).fill(null).map((_, i) => {
//             const id = String(i + 1);
//             return {
//               label: `Week ${id}`,
//               key: id,
//               children: (
//                 <>
//                   <h4>Questions for Week {id}</h4>
//                   {renderWeekTabContent(id)}
//                 </>
//               ),
//             };
//           })}
//         />
//       </div>
//     </>
//   );
// }

// export default Exam;
import React, { useState, useEffect } from "react";
import { Tabs, Spin, message } from "antd";
import Api from "../../../Api";
import "./Exam.css";

function Exam() {
  const [questionBank, setQuestionBank] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTechnology, setSelectedTechnology] = useState("React"); // Default technology
  const [courseWeeks, setCourseWeeks] = useState(6); // Default number of weeks for a course

  // Fetch question bank based on selected technology and week
  const getQuestionBank = async (week) => {
    try {
      setLoading(true);
      const response = await Api.get("QuestionBank/getWeek", {
        Technology: selectedTechnology, 
        week: week,
      });
      console.log("responsesss", response)
      setQuestionBank(response.data); // Assuming API returns an array of questions
      setLoading(false);
    } catch (error) {
      console.error("Error fetching question bank:", error);
      message.error("Failed to fetch questions. Please try again.");
      setLoading(false);
    }
  };

  // Fetch questions on component mount for the first week
  useEffect(() => {
    getQuestionBank(1); // Fetch for week 1 initially
  }, [selectedTechnology]);

  // Rendering individual week tabs
  const renderWeekTabContent = (week) => {
    const filteredQuestions = questionBank.filter((q) => q.week === week);

    if (loading) {
      return <Spin size="large" />;
    }

    return (
      <ul>
        {filteredQuestions.length ? (
          filteredQuestions.map((question) => (
            <li key={question._id}>{question.question}</li>
          ))
        ) : (
          <p>No questions available for Week {week}</p>
        )}
      </ul>
    );
  };

  return (
    <>
      <div className="exam_head">
        <h3>Full Stack Developer - {selectedTechnology} Course</h3>
      </div>
<div className="row exam-week">
      <div className="col-11 col-sm-10 col-md-8">
        {/* Tabs for Weekly Content */} 
        <Tabs
          defaultActiveKey="1"
          centered
          onChange={(activeKey) => getQuestionBank(Number(activeKey))} // Fetch questions for selected week
          items={new Array(courseWeeks).fill(null).map((_, i) => {
            const id = String(i + 1);
            return {
              label: `Week ${id}`,
              key: id,
              children: (
                <>
                  <h4>Questions for Week {id}</h4>
                  {renderWeekTabContent(Number(id))}
                </>
              ),
            };
          })}
        />
      </div>
      </div>
    </>
  );
}

export default Exam;

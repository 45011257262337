import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Api from '../../../Api';
import './AddCategory.css';
import "../../Exam/Technology/Technology.scss";
import { Container } from 'react-bootstrap';

function AddCategory() {
  const location = useLocation();
  const { categoryName, sectorName, domainName, selectedCategoryId } = location.state || {};
  const [categories, setCategories] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [sectorId, setSectorId] = useState(null);
  const [domains, setDomains] = useState([]);
  const [domainId, setDomainId] = useState(null);
  const [categoryNameState, setCategoryName] = useState(categoryName || '');
  const [sectorNameState, setSectorName] = useState(sectorName || '');
  const [domainNameState, setDomainName] = useState(domainName || '');

  useEffect(() => {
    Api.get('/coursesector/getsector')
      .then(res => {
        const sectorOptions = res.data.map(sector => ({ value: sector._id, label: sector.sectorName }));
        setSectors(sectorOptions);

        if (sectorName) {
          const selectedSector = sectorOptions.find(sector => sector.label === sectorName);
          if (selectedSector) {
            setSectorId(selectedSector.value);
          }
        }
      })
      .catch(err => console.error(err));
  }, [sectorName]);

  useEffect(() => {
    if (sectorId) {
      Api.get(`/coursedomain/getdomain/${sectorId}`)
        .then(res => {
          const domainOptions = res.data.map(domain => ({ value: domain._id, label: domain.domainName }));
          setDomains(domainOptions);

          if (domainName) {
            const selectedDomain = domainOptions.find(domain => domain.label === domainName);
            if (selectedDomain) {
              setDomainId(selectedDomain.value);
            }
          }
        })
        .catch(err => console.error(err));
    }
  }, [sectorId, domainName]);

  useEffect(() => {
    if (domainId) {
      Api.get(`/coursecategory/getcategory/${domainId}`)
        .then(res => setCategories(res.data))
        .catch(err => console.error(err));
    }
  }, [domainId]);

  const saveCategory = async (e) => {
    e.preventDefault();

    if (sectorId && domainId && categoryNameState) {
      try {
        if (selectedCategoryId) {
          await Api.put(`/coursecategory/updateoneCategory/${selectedCategoryId}`, {
            domainId,
            domainName: domainNameState,
            sectorName: sectorNameState,
            categoryName: categoryNameState,
          });
          alert('Category updated successfully!');
        } else {
          const res = await Api.post('/coursecategory/createcategory', {
            domainId,
            domainName: domainNameState,
            sectorName: sectorNameState,
            categoryName: categoryNameState,
          });
          setCategories([...categories, res.data]);
          alert('Category added successfully!');
        }
        setCategoryName('');
        setSectorName('');
        setDomainName('');
      } catch (error) {
        console.error(error);
        alert('Error occurred while saving the category.');
      }
    } else {
      alert('Please select sector, domain, and enter category name.');
    }
  };

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      opacity: state.isFocused ? 1 : 0,
    }),
    control: (provided) => ({
      ...provided,
      cursor: 'text',
    }),
  };

  return (
    <div className='mt-4'>
      <Container style={{ width: '90%', padding: '20px', margin: '20px' }}>
        <h2>{selectedCategoryId ? 'Edit Category' : 'Add Category'}</h2>
        <form onSubmit={saveCategory}>
          <div className="addcategory-container row px-2" style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto' }}>
            <Select
              className='col-lg-4'
              options={sectors}
              onChange={(selectedOption) => {
                setSectorId(selectedOption?.value);
                setSectorName(selectedOption?.label);
              }}
              placeholder="Select Sector"
              styles={customStyles}
              value={sectors.find(sector => sector.label === sectorNameState)}
            />

            <Select
              className='col-lg-4'
              options={domains}
              onChange={(selectedOption) => {
                setDomainId(selectedOption?.value);
                setDomainName(selectedOption?.label);
              }}
              placeholder="Select Domain"
              styles={customStyles}
              value={domains.find(domain => domain.label === domainNameState)}
            />

            <input
              type="text"
              style={{ width: 'auto' }}
              value={categoryNameState}
              onChange={e => setCategoryName(e.target.value)}
              placeholder="Enter Category Name"
              className="col-lg-4 category-input"
            />
          </div>
          <div className="px-2" style={{ display: 'flex', paddingBottom: '20px', justifyContent: 'center' }}>
            <button
              type="submit"
              style={{ width: 'fit-content' }}
              className="add-button"
            >
              {selectedCategoryId ? 'Update Category' : 'Add Category'}
            </button>
          </div>
        </form>
      </Container>
    </div>
  );
}

export default AddCategory;




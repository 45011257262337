import React from "react";
import "../../../Components/Css/Employer/projectpost.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Api from "../../../Api";
import { ToastContainer, toast } from 'react-toastify'; // Import Toast components
import 'react-toastify/dist/ReactToastify.css'; // Import Toast styles

function CategoryAdded() {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const handleCreateFormSubmit = async () => {
    const userDetails = {
      categoryName: getValues().categoryName,
    };

    try {
      const resp = await Api.post(`vendor/createCategory`, userDetails);
      console.log("resp.data", resp.data);
      toast.success("Category created successfully!"); // Show success toast
      reset(); // Reset the form after successful submission
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("Failed to create category. Please try again."); // Show error toast
    }
  };

  return (
    <div>
      <Container className="content-style" style={{ width: '90%' }}>
        <Row >
          <form onSubmit={handleSubmit(handleCreateFormSubmit)}>
            <div className="pages-title mt-3">Add Category</div>
            <Row className="mt-2" >
              <Col xs={12} sm={12} md={12} lg={6} className="px-5 py-3">
                <label className="create-title">Category Name</label>
                <br />
                <input
                  {...register("categoryName", { required: true })}
                  placeholder="Enter Category Name"
                  className="Create-input"
                />
                {errors.categoryName && (
                  <p className="error-text-color">Category Name is required</p>
                )}
              </Col>
            </Row>
            <div className="submitbuttons px-5 py-2 d-flex " style={{ justifyContent: 'flex-start' }}>
              <button className="button1 m-2 p-2" type="submit">
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </form>
          <ToastContainer position="top-right" autoClose={3000} /> {/* Add ToastContainer */}
        </Row>
      </Container>
    </div>
  );
}

export default CategoryAdded;
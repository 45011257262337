import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Select } from "antd";
import "../../../Components/Css/Students/Student.scss";
import Api from "../Api";
import { Dropdown } from "primereact/dropdown";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Option } from "antd/lib/mentions/index.js";
import "aos/dist/aos.css";
import AOS from "aos";
import "../../../Components/Css/Professional/professionalstyle.scss";

function PersonalInfo() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  const [countryList, setcountryList] = useState([]);
  const [countryNames, setCountryName] = useState(null);

  const [countryValue, setCountryValue] = useState();
  const [stateList, setstateList] = useState([]);
  const [stateNames, setStateName] = useState(null);
  const [stateValue, setStateValue] = useState();
  const [districtList, setDistrictList] = useState([]);
  const [districtValue, setDistrictValue] = useState();
  const [cityList, setCityList] = useState([]);
  const [cityValue, setCityValue] = useState();
  const [universityList, setUniversityList] = useState([]);
  const [collegeList, setCollegeList] = useState([]);
  const [autonomousList, setAutonomousList] = useState([]);
  const [instituteValue, setInstituteValue] = useState();
  const [institute, setInstitute] = useState([]);
  const [college, setCollege] = useState([]);
  const [collegeValue, setCollegeValue] = useState();

  const [user, setUser] = useState(null);
  const [collegestype, setCollegestype] = useState("");
  // const userId = localStorage.getItem("userId");
  const [collegetype, setCollegetype] = useState();
  const [userData, setUserData] = useState({});
  const [districtNames, setDistrictName] = useState(null);
  const [cityNames, setCityName] = useState(null);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({});

  const navigate = useNavigate();
  const [resres, setResres] = useState({
    status: null,
    message: null,
  });
  const onGender = (e) => {
    setCollegestype(e.value);
  };

  const collegetypeOption = [
    {
      label: "Affilated",
      value: "Affilated",
    },
    {
      label: " University",
      value: "University",
    },
    {
      label: "Deemed University",
      value: "Deemed University",
    },
    { label: "Others", value: "Others" },
  ];

  // useEffect(() => {
  //   console.log("User data:", user); // Add this log to check user data
  //   // Add this log to check institute options
  //   if (user && user.university) {
  //     console.log("University value:", user.university);
  //     setValue("instituteName",user.university);
  //   }
  // }, [user]);

  useEffect(() => {
    getInstitute();
    getUniversity();
    getCountry();
    getDetails();
    getState();
  }, []);
  useEffect(() => {
    if (countryValue) {
      getState(countryValue);
    }
  }, [countryValue]);

  const getUniversity = async () => {
    try {
      const res = await Api.get("university/getUniversityList");
      setUniversityList(res.data.data);
      console.log("University list", res.data.data);
    } catch (error) {
      console.error("Failed to fetch universities:", error);
    }
  };

  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (country_id) => {
    setCountryValue(country_id);
    Api.get(`state/stateById/${country_id}`).then((res) => {
      setstateList(res.data.data);
    });
  };
  // const handleStateChange = (value, option) => {
  //   const selectedStateName = option?.label;
  //   setStateValue({ id: value, name: selectedStateName });
  //   getDistrict(value);
  //   setStateName(selectedStateName);
  // };

  // const handleDistrictChange = (value, option) => {
  //   const selectedDistrictName = option?.label;
  //   setDistrictValue({ id: value, name: selectedDistrictName });
  //   getCity(value);
  //   setDistrictName(selectedDistrictName);
  // };

  // const handleCityChange = (value, option) => {
  //   const selectedCityName = option?.label;
  //   setCityValue(value);
  //   setCityName(selectedCityName);
  // };

  const getDistrict = async (state_id) => {
    setStateValue(state_id);
    try {
      const res = await Api.get(`district/districtById/${state_id}`);
      setDistrictList(res.data.data);
    } catch (error) {
      console.error("Failed to fetch districts:", error);
    }
  };

  const getCity = async (districtId) => {
    setDistrictValue(districtId);
    try {
      const res = await Api.get(`city/cityById/${districtId}`);
      setCityList(res.data.data);
    } catch (error) {
      console.error("Failed to fetch cities:", error);
    }
  };

  const getInstitute = async () => {
    try {
      const res = await Api.get("fields/getUniversityList");
      setInstitute(res.data.data);
      console.log("Institute data:", res.data.data);
    } catch (error) {
      console.error("Error fetching institute data:", error);
    }
  };

  const getCollege = (university_id) => {
    console.log("university_ID:", university_id);
    setInstituteValue(university_id);
    Api.get(`fields/getAllCollege/${university_id}`).then((res) => {
      setCollege(res.data.data);
      console.log("college_list:", res.data.data);

      if (user?.collegeName) {
        const selectedCollege = user?.collegeName;
        setCollegeValue(selectedCollege);
      }
    });
  };

  useEffect(() => {
    getInstitute();
  }, []);

  useEffect(() => {
    if (instituteValue) {
      getCollege(instituteValue);
    }
  }, [instituteValue]);

  useEffect(() => {
    if (user?.universityName) {
      const selectedUniversity = user?.universityName;
      setInstituteValue(selectedUniversity);
      getCollege(selectedUniversity);
    }

    if (user?.collegeName) {
      setCollegeValue(user?.collegeName);
    }
  }, [user]);
  // const prefillForm = (data) => {
  //   if (!data) return;
  //   console.log("Setting form values with user data:", data);

  //   // const instituteId = instituteValue ? instituteValue._id : null;
  //   // console.log("Prefilling university with ID:", instituteId);
  //   setValue("instituteName", data.university);
  //   setValue("college", data.college);
  //   setValue("address", data.address);
  //   setValue("country", data.country);
  //   setValue("state", data.state);
  //   setValue("district", data.district);
  //   setValue("city", data.city);
  //   setValue("pincode", data.pincode);
  //   setValue("instituteType", data.collegetype);
  //   setValue("websiteUrl", data.website);
  //   setValue("email", data.email);
  //   console.log("Form values set:", getValues());
  // };
  useEffect(() => {
    if (user) {
      setValue("university", user.universityName);
      setValue("college", user.collegeName);
      setValue("collegeType", user.collegeType);
      setValue("address", user.address);
      setCountryValue(user.country);
      setStateValue(user.state);
      setDistrictValue(user.district);
      setValue("city", user.city);
      setValue("pincode", user.pincode);
      setValue("instituteType", user.collegetype);
      setValue("websiteUrl", user.website);
      setValue("email", user.email);
      setValue("contactPerson", user.contactPerson);
      setValue("designation", user.designation);
      setValue("degree", user.degree);
      setValue("phone", user.contactNumber);
      setValue("department", user.department);
    }
  }, [user]);
  console.log(user);

  const getDetails = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      const res = await Api.get(`collegein/collegeinfo/${userId}`);
      console.log("Response data:", res.data);

      const data = res.data.data || res.data;
      if (data) {
        setUser(data);
      } else {
        console.error("Data object is missing in response");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Endpoint not found (404):", error.response);
      } else {
        console.error("Error fetching details:", error);
      }
    }
  };
  const handleFormSubmit = async (data) => {
    try {
      const userId = localStorage.getItem("userId");
      let countryName;
      let stateName;
      let districtName;
      if (countryValue !== user?.country) {
        countryName = countryList.find(
          (country) => country.id === countryValue
        ).name;
      } else {
        countryName = user?.country;
      }

      if (stateValue !== user?.state) {
        stateName = stateList.find((state) => state.id === stateValue).name;
      } else {
        stateName = user?.state;
      }

      if (districtValue !== user?.district) {
        districtName = districtList.find(
          (district) => district.id === districtValue
        ).name;
      } else {
        districtName = user?.district;
      }
      const Details = {
        universityName: instituteValue,
        collegeName: collegeValue,
        collegeType: data.collegeType,
        contactPerson: data.contactPerson,
        designation: data.designation,
        degree: data.degree,
        department: data.department,
        contactNumber: data.phoneNumber,
        pincode: data.pincode,
        country: countryName,
        state: stateName,
        district: districtName,
      };

      if (userId) {
        await Api.put(`/collegein/collegeinfo/${userId}`, Details);
        console.log("Profile updated successfully");
      } else {
        await Api.post(`/collegein/collegeinfocreate/${userId}`, Details);
        console.log("Profile created successfully");
      }
    } catch (error) {
      console.error("Failed to create/update profile:", error);
    }
  };

  console.log("instituteName:", getValues("university"));
  console.log("Institute array:", institute);
  console.log("college array:", college);
  console.log("countrylist:", countryList);
  console.log("statelist:", stateList);
  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="personal-informations p-4">
          <div className="pages-title mb-3">Institute Information</div>
          <div data-aos="zoom-in">
            <Row className="mb-2">
              <Col sm={24} md={8} className="p-2">
                <label className="input-title">University Name</label>
                <Select
                  className="input-field"
                  value={instituteValue || ""}
                  onChange={(value) => {
                    setInstituteValue(value);
                    getCollege(value);
                  }}
                  placeholder="Select a University"
                  style={{ border: "none" }}
                >
                  {institute?.map((option) => (
                    <Option
                      key={option._id}
                      value={option._id}
                      label={option.UniversityName}
                    >
                      {option.UniversityName}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col sm={24} md={8} className="p-2">
                <label className="input-title">College Name</label>
                <Select
                  className="input-field"
                  value={collegeValue || ""}
                  onChange={(value) => {
                    setCollegeValue(value);
                    setValue("college", value);
                  }}
                  placeholder="Select a College"
                  style={{ border: "none" }}
                  disabled={!college.length}
                >
                  {college?.map((option) => (
                    <Option
                      key={option._id}
                      value={option._id}
                      label={option.collegeName}
                    >
                      {option.collegeName}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col sm={24} md={8} className="p-2">
                <label className="input-title">College Type</label>
                <select
                  className="input-field"
                  {...register("collegeType", { required: true })}
                  onChange={(e) => {
                    setValue("collegeType", e.target.value);
                  }}
                >
                  <option value="">Select College Type</option>
                  <option value="Affiliated">Affiliated</option>
                  <option value="University">University</option>
                  <option value="Deemed University">Deemed University</option>
                  <option value="Others">Others</option>
                </select>
                {errors.collegeType && (
                  <span className="input-error">College Type is required</span>
                )}
              </Col>
            </Row>

            <Row className="mb-2">
              {/* <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">College</label>
              <br></br>
             
              <input
                className="input-field"
                {...register("college", { required: true })}
              />
              <br />
              {errors.college && (
                <span className="input-error">College required</span>
              )}
            </Col> */}
              {/* <Col xs={24} sm={24} md={8} className=" p-2">
              <label className="input-title">Atomonous College</label>
              <br></br>
         
              <input
                className="input-field"
                {...register("autonomous", { required: true })}
              />
              <br />
              {errors.autonomous && (
                <span className="input-error">
                  Autonomous college is required
                </span>
              )}
            </Col> */}
              <Col sm={24} md={8} xs={24} className="px-2">
                <label className="input-title">Country </label>
                {/* <Dropdown
                filter
                className="input-field"
                value={countryValue}
                options={countryList}
                {...register("country", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getState(e.value)}
              /> */}
                <Select
                  className="input-field"
                  value={countryValue}
                  onChange={(value) => {
                    setCountryValue(value);
                    getState(value);
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  placeholder="Select a Country"
                  style={{ border: "none" }}
                >
                  {countryList?.map((country) => (
                    <Option
                      key={country.id}
                      value={country.id}
                      label={country.name}
                    >
                      {country.name}
                    </Option>
                  ))}
                </Select>

                {errors.country && (
                  <p className="error-text-color">country is required</p>
                )}
              </Col>
            </Row>
            <Row className="mb-4">
              <Col sm={24} md={8} xs={24} className="px-2">
                <label className="input-title">State</label>
                {/* <Dropdown
                filter
                className="input-field"
                value={stateValue}
                options={stateList}
                {...register("State", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getDistrict(e.value)}
              > */}
                <Select
                  className="input-field"
                  value={stateValue}
                  onChange={(value) => {
                    setStateValue(value);
                    getDistrict(value);
                  }}
                  showSearch
                  placeholder="Select a State"
                  style={{ border: "none" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {stateList?.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  ))}
                </Select>

                {errors.State && (
                  <span className="input-error">State is required</span>
                )}
              </Col>

              <Col sm={24} md={8} xs={24} className="px-2">
                <label className="input-title">District</label>

                {/* <Dropdown
                filter
                className="input-field"
                value={districtValue}
                options={districtList}
                {...register("district", { required: true })}
                optionLabel={"name"}
                optionValue={"id"}
                onChange={(e) => getCity(e.value)}
              /> */}
                <Select
                  className="input-field"
                  value={districtValue}
                  onChange={(value) => {
                    setDistrictValue(value);
                  }}
                  showSearch
                  placeholder="Select a District"
                  style={{ border: "none" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {districtList?.map((district) => (
                    <Option key={district.id} value={district.id}>
                      {district.name}
                    </Option>
                  ))}
                </Select>

                {errors.district && (
                  <span className="input-error">District is required</span>
                )}
              </Col>
              <Col sm={24} md={8} xs={24} className="px-2">
                <label className="input-title">City</label>
                {/* <Dropdown
                filter
                className="input-field"
                value={cityValue}
                options={cityList}
                {...register("city", { required: true })}
                optionLabel={"cityName"}
                optionValue={"_id"}
                onChange={(e) => setCityValue(e.value)}
                placeholder="Select a city"
              /> */}
                <input
                  className="input-field"
                  type="text"
                  defaultValue={user?.city}
                  placeholder="Select a City"
                  style={{ border: "none" }}
                  {...register("city", { required: true })}
                />
                {errors.city && <p className="text-danger">City is required</p>}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">Pincode </label>
                <input
                  type="number"
                  className="input-field"
                  {...register("pincode", {
                    required: true,
                    pattern: {
                      value: /^0*?\d{6}$/,
                    },
                  })}
                />

                {errors.pincode && (
                  <span className="Stud-personal-error">
                    Pincode is required
                  </span>
                )}
              </Col>

              <Col sm={12} md={8} className=" p-2">
                <label className="input-title">Address </label>
                <input
                  className="input-field"
                  {...register("address", { required: true })}
                />

                {errors.address && (
                  <span className="Stud-personal-error">
                    Address is required
                  </span>
                )}
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">Contact Person </label>

                <input
                  className="input-field"
                  {...register("contactPerson", { required: true })}
                />

                {errors.Name && (
                  <span className="Stud-personal-error">Name is required</span>
                )}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">Designation </label>

                <input
                  className="input-field"
                  {...register("designation", { required: true })}
                />

                {errors.Designation && (
                  <span className="Stud-personal-error">
                    Designation is required
                  </span>
                )}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title"> Degree </label>
                <input
                  className="input-field"
                  {...register("degree", { required: true })}
                />

                {errors.Degree && (
                  <span className="Stud-personal-error">
                    Degree is required
                  </span>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title"> Department </label>
                <input
                  className="input-field"
                  {...register("department", { required: true })}
                />

                {errors.Department && (
                  <span className="Stud-personal-error">
                    Department is required
                  </span>
                )}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">Contact Number </label>
                <input
                  className="input-field"
                  {...register("phone", { required: true })}
                />

                {errors.PhoneNumber && (
                  <span className="Stud-personal-error">
                    Contact number is required
                  </span>
                )}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">Website </label>
                <input
                  className="input-field"
                  {...register("websiteUrl", { required: true })}
                />

                {errors.websiteUrl && (
                  <span className="Stud-personal-error">
                    Website is required
                  </span>
                )}
              </Col>
              <Col xs={24} sm={24} md={8} className=" p-2">
                <label className="input-title">E-mail </label>
                <input
                  className="input-field"
                  {...register("email", { required: true })}
                />

                {errors.email && (
                  <span className="Stud-personal-error">
                    E-mail is required
                  </span>
                )}
              </Col>
            </Row>

            <div className="submitbuttons p-2">
              <button className="button1 m-2 p-2" type="submit">
                Submit
              </button>
              <button className="button2 m-2 p-2" type="reset">
                Reset
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PersonalInfo;

import React from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../Users/UsersTab.css";
import Jobpostlist from "../../../DashboardTablelist/Jobpostlist";
import ProjectPost from "../../../../Admin/Feril/ProjectJob/ProjectPost";

function CareerBlitzTabs() {
  const basePath = "/admin";

  const tabs = [
    {
      id: 1,
      name: "Job Post",
      path: `${basePath}/users/careerblitz/Jobpost`,
      component: Jobpostlist,
    },
    {
      id: 2,
      name: "Project Post",
      path: `${basePath}/users/careerblitz/ProjectPost`,
      component: ProjectPost,
    },
  ];

  return (
    <div>
      <div className="userstab-container">
        <div className="tabs">
          {tabs.map((tab) => (
            <NavLink
              key={tab.id}
              to={tab.path}
              className="tab-link"
              style={({ isActive }) => ({
                backgroundColor: isActive ? "#007BFF" : "#f0f0f0",
                color: isActive ? "white" : "#007BFF",
              })}
            >
              {tab.name}
            </NavLink>
          ))}
        </div>
        <Routes>
          {tabs.map((tab) => (
            <Route key={tab.id} path={tab.path} element={<tab.component />} />
          ))}
        </Routes>
      </div>
    </div>
  );
}

export default CareerBlitzTabs;

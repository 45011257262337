import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";
import Api from "../../../Api";
import { notification } from "antd"; 
import "./AddSector.css";

function AddSectorForm() {
  const [sectorName, setSectorName] = useState("");
  const [description, setDescription] = useState(""); 
  const [loading, setLoading] = useState(false);
  const { sectorId } = useParams(); 
  const navigate = useNavigate();

  useEffect(() => {
    if (sectorId) {
      const fetchSector = async () => {
        try {
          const res = await Api.get(`/coursesector/getsector/${sectorId}`);
          setSectorName(res.data.sectorName);
          setDescription(res.data.description || ""); 
        } catch (error) {
          console.error("Failed to fetch sector data", error);
          notification.error({
            message: "Error",
            description: "Failed to fetch sector data. Please try again.",
          });
        }
      };

      fetchSector();
    }
  }, [sectorId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!sectorName) {
      notification.error({
        message: "Error",
        description: "Sector name is required.",
      });
      return;
    }

    try {
      setLoading(true);
      if (sectorId) {
        await Api.put(`/coursesector/updatesector/${sectorId}`, {
          sectorName,
          description,
        });
        notification.success({
          message: "Success",
          description: "Sector updated successfully",
        });
      } else {
        await Api.post("/coursesector/createsector", {
          sectorName,
          description,
        });
        notification.success({
          message: "Success",
          description: "Sector added successfully",
        });
      }
      navigate("/admin/managesector"); 
    } catch (error) {
      console.error(error);
      notification.error({
        message: sectorId ? "Error" : "Failed to add sector",
        description: sectorId ? "Failed to update sector" : "Failed to add sector. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
    <h4 className="text-center mb-4">
      {sectorId ? "Edit Sector" : "Add Sector"}
    </h4>
    <form onSubmit={handleSubmit} className="sector-form">
      <div className="row mb-3">
        <div className="col-md-6">
          <label htmlFor="sectorName" className="form-label">
            Sector Name
          </label>
          <input
            type="text"
            id="sectorName"
            className="form-control"
            placeholder="Enter sector name"
            value={sectorName}
            onChange={(e) => setSectorName(e.target.value)}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/admin/managesector")}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? "Saving..." : sectorId ? "Update Sector" : "Add Sector"}
          </button>
        </div>
      </div>
    </form>
  </div>
  
  );
}

export default AddSectorForm;

import React, { useState, useEffect } from 'react';
import Api from '../../../Api.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../Components/Css/Professional/professionalstyle.scss";

import { Col, Row } from 'react-bootstrap';

function UserForm() {
  const [formData, setFormData] = useState({
    panNumber: '',
    aadhaarNumber: '',
    addressProof: '',
    cardImage: null,
    bankName: '',
    accountNumber: '',
    confirmAccountNumber: '', // New state
    ifscCode: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [existingImageName, setExistingImageName] = useState('');
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [isImageZoomed, setIsImageZoomed] = useState(false); 

  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (!userId) {
      setError('User ID is missing in localStorage');
      return;
    }

    const fetchApiData = async () => {
      setLoading(true);
      try {
        const response = await Api.get(`counselorkyc/${userId}`);

        if (response.data) {
          setFormData({
            panNumber: response.data.panCardNumber || '',
            aadhaarNumber: response.data.aadharCardNumber || '',
            addressProof: response.data.addressProof || '',
            cardImage: response.data.cardImage || null,
            bankName: response.data.bankName || '',
            accountNumber: response.data.accountNumber || '', // Fetch account number
            confirmAccountNumber: response.data.confirmAccountNumber || '', // Reset confirm field on load
            ifscCode: response.data.ifscCode || '',
          });

          if (response.data.cardImage) {
            setExistingImageName(response.data.cardImage.split('/').pop());
            setImagePreview(`/cards/${response.data.cardImage.split('/').pop()}`);
          }

          setIsUpdateMode(true); 
        }
      } catch (error) {
        setError('Error fetching data');
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchApiData();
  }, [userId]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.size > 5 * 1024 * 1024) {
      setError('File size should not exceed 5MB.');
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      cardImage: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setExistingImageName('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userId) {
      setError('User ID is missing in localStorage');
      return;
    }
  // Validation for account number matching
  if (formData.accountNumber !== formData.confirmAccountNumber) {
    setError('Account number and confirm account number do not match.');
    return; // Stop form submission
  }
    setLoading(true);
    setError('');

    const formDataToSend = new FormData();
    formDataToSend.append('panCardNumber', formData.panNumber);
    formDataToSend.append('aadharCardNumber', formData.aadhaarNumber);
    formDataToSend.append('addressProof', formData.addressProof);
    formDataToSend.append('bankName', formData.bankName);
    formDataToSend.append('accountNumber', formData.accountNumber);
    formDataToSend.append('confirmAccountNumber', formData.confirmAccountNumber);
    formDataToSend.append('ifscCode', formData.ifscCode); 

    if (formData.cardImage && typeof formData.cardImage !== 'string') {
      formDataToSend.append('cardImage', formData.cardImage);
    }

    try {
      let response;
      if (isUpdateMode) {
        response = await Api.put(`counselorkyc/update/${userId}/`, formDataToSend);
      } else {
        response = await Api.post(`counselorkyc/create/${userId}/`, formDataToSend);
      }

      if (response.status === 200 || response.status === 201) {
        alert(isUpdateMode ? 'Updated successfully!' : 'Submitted successfully!');
        setIsUpdateMode(true);
        const updatedResponse = await Api.get(`counselorkyc/${userId}`);
        if (updatedResponse.data) {
          setFormData({
            panNumber: updatedResponse.data.panCardNumber || '',
            aadhaarNumber: updatedResponse.data.aadharCardNumber || '',
            addressProof: updatedResponse.data.addressProof || '',
            cardImage: updatedResponse.data.cardImage || null,
            bankName: updatedResponse.data.bankName || '',
            accountNumber: updatedResponse.data.accountNumber || '',
            confirmAccountNumber: updatedResponse.data.confirmAccountNumber || '',
            ifscCode: updatedResponse.data.ifscCode || '',
          });

          if (updatedResponse.data.cardImage) {
            setExistingImageName(updatedResponse.data.cardImage.split('/').pop());
            setImagePreview(`/cards/${updatedResponse.data.cardImage.split('/').pop()}`);
          }
        }
      } else {
        alert('Failed to submit/update the form!');
      }
    } catch (error) {
      setError('Error submitting form');
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = () => {
    setIsImageZoomed(!isImageZoomed); 
  };

  return (
    <div className="container mt-4" >
      {loading && <div>Loading...</div>}
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}  className='px-4'>
        <Row className='px-4'>
          <Col lg={12} style={{width:'40%'}}>
          <div className="mb-3">
          
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Preview"
              style={{
                width: "200px",
                height: "180px",
                marginBottom: "16px",
                transform: isImageZoomed ? 'scale(2)' : 'scale(1)',
                transition: 'transform 0.3s ease'
              }}
              onClick={handleImageClick}
            />
          )}
          <label className="input-title" htmlFor="cardImage">Upload Card Image</label>
          <input
            type="file"
            id="cardImage"
            onChange={handleFileChange}
            accept="image/*"
          />
        </div>
        </Col>
        </Row>
        <Row style={{width:'90%'}}className='px-4'>
        <Col lg={4} md={6} xs={12}>
        <div className="mb-3">
          <label className="input-title" htmlFor="panNumber">Pan Card Number</label>
          <input
            type="text"
            className="Professional__UpdateProfile__Input"
            id="panNumber"
            value={formData.panNumber}
            onChange={handleInputChange}
            required
          />
        </div>
        </Col>
        <Col  lg={4}>
        <div className="mb-3">
          <label className="input-title" htmlFor="aadhaarNumber">Aadhar Card Number</label>
          <input
            type="text"
            className="Professional__UpdateProfile__Input"
            id="aadhaarNumber"
            value={formData.aadhaarNumber}
            onChange={handleInputChange}
            required
          />
        </div>
        </Col>
       
          <Col  lg={4} >
        <div className="mb-3">
          <label className="input-title" htmlFor="addressProof">Address Proof</label>
          <input
            type="text"
            className="Professional__UpdateProfile__Input"
            id="addressProof"
            value={formData.addressProof}
            onChange={handleInputChange}
          />
        </div>
        </Col>
        </Row>
        
        <Row style={{ width: '90%' }} className='px-4'>
  <Col lg={4} md={6} xs={12}>
    <div className="mb-3">
      <label className="input-title" htmlFor="bankName">Bank Name</label>
      <input
        type="text"
        className="Professional__UpdateProfile__Input"
        id="bankName"
        value={formData.bankName}
        onChange={handleInputChange}
        required
      />
    </div>
  </Col>
  <Col lg={4}>
    <div className="mb-3">
      <label className="input-title" htmlFor="accountNumber">Account Number</label>
      <input
        type="text"
        className="Professional__UpdateProfile__Input"
        id="accountNumber"
        value={formData.accountNumber}
        onChange={handleInputChange}
        required
      />
    </div>
  </Col>
  <Col lg={4}>
            <div className="mb-3">
              <label className="input-title" htmlFor="confirmAccountNumber">Confirm Account Number</label>
              <input
                type="password"
                className="Professional__UpdateProfile__Input"
                id="confirmAccountNumber"
                value={formData.confirmAccountNumber}
                onChange={handleInputChange}
                required
              />
            </div>
          </Col>
</Row>
<Row style={{ width: '90%' }} className='px-4'>
  <Col lg={4}>
    <div className="mb-3">
      <label className="input-title" htmlFor="ifscCode">IFSC Code</label>
      <input
        type="text"
        className="Professional__UpdateProfile__Input"
        id="ifscCode"
        value={formData.ifscCode}
        onChange={handleInputChange}
        required
      />
    </div>
  </Col>
</Row>
<button type="submit" className="btn btn-primary" style={{marginLeft:'30px',cursor:'pointer'}}>
          {isUpdateMode ? 'Update' : 'Submit'}
        </button>
      </form>
    </div>
  );
}

export default UserForm;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Card, Col } from "react-bootstrap";
import "../Vendor/Index.css";
import Addlist from "./Addlist";
// import { Input, Radio, Space } from "antd";
import { MultiSelect } from "primereact/multiselect";
import { Option } from "antd/lib/mentions";
import { Radio, Select } from "antd";
import Api from "../../Api";
import { Dropdown } from "primereact/dropdown";
import "../../Components/Css/Professional/professionalstyle.scss"

function Addcategory() {
  const [skills, setSkills] = useState([]);
  const [skillist, setskilList] = useState([]);
  const [User, setUser] = useState([]);
  const [CheckReact, setCheckReact] = useState(false);
  const [value, setValue] = useState(null);
  const [reactValues, setReactValues] = useState({
    react1: "",
    react2: "",
    react3: "",
    react4: "",
    react5: "",
    react6: "",
  });
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  const [selectedCities, setSelectedCities] = useState(null);
  const cities = [
    { name: "Veg", code: "NY" },
    { name: "nonveg", code: "RM" },
    { name: "Both", code: "IST" },
  ];
  const [selectedCity, setSelectedCity] = useState(null);

  const [selectedType, setSelectedType] = useState(null);
  const types = [
    { name: "2 Sharing", code: "NY" },
    { name: "3 Sharing", code: "RM" },
    { name: "4 Sharing", code: "IST" },
  ];

  const id = localStorage.getItem("id");
  // useEffect(() => {
  //   getoneUser();
  // });
  // const getoneUser = () => {
  //   Api.get(vendor/vendor_get/${id}).then((res) => {
  //     setUser(res.data);
  //   });
  // };
  const handleChange = (e) => {
    console.log(e, "event");
    setValue(e.target.value);
  };

  const [vendorDetails, setVendorDetails] = useState([]);
  useEffect(() => {
    getValue();
    getCategory();
    getCountry();
    getState();
    getDistrict();
  }, []);
  const getValue = async () => {
    await Api.get(`/vendor/vendor_get/${id}`).then((res) => {
      setVendorDetails(res.data.data);
    });
  };
  const [categoryList, setCategoryList] = useState();
  const getCategory = async () => {
    await Api.get(`/vendor/getAllCategory`).then((res) => {
      setCategoryList(res.data.data);
    });
  };

  const [countryList, setcountryList] = useState([]);
  const [stateList, setstateList] = useState([]);
  const [districtList, setdistrictList] = useState([]);
  console.log("step1", districtList)
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [districtValue, setDistrictValue] = useState();
  const [cityvalue, setCityValue] = useState();

  const getCountry = async () => {
    await Api.get("country/getallcountry").then((res) => {
      setcountryList(res.data.data);
    });
  };

  const getState = (countryId) => {
    setCountry(countryId);
    Api.get(`state/stateById/${countryId}`).then((res) => {
      setstateList(res.data.data);
    });
  };

  const getDistrict = (stateId) => {
    console.log("district", stateId)
    setState(stateId);
    Api.get(`district/districtById/${stateId}`).then((res) => {
      setdistrictList(res.data.data);
    });
  };

  const [cityList, setCityList] = useState([]);
  const getCity = (districtId) => {
    setDistrictValue(districtId);
    Api.get(`city/cityById/${districtId}`).then((res) => {
      console.log(res.data, "ghjfhjgf");
      setCityList(res.data.data);
    });
  };

  const [addCategory, setAddCategory] = useState([]);

  const handleFormSubmit = async () => {
    const userDetails = {
      companyName: getValues().companyName,
      Description: getValues().Description,
      email: getValues().Email,
      Websitelink: getValues().Websitelink,
      Regularprice: getValues().Regularprice,
      Discountprice: getValues().Discountprice,
      country: getValues().country,
      State: getValues().State,
      district: getValues().district,
      City: getValues().City,
      category: value,
    };
    console.log("userDetails", userDetails);
    await Api.post(`vendor/createAddCategory`, userDetails)
      .then((res) => {
        setAddCategory({
          status: res.data?.status,
          message: res.data?.message,
        });
        reset();
      })
      .catch((err) => {
        setAddCategory({
          status: err?.response.data?.status,
          message: err?.response.data?.message,
        });
      });
  };

  // const [value, setValue] = useState();
  // const [subcategoryList, setSubCategoryList] = useState();

  // const getCategoryList = async (categoryId) => {
  //   setValue(categoryId);
  //   console.log("categoryId", categoryId);
  //   await Api.get(/vendor/categoryById/${categoryId}).then((res) => {
  //     setSubCategoryList(res.data.data);
  //     console.log("res.data", res.data);
  //   });
  // };



  return (
    <div>
      <form>
        <Card className="category_Card">
          <Row className="gx-3 gy-3">
            <h4
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
                fontWeight: "bold",
                margin: "2%",
              }}
            >
              Create New Category
            </h4>
          </Row>
          <Row className="gx-3 gy-3">

            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">Company Name</label>
              <input
                className="Professional__UpdateProfile__Input"
                // value={vendorDetails.companyName}
                required="required"
                placeholder="Name"
                {...register("companyName", { required: true })}
              />
              {errors.companyName && <span>This field is required</span>}
            </Col>

            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">Description</label>
              <input
                className="Professional__UpdateProfile__Input"
                // value="text"
                required="required"
                placeholder="Description"
                {...register("Description", { required: true })}
              />
              {errors.Description && (
                <p className="error-text-color">Description is required</p>
              )}
            </Col>

            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">Email Id</label>
              <input
                className="Professional__UpdateProfile__Input"
                // value={vendorDetails.email}
                required="required"
                placeholder="Email"
                {...register("Email", { required: true })}
              />
              {errors.Email && (
                <p className="error-text-color">Email is required</p>
              )}
            </Col>

            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">Website Link</label>
              <input
                className="Professional__UpdateProfile__Input"
                type="text"
                placeholder="Website Link"
                // name="name"
                // value="test"
                required="required"
                {...register("Websitelink", { required: true })}
              />
              {errors.Websitelink && (
                <p className="error-text-color">Websitelink is required</p>
              )}
            </Col>

            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title"> Regular Price</label>
              <input
                className="Professional__UpdateProfile__Input"
                type="text"
                // name="name"
                // value="test"
                required="required"
                placeholder="Regular Price"
                {...register("Regularprice", { required: true })}
              />
              {errors.Regularprice && (
                <p className="error-text-color">Regularprice is required</p>
              )}
            </Col>

            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title"> Discount Price</label>
              <input
                className="Professional__UpdateProfile__Input"
                type="text"
                // name="name"
                // value="test"
                required="required"
                placeholder="Discount Price"
                {...register("Discountprice", { required: true })}
              />
              {errors.Discountprice && (
                <p className="error-text-color">Discountprice is required</p>
              )}
            </Col>

            {/* Country Field */}
            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">Country</label>
              <Select
                className="Professional__UpdateProfile__Input"
                placeholder="Select a Country"
                value={country}
                onChange={(value) => {
                  setCountry(value);
                  getState(value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {countryList.map((c) => (
                  <Option key={c.id} value={c.id}>
                    {c.name}
                  </Option>
                ))}
              </Select>
              {errors.country && (
                <p className="error-text-color">Country is required</p>
              )}
            </Col>

            {/* State Field */}
            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">State <sup className="required_column">*</sup></label>
              <Select
                className="Professional__UpdateProfile__Input"
                placeholder="Select a State"
                value={state}
                onChange={(value) => {
                  setState(value);
                  getDistrict(value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {stateList.map((s) => (
                  <Option key={s.id} value={s.id}>
                    {s.name}
                  </Option>
                ))}
              </Select>
              {errors.State && (
                <p className="error-text-color">State is required</p>
              )}
            </Col>

            {/* District Field */}
            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">District <sup className="required_column">*</sup></label>
              <Select
                className="Professional__UpdateProfile__Input"
                placeholder="Select a District"
                value={districtValue}
                onChange={(value) => {
                  setDistrictValue(value);
                  getCity(value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {districtList.map((d) => (
                  <Option key={d.id} value={d.id}>
                    {d.name}
                  </Option>
                ))}
              </Select>
              {errors.district && (
                <p className="error-text-color">District is required</p>
              )}
            </Col>

            {/* City Field */}
            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">City <sup className="required_column">*</sup></label>
              <Select
                className="Professional__UpdateProfile__Input"
                placeholder="Select a City"
                value={cityvalue}
                onChange={(value) => setCityValue(value)}
                showSearch
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {cityList.map((c) => (
                  <Option key={c._id} value={c._id}>
                    {c.cityName}
                  </Option>
                ))}
              </Select>
              {errors.City && (
                <p className="error-text-color">City is required</p>
              )}
            </Col>

            <Col lg={4} md={6} xs={12} className="px-4 py-2">
              <label className="input-title">Categories</label>
              <select
                required="required"
                onChange={handleChange}
                className="Professional__UpdateProfile__Input"
              >
                <option value="null">Categories</option>
                <option value="careerblitz PG">careerblitz PG</option>
                <option value="careerblitz food">careerblitz food</option>
                <option value="careerblitz Tea">careerblitz Tea</option>
                <option value="careerblitz Internet">careerblitz Internet</option>
                <option value="careerblitz Laptop">careerblitz Laptop</option>
              </select>
            </Col>

          </Row>

          <Row>
            <div className="mt-2">
              {value == "careerblitz PG" ? (
                <div style={{ marginLeft: "60px" }}>
                  <Row>
                    <Col sm={12} md={4} className="mt-4">
                      PG Name
                    </Col>
                    <Col sm={12} md={4} className="mt-4">
                      <input
                        type="text"
                        // name="react1"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={12} md={4} className="2">
                      Gender
                    </Col>
                    <Col sm={12} md={4} className="2">
                      <Radio.Group name="radiogroup" defaultValue={1}>
                        <Radio value={1}>Male</Radio>
                        <Radio value={2}>Female</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={12} md={4} className="2">
                      Contact Number
                    </Col>
                    <Col sm={12} md={4} className="2">
                      <input
                        type="number"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col sm={12} md={4} className="mt-2">
                      Room Type
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <MultiSelect
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.value)}
                        options={types}
                        optionLabel="name"
                        placeholder="Select a Room Type"
                        className="inputcolumn"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={12} md={4} className="mt-2">
                      Food Type
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <MultiSelect
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        placeholder="Select a Food Type"
                        className="inputcolumn"
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </Row>
          <Row>
            <div className="mt-2">
              {value == "careerblitz food" ? (
                <div style={{ marginLeft: "60px" }}>
                  <Row>
                    <Col sm={12} md={4} className="">
                      Vendor Name
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="text"
                        // name="react1"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4} className="mt-2">
                      Food Type
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <MultiSelect
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        placeholder="Select a Food type"
                        className="inputcolumn"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={4} className="mt-2">
                      Delivery
                    </Col>
                    <Col sm={12} md={4} className="mt-1">
                      <Radio.Group name="radiogroup" defaultValue={1}>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={2}>No</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </Row>
          <Row>
            <div className="mt-2">
              {value == "careerblitz Tea" ? (
                <div style={{ marginLeft: "60px" }}>
                  <Row>
                    <Col sm={12} md={4} className="">
                      Name
                    </Col>
                    <Col sm={12} md={4}>
                      <input
                        type="number"
                        // name="react1"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4} className="mt-2">
                      Food Type
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <MultiSelect
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        placeholder="Select a Food type"
                        className="inputcolumn"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={4} className="mt-2">
                      Phone Number
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <input
                        type="number"
                        // name="react6"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </Row>
          <Row>
            <div className="mt-2">
              {value == "careerblitz Internet" ? (
                <div style={{ marginLeft: "60px" }}>
                  <Row>
                    <Col sm={12} md={4} className="mt-2">
                      Service provide Name
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <input
                        type="number"
                        // name="react1"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4} className="mt-2">
                      Type
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <Radio.Group name="radiogroup" defaultValue={1}>
                        <Radio value={1}>Broad Band</Radio>
                        <Radio value={2}>Other</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={4} className="mt-2">
                      Package Name
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <input
                        type="text"
                        // name="react6"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4} className="mt-2">
                      Speed
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <input
                        type="text"
                        // name="react6"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4} className="mt-2">
                      Price
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <input
                        type="number"
                        // name="react6"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={4} className="mt-2">
                      One time charge
                    </Col>
                    <Col sm={12} md={4} className="mt-2">
                      <input
                        type="number"
                        // name="react6"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </Row>
          <Row>
            <div className="mt-2">
              {value == "careerblitz Laptop" ? (
                <div style={{ marginLeft: "60px" }}>
                  <Row>
                    <Col sm={12} md={12} className="mt-1">
                      <Radio.Group name="radiogroup" defaultValue={1}>
                        <Radio value={1}>Rent</Radio>
                        <Radio value={2}>Sale</Radio>
                        <Radio value={3}>Both</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={2} className="mt-2">
                      Windows &nbsp;
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    &nbsp;
                    <Col sm={12} md={2} className="mt-2">
                      Apple &nbsp;
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={2} className="mt-2">
                      Mac &nbsp;
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={2} className="mt-2">
                      Ipad &nbsp;
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setReactValues((pre) => {
                            return { ...pre, [e.target.name]: e.target.value };
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </Row>
          <Row>
            <div className="addbutton_column mb=3 mt-4">
              <button
                className="button1"
                onClick={handleSubmit(handleFormSubmit)}
              >
                Submit
              </button>
              {/* <button className="button2 ms-2">Back</button> */}
            </div>
          </Row>
        </Card>
      </form>
      {/* <Col xs={12} md={8}>
        
                    {route==='list' && 
                <Addlist/>
                   }
                  
         </Col> */}
    </div>
  );
}

export default Addcategory;
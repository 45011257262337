import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { DatePicker } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Api from "../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmployerSignupForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [resRes, setResRes] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  useEffect(() => {
    handleFormSubmit();
  });

  const handleFormSubmit = async (data) => {
    const employerDetails = {
      role: "employer",
      name: data.name,
      employerName: data.employerName,
      email: data.email,
      phone: data.phone,
      industryType: data.industryType,
      // establishedDate: data.establishedDate,
      password: data.password,
    };

    try {
      const res = await Api.post(`employer/Employer_create`, employerDetails);
      setResRes({
        status: res.data?.status,
        message: res.data?.message,
      });
      toast.success(res.data?.message);
      setTimeout(() => {
        navigate("/login");
      }, 10000);
    } catch (err) {
      setResRes({
        status: err?.response.data?.status,
        message: err?.response.data?.message,
      });
      toast.error(err?.response.data?.message);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="signup-form-container">
      <h2 className="form-heading" style={{ color: "blue" }}>
        Company Signup
      </h2>

      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Company Name</Form.Label>
              <Form.Control
                {...register("name", { required: true })}
                className="custom-input"
                placeholder="Enter Company Name"
                type="text"
              />
              {errors.name?.type === "required" && (
                <p className="error-text-color">Name is required</p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Employer Name</Form.Label>
              <Form.Control
                {...register("employerName", { required: true })}
                className="custom-input"
                placeholder="Enter Employer Name"
                type="text"
              />
              {errors.employerName?.type === "required" && (
                <p className="error-text-color">Employer Name is required</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Email ID</Form.Label>
              <Form.Control
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
                className="custom-input"
                placeholder="Enter Email"
                type="email"
              />
              {errors.email && (
                <p className="error-text-color">{errors.email.message}</p>
              )}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Phone Number</Form.Label>
              <Form.Control
                {...register("phone", {
                  minLength: 10,
                  maxLength: 10,
                  required: true,
                })}
                className="custom-input"
                placeholder="Enter Phone Number"
                type="text"
              />
              {errors.phone?.type === "required" && (
                <p className="error-text-color">Phone Number is required</p>
              )}
              {errors.phone?.type === "minLength" && (
                <p className="error-text-color">
                  Minimum 10 digits are required
                </p>
              )}
              {errors.phone?.type === "maxLength" && (
                <p className="error-text-color">
                  Maximum 10 digits are required
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Industry Type</Form.Label>
              <Form.Control
                {...register("industryType", { required: true })}
                className="custom-input"
                as="select"
              >
                <option value="">Select Type</option>

                <option value="IT">IT</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Finance">Finance</option>
                <option value="Healthcare">Healthcare</option>
              </Form.Control>
              {errors.industryType?.type === "required" && (
                <p className="error-text-color">Industry Type is required</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Group className="custom-form-group">
              <Form.Label className="form-label">Password</Form.Label>
              <div className="input-icon-container">
                <Form.Control
                  {...register("password", { required: true, minLength: 6 })}
                  className="custom-input"
                  placeholder="Enter Password"
                  type={passwordVisible ? "text" : "password"}
                />
                <div
                  className="eye-icon-container"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              </div>
              {errors.password?.type === "required" && (
                <p className="error-text-color">Password is required</p>
              )}
              {errors.password?.type === "minLength" && (
                <p className="error-text-color">
                  Password must be at least 6 characters
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Button className="next-btn" type="submit">
          Submit
        </Button>
        {resRes && (
          <div className={`response-message ${resRes.status}`}>
            <p>{resRes.message}</p>
          </div>
        )}

        <ToastContainer />
      </Form>
    </div>
  );
};

export default EmployerSignupForm;

import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { CgProfile } from "react-icons/cg";
import { BiBookReader, BiSupport, BiCalendar } from "react-icons/bi";
import { SnippetsOutlined } from "@ant-design/icons";
import {
  AiOutlineContacts,
  AiOutlineFundProjectionScreen,
  AiFillQuestionCircle,
  AiOutlineSchedule,
} from "react-icons/ai";
import { BsFillBookmarkCheckFill } from "react-icons/bs";
import { MdOutlineDashboardCustomize, MdPerson } from "react-icons/md";
const { SubMenu } = Menu;

function Sidebar({ collapsed }) {
  const onClick = (e) => {
    console.log("click ", e);
  };
  return (
    <div className={collapsed === true ? "sidebarcontent open" : "d-none"}>
      <Menu onClick={onClick} mode="inline" className="nav-list">
        <NavLink to="" activeClassName="main-nav-style">
          <div className="Nav-Icon2">
            <CgProfile />
          </div>
          <div className="name-tag">Employee</div>
          <div className="name-tag">123</div>
        </NavLink>
        <NavLink to="/employee" ClassName="main-nav-style">
          <MdOutlineDashboardCustomize className="Nav-Icon" />
          Dashboard
        </NavLink>
        <NavLink to="/employee/profile" ClassName="main-nav-style">
          <MdPerson className="Nav-Icon" />
          My Profile
        </NavLink>
        <NavLink to="/employee/calender" ClassName="main-nav-style">
          <BiCalendar className="Nav-Icon" />
          Leaves
        </NavLink>
        <SubMenu
          icon={<BiBookReader size={20} className="Nav-Icon1" />}
          title="Interview "
        >
          <SubMenu
            icon={<AiOutlineContacts size={20} className="Nav-Icon1" />}
            title="HR "
          >
            <NavLink to="/employee/schedule" ClassName="sub-nav-style">
              <AiOutlineSchedule className="Nav-Icon" />
              Schedule
            </NavLink>
            <NavLink to="/employee/question" ClassName="sub-nav-style">
              <AiFillQuestionCircle className="Nav-Icon" />
              Questions
            </NavLink>
            {/* <NavLink to={``} ClassName="sub-nav-style">
              <BsFillBookmarkCheckFill className="Nav-Icon" />
              Remarks
            </NavLink>
            <NavLink to={``} ClassName="main-nav-style">
              <FcRating className="Nav-Icon" />
              Rating
            </NavLink> */}
          </SubMenu>
          <SubMenu
            icon={<AiOutlineContacts size={20} className="Nav-Icon1" />}
            title="Contract Staffing "
          >
            <NavLink to="/employee/interview" ClassName="sub-nav-style">
              <AiOutlineSchedule className="Nav-Icon" />
              Interview schedule
            </NavLink>
            <NavLink to="/employee/question" ClassName="sub-nav-style">
              <AiFillQuestionCircle className="Nav-Icon" />
              Questions
            </NavLink>
            <NavLink to={``} ClassName="main-nav-style">
              <BsFillBookmarkCheckFill className="Nav-Icon" />
              Remarks
            </NavLink>
          </SubMenu>
        </SubMenu>
        <SubMenu
          icon={
            <AiOutlineFundProjectionScreen size={20} className="Nav-Icon1" />
          }
          title="Projects "
        >
          <NavLink to={``} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Current Project
          </NavLink>
          <NavLink to={``} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Pending
          </NavLink>
          <NavLink to={``} ClassName="main-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Complete
          </NavLink>
        </SubMenu>
        <SubMenu
          icon={<SnippetsOutlined size={20} className="Nav-Icon1" />}
          title="Tickets "
        >
          <NavLink to={``} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Process
          </NavLink>
          <NavLink to={``} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Closed
          </NavLink>
          <NavLink to={``} ClassName="main-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Need Help?
          </NavLink>
          <NavLink to={``} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Hold
          </NavLink>
          <NavLink to={``} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Out of Scope
          </NavLink>
          <NavLink to={``} ClassName="main-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            ReOpen
          </NavLink>
        </SubMenu>
        <SubMenu
          icon={<BiSupport size={20} className="Nav-Icon1" />}
          title="Support "
        >
          <NavLink to={``} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Technical Issuse
          </NavLink>
          <NavLink to={``} ClassName="sub-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Salary Issuse
          </NavLink>
          <NavLink to={``} ClassName="main-nav-style">
            <SnippetsOutlined className="Nav-Icon" />
            Complaints
          </NavLink>
        </SubMenu>
      </Menu>
    </div>
  );
}

export default Sidebar;

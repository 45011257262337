import React, { useEffect, useState } from "react";
import { Input, Button, Card, Row, Col, Rate, Avatar, Select } from "antd";

import { CiBookmark } from "react-icons/ci";

import "./Courses.css";
import Footer from "../Components/Footer";
import HeaderNavBar from "../Components/HeaderNavbar/index";
import { useNavigate } from "react-router-dom";
import Api from "../Api";

const { Search } = Input;

const Courses = () => {
  const [filteredCourses, setFilteredCourses] = useState([]);
  // const [courseList, setCourseList] = useState([]);
  // console.log("courseList", courseList);
  const [sectors, setSectors] = useState([]);
  console.log("sectors", sectors);
  const [selectedSector, setSelectedSector] = useState("");
  console.log("selectedSector", selectedSector);
  const [domains, setDomains] = useState([]);
  console.log("domains", domains);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [selectedcategory, setSelectedCategory] = useState("");

  const [categories, setCategories] = useState([]);
  console.log("categories", categories);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchCourses = async () => {
  //     try {
  //       const response = await Api.get("/course/getAllCourses");
  //       // console.log("respoggnse", response);
  //       // const data = response?.data || [];
  //       // setCourseList(response.data);
  //       setFilteredCourses(response.data);
  //     } catch (err) {
  //       console.error("Error fetching courses:", err.message);
  //     }
  //   };

  //   fetchCourses();
  // }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await Api.get("/course/getAllCourses");
        console.log("API Response:", response.data);
        let filteredData = response.data;

        if (selectedSector) {
          filteredData = filteredData.filter(
            (course) => course.title === selectedSector
          );
        }

        // if (selectedDomain) {
        //   filteredData = filteredData.filter(
        //     (course) => course.domainId === selectedDomain
        //   );
        // }

        // if (selectedcategory) {
        //   filteredData = filteredData.filter(
        //     (course) => course.categoryId === selectedcategory
        //   );
        // }

        console.log("Filtered Data:", filteredData);
        setFilteredCourses(filteredData);
      } catch (err) {
        console.error("Error fetching courses:", err.message);
      }
    };

    fetchCourses();
  }, [selectedSector, selectedDomain, selectedcategory]);

  useEffect(() => {
    const fetchSectors = async () => {
      try {
        const response = await Api.get("/coursesector/getsector");
        setSectors(response.data);
      } catch (err) {
        console.error("Error fetching sectors:", err.message);
      }
    };

    fetchSectors();
  }, []);

  // const sectorId = sectors.map((sector) => sector._id);

  // console.log(sectorId);
  useEffect(() => {
    const fetchDomains = async () => {
      try {
        // const response = await Api.get("/coursedomain/getalldomains");
        const response = await Api.get(
          `/coursedomain/getdomain/${selectedSector}`
        );
        console.log("ahanaa", response);

        setDomains(response.data);
      } catch (error) {
        console.error("Error fetching domains:", error);
      }
    };

    fetchDomains();
  }, [selectedSector]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await Api.get(
          `/coursecategory/getcategory/${selectedDomain}`
        );
        console.log("aoaa", response);

        //     if (Array.isArray(response.data.data)) {
        //       setCategories(response.data.data);
        //     } else {
        //       console.error("Categories data is not an array");
        //       setCategories([]);
        //     }
        //   } catch (error) {
        //     console.error("Error fetching categories:", error);
        //     setCategories([]);
        //   }
        // };

        // console.log("ahanaa", response);

        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };

    fetchCategories();
  }, [selectedDomain]);

  const handleDomainChange = (event) => {
    setSelectedDomain(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleChange = (event) => {
    setSelectedSector(event.target.value);
  };

  const handleSearch = () => {
    setSelectedSector(selectedSector);
    setSelectedDomain(selectedDomain);
    setSelectedCategory(selectedcategory);
  };

  return (
    <div className="page-container">
      <HeaderNavBar />

      <div className="bus-search-bar">
        <div className="input-section">
          <div className="input-field">
            <select value={selectedSector} onChange={handleChange}>
              <option value="" disabled>
                Select Sector
              </option>
              {sectors.map((sector) => (
                <option key={sector.id} value={sector._id}>
                  {sector.sectorName}
                </option>
              ))}
            </select>
          </div>

          <div className="input-field">
            <select
              id="domain-dropdown"
              value={selectedDomain}
              onChange={handleDomainChange}
            >
              <option value=""> Select Domain </option>
              {domains.map((domain) => (
                <option key={domain.id} value={domain._id}>
                  {domain.domainName}
                </option>
              ))}
            </select>
          </div>

          <div className="input-field">
            <select
              id="category-dropdown"
              value={selectedcategory}
              onChange={handleCategoryChange}
            >
              {/* <option value=""> Select Subdomain </option>
              {Array.isArray(categories) &&
                categories.map((category) => (
                  <option key={category.id} value={category._id}>
                    {category.categoryName}
                  </option>
                ))} */}
              <option value=""> Select SubDomain </option>
              {categories.map((category) => (
                <option key={category.id} value={category._id}>
                  {category.categoryName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button className="search-button" onClick={handleSearch}>
          SEARCH COURSE
        </button>
      </div>
      <div className="course-cards-container">
        <Row gutter={[24, 24]} className="course-cards-row">
          {filteredCourses.map((course, index) => (
            <Col
              style={{ padding: "20px" }}
              key={index}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              {console.log(
                "Image URL:",
                `${process.env.REACT_APP_DEV_BASE_URL}${course.image}`
              )}
              <Card
                style={{ height: "fit-content", padding: "25px" }}
                hoverable
                className="course-card"
                cover={
                  <img
                    alt={course.title}
                    src={course.image}
                    style={{
                      width: "100%",
                      height: "175px",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                }
                onClick={() => {
                  navigate("/batchespage", { state: { id: course._id } });
                }}
              >
                <div className="card-header">
                  <Rate
                    allowHalf
                    style={{ fontSize: "20px" }}
                    defaultValue={course.rating}
                    disabled
                  />
                  <span>({course.reviews} Reviews)</span>
                  <CiBookmark className="bookmark-icon" />
                </div>
                <Card.Meta
                  title={course.title}
                  description={
                    <>
                      <p>
                        <span>
                          {course.syllabus.length} Sections |{" "}
                          {course.syllabus.reduce(
                            (totalLessons, section) =>
                              totalLessons + section.lessons.length,
                            0
                          )}{" "}
                          Lessons|
                        </span>
                        {course.enrollments} Students
                      </p>
                      <p>
                        {course.description.length > 200
                          ? course.description.slice(0, 200) + "..."
                          : course.description}
                      </p>

                      <div className="author-info">
                        <>
                          <Avatar
                            src={course.image}
                            size="small"
                            className="course-card-profileimg"
                          />
                          <span>
                            {/* By {course.batches[0].trainer.firstName} in{" "} */}
                            {course.category}
                          </span>
                        </>
                      </div>
                      <p>
                        <strong>₹{course.offer_price}</strong>{" "}
                        <del>₹{course.original_price}</del>{" "}
                        <span>
                          {course.original_price && course.offer_price
                            ? `(${Math.round(
                                ((course.original_price - course.offer_price) /
                                  course.original_price) *
                                  100
                              )}% Off)`
                            : "(No Discount)"}
                        </span>
                      </p>
                      <Button type="primary" className="learn-more-btn">
                        Learn More
                      </Button>
                    </>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default Courses;

import React, { useState, useEffect } from "react";
import { Table, Input, Button, Popconfirm, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../../Components/Css/Table.scss";

function ManageSectors() {
  const [sectors, setSectors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out" });
    fetchSectors();
  }, []);

  const fetchSectors = async () => {
    try {
      setLoading(true);
      const res = await Api.get("/coursesector/getsector");
      console.log("res", res);
      setSectors(res.data);
    } catch (err) {
      console.error(err);
      message.error("Failed to fetch sectors");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSector = async (id) => {
    try {
      setLoading(true);
      await Api.delete(`/coursesector/deletesector/${id}`);
      setSectors(sectors.filter((sector) => sector._id !== id));
      message.success("Sector deleted successfully");
    } catch (err) {
      console.error(err);
      message.error("Failed to delete sector");
    } finally {
      setLoading(false);
    }
  };

  const filteredSectors = sectors.filter((sector) =>
    sector.sectorName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {index + 1}
        </div>
      ),
    },
    {
      title: "Sector Name",
      dataIndex: "sectorName",
      key: "sectorName",
      render: (text, record, index) => (
        <div data-aos="fade-right" data-aos-delay={index * 100}>
          {text}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record, index) => (
        <div data-aos="zoom-in" data-aos-delay={index * 100}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/admin/editsector/${record._id}`)}
          />
          <Popconfirm
            title="Are you sure you want to delete this sector?"
            onConfirm={() => handleDeleteSector(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="data_list_main p-3">
      <h4 className="data_list_header">Manage Sectors</h4>
      <div
        className="data_list_top mt-4 py-4 px-3"
        style={{ backgroundColor: "#c5c5c5" }}
      >
        <Input
          className="data_list_search ps-2"
          placeholder="Search by sector name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="data_list_button"
          onClick={() => navigate("/admin/addsector")}
        >
          Add Sector
        </Button>
      </div>
      <div className="Datalist-Table mt-2">
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={filteredSectors.map((sector, index) => ({
            ...sector,
            key: sector._id,
            index,
          }))}
          loading={loading}
          pagination={{
            pageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20"],
          }}
          className="sectors-table"
        />
      </div>
    </div>
  );
}

export default ManageSectors;

import React, { useState, useEffect } from "react";
import { Table, Input, Button, message, Popconfirm } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Api from "../../../Api.js";

function ManageBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await Api.get("/adminblog/getallblogs");
        console.log("Response:", response);
        if (Array.isArray(response.data)) {
          setBlogs(response.data);
        } else {
          message.error("Failed to fetch blogs");
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
        message.error("Error fetching blogs");
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  const handleDeleteBlog = async (id) => {
    try {
      setLoading(true);
      await Api.delete(`/adminblog/${id}`);
      setBlogs(blogs.filter((blog) => blog._id !== id));
      message.success("Blog deleted successfully");
    } catch (error) {
      console.error("Error deleting blog:", error);
      message.error("Failed to delete blog");
    } finally {
      setLoading(false);
    }
  };

  const filteredBlogs = blogs.filter((blog) =>
    blog.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Blog Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        new Date(createdAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer", color: "#1890ff" }}
            onClick={() =>
              navigate(`/admin/editblog/${record._id}`, {
                state: { blogData: record._id },
              })
            }
          />

          <Popconfirm
            title="Are you sure you want to delete this blog?"
            onConfirm={() => handleDeleteBlog(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ cursor: "pointer", color: "red" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="data_list_main p-3">
      <h4 className="data_list_header">Manage Blogs</h4>
      <div
        className="data_list_top mt-4 py-4 px-3"
        style={{ backgroundColor: "#c5c5c5" }}
      >
        <Input
          className="data_list_search ps-2"
          placeholder="Search by blog title"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="data_list_button"
          onClick={() => navigate("/admin/createblog")}
        >
          Add Blog
        </Button>
      </div>
      <div className="Datalist-Table mt-2">
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={filteredBlogs.map((blog, index) => ({
            ...blog,
            key: blog._id,
            index,
          }))}
          loading={loading}
          pagination={{
            pageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20"],
          }}
          className="blogs-table"
        />
      </div>
    </div>
  );
}

export default ManageBlogs;

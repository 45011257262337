import React, { useState , useEffect} from "react";
import { NavLink } from "react-router-dom";
import { SnippetsOutlined } from "@ant-design/icons";
import { MdPerson, MdMenuBook } from "react-icons/md";

import { AiOutlineContacts } from "react-icons/ai";
import { FaBlogger, FaHandPaper } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import "../../../../Components/Css/Sidebar.scss";
import SidebarToggle from "../../../Student/DefaultLayout/SidebarToogle";
import { SiSemanticweb } from "react-icons/si";
import Api from "../../../../Api";

function Sidebar() {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
const [userData, setUserData] = useState({});
const userId = localStorage.getItem("userId");


  const toggleRightSidebar = () => {
    setIsRightSidebarCollapsed((prev) => !prev);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };
  const getName = () => {
    Api.get(`college/college_get/${userId}`)
      .then((res) => {
        setUserData(res.data);
        console.log('userData',userData)

      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
      });
  };

  useEffect(() => {
    getName();
  }, [userId]);
  
  const handleSubmenuClick = (submenu) => {
    if (submenu === activeSubmenu) {
      setActiveSubmenu(null);
      setIsRightSidebarCollapsed(true);
    } else {
      setActiveSubmenu(submenu);
      setIsRightSidebarCollapsed(false);
    }
};
   

  const role = localStorage.getItem("name");
  const id = localStorage.getItem("regid");
  const companyName = localStorage.getItem("companyName");

  const hasSubmenuItems = () => {
    return (
      activeSubmenu === "jobs" ||
      activeSubmenu === "interviewlist" ||
      activeSubmenu === "Seminar"
    );
  };

  return (
    <div
      className={`sidebar-container ${isSidebarCollapsed ? "collapsed" : ""}`}
    >
      <SidebarToggle
        toggleSidebar={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
      />

      {!isSidebarCollapsed && (
        <div className="sidebar-content">
          <div className="nav-list-sidebar">
            <NavLink
              to={`/college/companyhome`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <div className="profile-icon">
                <CgProfile />
              </div>
              <div className="profile-details">
                <div className="profile-role">{userData.collegeName}</div>
                <div className="profile-id">{id}</div>
              </div>
            </NavLink>
            <NavLink
              to={`/college/myprofile`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdPerson className="nav-icon" />
              <span className="side-title">Our Profile</span>
            </NavLink>
            <NavLink
              to={`/college/application`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <MdMenuBook className="Nav-Icon" />
              <span className="side-title">Received Applications</span>
            </NavLink>
            <NavLink
              to={`/college/blog`}
              activeClassName="active"
              className="nav-item"
              onClick={() => {
                setActiveSubmenu(null);
                setIsRightSidebarCollapsed(true);
              }}
            >
              <FaBlogger className="nav-icon" />
              <span className="side-title">Blog</span>
            </NavLink>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("jobs")}
            >
              <FaHandPaper
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Jobs</span>
            </div>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("interviewlist")}
            >
              <AiOutlineContacts
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Interview List</span>
            </div>
            <div
              className="nav-item"
              onClick={() => handleSubmenuClick("Seminar")}
            >
              <SiSemanticweb
                className="nav-icon"
                style={{ fontSize: "22px", marginLeft: "6px" }}
              />
              <span className="side-title">Seminar</span>
            </div>
          </div>
        </div>
      )}

      {hasSubmenuItems() && (
        <div
          className={`right-sidebar ${
            isRightSidebarCollapsed ? "collapsed" : ""
          }`}
        >
          <div className="right-sidebar-header">
            <div className="collapse-btn" onClick={toggleRightSidebar}>
              {isRightSidebarCollapsed ? ">" : "<"}
            </div>
          </div>

          {activeSubmenu === "jobs" && (
            <>
              <NavLink to={`/college/CreateJob`} className="submenu-item">
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Create Job</span>
              </NavLink>
              <NavLink to={`/college/viewjob`} className="submenu-item">
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">View Job</span>
              </NavLink>
              <NavLink to={`/college/form`} className="submenu-item">
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Schedule Interview</span>
              </NavLink>
            </>
          )}

          {activeSubmenu === "interviewlist" && (
            <>
              <NavLink
                to={`/college/interview/selected`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">selected</span>
              </NavLink>
              <NavLink to={`/college/interview/hold`} className="submenu-item">
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Hold</span>
              </NavLink>
              <NavLink
                to={`/college/interview/rejected`}
                className="submenu-item"
              >
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Rejected</span>
              </NavLink>
            </>
          )}

          {activeSubmenu === "Seminar" && (
            <>
              <NavLink to={`/college/seminar`} className="submenu-item">
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">Create Seminar</span>
              </NavLink>
              <NavLink to={`/college/requestseminar`} className="submenu-item">
                <SnippetsOutlined className="nav-sub-icon" />
                <span className="sub-side-title">View Seminar</span>
              </NavLink>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Sidebar;

import React, { useState, useEffect } from "react";
import { Table, Input, Button, message, Popconfirm } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Api from "../../../../../Api";
import "../../../../../Components/Css/Table.scss";

function ManageAds() {
  const [ads, setAds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAds = async () => {
      try {
        setLoading(true);
        const response = await Api.get("/adadmin/getalladss");
        if (response.data.success) {
          setAds(response.data.data);
          console.log(response.data.data)
        } else {
          message.error("Failed to fetch ads");
        }
      } catch (error) {
        console.error("Error fetching ads:", error);
        message.error("Error fetching ads");
      } finally {
        setLoading(false);
      }
    };
    fetchAds();
  }, []);

  const handleDeleteAd = async (id) => {
    try {
      setLoading(true);
      await Api.delete(`/adadmin/deleteads/${id}`);
      setAds(ads.filter((ad) => ad._id !== id));
      message.success("Ad deleted successfully");
    } catch (error) {
      console.error("Error deleting ad:", error);
      message.error("Failed to delete ad");
    } finally {
      setLoading(false);
    }
  };

  const filteredAds = ads.filter((ad) =>
    ad.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Ad Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl) => (
        <img
          src={imageUrl}
          alt="Ad"
          style={{ width: "80px", height: "50px", objectFit: "cover" }}
        />
      ),
    },
    {
      title: "Website",
      dataIndex: "websiteUrl",
      key: "websiteUrl",
      render: (websiteUrl) => {
        const truncatedUrl = websiteUrl.length > 15 ? `${websiteUrl.slice(0, 15)}...` : websiteUrl;
        return (
          <a href={websiteUrl} target="_blank" rel="noopener noreferrer" title={websiteUrl}>
            {truncatedUrl}
          </a>
        );
      },
    },
    
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        new Date(createdAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {/* <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer", color: "#1890ff" }}
            onClick={() => navigate(`/admin/adedit/${record._id}`)}
          /> */}
          <Popconfirm
            title="Are you sure you want to delete this ad?"
            onConfirm={() => handleDeleteAd(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ cursor: "pointer", color: "red" }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="data_list_main p-3">
      <h4 className="data_list_header">Manage Ads</h4>
      <div
        className="data_list_top mt-4 py-4 px-3"
        style={{ backgroundColor: "#c5c5c5" }}
      >
        <Input
          className="data_list_search ps-2"
          placeholder="Search by ad name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="data_list_button"
          onClick={() => navigate("/admin/adcreate")}
        >
          Add Ad
        </Button>
      </div>
      <div className="Datalist-Table mt-2">
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={filteredAds.map((ad, index) => ({
            ...ad,
            key: ad._id,
            index,
          }))}
          loading={loading}
          pagination={{
            pageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20"],
          }}
          className="ads-table"
        />
      </div>
    </div>
  );
}

export default ManageAds;

import React, { useState, useEffect } from 'react';
import { Table, Button, Popconfirm } from 'antd';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import Api from "../../../Api";
import { useNavigate } from "react-router-dom";
import "./AddSubCategory.css";

function SubCategory() {
  const [subCategories, setSubCategories] = useState([]);
  const [search, setSearch] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    Api.get("/coursesubcategory/getallsubcategory")
      .then((res) => {
        console.log(res.data)
        setSubCategories(res.data);
      })
      .catch((err) => {
        console.error("Error fetching subcategories:", err);
        setMessage("Error fetching subcategories.");
      });
  }, []);

  const deleteSubCategory = (id) => {
    Api.delete(`/coursesubcategory/${id}`)
      .then(() => {
        setSubCategories((prev) => prev.filter(subCategory => subCategory._id !== id));
        setMessage("Subcategory deleted successfully.");
      })
      .catch((err) => {
        console.error("Error deleting subcategory:", err);
        setMessage("Error deleting subcategory.");
      });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredSubCategories = subCategories.filter(subCategory =>
    subCategory.subcategoryName.toLowerCase().includes(search.toLowerCase())
  );

  const handleEdit = (subcategory) => {
    navigate(`/admin/editSubCategory/${subcategory._id}`, {
      state: {
        categoryName: subcategory.categoryName,
        sectorName: subcategory.sectorName,
        domainName: subcategory.domainName,
        subCategoryName: subcategory.subcategoryName,
        selectedSubCategoryId: subcategory._id
      },
    });
  };

  const columns = [
    {
      title: 'Sector',
      dataIndex: 'sectorName',
    },
    {
      title: 'Domain',
      dataIndex: 'domainName',
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
    },
    {
      title: 'Sub-Category',
      dataIndex: 'subcategoryName',
    },
    {
      title: 'Action',
      render: (_, record) => (
        <div>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteSubCategory(record._id)}
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="subcategory-container">
      <h2>Sub-Categories</h2>
      <div className="data_list_top mt-4 py-4 px-3" style={{ backgroundColor: "#c5c5c5" }}>
        <input
        className="data_list_search ps-2"
          placeholder="Search"
          value={search}
          onChange={handleSearch}
        />
        <Button
          className="data-list-button"
          onClick={() => navigate("/admin/addSubCategory")}
        >
          <BsFillPersonPlusFill />
          &nbsp;Add New
        </Button>
      </div>
      {message && <div className="message">{message}</div>}
      <Table className="Datalist-Table mt-2"
        columns={columns}
        dataSource={filteredSubCategories}
        pagination={true}
        style={{ marginTop: '20px' }}
        rowKey="_id" 
      />
    </div>
  );
}

export default SubCategory;

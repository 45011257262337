import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../../../../../Api.js";

const ManageCourses = () => {
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [uniqueDomains, setUniqueDomains] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [filters, setFilters] = useState({
    title: "",
    domain: "",
    category: "",
    minPrice: "",
    maxPrice: "",
  });
  const [loading, setLoading] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await Api.get("/course/getAllCourses");
        const coursesData = response.data;

        const domains = [...new Set(coursesData.map((course) => course.domain))];
        const categories = [
          ...new Set(coursesData.map((course) => course.category)),
        ];

        setCourses(coursesData);
        setFilteredCourses(coursesData);
        setUniqueDomains(domains);
        setUniqueCategories(categories);
      } catch (error) {
        console.error("Failed to fetch courses:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCourses();
  }, []);

  useEffect(() => {
    const filtered = courses.filter((course) => {
      const matchesTitle = course.title
        .toLowerCase()
        .includes(filters.title.toLowerCase());
      const matchesDomain = filters.domain
        ? course.domain === filters.domain
        : true;
      const matchesCategory = filters.category
        ? course.category === filters.category
        : true;
      const matchesPrice =
        (!filters.minPrice || course.offer_price >= parseInt(filters.minPrice)) &&
        (!filters.maxPrice || course.offer_price <= parseInt(filters.maxPrice));

      return matchesTitle && matchesDomain && matchesCategory && matchesPrice;
    });

    setFilteredCourses(filtered);
  }, [filters, courses]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      title: "",
      domain: "",
      category: "",
      minPrice: "",
      maxPrice: "",
    });
  };

  const handleCardClick = (courseId) => {
    navigate(`/admin/batchmanagement/${courseId}`);
  };

  return (
    <div className="container mt-5 p-4">
      <h4 className="text-center mb-4">Manage Courses</h4>

      <div className="text-end mb-3">
        <button
          className="btn btn-primary"
          onClick={() => setShowFilterModal(true)}
        >
          Open Filters
        </button>
      </div>

      {showFilterModal && (
        <div
          className="modal show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Filter Courses</h5>
                <button
                  className="btn-close"
                  onClick={() => setShowFilterModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row g-3">
                  <div className="col-md-12">
                    <label className="form-label">Search by Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Search by Title"
                      value={filters.title}
                      onChange={handleFilterChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Filter by Domain</label>
                    <select
                      className="form-select"
                      name="domain"
                      value={filters.domain}
                      style={{width:"100%"}}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Domain</option>
                      {uniqueDomains.map((domain) => (
                        <option key={domain} value={domain}>
                          {domain}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Filter by Category</label>
                    <select
                      className="form-select"
                      name="category"
                      value={filters.category}
                      style={{width:"100%"}}
                      onChange={handleFilterChange}
                    >
                      <option value="">Select Category</option>
                      {uniqueCategories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Min Price</label>
                    <input
                      type="number"
                      className="form-control"
                      name="minPrice"
                      placeholder="Min Price"
                      value={filters.minPrice}
                      onChange={handleFilterChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Max Price</label>
                    <input
                      type="number"
                      className="form-control"
                      name="maxPrice"
                      placeholder="Max Price"
                      value={filters.maxPrice}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowFilterModal(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-danger"
                  onClick={handleResetFilters}
                >
                  Reset Filters
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => setShowFilterModal(false)}
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <div className="row custom-grid d-flex column-gap-3">
          {filteredCourses.length === 0 ? (
            <div className="text-center">No courses found.</div>
          ) : (
            filteredCourses.map((course) => (
              <div
                key={course._id}
                className="col-md-3"
                onClick={() => handleCardClick(course._id)}
                style={{ cursor: "pointer", width: "300px", height: "fit-content" }}
              >
                <div className="card h-100 shadow-sm">
                  <img
                    src={course.image}
                    className="card-img-top"
                    alt={course.title}
                    style={{
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  <div className="card-body">
                    <h5 className="card-title text-truncate">{course.title}</h5>
                    <p className="card-text text-muted">
                      {course.description.length > 120
                        ? `${course.description.slice(0, 120)}...`
                        : course.description}
                    </p>
                  </div>
                  <div className="card-footer text-center">
                    <span className="text-primary">View Details</span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default ManageCourses;

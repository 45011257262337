import React, { useState, useEffect } from "react";
import { Table, Button, Input, Popconfirm } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../../../Api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const ManageBatches = () => {
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchBatches = async () => {
      try {
        const response = await Api.get(`/course/getallbatches/${id}`);
        const fetchedBatches = response.data;
        setBatches(fetchedBatches);
      } catch (error) {
        console.error("Error fetching batches:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBatches();
  }, [id]);

  const handleDeleteBatch = async (batchId) => {
    try {
      await Api.delete(`/batch/${batchId}`);
      setBatches((prev) => prev.filter((batch) => batch._id !== batchId));
    } catch (error) {
      console.error("Error deleting batch:", error);
    }
  };

  const viewAppliedStudents = (batchId) => {
    navigate(`/admin/viewappliedstudents`, { state: { batchId, courseId: id } });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Batch Name",
      dataIndex: "batch_name",
      key: "batch_name",
    },
    {
      title: "Course Name",
      dataIndex: "course_name",
      key: "course_name",
      render: (text, record) => record.course_name.title,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (startDate) => new Date(startDate).toLocaleDateString(),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (endDate) => new Date(endDate).toLocaleDateString(),
    },
    {
      title: "Max Enrollments",
      dataIndex: "max_enrollments",
      key: "max_enrollments",
    },
    {
      title: "Current Enrollments",
      dataIndex: "current_enrollments",
      key: "current_enrollments",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/admin/editbatch/${record._id}`)}
          />
          <Popconfirm
            title="Are you sure you want to delete this batch?"
            onConfirm={() => handleDeleteBatch(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </Popconfirm>
          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => viewAppliedStudents(record.batch_id)}
          >
            View Applied Students
          </Button>
        </div>
      ),
    },
  ];

  const filteredBatches = batches.filter((batch) =>
    batch.batch_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="data_list_main p-3">
      <h4 className="data_list_header">Manage Batches</h4>
      <div
        className="data_list_top mt-4 py-4 px-3"
        style={{ backgroundColor: "#c5c5c5" }}
      >
        <Input
          className="data_list_search ps-2"
          placeholder="Search by batch name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          className="data_list_button"
          onClick={() => navigate("/admin/Batch/create")}
        >
          Add Batch
        </Button>
      </div>
      <div className="Datalist-Table mt-2">
        <Table
          columns={columns}
          dataSource={filteredBatches.map((batch, index) => ({
            ...batch,
            key: batch._id,
            index,
          }))}
          loading={loading}
          pagination={{
            pageSize: 5,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20"],
          }}
          className="batches-table"
        />
      </div>
    </div>
  );
};

export default ManageBatches;
